import React from 'react';
import './App.css';

import Geocode from "react-geocode";

// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

import Login from './pages/login/login';
import Home2 from './pages/home2/home2';
import Home from './pages/home/home'

import { unregister } from './serviceWorker';
import Demo from './pages/demo/demo';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddCompany from './pages/addCompany/addCompany';
import Mailing from './pages/mailing/mailing';
import Trials from './pages/trials/trials';
import Blank from './pages/mailing/blank';
import Archives from './pages/archives/archives';
import MailingNews from './pages/mailingNews/mailingNews';

function App() {

  unregister();

  Geocode.setApiKey("AIzaSyB2V9btgJstv-Y6ruuGxBM2xIZPJHtXQiM");

  const basename = document.getElementsByTagName('base')[0].getAttribute('href');

  return (
    <Router basename={basename}>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        <Route exact path="/" component={Login} />
        {/* <Route exact path="/home" component={Home} /> */}
        <Route exact path="/home" component={Home2} />
        <Route exact path="/trials" component={Trials} />
        <Route exact path="/archive" component={Archives} />
        <Route exact path="/add" component={AddCompany} />
        <Route exact path="/demo" component={Demo} />
        <Route exact path="/mailing" component={Mailing} />
        <Route exact path="/news" component={MailingNews} />
        <Route exact path="/blank" component={Blank} />
        {/* <Route exact path="/availability" component={Availability} /> */}
        {/* <Route exact path="/availabilitySchool" component={AvailabilitySchool} />
        <Route exact path="/schedule" component={Schedule} /> */}
        {/* <Route exact path="/planner" component={Planner} /> */}
        {/* <Route exact path="/planner" component={PlannerV2} />
        <Route exact path="/employees" component={Employees} /> */}

        {/* <Route exact path="/sections" component={Sections} /> */}
        {/* <Route exact path="/functions" component={Functions} /> */}
        {/* <Route exact path="/deadlines" component={Deadlines} /> */}
        {/* <Route exact path="/shifts" component={Shifts} /> */}
        {/* <Route exact path="/times" component={Times} /> */}
        {/* <Route exact path="/breaks" component={Breaks} /> */}
        {/* <Route exact path="/trade_requests" component={TradeRequests} /> */}
        {/* <Route exact path="/available_shifts_requests" component={AvailableShiftsAdmin} /> */}
        {/* <Route exact path="/holiday_requests" component={HolidayRequests} /> */}
        {/* <Route exact path="/blocked_days" component={Blocked} /> */}
        {/* <Route exact path="/hour_registration" component={HoursRegistration} /> */}
        {/* <Route exact path="/user_hours_details" component={UserHours} /> */}
        {/* <Route exact path="/register_user" component={RegisterUser} />
        <Route exact path="/registration_code" component={RegistrationCode} />
        <Route exact path="/registration_codes" component={RegistrationCodes} />
        <Route exact path="/account" component={Account} />
        <Route exact path="/my_hours" component={MyHours} />
        <Route exact path="/holidays" component={Holidays} />
        <Route exact path="/trade" component={Trade} />
        <Route exact path="/accounts" component={Accounts} />
        <Route exact path="/password" component={Password} />
        <Route exact path="/code_register_user" component={RegisterUserCode} />
        <Route exact path="/active_users" component={ActiveUsers} />
        <Route exact path="/app_options" component={AppOptions} />
        <Route exact path="/history" component={History} />
        <Route exact path="/all_deadlines" component={DeadlinesView} /> */}
        {/* <Route exact path="/availabilities" component={Availabilities} /> */}
        {/* <Route exact path="/absences" component={Absences} /> */}
        {/* <Route exact path="/employees" component={Employees} /> */}
        {/* <Route exact path="/finances" component={Finances} /> */}
        {/* <Route exact path="/contract" component={ViewContract} /> */}
        {/* <Route exact path="/particularities" component={Particular} /> */}
        {/* <Route exact path="/absencetypes" component={Absencetypes} /> */}
        {/* <Route exact path="/skills" component={Skills} /> */}
        {/* <Route exact path="/planner_arrange" component={PlannerArrange} />
        <Route exact path="/function_arrange" component={FunctionArrange} /> */}
        {/* <Route exact path="/whats_new" component={WhatsNew} /> */}
        {/* <Route exact path="/standard_availability_requests" component={StandardAvailabilityRequests} /> */}
        {/* <Route exact path="/salaries" component={Salaries} />
        <Route exact path="/requests" component={Requests} />
        <Route exact path="/manage" component={MyCompany} />
        <Route exact path="/reports" component={Reports} /> */}

        {/* <Route exact path="/forgot_password" component={ResetPassword} />
        <Route exact path="/reset" component={ResetPassword2} />

        <Route exact path="/store_code" component={StoreCode} />

        <Route exact path="/time_registration" component={ClockSystem} />

        <Route exact path="/company_info/:storecode/:serverip/:teamid" component={InfoWebView} />

        <Route exact path="/admin/yT5AHMZNF5L6s5PT7uSKudRrUGRtUfGP" component={Admin} /> */}
      </Switch>
    </Router>
  );
}

export default App;
