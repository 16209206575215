import Constants from "../constants/Constants";



class APISendNewsMail {

    async Request(emails, companycode, week, body) {

        let response = null;

        let emailServer = 'tiemdo.com';

        try {
            response = await fetch(`https://${emailServer}/php/mail/nl/newsMail.php/`, {
                method: 'post',
                body: new URLSearchParams(`emails=${emails}&cc=${companycode}&week=${week}&body=${body}`)
            });
        } catch(err) {
            return err;
        }

        let sqlData = null;

        try {
            sqlData = await response.text(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData === "1") {
            return true;

        } else if (sqlData === "0") {
            return false;

        } else {
            return false; //data

        }

        //return null;
        }
  
}
  
// singleton
export default (new APISendNewsMail());