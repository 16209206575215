/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './sections.css';
import '../times/times.css';
import '../../../constants/DefaultStyle.css';
import '../reports/reports.scss';

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import APIAddSection from '../../../classes/APIAddSection';
import APIEditFunctionSection from '../../../classes/APIEditFunctionSection';
import APIEditSection from '../../../classes/APIEditSection';

import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';
import APIDeleteSection from '../../../classes/APIDeleteSection';
import APIDeleteFunctionSection from '../../../classes/APIDeleteFunctionSection';

class timesModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        name: '',

        functions: [],

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if(this.mode === 1) { //EDITING

      let _functions = [];

      for(const _function of this.props._functions) {

        if(_function.functie_afdeling_id === this.modalData.afdeling_id) {
          _functions.push(_function);
        }

      }

      this.setState({
        name: this.modalData.afdeling_naam,
        functions: _functions,
      });

    }

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze afdeling wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteTimes.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveTimes() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    let added = await APIAddSection.Request(
      this.state.name,
    );

    if(added >= 0) {

      for(const _function of this.state.functions) {

        await APIEditFunctionSection.Request(
          _function.functie_id,
          added,
        );

      }

    }

    if(added >= 0) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async editTimes() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    let added = await APIEditSection.Request(
      this.state.name,
      this.modalData.afdeling_id,
    );

    if(added === true) {

      let _functions = [];

      for(const _function of this.props._functions) {

        if(_function.functie_afdeling_id === this.modalData.afdeling_id) {
          _functions.push(_function);
        }

      }

      for(const oldFunction of _functions) {

        if(Constants.arrayContains(this.state.functions, oldFunction) === false) {

          added = await APIDeleteFunctionSection.Request(
            oldFunction.functie_id,
          );

        }

      }

      for(const _function of this.state.functions) {

        added = await APIEditFunctionSection.Request(
          _function.functie_id,
          this.modalData.afdeling_id,
        );

      }

    }

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async deleteTimes() {

    this.setState({loading: true});

    const added = await APIDeleteSection.Request(
      this.modalData.afdeling_id,
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  checkValidations() {

    if(this.state.name === '') {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen afdelingsnaam ingevoerd',
      });
      return false;
    }

    return true;

  }

  selectingFunction(_function) {

    this.setState({showError: false});

    if(Constants.arrayContains(this.state.functions, _function)) {

        this.deselectFunction(_function);

    } else {

        this.selectFunction(_function);

    }

  }

  selectFunction(_function) {

    let selectedFunctions = this.state.functions;

    selectedFunctions.push(_function); // be sure to clear this when adding availability

    this.setState({ functions: selectedFunctions });

  }

  deselectFunction(_function) {

      let selectedFunctions = this.state.functions;

      Constants.removeFromArray(selectedFunctions, _function);

      this.setState({ functions: selectedFunctions });

  }

  getFunctionBars() {

    let people = [];

    for(let key in this.props._functions) {

        const _function = this.props._functions[key];

        const disabled = _function.functie_afdeling_id !== null && _function.functie_afdeling_id !== this.modalData.afdeling_id;

        people.push(

            disabled === true ?

              <div className='reports_toggleButton_disabled' style={{marginTop: '0.5vw', marginLeft: '0.6vw'}}>
                <i className='far fa-times-circle' />
                <span>{_function.functie_naam}</span>
              </div>

            :
            Constants.arrayContains(this.state.functions, _function) === true ?

              <div className='reports_toggleButton_active' style={{marginTop: '0.5vw', marginLeft: '0.6vw'}} onClick={() => this.selectingFunction(_function)}>
                <i className='fas fa-check-circle' />
                <span>{_function.functie_naam}</span>
              </div>

            :

              <div className='reports_toggleButton' style={{marginTop: '0.5vw', marginLeft: '0.6vw'}} onClick={() => this.selectingFunction(_function)}>
                <i className='fal fa-circle' />
                <span>{_function.functie_naam}</span>
              </div>

        );

    }

    return people;

  }

  render() {

    const functionBars = this.getFunctionBars();

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="sectionsModal" className="modal" onClick={(e) => e.target === document.getElementById("zsectionsModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Afdeling toevoegen</p>
            
            :
            this.mode === 1 ?

              <p>Afdeling wijzigen</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Naam afdeling</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                           <input 
                            className={'inputField2'}
                            maxLength={50}
                            value={this.state.name}
                            onChange={(event) => this.setState({ name: event.target.value })}
                            placeholder={'Naam afdeling'}
                          />

                        </div>

                    </div>

                    <div className='modal-row2' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_padding'>
                          <span><b>Bijbehorende functies</b></span>
                        </div>

                        <div className='modal-rowSub2'>

                          <div style={{display: 'flex', width: '100%', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {functionBars}
                          </div>

                        </div>

                    </div>

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}

              {this.mode === 1 ?
                <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                  <span>Verwijderen</span>
                </div>
              : null}

              {this.mode === 1 ?      
                <div className='modal-submitButton' onClick={() => this.editTimes()}>
                  <span>Opslaan</span>
                </div>
              : 
                <div className='modal-submitButton' onClick={() => this.saveTimes()}>
                  <span>Toevoegen</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default timesModal;