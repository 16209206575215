/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../options/appOptions/appOptions.css';
import '../../constants/DefaultStyle.css';
import '../options/hoursRegistration/hoursRegistration.css';

//js components & classes
import Data from '../../constants/Data';

import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import APIEDIT from '../../classes/global/APIEDIT';

import DatePicker from 'react-datepicker';
import * as dateLoc from 'date-fns/locale';
import APIGET from '../../classes/global/APIGET';
import APIADD from '../../classes/global/APIADD';
import APIADDLOGIN from '../../classes/login/APIADDLOGIN';
import { toast } from 'react-toastify';
import APIGETLOGIN from '../../classes/login/APIGETLOGIN';

class ContractModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.modalMode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: true,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        name: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        zip: '',
        place: '',
        comment: '',
        state: null,

        showError: false,
        errorText: '',

    };

  }

  async componentDidMount() {

    this.setState({ loading: false, });

  }

  setDate1(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        date = null;
    }

    this.setState({
        start: date,
        showError: false,
    });

  }

  setDate2(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        date = null;
    }

    this.setState({
        end: date,  
        showError: false,
    });

  }

  selectType(event) {

    this.setState({
        state: event.target.value,
        showError: false,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  checkValidations() {

    if (Constants.isEmpty(this.state.name) === true) {
        this.setState({
          showError: true,
          errorText: `Je hebt geen naam ingevoerd`,
        });
        return false;
    }

    if (Constants.isEmpty(this.state.email) === true) {
      this.setState({
        showError: true,
        errorText: `Je hebt geen e-mail ingevoerd`,
      });
      return false;
    }

    if (Constants.isEmpty(this.state.phone) === true) {
      this.setState({
        showError: true,
        errorText: `Je hebt geen telefoon ingevoerd`,
      });
      return false;
    }

    if (Constants.isEmpty(this.state.company) === true) {
      this.setState({
        showError: true,
        errorText: `Je hebt geen bedrijfsnaam ingevoerd`,
      });
      return false;
    }

    return true;

  }

  async addContract() {

    if (this.state.loading === true || this.checkValidations() === false) {
        return;
    }

    this.setState({loading: true});

    const name = Constants.isEmpty(this.state.name) ? 'NULL' : `'${this.state.name}'`;
    const email = Constants.isEmpty(this.state.email) ? 'NULL' : `'${this.state.email}'`;
    const phone = Constants.isEmpty(this.state.phone) ? 'NULL' : `'${this.state.phone}'`;

    const company = Constants.isEmpty(this.state.company) ? 'NULL' : `'${this.state.company}'`;
    const address = Constants.isEmpty(this.state.address) ? 'NULL' : `'${this.state.address}'`;
    const zip = Constants.isEmpty(this.state.zip) ? 'NULL' : `'${this.state.zip}'`;
    const place = Constants.isEmpty(this.state.place) ? 'NULL' : `'${this.state.place}'`;

    const status = Constants.isEmpty(this.state.state) ? 'NULL' : `${this.state.state}`;

    const added = await APIADDLOGIN.Request(`INSERT INTO Aanvraag (aanvraag_naam, aanvraag_adres, aanvraag_postcode, aanvraag_plaats, aanvraag_eigenaar, aanvraag_persoonlijk_email, aanvraag_persoonlijk_telefoon, aanvraag_pakket, aanvraag_status_acp, aanvraag_datum)`, `VALUES (${company}, ${address}, ${zip}, ${place}, ${name}, ${email}, ${phone}, 3, ${status}, '${Constants.dateToString(new Date())}')`);

    if (added) {

      if (Constants.isEmpty(this.state.comment) === false) {

        const leadID = await APIGETLOGIN.Request(`SELECT aanvraag_id`, `FROM Aanvraag`, null, null, null, `ORDER BY aanvraag_id DESC LIMIT 1`);

        const date = new Date();

        await APIADDLOGIN.Request(`INSERT INTO CommentaarLead`, `VALUES (NULL, '${Data.data.accountData.account_id}', ${leadID[0].aanvraag_id}, '${this.state.comment}', '${Constants.dateToString(date)}', '${date.getHours() < 10 ? '0' + date.getHours(): date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes(): date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds(): date.getSeconds()}')`); // seconds add

      }

      toast.success('😄 Lead is toegevoegd!', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        icon: false, 
      });

    }

    this.reloadScreen();
    this.closeModal();

  }

  onChangeNumberDataFloat(what, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseFloat(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseFloat(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'hours') {

      let absAmount = this.state.hours;

      if (nr !== '') {
        absAmount = parseFloat(nr);
      } else {
        absAmount = '';
      }

      this.setState({ hours: absAmount });

    } else if (what === 'maxHours') {

        let absAmount = this.state.maxHours;
  
        if (nr !== '') {
          absAmount = parseFloat(nr);
        } else {
          absAmount = '';
        }
  
        this.setState({ maxHours: absAmount });
  
    } else if (what === 'salaryMonth') {

        let absAmount = this.state.salaryMonth;
  
        if (nr !== '') {
          absAmount = parseFloat(nr);
        } else {
          absAmount = '';
        }
  
        this.setState({ salaryMonth: absAmount });
  
    } else if (what === 'salary') {

        let absAmount = this.state.salary;
  
        if (nr !== '') {
          absAmount = parseFloat(nr);
        } else {
          absAmount = '';
        }
  
        this.setState({ salary: absAmount });
  
    // CONTRACT HOURS WORKDAY 
    } else if (what === 'monday') {
      let day = this.state.monday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ monday: day });
    } else if (what === 'tuesday') {
      let day = this.state.tuesday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ tuesday: day });
    } else if (what === 'wednesday') {
      let day = this.state.wednesday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ wednesday: day });
    } else if (what === 'thursday') {
      let day = this.state.thursday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ thursday: day });
    } else if (what === 'friday') {
      let day = this.state.friday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ friday: day });
    } else if (what === 'saturday') {
      let day = this.state.saturday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ saturday: day });
    } else if (what === 'sunday') {
      let day = this.state.sunday;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ sunday: day });
    } else if (what === 'workDayHours') {
      let day = this.state.workDayHours;
      if (nr !== '') {
        day = parseFloat(nr);
      } else {
        day = '';
      }
      this.setState({ workDayHours: day });
    }

  }

  onChangeNumberDataInt(what, min, max, event) {

    let formatted = event.target.value;

    let nr = formatted;

    if(parseInt(nr) < min && nr !== '') {
        nr = min;
    } else
    if(parseInt(nr) > max && nr !== '') {
        nr = max;
    }

    if (what === 'days') {

      let absAmount = this.state.days;

      if (nr !== '') {

        absAmount = nr;

        const days = parseInt(absAmount);
        const contractHours = Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) === false && parseFloat(Data.data.appOptions.opties_dag_werkuren) !== 0 && isNaN(parseFloat(Data.data.appOptions.opties_dag_werkuren)) === false ? parseFloat(Data.data.appOptions.opties_dag_werkuren) : 8;
        const fte = Constants.isEmpty(Data.data.appOptions.opties_fteUren) === false && parseFloat(Data.data.appOptions.opties_fteUren) !== 0 && isNaN(parseFloat(Data.data.appOptions.opties_fteUren)) === false ? parseFloat(Data.data.appOptions.opties_fteUren) : 40;

        const maxHoursYear = fte * 52;
        const vacationMaxYear = contractHours * days;

        this.onChangeNumberDataFloat('factor', 0, 1, { target: { value: vacationMaxYear / maxHoursYear } });

      } else {
        absAmount = '';
      }

      this.setState({ days: absAmount });

    }

  }

  closeContractModal() {

    this.reloadScreen();
    this.closeModal();

  }

  render() {

    return (
        <div id="contractModal" className="modal" onClick={(e) => e.target === document.getElementById("zcontractModal") ? this.closeContractModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeContractModal() : null}>&times;</span>
            <p>Lead toevoegen</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                  <div className='modal-row'>

                      <div className='modal-rowSub1'>
                          <span><b>Naam eigenaar *</b></span>
                      </div>

                      <div className='modal-rowSub2'>
                          <input
                              className={'inputField2'}
                              onChange={(event) => this.setState({ name: event.target.value })}
                              value={this.state.name}
                              placeholder={'Naam'}
                          />
                      </div>

                  </div>

                  <div className='modal-row' style={{marginTop: '1vw'}}>

                      <div className='modal-rowSub1'>
                          <span><b>E-mail eigenaar *</b></span>
                      </div>

                      <div className='modal-rowSub2'>
                          <input
                              className={'inputField2'}
                              onChange={(event) => this.setState({ email: event.target.value })}
                              value={this.state.email}
                              placeholder={'E-mail'}
                          />
                      </div>

                  </div>

                  <div className='modal-row' style={{marginTop: '1vw'}}>

                      <div className='modal-rowSub1'>
                          <span><b>Telefoon eigenaar *</b></span>
                      </div>

                      <div className='modal-rowSub2'>
                          <input
                              className={'inputField2'}
                              onChange={(event) => this.setState({ phone: event.target.value })}
                              value={this.state.phone}
                              placeholder={'Telefoon'}
                          />
                      </div>

                  </div>

                  <div className='modal-row' style={{marginTop: '2vw'}}>

                      <div className='modal-rowSub1'>
                          <span><b>Bedrijfsnaam *</b></span>
                      </div>

                      <div className='modal-rowSub2'>
                          <input
                              className={'inputField2'}
                              onChange={(event) => this.setState({ company: event.target.value })}
                              value={this.state.company}
                              placeholder={'Bedrijf'}
                          />
                      </div>

                  </div>

                  <div className='modal-row' style={{marginTop: '1vw'}}>

                      <div className='modal-rowSub1'>
                          <span><b>Adres</b></span>
                      </div>

                      <div className='modal-rowSub2'>
                          <input
                              className={'inputField2'}
                              onChange={(event) => this.setState({ address: event.target.value })}
                              value={this.state.address}
                              placeholder={'Adres'}
                          />
                      </div>

                  </div>

                  <div className='modal-row' style={{marginTop: '1vw'}}>

                      <div className='modal-rowSub1'>
                          <span><b>Postcode</b></span>
                      </div>

                      <div className='modal-rowSub2'>
                          <input
                              className={'inputField2'}
                              onChange={(event) => this.setState({ zip: event.target.value })}
                              value={this.state.zip}
                              placeholder={'Postcode'}
                          />
                      </div>

                  </div>

                  <div className='modal-row' style={{marginTop: '1vw'}}>

                      <div className='modal-rowSub1'>
                          <span><b>Plaats</b></span>
                      </div>

                      <div className='modal-rowSub2'>
                          <input
                              className={'inputField2'}
                              onChange={(event) => this.setState({ place: event.target.value })}
                              value={this.state.place}
                              placeholder={'Plaats'}
                          />
                      </div>

                  </div>

                  <div className='modal-row' style={{marginTop: '2vw'}}>

                    <div className='modal-rowSub1'>
                        <span><b>Status</b></span>
                    </div>

                    <div className='modal-rowSub2'>
                        <select 
                            name="Selecteer een status" 
                            className={'dropDownField'}
                            onChange={(event) => this.selectType(event)}
                        >
                            <option value={'0'} selected={'0' === this.state.state || null === this.state.type}>Geen</option>
                            <option value={'1'} selected={'1' === this.state.state}>Benaderd</option>
                            <option value={'2'} selected={'2' === this.state.state}>Demo in behandeling</option>
                            <option value={'3'} selected={'3' === this.state.state}>Demo gegeven</option>
                            <option value={'4'} selected={'4' === this.state.state}>Proefperiode</option>
                            <option value={'5'} selected={'5' === this.state.state}>Actief</option>
                        </select>
                    </div>

                  </div>

                  <div className='modal-row2' style={{marginTop: '1vw'}}>

                      <div className='modal-rowSub1'>
                        <span><b>Opmerking</b></span>
                        <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Max. 1000 tekens</span>
                      </div>

                      <div className='modal-rowSub2'>
                          <textarea
                            className={'inputFieldBig'}
                            onChange={(event) => this.setState({ comment: event.target.value })}
                            value={this.state.comment}
                            placeholder={'Opmerking'}
                            maxLength={1000}
                          />
                      </div>

                  </div>

                    {/* <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Begindatum</b></span>
                        </div>

                        <div className='modal-rowSub2'>
                            <DatePicker
                                selected={this.state.start}
                                onChange={(date) => this.setDate1(date)}
                                dateFormat="P"
                                className={'inputFieldTime'}
                                placeholderText={'Selecteer een datum'}
                                showWeekNumbers
                                locale={dateLoc['nl-NL'.substr(0, 2)]}
                            />
                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Einddatum</b></span>
                        </div>

                        <div className='modal-rowSub2'>
                            <DatePicker
                                selected={this.state.end}
                                minDate={this.state.start}
                                onChange={(date) => this.setDate2(date)}
                                dateFormat="P"
                                className={'inputFieldTime'}
                                placeholderText={'Selecteer een datum'}
                                showWeekNumbers
                                locale={dateLoc['nl-NL'.substr(0, 2)]}
                            />
                        </div>

                    </div>

                    <div className='modal-row' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1'>
                            <span><b>Contract</b></span>
                        </div>

                        <div className='modal-rowSub2'>
                            <select 
                                name="Selecteer een contract" 
                                className={'dropDownField'}
                                onChange={(event) => this.selectType(event)}
                            >
                                <option value={'0'} selected={'0' === this.state.type}>Flex contract (oproep, nuluren, flex)</option>
                                <option value={'1'} selected={'1' === this.state.type}>Min-max contract</option>
                                <option value={'2'} selected={'2' === this.state.type}>Vaste uren contract</option>
                            </select>
                        </div>

                    </div>

                    {this.state.type === '1' ?

                        <div>

                            <div className='modal-row' style={{marginTop: '2vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Minimale uren</b></span>
                                </div>

                                <div className='modal-rowSub2'>
                                    <input
                                        className={'inputField2'}
                                        onChange={(event) => this.onChangeNumberDataFloat('hours', 0, 168, event)}
                                        value={this.state.hours}
                                        placeholder={'Minimale contracturen'}
                                        type='number'
                                        step={0.01}
                                    />
                                </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Maximale uren</b></span>
                                </div>

                                <div className='modal-rowSub2'>
                                    <input
                                        className={'inputField2'}
                                        onChange={(event) => this.onChangeNumberDataFloat('maxHours', 0, 168, event)}
                                        value={this.state.maxHours}
                                        placeholder={'Maximale contracturen'}
                                        type='number'
                                        step={0.01}
                                    />
                                </div>

                            </div>

                        </div>

                    :
                    this.state.type === '2' ?

                      <div>

                        <div className='modal-row' style={{marginTop: '2vw'}}>

                            <div className='modal-rowSub1'>
                              <span><b>Vaste werkdagen</b></span>
                            </div>

                            <div className='modal-rowSub2'>
                              <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={this.state.defaultWorkdays}
                                    onClick={() => this.setState({ defaultWorkdays: !this.state.defaultWorkdays })}
                                    readOnly
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>

                        </div>

                        {this.state.defaultWorkdays === true ?

                          <div className='modal-row2' style={{marginTop: '1vw'}}>

                            <div className='modal-rowSub1'>
                              <span><b>Werkdagen en arbeidsduur</b></span>
                              <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Arbeidsduur per werkdag</span>
                            </div>

                            <div className='modal-rowSub2'>
                              <div className='contract_workdays_row'>
                                <div className='contract_workdays_row_sub'>
                                  <span>ma</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('monday', 0, 24, event)}
                                    value={this.state.monday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>di</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('tuesday', 0, 24, event)}
                                    value={this.state.tuesday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>wo</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('wednesday', 0, 24, event)}
                                    value={this.state.wednesday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>do</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('thursday', 0, 24, event)}
                                    value={this.state.thursday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>vr</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('friday', 0, 24, event)}
                                    value={this.state.friday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>za</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('saturday', 0, 24, event)}
                                    value={this.state.saturday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                                <div className='contract_workdays_row_sub'>
                                  <span>zo</span>
                                  <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('sunday', 0, 24, event)}
                                    value={this.state.sunday}
                                    placeholder={Constants.isEmpty(Data.data.appOptions.opties_dag_werkuren) || Data.data.appOptions.opties_dag_werkuren === '0' ? '8.00' : Data.data.appOptions.opties_dag_werkuren}
                                    type='number'
                                    style={{marginTop: '0.2vw', width: 'calc(95% - 1.4vw - 2px)'}}
                                  />
                                </div>
                              </div>
                              <span style={{marginTop: '0.2vw', fontSize: '0.8vw'}}><b>Contracturen</b>: {Constants.msToTime2(totalHours * 1000 * 60 * 60)}</span>
                            </div>

                          </div>
                      
                        : 

                          <div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                              <div className='modal-rowSub1'>
                                <span><b>Contracturen</b></span>
                              </div>

                              <div className='modal-rowSub2'>
                                <input
                                  className={'inputField2'}
                                  onChange={(event) => this.onChangeNumberDataFloat('hours', 0, 168, event)}
                                  value={this.state.hours}
                                  placeholder={'Contracturen'}
                                  type='number'
                                  step={0.01}
                                />
                              </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                              <div className='modal-rowSub1'>
                                <span><b>Arbeidsduur per werkdag</b></span>
                              </div>

                              <div className='modal-rowSub2'>
                                <input
                                  className={'inputField2'}
                                  onChange={(event) => this.onChangeNumberDataFloat('workDayHours', 0, 24, event)}
                                  value={this.state.workDayHours}
                                  placeholder={'Arbeidsduur'}
                                  type='number'
                                  step={0.01}
                                />
                              </div>

                            </div>

                          </div>
                      
                        }

                      </div>

                    : null}

                    {this.state.type === '2' ?

                        <div className='modal-row' style={{marginTop: '2vw'}}>

                            <div className='modal-rowSub1'>
                                <span><b>Maandloon</b></span>
                            </div>

                            <div className='modal-rowSub2'>
                                <input
                                    className={'inputField2'}
                                    onChange={(event) => this.onChangeNumberDataFloat('salaryMonth', 0, 999999999, event)}
                                    value={this.state.salaryMonth}
                                    placeholder={'Maandloon'}
                                    type='number'
                                    step={0.01}
                                />
                            </div>

                        </div>

                    :

                        <div>

                            <div className='modal-row2' style={{marginTop: '2vw'}}>

                                <div className='modal-rowSub1_padding'>
                                    <span><b>Loonmodel</b></span>
                                </div>

                                <div className='modal-rowSub2'>

                                    <select
                                        name="Selecteer een loonmodel"
                                        className={'dropDownField'}
                                        style={{width: '100%', fontSize: '0.8vw'}}
                                        onChange={(event) => this.selectSalaryModel(event)}
                                    >
                                        <option value={'null'} selected={this.state.salaryModel === null}>Geen</option>
                                        {this.getSalaryModelBars()}
                                    </select>

                                    <span className='defaultLinkO' style={{marginTop: 2}} onClick={() => this.props.history.push({pathname: '/app_options', menu: 'general', subMenu: 'salaries'})}>Beheer loonmodellen</span>

                                </div>

                            </div>

                            <div className='modal-row' style={{marginTop: '1vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Uurloon</b></span>
                                </div>

                                <div className='modal-rowSub2'>
                                    <input
                                        className={this.state.salaryModel !== null ? 'inputField2Disabled' : 'inputField2'}
                                        onChange={(event) => this.onChangeNumberDataFloat('salary', 0, 999999999, event)}
                                        value={this.state.salary}
                                        placeholder={'Uurloon'}
                                        type='number'
                                        step={0.01}
                                        disabled={this.state.salaryModel !== null}
                                    />
                                </div>

                            </div>

                        </div>

                    }

                    {Constants.isTrue(Data.data.appOptions.opties_verlofsaldo) ?

                        <div>

                            <div className='modal-row' style={{marginTop: '2vw'}}>

                                <div className='modal-rowSub1'>
                                    <span><b>Verlof opbouwen</b></span>
                                </div>

                                <div className='modal-rowSub2'>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={this.state.leaveBalance}
                                            onClick={() => this.setState({ leaveBalance: !this.state.leaveBalance })}
                                            readOnly
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>

                            {this.state.leaveBalance === true ?

                                <div className='modal-row2' style={{marginTop: '1vw'}}>

                                    <div className='modal-rowSub1'>
                                        <span><b>Verlofdagen</b></span>
                                    </div>

                                    <div className='modal-rowSub2'>

                                        <div className='hr_hoursBar' style={{width: '100%', fontSize: '0.8vw', fontWeight: 'bold'}}>

                                          <div className='hr_hoursBar_sub1'>
                                            <span>Saldo</span>
                                          </div>

                                          <div className='hr_hoursBar_sub3'>
                                            <span>Opbouw per uur</span>
                                          </div>

                                          <div className='hr_hoursBar_sub3'>
                                            &nbsp;
                                          </div>

                                        </div>
                                        
                                        {this.renderUserLeaveBalances()}

                                    </div>

                                </div>

                            : null}

                        </div>

                    : null} */}

                    <div style={{height: '1vw', width: '100%'}} />

                </div>

            </div>

          {this.state.loading === true ?

            <div className='modal-bottom'>

              <div className='modal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='modal-bottom'>

              {this.showError()}
   
                <div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.closeContractModal()}>
                    <span>Annuleren</span>
                </div>
            
                <div className='modal-submitButton' onClick={() => this.addContract()}>
                    <span>Toevoegen</span>
                </div>

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default ContractModal;