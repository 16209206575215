import Data from '../../constants/Data';

class APIGETInvoiceDownload {

    async Request(invoiceID) {

        let response = null;

          try {
              response = await fetch(`https://${Data.data.serverIP}/phppayments/getInvoiceDownload.php/`, {
                  method: 'post',
                  body: new URLSearchParams(`invoiceID=${invoiceID}`)
              });
          } catch(err) {
              // console.log(err);
              return 'error';
          }

          let sqlData = null;

          try {
              sqlData = await response.json(); //or response.text() //response.json()
            //   console.log(sqlData)
          } catch(err) {
              let response2 = null;
              try {
                  response2 = await fetch(`https://${Data.data.serverIP}/phppayments/getInvoiceDownload.php/`, {
                  method: 'post',
                  body: new URLSearchParams(`invoiceID=${invoiceID}`)
                  });
              } catch(err) {
                  // console.log(err);
                  return 'error';
              }
              const error = await response2.text();
            //   console.log(error);
          }

        //   return sqlData;

          return sqlData["\u0000*\u0000_values"];

        // if (sqlData === "1") {

        //     return true;

        // } else if (sqlData === "0") {

        //     return false;

        // } else {

        //     return false; //data

        // }
        // //return null;

        }
  
}
  
// singleton
export default (new APIGETInvoiceDownload());