
import Data from '../constants/Data';

class GlobalAPIGetTime {

    async Request() {

        let response = null;

        try {
            response = await fetch(`https://${Data.data.serverIP}/phpglobalcalls/getTime.php/`, {method: 'POST'});
        } catch(err) {
            return 'error';
        }

        let sqlData = null;
        let dateObject = null;

        try {
            sqlData = await response.text(); //or response.text() //response.json()
            dateObject = sqlData;

            // if(dateObject.toTimeString().substring(0, 8) !== sqlData.substring(11, 19)) {
            //     dateObject = new Date(dateObject.getTime() + (dateObject.getTimezoneOffset() * 60000));
            // }
        } catch(err) {
            await this.Request();
        }

        return dateObject;

    }
  
}
  
// singleton
export default (new GlobalAPIGetTime());