/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './home.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import APICanTradeShift from '../../classes/APICanTradeShift';

import DatePicker from 'react-datepicker';
import BetterAlert from '../../components/alert/alert';
import TradeShiftModal from './tradeShiftModal';

class HomeModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        date: Constants.stringToDate(this.modalData.rooster_datum),
        dateText: this.modalData.rooster_datum,

        startTime: null,
        startText: `00:00`,

        endTime: null,
        endText: `00:00`,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

        canTrade: false,

        showModal2: false,
        modalMode2: 0,
        modalData2: [],

    };

  }

  async componentDidMount() {

    this.setState({loading: true});

    const startHour = parseInt(this.modalData.rooster_begin.substring(0, 2));
    const startMin = parseInt(this.modalData.rooster_begin.substring(3, 5));
    const endHour = parseInt(this.modalData.rooster_eind.substring(0, 2));
    const endMin = parseInt(this.modalData.rooster_eind.substring(3, 5));

    const startTime = new Date();
    startTime.setHours(startHour, startMin);

    const endTime = new Date();
    endTime.setHours(endHour, endMin);

    this.setStartTime(startTime);
    this.setEndTime(endTime);

    const canTrade = await APICanTradeShift.Request(this.modalData.rooster_id);

    this.setState({loading: false, canTrade: canTrade});

  }

  openTradeShiftModal(mode, data) {

    this.setState({
      showModal2: true, 
      modalMode2: mode,
      modalData2: data,
    });

  }

  closeModal2() {

    this.setState({ 
      showModal2: false,
      modalData2: [],
      modalMode2: 0,
    });

  }

  setStartTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const startHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const startMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        startTime: date,
        startText: `${startHours}:${startMins}`,
        showError: false,
    });

  }

  setEndTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const endHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const endMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        endTime: date,
        endText: `${endHours}:${endMins}`,
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze geblokkeerde dag wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteBlocked.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="homeModal" className="modal" onClick={(e) => e.target === document.getElementById("zhomeModal") ? this.closeModal() : null}>

        <div className="modal-content" style={{width: '30%'}}>
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            
            <p>{Constants.getDayName(this.state.date)} {this.state.date.getDate()} {Constants.getMonthName(this.state.date)} {this.state.date.getFullYear()}</p>
            
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showModal2 === true ?
                    
                    <TradeShiftModal
                        _closeModal2={this.closeModal2.bind(this)}
                        _modalData2={this.state.modalData2}
                        _modalMode2={this.state.modalMode2}
                        _reloadScreen={this.reloadScreen}
                        history={this.props.history}
                    />

                :

                    null
                
                }

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div>Van:</div>

                    <DatePicker
                        selected={this.state.startTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Van"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        disabled
                        className={'inputFieldTime'}
                    />

                    <div style={{marginTop: 5}}>Tot:</div>

                    <DatePicker
                        selected={this.state.endTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Van"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        disabled
                        className={'inputFieldTime'}
                    />

                    <div style={{marginTop: 5}}>Pauze:</div>

                    <input 
                        className={'inputField2'}
                        disabled
                        value={this.modalData.rooster_pauze.substring(0, 5)}
                    />

                    <div style={{marginTop: 5}}>Als:</div>

                    <input 
                        className={'inputField2'}
                        disabled
                        value={this.modalData.functie_naam}
                    />

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.state.canTrade === false ?

                        <div style={{textAlign: 'center', marginTop: '3vw'}}>Je kunt deze dienst op dit moment niet ruilen</div>

                    :

                        <div style={{textAlign: 'center', marginTop: '3vw'}}>Je kunt deze dienst ruilen via de mobiele app</div>

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default HomeModal;