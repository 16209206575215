/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './login.css';
import '../planner/planner.scss';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';

import APIRegisterCheckCode from '../../classes/APIRegisterCheckCode';
import APICheckStoreCode from '../../classes/APICheckStoreCode';

import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';

class RegisterCodeModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    this.closeModal = this.props._closeModal;
    
    this.state = {

        loading: false,

        storecode: '',

        codeValid: false,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    const inputs = document.querySelectorAll('.login_passcode-area input');
    const parent = this;
    //inputs[0].focus();
    for (let elem of inputs) {
        elem.addEventListener('input', function() {
            const value = this.value;
            const nextElement = this.nextElementSibling;
            if (value === '' || !nextElement) {
                return;
            }
            parent.setState({showError: false});
            nextElement.focus();
        });
    }
    for (let elem of inputs) {
        elem.addEventListener('keydown', function(event) {
            if (event.keyCode === 39 && this.nextElementSibling !== null) {
                parent.setState({showError: false});
                this.nextElementSibling.focus();
            }
            if (event.keyCode === 37 && this.previousElementSibling !== null) {
                if(parent.state.codeValid === true) {
                    parent.setState({codeValid: false, showError: false});
                }
                this.previousElementSibling.focus();
            }
            if (event.keyCode === 8 && event.metaKey) {
                for (let innerElem of inputs) {
                    if(parent.state.codeValid === true) {
                        parent.setState({codeValid: false, showError: false});
                    }
                    innerElem.value = '';
                }
                inputs[0].focus();
            } else if (event.keyCode === 8) {
                if(elem.value === '' && this.previousElementSibling !== null) {
                    if(parent.state.codeValid === true) {
                        parent.setState({codeValid: false, showError: false});
                    }
                    this.previousElementSibling.focus();
                    return;
                }
                if(parent.state.codeValid === true) {
                    parent.setState({codeValid: false, showError: false});
                }
                elem.value = '';
            }
        });
    }

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async checkCodeLimitations(codeObject) {

    // bedrijf status check
    if(codeObject.bedrijf_status === '0') {
      this.setState({showError: true, errorText: 'Bedrijfscode kan op dit moment niet gebruikt worden. Neem contact op via www.kodict.nl'});
      return false;
    }

    // server status check
    if(codeObject.server_status === '0') {
      this.setState({showError: true, errorText: 'Er is een serverfout opgetreden. Probeer later opnieuw'});
      return false;
    }
    if(codeObject.server_status === '2') {
      this.setState({showError: true, errorText: 'De server is in migratie. Probeer later opnieuw'});
      return false;
    }
    if(codeObject.server_status === '3') {
      this.setState({showError: true, errorText: 'De server is in onderhoud. Probeer later opnieuw'});
      return false;
    }

    // is within 24 hours
    const timeNow = await Constants.getDateNow();

    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = timeNow;
    const secondDate = new Date(`${codeObject.bedrijf_proef_datum}T23:59:00`);

    const diffDays = Math.abs((firstDate - secondDate) / oneDay);

    if(diffDays > 14) {
      this.setState({showError: true, errorText: 'De proeftijd is verstreken. Upgrade jouw pakket om weer gebruik te kunnen maken van Tiempo'});
      return false;
    }

  }

  async continue() {

    if(this.state.storecode === '' || this.input1.value === '' || this.input2.value === '' || this.input3.value === '' || this.input4.value === '') {
        return;
    }

    this.setState({loading: true});

    const storecodeData = await APICheckStoreCode.Request(this.state.storecode);

    if(storecodeData !== null) {

        Data.data.serverIP = storecodeData.server_ip;

        if(await this.checkCodeLimitations(storecodeData) === false) {
            Data.data.accountData = [];
            Data.data.accountDataExtra = [];
            Data.data.loggedIn = false;
            Data.data.serverIP = '';
            Data.data.storeCode = '';
            Data.data.storeName = '';
            Data.data.storePhone = '';
            Data.data.storeAddress = '';
            Data.data.storeZip = '';
            Data.data.storeCity = '';
            Data.data.storeKvK = '';
            Data.data.pakketID = 0;
            Data.data.storeTimezone = '';
            Data.data.storeCurrency = '';
            Data.data.storeLang = '';

            localStorage.setItem('@storecode', '');
            localStorage.setItem('@username', '');
            localStorage.setItem('@password', '');

            this.setState({loading: false});
            return;
        }

        Data.data.serverIP = storecodeData.server_ip;
        Data.data.storeCode = storecodeData.bedrijf_code;

        const validCode = await APIRegisterCheckCode.Request(`${this.input1.value}${this.input2.value}${this.input3.value}${this.input4.value}`);

        if(validCode !== null) { //biem
    
            this.setState({loading: false});

            this.props.history.push({
                pathname: `/code_register_user`,
                registerObject: validCode,
                regCode: `${this.input1.value}${this.input2.value}${this.input3.value}${this.input4.value}`,
            });
    
        } else {
    
            this.setState({
                showError: true,
                errorText: 'Registratiecode is onjuist',
                loading: false,
            });
    
        } 

    } else {

        this.setState({
            showError: true,
            errorText: 'Bedrijfscode is onjuist',
            loading: false,
        });

    }

  }

  enterStoreCode(event) {

    this.setState({ storecode: event.target.value, showError: false })

    if(event.target.value !== '' && this.input1.value !== '' && this.input2.value !== '' && this.input3.value !== '' && this.input4.value !== '') {
        this.setState({codeValid: true});
    } else {
        this.setState({codeValid: false});
    }

  }

  async enterCode() {

    if(this.state.storecode !== '' && this.input1.value !== '' && this.input2.value !== '' && this.input3.value !== '' && this.input4.value !== '') {

        this.setState({codeValid: true});

    } else {

        if(this.state.codeValid === true) {

            this.setState({codeValid: false});

        }
    }

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="registerCodeModal" className="modal" onClick={(e) => e.target === document.getElementById("zregisterCodeModal") ? this.closeModal() : null}>

        <div className="modal-content" style={{height: 'auto', width: '30%'}}>
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            
              <p>Registratiecode invoeren</p>
            
          </div>
            <div className="modal-body" style={{textAlign: 'center'}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div>Vul hieronder de bedrijfscode en registratiecode in die je hebt ontvangen van jouw leidinggevende.</div>

                    <input 
                        className={'inputField2'}
                        style={{marginTop: 40}}
                        maxLength={30}
                        value={this.state.storecode}
                        onChange={(event) => this.enterStoreCode(event)}
                        placeholder={'Bedrijfscode'}
                    />

                    <div className="login_flex-box">
                        <div className="login_passcode-area">
                            <input ref={(ref) => this.input1=ref} type="text" maxLength="1" onChange={() => this.enterCode()} />
                            <input ref={(ref) => this.input2=ref} type="text" maxLength="1" onChange={() => this.enterCode()} />
                            <input ref={(ref) => this.input3=ref} type="text" maxLength="1" onChange={() => this.enterCode()} />
                            <input ref={(ref) => this.input4=ref} type="text" maxLength="1" onChange={() => this.enterCode()} />
                        </div>
                    </div>

                    <div style={{height: '1vw', width: '100%'}} />

                </div>
            
                {/* <div className='plan_modal-submit' style={{marginBottom: 0}}>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.state.codeValid === true ?

                        <div className='submitButton' onClick={() => this.continue()}>Verder</div>

                    :

                        <div className='submitButtonLoading'>Verder</div>

                    }

                    {this.showError()}

                </div> */}

            </div>

            {this.state.loading === true ?

                <div className='modal-bottom'>

                  <div className='modal-loadingButtonText'>
                    <div className="lds-dual-ring-button" />
                  </div>

                </div>

              :

                <div className='modal-bottom'>

                  {this.showError()}

                  {this.state.codeValid === true ? 
                    <div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.closeModal()}>
                      <span>Annuleren</span>
                    </div>
                  : null}

                  {this.state.codeValid === true ?    
                    <div className='modal-submitButton' onClick={() => this.continue()}>
                      <span>Verder</span>
                    </div>
                  : 
                    <div className='modal-cancelButton' onClick={() => this.closeModal()}>
                      <span>Annuleren</span>
                    </div>
                  }

                </div>

              }

        </div>

      </div>
    );
  }
  
}

export default RegisterCodeModal;