import React, {useState} from 'react';

const ServerSelectionDropdown = ({servers, onSelectionChange}) => {
    const [selectedServers, setSelectedServers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const handleServerToggle = (server) => {
        setSelectedServers(prev =>
            prev.includes(server)
                ? prev.filter(s => s !== server)
                : [...prev, server]
        );
    };

    const handleApply = () => {
        onSelectionChange(selectedServers);
        setIsOpen(false);
    };

    return (
        <div className="relative" style={{position: 'relative'}}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="dropDownField"
                style={{width: '100%', textAlign: 'left', cursor: 'pointer'}}
            >
                Selecteer Servers ({selectedServers.length})
            </button>
            {isOpen && (
                <div style={{
                    position: 'absolute',
                    zIndex: 10,
                    width: '100%',
                    marginTop: '5px',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                }}>
                    {servers.map((server) => (
                        <div key={server} style={{padding: '8px', display: 'flex', alignItems: 'center'}}>
                            <input
                                type="checkbox"
                                id={server}
                                checked={selectedServers.includes(server)}
                                onChange={() => handleServerToggle(server)}
                                style={{marginRight: '8px'}}
                            />
                            <label htmlFor={server} style={{fontSize: '14px', cursor: 'pointer'}}>
                                {server}
                            </label>
                        </div>
                    ))}
                    <div style={{padding: '8px', textAlign: 'right'}}>
                        <button
                            onClick={handleApply}
                            style={{
                                padding: '6px 12px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            Toepassen
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServerSelectionDropdown;