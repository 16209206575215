/* eslint-disable no-loop-func */
import React from 'react';

//styles
import '../startupModal/startupModal.scss';
import '../../constants/DefaultStyle.css';
import '../../pages/options/appOptions/appOptions.css';
import '../../pages/options/deadlines/deadlines.css';
import '../../pages/planner/planner.scss';
import '../../pages/options/functions/functions.css';
import '../../pages/employees/employees.css';
import '../../pages/options/salaries/salaries.css';
import '../smartPlanModal/smartPlanModal.css';
import '../../pages/options/workWeek/workWeek.css';
import '../../pages/options/hoursRegistration/hoursRegistration.css';
import '../../pages/options/myHours/myHours.css';
import '../../pages/availability/availability.css';
import '../../pages/schedule/schedule.scss';
import '../../pages/options/times/times.css';


//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import BetterAlert from '../alert/alert';
import QRCode from 'qrcode.react';
import APIRegisterAddRegistrationCode from '../../classes/APIRegisterAddRegistrationCode';

class RegistrationCodeSendModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
    });

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;

    this.code = this.makeCode(4);
    
    this.state = {

        loading: true,
        reloading: false,

        steps: [],
        curStep: 'open',

        menu: 'open',
        selectedTeam: null,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        generated: false,

    };

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    } else if(Data.data.loggedIn === true && Data.data.accountData.account_rights !== '2') {
      return;
    }

    this.calcSteps();

    this.setState({loading: false});

  }

    makeCode(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

  calcSteps() {

    let steps = {};
    
    steps['open'] = typeof this.state.steps.open !== 'undefined' ? this.state.steps.open : false;
    steps['qr'] = typeof this.state.steps.qr !== 'undefined' ? this.state.steps.qr : false;

    this.setState({ steps: steps });

  }

  async nextStep() {

    // if (await this.checkValidations() === false) {
    //   return;
    // }

    this.wentBack = false;
    this.setState({ showError: false });

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex += 1;
    if(nextIndex > keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    if (nextKey === 'qr' && this.state.generated === false) {
        await this.generateCode();
    } 

    steps[curStep] = true;

    this.selectMenu(nextKey);

    this.setState({ steps: steps, curStep: nextKey });

  }

  prevStep() {

    this.wentBack = false;
    this.setState({ showError: false });

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex -= 1;
    if(nextIndex >= keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    steps[curStep] = false;

    this.selectMenu(nextKey);

    this.setState({ steps: steps, curStep: nextKey });

  }

  async generateCode() {

    this.setState({ loading: true });

    await APIRegisterAddRegistrationCode.Request(
        this.code,
        null,
        null,
        0,
        null,
        null,
        Constants.dateToString(new Date()),
    );

    this.setState({ generated: true, loading: false });

  }

  selectMenu(menu) {

    this.setState({
      menu: menu,
    });

  }

  selectMenuManual(menu) {

    if (this.wentBack === true) {
      this.setState({
        menu: menu,
      });
      return;
    }

    this.wentBack = true;

    let curStep = this.state.curStep;
    let steps = this.state.steps;

    var keys = Object.keys( steps ),
        idIndex = keys.indexOf( curStep ),
        nextIndex = idIndex -= 1;
    if(nextIndex >= keys.length){
        //we're at the end, there is no next
        return;
    }
    var nextKey = keys[ nextIndex ];

    steps[curStep] = false;

    this.setState({
      menu: menu,
      curStep: nextKey,
      steps: steps,
    });

  }

  goBack() {

    this.props._closeModal();

  }

  closeModal2() {

    this.setState({
      showSectionsModal: false,
      showFunctionsModal: false,
      showShiftsModal: false,
      showBreaksModal: false,
      modalData: [],
      modalMode: 0,
    });
    
  }

  hasDoneStep(key) {

    const done = this.state.steps[key];

    if (typeof done === 'undefined') {
      if (key === 'open' || this.state.menu === key || this.state.subMenu === key) {
        return '';
      }
      return '_disabled';
    }

    // if (this.state.menu === 'general' && key === 'general') {
    //     return '';
    // }

    if (this.state.menu === 'open' && this.state.subMenu === key) {
      return '';
    }

    if (this.state.menu === key && this.state.menu !== 'open') {
      return '';
    }

    if (this.state.menu === key || this.state.subMenu === key) {
      if (done) {
        return '_done';
      } else {
        return '';
      }
    }

    if (done) {
      return '_done';
    }

    return '_disabled';

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="tutorialModal" className="tutmodal" onClick={(e) => e.target === document.getElementById("ztutorialModal") ? this.goBack() : null}>

        <div className="tutmodal-content" style={{minHeight: this.state.loading === true ? '15vw' : null}}>

          <div className="tutmodal-header">
              <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
              <p>Een registratiecode versturen</p>
          </div>

          {/* {this.state.loading === true ?
            <div className="lds-dual-ring loaderModal" />
          : null} */}

          <div className="tutmodal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

              {this.state.showAlert === true ?
            
                <BetterAlert 
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                />

              :

                null
          
              }

              <div className='tutmodal_menu'>

                <div className='employees_menuSubTitle'>
                  <div>Registratiecode maken</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('open')} ${this.state.menu === 'open' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('open')}>
                  <div>Open de app</div>
                </div>

                <div className={`tutmodal_menuButton${this.hasDoneStep('qr')} ${this.state.menu === 'qr' ? 'activeAv' : ''}`} onClick={() => this.selectMenuManual('qr')}>
                  <div>Scan de QR-code</div>
                </div>

              </div>

              <div className='tutmodal_content' style={this.state.menu !== 'general' && this.state.menu !== 'teams' ? {width: 'calc(87% - 1px)'} : null}>

                {this.state.loading === true ?
                
                  <div className='lds-dual-ring loaderModal' />

                :

                  <div className='tutmodal_container'>

                    {this.state.reloading === true ?
                              
                      <div className='lds-dual-ring loader3' />

                    :

                      null

                    }

                    {this.state.menu === 'open' ?

                        <div>

                            <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>QR-functie openen in de app</div>

                            <div style={{marginTop: '1vw'}}><b>Stap 1:</b> Open onze 'Kodict Tiempo' app</div>
                            <div style={{marginTop: '0.5vw'}}><b>Stap 2:</b> Druk onderin op het tabblad 'Menu'</div>
                            <div style={{marginTop: '0.5vw'}}><b>Stap 3:</b> Scroll naar het kopje 'Registratie' en druk op 'Medewerker registreren'</div>
                            <div style={{marginTop: '0.5vw'}}><b>Stap 4:</b> Druk op 'QR-code scannen'</div>

                            <div style={{marginTop: '2vw'}}>Klik hieronder op {this.state.generated === false ? <b>QR-code genereren</b> : <b>volgende</b>} als je deze stappen hebt gevolgd</div>

                        </div>

                    :
                    this.state.menu === 'qr' ?

                      <div>

                        <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginBottom: '2vw'}}>Scan de QR-code</div>

                        <div style={{marginTop: '1vw', marginBottom: '1vw'}}><b>Stap 5:</b> Scan de QR-code hieronder</div>

                        <QRCode 
                            value={`${this.code}`}
                            size={256}
                        />

                        <div style={{marginTop: '1vw'}}><b>Stap 6:</b> Deel de 4-cijferige code met een medewerker</div>

                        <div style={{marginTop: '2vw'}}>Klik hieronder op <b>voltooien</b> als je klaar bent</div>

                      </div>

                    :

                      null

                    }

                  </div>

                }

              </div>

              {/* <div style={{height: '1vw', width: '100%'}} /> */}

          </div>

          {this.state.loading === true ?

            <div className='tutmodal-bottom'>

              <div className='tutmodal-loadingButtonText'>
                <div className="lds-dual-ring-button" />
              </div>

            </div>

          :

            <div className='tutmodal-bottom'>

              {this.showError()}

                <div className='modal-deleteButton' style={{marginRight: '1.2vw'}} onClick={() => this.closeModal()}>
                  <span>Annuleren</span>
                </div>

              {this.state.menu === 'qr' ?
                <div className='modal-normalButton' onClick={() => this.closeModal()}>
                  <span>Voltooien</span>
                </div>
              :
              this.state.generated === false ?
                <div className='modal-normalButton' onClick={() => this.nextStep()}>
                  <span>QR-code genereren</span>
                </div>
              :
                <div className='modal-normalButton' onClick={() => this.nextStep()}>
                  <span>Volgende</span>
                </div>
              }

            </div>

          }

        </div>

      </div>
    );
  }
  
}

export default RegistrationCodeSendModal;