/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './times.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import APIAddDefaultShift from '../../../classes/APIAddDefaultShift';
import APIEditDefaultShift from '../../../classes/APIEditDefaultShift';
import APIDeleteDefaultShift from '../../../classes/APIDeleteDefaultShift';

import DatePicker from 'react-datepicker';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';

class timesModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        startTime: null,
        startText: '00:00',

        endTime: null,
        endText: '00:00',

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if(this.mode === 0) { //ADDING

        const startTime = new Date();
        startTime.setHours(0, 0);
    
        const endTime = new Date();
        endTime.setHours(0, 0);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);
        
    }

    if(this.mode === 1) { //EDITING

        const startHour = parseInt(this.modalData.dienst_begin.substring(0, 2));
        const startMin = parseInt(this.modalData.dienst_begin.substring(3, 5));
        const endHour = parseInt(this.modalData.dienst_eind.substring(0, 2));
        const endMin = parseInt(this.modalData.dienst_eind.substring(3, 5));

        const startTime = new Date();
        startTime.setHours(startHour, startMin);
    
        const endTime = new Date();
        endTime.setHours(endHour, endMin);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);

    }

  }

  setStartTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const startHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const startMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        startTime: date,
        startText: `${startHours}:${startMins}`,
        showError: false,
    });

  }

  setEndTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const endHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const endMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        endTime: date,
        endText: `${endHours}:${endMins}`,
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze standaard tijden wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteTimes.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveTimes() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIAddDefaultShift.Request(
      this.state.startText,
      this.state.endText,
    );

    if(added === true) {

      if (this.props._fromTut === true) {

        this.goBack();

        return;

      }

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async editTimes() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEditDefaultShift.Request(
      this.state.startText,
      this.state.endText,
      parseInt(this.modalData.dienst_id),
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async deleteTimes() {

    this.setState({loading: true});

    const added = await APIDeleteDefaultShift.Request(
      parseInt(this.modalData.dienst_id),
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  checkValidations() {

    if(this.state.startTime.getTime() === this.state.endTime.getTime()) {
        this.setState({
          showError: true,
          errorText: 'Begintijd kan niet gelijk zijn aan eindtijd',
        });
        return false;
      }
      if(this.state.startTime.getTime() > this.state.endTime.getTime()) {
          this.setState({
            showError: true,
            errorText: 'Begintijd kan niet later zijn dan eindtijd',
          });
          return false;
      }

    return true;

  }

  goBack() {

    if (this.props._fromTut === true) {

        this.props._reloadTutScreen();

    }

    this.props._closeModal();

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="timesModal" className="modal" onClick={(e) => e.target === document.getElementById("ztimesModal") ? this.goBack() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Standaard tijden toevoegen</p>
            
            :
            this.mode === 1 ?

              <p>Standaard tijden wijzigen</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div>Van:</div>

                    <DatePicker
                        selected={this.state.startTime}
                        onChange={(date) => this.setStartTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Van"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={'inputFieldTime'}
                    />

                    <div style={{marginTop: 5}}>Tot:</div>

                    <DatePicker
                        selected={this.state.endTime}
                        onChange={(date) => this.setEndTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Tot"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        className={'inputFieldTime'}
                    />

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 1 ?

                        <div>

                            <div className='submitButton' onClick={() => this.editTimes()}>Opslaan</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.deleteAlert()}>Verwijderen</div>

                        </div>

                    :

                        <div className='submitButton' onClick={() => this.saveTimes()}>Toevoegen</div>

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default timesModal;