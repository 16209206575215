import APIAddUserPlanner from '../../classes/APIAddUserPlanner';
import APIEditUserPlanner from '../../classes/APIEditUserPlanner';
import APIDeleteUserPlanner from '../../classes/APIDeleteUserPlanner';
import Data from '../../constants/Data';
import APIDeleteUsedDayShift from '../../classes/APIDeleteUsedDayShift';
import APIGetAllSchoolSchedule from '../../classes/APIGetAllSchoolSchedule';
import APIGetAllUnavailabilities from '../../classes/APIGetAllUnavailabilities';
import APIGetUserEditSchool from '../../classes/APIGetUserEditSchool';
import APIGetUserEditAvailable from '../../classes/APIGetUserEditAvailable';
import APIGetUserEditUnavailable from '../../classes/APIGetUserEditUnavailable';
import KodictNotifications from '../../constants/KodictNotifications';
import APIGET from '../../classes/global/APIGET';
import Constants from '../../constants/Constants';
import APIGetAllPlannedHoursWeek from '../../classes/APIGetAllPlannedHoursWeek';
import { max } from 'moment';
import APIADD from '../../classes/global/APIADD';
import APIAddToTeamPlanner from '../../classes/APIAddToTeamPlanner';

class Plan {

    // FROM DRAGGING AND CLICKING TO DEFAULT SHIFT
    async planUser(fromDay, toDay, date, fromPlanObject, toPlanObject) {

        if(fromDay !== toDay) {
            return;
        }

        // BREAKING CHANGES

        //const dateObject = Constants.getDateTZD(date);
        const dateObject = Constants.stringToDate(date);
        const dateString = Constants.dateToString(dateObject);

        let available = true;

        if (typeof fromPlanObject.beschikbaar_begin !== 'undefined' || typeof fromPlanObject.onbeschikbaar_begin !== 'undefined'
            || typeof fromPlanObject.schoolrooster_begin !== 'undefined') {
                available = await this.checkCorrectTimes(date, fromPlanObject, toPlanObject, toDay);
        } else {
            available = await this.checkCorrectTimesDirectly(date, fromPlanObject, toPlanObject.rooster_begin.substr(0, 5), toPlanObject.rooster_eind.substr(0, 5));
        }

        let _break = toPlanObject.rooster_pauze.substring(0,5);
        if (Data.data.appOptions.brk === 'CALC' && Constants.isEmpty(toPlanObject.rooster_datum) === false) {
            _break = await this.getBreakRule(toPlanObject.rooster_datum, toPlanObject.rooster_begin, toPlanObject.rooster_datum, toPlanObject.rooster_eind, toPlanObject);
            _break = _break.str.substr(0, 5);
        }

        //const available = await this.checkCorrectTimes(date, fromPlanObject, toPlanObject, toDay);

        // const added = await APIAddUserPlanner.Request(
        //     dateObject.getFullYear(),
        //     dateObject.getMonth() + 1,
        //     dateObject.getDate(),
        //     toPlanObject.rooster_begin.substring(0,5),
        //     toPlanObject.rooster_eind.substring(0,5),
        //     toPlanObject.functie_id,
        //     fromPlanObject.info_id,
        //     _break,
        //     available === false ? 1 : 0,
        // );

        await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${dateString}', '${toPlanObject.rooster_begin.substring(0,5)}', '${toPlanObject.rooster_eind.substring(0,5)}', ${fromPlanObject.info_id}, ${toPlanObject.functie_id === null ? 'NULL' : toPlanObject.functie_id}, 0, '${_break}', ${available === false ? 1 : 0})`);

        const added = await APIGET.Request(`SELECT rooster_id`, `FROM Rooster`, null,null,null, `ORDER BY rooster_id DESC LIMIT 1`);

        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            await APIAddToTeamPlanner.Request(Data.data.chosenTeam, parseInt(added[0].rooster_id));
        }

        // const nightShift = this.isNightShift(toPlanObject);
        // if (nightShift === true) {
        //     await APIADD.Request(`INSERT INTO RoosterNacht`, `VALUES (NULL, ${added[0].rooster_id})`);
        // }

        return parseInt(added[0].rooster_id);

    }

    //EDIT USER DIRECTLY WITH GIVEN DATA
    async editUserDirectly(start, end, planData) {

        if (start === planData.rooster_begin.substring(0, 5) && end === planData.rooster_eind.substring(0, 5)) {
            return true;
        }

        const available = await this.checkCorrectTimesDirectly(planData.rooster_datum, planData, start, end);

        let _break = planData.rooster_pauze.substring(0,5);
        if (Data.data.appOptions.brk === 'CALC' && Constants.isEmpty(planData.rooster_datum) === false) {
            _break = await this.getBreakRule(planData.rooster_datum, start, this.isNightShift2(planData), end);
            _break = _break.str.substr(0, 5);
        }

        const added = await APIEditUserPlanner.Request(
            start,
            end,
            planData.functie_id,
            planData.info_id,
            planData.rooster_id,
            _break,
            available === false ? 1 : 0,
        );

        if (added === true) {

            if(planData.rooster_publiceren === '1') {

                const dateObj = Constants.stringToDate(planData.rooster_datum);

                await KodictNotifications.sendPushNotification(
                  planData.info_id,
                  'planner',
                  'Planner',
                  `Jouw rooster is gewijzigd van ${dateObj.toLocaleDateString()} ${planData.rooster_begin.substr(0, 5)}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${planData.rooster_eind.substr(0, 5)}` : ``} naar ${dateObj.toLocaleDateString()} ${start}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${end}` : ``}. Tik hier om jouw roostermeldingen te updaten`
                );
              }

        }

        return added;

    }

    // FROM DRAGGING AND CLICKING TO DEFAULT SHIFT
    async planUserModal(date, startTime, endTime, functionID, infoID, brk, inc) {

        const dateObject = date;

        const added = await APIAddUserPlanner.Request(
            dateObject.getFullYear(),
            dateObject.getMonth() + 1,
            dateObject.getDate(),
            startTime,
            endTime,
            functionID,
            infoID,
            brk,
            inc
        );

        return added;

    }

    // EDIT
    async editUserPlanner(start, end, fid, id, rid, brk, inc) {

        const added = await APIEditUserPlanner.Request(
            start,
            end,
            fid,
            id,
            rid,
            brk,
            inc
        );

        return added;

    }

    // DELETE
    async deleteUserPlanner(data) {

        //await APIDeleteUsedDayShift.Request(data.rooster_id);

        const added = await APIDeleteUserPlanner.Request(data.rooster_id);

        if (data.rooster_publiceren === '1') {

            const dateObj = Constants.stringToDate(data.rooster_datum);

            await KodictNotifications.sendPushNotification(
              data.info_id,
              'planner',
              'Planner',
              `Je bent van het rooster verwijderd voor ${dateObj.toLocaleDateString()} ${data.rooster_begin.substr(0, 5)}${Constants.isTrue(Data.data.appOptions.opties_eindtijd_weergeven) ? ` - ${data.rooster_eind.substr(0, 5)}` : ``}. Tik hier om jouw roostermeldingen te updaten`
            );

        }

        return added;

    }

    userHasAllSkills(skillsSchedule, skillsUser) {

        let skills = null;
        let userSkills = [];
        let userHasAllSkills = true;
        if (typeof skillsSchedule !== 'undefined') {
            skills = skillsSchedule;
        }
        if (typeof skillsUser !== 'undefined') {
            userSkills = skillsUser;
        }

        if (skills !== null) {
            for (const skill of skills) {
                const userHasSkill = Constants.objectArrayContainsKey(userSkills, 'comp_id', skill.comp_id);
                if (userHasSkill === false) {
                    userHasAllSkills = false;
                    break;
                }
            }
        }

        return userHasAllSkills;

    }

    userHasSkill(skillsUser, skill) {

        let userSkills = [];
        if (typeof skillsUser !== 'undefined') {
            userSkills = skillsUser;
        }

        return Constants.objectArrayContainsKey(userSkills, 'comp_id', skill.comp_id);

    }

    canAddCumulative(maxAmount, shifts, userID, date, deleteDate) {

        const userC = shifts[userID];
        let userC2 = [];

        if (typeof userC === 'undefined' || isNaN(parseInt(maxAmount))) {
            return true;
        }

        for (const sch of userC) {
            userC2.push(sch);
        }

        let count = 0;
        let oldDate = null;
        let daysArray = userC2;
        let daysCumulative = [];
        let canAdd = true;

        // if (deleteDate !== true) {
        //     daysArray.push({ rooster_datum: date, rooster_info_id: userID });
        // }
  
        if (typeof deleteDate !== 'undefined') {
            if (deleteDate !== date) {
                daysArray.push({ rooster_datum: date, rooster_info_id: userID}); // add shift which is being planned
            }
        } else {
            daysArray.push({ rooster_datum: date, rooster_info_id: userID}); // add shift which is being planned
        }
  
        daysArray.sort((a, b) => (a.rooster_datum > b.rooster_datum) ? 1 : -1);
  
        for (const schedule of userC2) { // check above current date

            // if (deleteDate === true) {
            //     continue;
            // }

          if (typeof deleteDate !== 'undefined') {
            if (deleteDate === schedule.rooster_datum) {
                continue;
            }
          }
  
          if (oldDate === null) {
  
            oldDate = Constants.stringToDate(schedule.rooster_datum);
            daysCumulative.push(schedule);
            count++;
  
          } else {
  
            let newDate = Constants.stringToDate(schedule.rooster_datum);
            // newDate.setHours(0,0,0,0);
            // oldDate.setHours(0,0,0,0);
  
            const diffTime = Math.abs(newDate.getTime() - oldDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            
            if (diffDays > 1) {
              oldDate = newDate;
              count = 1;
              daysCumulative = [];
              daysCumulative.push(schedule);
              continue;
            }
  
            oldDate = newDate;
            count++;
            daysCumulative.push(schedule);

            if (count > maxAmount) { //count higher than max and cumulativedays got datefrom

              if (Constants.objectArrayContainsKey(daysCumulative, 'rooster_datum', date) === true) {
                canAdd = false;
                break;
              } else {
                count -= 1;
                daysCumulative.shift();
              }
  
            }
  
          }
  
        }

        return canAdd;

    }

    // checkUserPlanSettings(userSettings, userID, date, toPlan, hoursWeek, endString, cumulative) {

    //     if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
    //         return true;
    //     }

    //     console.log(typeof endString === 'undefined')

    //     const end = typeof endString !== 'undefined' ? endString : typeof toPlan.rooster_eind !== 'undefined' ? toPlan.rooster_eind : typeof toPlan.dagdienst_eind !== 'undefined' ? toPlan.dagdienst_eind : '00:00';

    //     let dateObject = Constants.getDateTZ(date, end.substr(0,5));
    //     dateObject.setSeconds(0,0);

    //     const maxDays = parseInt(userSettings.info2_max_dagen);
    //     let maxEnd = Constants.getDateTZ(date, userSettings.info2_max_eind.substr(0,5));
    //     maxEnd.setSeconds(0,0);
    //     const maxCumulative = parseInt(userSettings.info2_opeenvolgend);
    //     let daysWorked = 0;

    //     for (const person of hoursWeek) {
    //         if (parseInt(person.info_id) === parseInt(userID)) {
    //             daysWorked = parseInt(person.rooster_werkdagen);
    //             break;
    //         }
    //     }

    //     console.log(maxCumulative);

    //     console.log(userSettings.info2_max_eind.substr(0,5) !== '00:00' && end !== '00:00' && dateObject.getTime() > maxEnd.getTime(), )

    //     console.log('checkuserplansettings', date, userID, toPlan);

    //     // checks
    //     if (maxDays !== 0 && ((daysWorked >= maxDays && toPlan.info_id !== userID && date !== toPlan.rooster_datum) || (daysWorked >= maxDays && toPlan.info_id === userID && typeof endString !== 'undefined'))) {
    //         alert(`Deze medewerker mag maximaal ${maxDays} dagen per week werken`);
    //         return false;
    //     } else if (userSettings.info2_max_eind.substr(0,5) !== '00:00' && end !== '00:00' && dateObject.getTime() > maxEnd.getTime()) {
    //         alert(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken`);
    //         return false;
    //     } else if (maxCumulative !== 0 && (this.canAddCumulative(cumulative, userID, date) === false)) {
    //         alert(`Deze medewerker mag maximaal ${maxCumulative} dagen achter elkaar werken`);
    //         return false;
    //     }

    //     return true;
        
    // }

    // checkUserPlanSettings2(userSettings, userID, date, toPlan, hoursWeek, endString, cumulative) {

    //     if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
    //         return true;
    //     }

    //     const end = typeof endString !== 'undefined' ? endString : typeof toPlan.rooster_eind !== 'undefined' ? toPlan.rooster_eind : typeof toPlan.dagdienst_eind !== 'undefined' ? toPlan.dagdienst_eind : '00:00';

    //     let dateObject = Constants.getDateTZ(date, end.substr(0,5));
    //     dateObject.setSeconds(0,0);

    //     const maxDays = parseInt(userSettings.info2_max_dagen);
    //     let maxEnd = Constants.getDateTZ(date, userSettings.info2_max_eind.substr(0,5));
    //     maxEnd.setSeconds(0,0);
    //     const maxCumulative = parseInt(userSettings.info2_opeenvolgend);
    //     let daysWorked = 0;

    //     for (const person of hoursWeek) {
    //         if (parseInt(person.info_id) === parseInt(userID)) {
    //             daysWorked = parseInt(person.rooster_werkdagen);
    //             break;
    //         }
    //     }

    //     console.log('checkuserplansettings2', date, userID, toPlan);

    //     // checks
    //     if (maxDays !== 0 && ((daysWorked >= maxDays && toPlan.info_id !== userID) || (daysWorked >= maxDays && toPlan.info_id === userID && typeof endString !== 'undefined'))) {
    //         alert(`Deze medewerker mag maximaal ${maxDays} dagen per week werken`);
    //         return false;
    //     } else if (userSettings.info2_max_eind.substr(0,5) !== '00:00' && end !== '00:00' && dateObject.getTime() > maxEnd.getTime()) {
    //         alert(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken`);
    //         return false;
    //     } else if (maxCumulative !== 0 && (this.canAddCumulative(cumulative, userID, date) === false)) {
    //         alert(`Deze medewerker mag maximaal ${maxCumulative} dagen achter elkaar werken`);
    //         return false;
    //     }

    //     return true;
        
    // }

    // checkUserPlanSettingsModal(userSettings, userID, date, toPlan, hoursWeek, endString, cumulative) {

    //     if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
    //         return true;
    //     }

    //     const end = typeof endString !== 'undefined' ? endString : typeof toPlan.rooster_eind !== 'undefined' ? toPlan.rooster_eind : typeof toPlan.dagdienst_eind !== 'undefined' ? toPlan.dagdienst_eind : '00:00';

    //     let dateObject = Constants.getDateTZ(date, end.substr(0,5));
    //     dateObject.setSeconds(0,0);

    //     const maxDays = parseInt(userSettings.info2_max_dagen);
    //     let maxEnd = Constants.getDateTZ(date, userSettings.info2_max_eind.substr(0,5));
    //     maxEnd.setSeconds(0,0);
    //     const maxCumulative = parseInt(userSettings.info2_opeenvolgend);
    //     let daysWorked = 0;

    //     for (const person of hoursWeek) {
    //         if (parseInt(person.info_id) === parseInt(userID)) {
    //             daysWorked = parseInt(person.rooster_werkdagen);
    //             break;
    //         }
    //     }

    //     console.log('checkuserplansettingsmodal', date, userID, toPlan);

    //     // checks
    //     if (maxDays !== 0 && daysWorked >= maxDays) {
    //         alert(`Deze medewerker mag maximaal ${maxDays} dagen per week werken`);
    //         return false;
    //     } else if (userSettings.info2_max_eind.substr(0,5) !== '00:00' && end !== '00:00' && dateObject.getTime() > maxEnd.getTime()) {
    //         alert(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken`);
    //         return false;
    //     } else if (maxCumulative !== 0 && this.canAddCumulative(cumulative, userID, date) === false) {
    //         alert(`Deze medewerker mag maximaal ${maxCumulative} dagen achter elkaar werken`);
    //         return false;
    //     }

    //     return true;
        
    // }

    // checkUserPlanSettingsModalEdit(userSettings, userID, date, toPlan, hoursWeek, endString, cumulative) {

    //     if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
    //         return true;
    //     }

    //     const end = typeof endString !== 'undefined' ? endString : typeof toPlan.rooster_eind !== 'undefined' ? toPlan.rooster_eind : typeof toPlan.dagdienst_eind !== 'undefined' ? toPlan.dagdienst_eind : '00:00';

    //     let dateObject = Constants.getDateTZ(date, end.substr(0,5));
    //     dateObject.setSeconds(0,0);

    //     const maxDays = parseInt(userSettings.info2_max_dagen);
    //     let maxEnd = Constants.getDateTZ(date, userSettings.info2_max_eind.substr(0,5));
    //     maxEnd.setSeconds(0,0);
    //     const maxCumulative = parseInt(userSettings.info2_opeenvolgend);
    //     let daysWorked = 0;

    //     for (const person of hoursWeek) {
    //         if (parseInt(person.info_id) === parseInt(userID)) {
    //             daysWorked = parseInt(person.rooster_werkdagen);
    //             break;
    //         }
    //     }

    //     console.log('checkuserplansettingsmodaledit', date, userID, toPlan);

    //     // checks
    //     if (maxDays !== 0 && daysWorked >= maxDays && toPlan.info_id !== userID) {
    //         alert(`Deze medewerker mag maximaal ${maxDays} dagen per week werken`);
    //         return false;
    //     } else if (userSettings.info2_max_eind.substr(0,5) !== '00:00' && end !== '00:00' && dateObject.getTime() > maxEnd.getTime()) {
    //         alert(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken`);
    //         return false;
    //     } else if (maxCumulative !== 0 && this.canAddCumulative(cumulative, userID, date) === false && toPlan.info_id !== userID) {
    //         alert(`Deze medewerker mag maximaal ${maxCumulative} dagen achter elkaar werken`);
    //         return false;
    //     }

    //     return true;
        
    // }

    // checkUserPlanSettingsEdit(userSettings, userID, date, toPlan, hoursWeek, endString, cumulative, deleteDate) {

    //     if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
    //         return true;
    //     }

    //     const end = typeof endString !== 'undefined' ? endString : typeof toPlan.rooster_eind !== 'undefined' ? toPlan.rooster_eind : typeof toPlan.dagdienst_eind !== 'undefined' ? toPlan.dagdienst_eind : '00:00';

    //     let dateObject = Constants.getDateTZ(date, end.substr(0,5));
    //     dateObject.setSeconds(0,0);

    //     let maxEnd = Constants.getDateTZ(date, userSettings.info2_max_eind.substr(0,5));
    //     maxEnd.setSeconds(0,0);

    //     const maxCumulative = parseInt(userSettings.info2_opeenvolgend);

    //     console.log('checkuserplansettingsedit', toPlan.rooster_datum, date, userID, toPlan, deleteDate);

    //     //switching shifts & dates make sure to delete old date
    //     //if (date !==)

    //     // checks
    //     if (maxCumulative !== 0 && this.canAddCumulative(cumulative, userID, date, deleteDate) === false && userID !== toPlan.info_id && date !== toPlan.rooster_datum) {
    //         alert(`Deze medewerker mag maximaal ${maxCumulative} dagen achter elkaar werken`);
    //         return false;
    //     } else if (userSettings.info2_max_eind.substr(0,5) !== '00:00' && end !== '00:00' && dateObject.getTime() > maxEnd.getTime()) {
    //         alert(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken`);
    //         return false;
    //     }

    //     return true;
        
    // }

    // NEW

    checkInfoExtraAdd(userSettings, userID, date, toPlan, allPlannerData, cumulative) {

        if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
            return true;
        }

        if (typeof toPlan.count !== 'undefined' && toPlan.count !== null) {
            toPlan = toPlan.shifts[0];
        }

        const start = typeof toPlan.rooster_begin !== 'undefined' ? toPlan.rooster_begin : typeof toPlan.dagdienst_begin !== 'undefined' ? toPlan.dagdienst_begin : '00:00';
        const end = typeof toPlan.rooster_eind !== 'undefined' ? toPlan.rooster_eind : typeof toPlan.dagdienst_eind !== 'undefined' ? toPlan.dagdienst_eind : '00:00';
        const rData = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: end,
        };
        const dateObject = Constants.stringToDateTime(this.isNightShift2(rData), end.substr(0, 5));

        const rData2 = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5),
        };
        const maxEnd = Constants.stringToDateTime(this.isNightShift2(rData2), Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5));

        const maxDays = parseInt(userSettings.info2_max_dagen);
        const maxCumulative = parseInt(userSettings.info2_opeenvolgend);
        let daysWorked = 0;

        let userWorkDays = [];
        for (const key in allPlannerData) {
            for (const plan of allPlannerData[key]) {
                if (userID === plan.info_id && plan.rooster_datum !== toPlan.rooster_datum) {
                    if (Constants.getArrayItem(userWorkDays, 'rooster_datum', plan.rooster_datum) === null) {
                        userWorkDays.push(plan);
                    }
                }
            }
        }
        daysWorked = userWorkDays.length;

        let _deleteDate;
        if (typeof toPlan.rooster_datum !== 'undefined' && toPlan.rooster_datum !== date && userID === toPlan.info_id) {
            _deleteDate = toPlan.rooster_datum;
        }

        let canPlan = true;

        // checks
        if (Constants.isEmpty(maxDays) === false && maxDays !== 0 && daysWorked >= maxDays && Constants.getArrayItem(userWorkDays, 'rooster_datum', date) === null) {
            const add = window.confirm(`Deze medewerker mag maximaal ${maxDays} dagen per week werken. Wil je deze medewerker toch inplannen?`);
            canPlan = add;
        }
        if (Constants.isEmpty(maxEnd) === false && canPlan === true && Constants.isEmpty(userSettings.info2_max_eind) === false && dateObject.getTime() > maxEnd.getTime()) {
            const add = window.confirm(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken. Wil je deze medewerker toch inplannen?`);
            canPlan = add;
        }
        if (Constants.isEmpty(maxCumulative) === false && canPlan === true && maxCumulative !== 0 && this.canAddCumulative(maxCumulative, cumulative, userID, date, _deleteDate) === false) {
            const add = window.confirm(`Deze medewerker mag maximaal ${maxCumulative} dagen achter elkaar werken. Wil je deze medewerker toch inplannen?`);
            canPlan = add;
        }

        return canPlan;
        
    }

    checkInfoExtraAddDirectly(userSettings, userID, date, allPlannerData, cumulative, startString, endString) {

        if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
            return true;
        }

        const start = typeof startString !== 'undefined' ? startString : '00:00';
        const end = typeof endString !== 'undefined' ? endString : '00:00';
        const rData = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: end,
        };
        const dateObject = Constants.stringToDateTime(this.isNightShift2(rData), end.substr(0, 5));

        const rData2 = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5),
        };
        const maxEnd = Constants.stringToDateTime(this.isNightShift2(rData2), Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5));

        const maxDays = parseInt(userSettings.info2_max_dagen);
        const maxCumulative = parseInt(userSettings.info2_opeenvolgend);
        let daysWorked = 0;

        let userWorkDays = [];
        for (const key in allPlannerData) {
            for (const plan of allPlannerData[key]) {
                if (userID === plan.info_id) {
                    if (Constants.getArrayItem(userWorkDays, 'rooster_datum', plan.rooster_datum) === null) {
                        userWorkDays.push(plan);
                    }
                }
            }
        }
        daysWorked = userWorkDays.length;

        let canPlan = true;

        // checks
        if (Constants.isEmpty(maxDays) === false && maxDays !== 0 && daysWorked >= maxDays && Constants.getArrayItem(userWorkDays, 'rooster_datum', date) === null) {
            const add = window.confirm(`Deze medewerker mag maximaal ${maxDays} dagen per week werken. Wil je deze medewerker toch inplannen?`);
            canPlan = add;
        }
        if (Constants.isEmpty(maxEnd) === false && canPlan === true && Constants.isEmpty(userSettings.info2_max_eind) === false && dateObject.getTime() > maxEnd.getTime()) {
            const add = window.confirm(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken. Wil je deze medewerker toch inplannen?`);
            canPlan = add;
        }
        if (Constants.isEmpty(maxCumulative) === false && canPlan === true && maxCumulative !== 0 && this.canAddCumulative(maxCumulative, cumulative, userID, date) === false) {
            const add = window.confirm(`Deze medewerker mag maximaal ${maxCumulative} dagen achter elkaar werken. Wil je deze medewerker toch inplannen?`);
            canPlan = add;
        }

        return canPlan;
        
    }

    checkInfoExtraAddNoAlert(userSettings, userID, date, toPlan, allPlannerData, cumulative) {

        if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
            return true;
        }

        if (typeof toPlan.count !== 'undefined' && toPlan.count !== null) {
            toPlan = toPlan.shifts[0];
        }

        const start = typeof toPlan.rooster_begin !== 'undefined' ? toPlan.rooster_begin : typeof toPlan.dagdienst_begin !== 'undefined' ? toPlan.dagdienst_begin : '00:00';
        const end = typeof toPlan.rooster_eind !== 'undefined' ? toPlan.rooster_eind : typeof toPlan.dagdienst_eind !== 'undefined' ? toPlan.dagdienst_eind : '00:00';
        const rData = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: end,
        };
        const dateObject = Constants.stringToDateTime(this.isNightShift2(rData), end.substr(0, 5));

        const rData2 = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5),
        };
        const maxEnd = Constants.stringToDateTime(this.isNightShift2(rData2), Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5));

        const maxDays = parseInt(userSettings.info2_max_dagen);
        const maxCumulative = parseInt(userSettings.info2_opeenvolgend);
        let daysWorked = 0;

        let userWorkDays = [];
        for (const key in allPlannerData) {
            for (const plan of allPlannerData[key]) {
                if (userID === plan.info_id) {
                    if (Constants.getArrayItem(userWorkDays, 'rooster_datum', plan.rooster_datum) === null) {
                        userWorkDays.push(plan);
                    }
                }
            }
        }
        daysWorked = userWorkDays.length;

        let canPlan = true;

        // checks
        if (Constants.isEmpty(maxDays) === false && maxDays !== 0 && daysWorked >= maxDays && Constants.getArrayItem(userWorkDays, 'rooster_datum', date) === null) {
            //const add = window.confirm(`Deze medewerker mag maximaal ${maxDays} dagen per week werken. Wil je deze medewerker toch inplannen?`);
            canPlan = false;
        }
        if (Constants.isEmpty(maxEnd) === false && canPlan === true && Constants.isEmpty(userSettings.info2_max_eind) === false && dateObject.getTime() > maxEnd.getTime()) {
            //const add = window.confirm(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken. Wil je deze medewerker toch inplannen?`);
            canPlan = false;
        }
        if (Constants.isEmpty(maxCumulative) === false && canPlan === true && maxCumulative !== 0 && this.canAddCumulative(maxCumulative, cumulative, userID, date) === false) {
            //const add = window.confirm(`Deze medewerker mag maximaal ${maxCumulative} dagen achter elkaar werken. Wil je deze medewerker toch inplannen?`);
            canPlan = false;
        }

        return canPlan;
        
    }

    checkInfoExtraEditNoDayCheck(userSettings, userID, date, toPlan) {

        if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
            return true;
        }

        if (typeof toPlan.count !== 'undefined' && toPlan.count !== null) {
            toPlan = toPlan.shifts[0];
        }

        const start = typeof toPlan.rooster_begin !== 'undefined' ? toPlan.rooster_begin : typeof toPlan.dagdienst_begin !== 'undefined' ? toPlan.dagdienst_begin : '00:00';
        const end = typeof toPlan.rooster_eind !== 'undefined' ? toPlan.rooster_eind : typeof toPlan.dagdienst_eind !== 'undefined' ? toPlan.dagdienst_eind : '00:00';
        const rData = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: end,
        };
        const dateObject = Constants.stringToDateTime(this.isNightShift2(rData), end.substr(0, 5));

        const rData2 = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5),
        };
        const maxEnd = Constants.stringToDateTime(this.isNightShift2(rData2), Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5));

        // checks
        if (Constants.isEmpty(maxEnd) === false && Constants.isEmpty(userSettings.info2_max_eind) === false && dateObject.getTime() > maxEnd.getTime()) {
            const add = window.confirm(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken. Wil je deze medewerker toch inplannen?`);
            return add;
        }

        return true;
        
    }

    checkInfoExtraEditOtherDayOtherUser(userSettings, userID, date, toPlan, allPlannerData, cumulative, deleteDate) {

        if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
            return true;
        }

        if (typeof toPlan.count !== 'undefined' && toPlan.count !== null) {
            toPlan = toPlan.shifts[0];
        }

        const start = typeof toPlan.rooster_begin !== 'undefined' ? toPlan.rooster_begin : typeof toPlan.dagdienst_begin !== 'undefined' ? toPlan.dagdienst_begin : '00:00';
        const end = typeof toPlan.rooster_eind !== 'undefined' ? toPlan.rooster_eind : typeof toPlan.dagdienst_eind !== 'undefined' ? toPlan.dagdienst_eind : '00:00';
        const rData = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: end,
        };
        const dateObject = Constants.stringToDateTime(this.isNightShift2(rData), end.substr(0, 5));

        const rData2 = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5),
        };
        const maxEnd = Constants.stringToDateTime(this.isNightShift2(rData2), Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5));
        const maxCumulative = parseInt(userSettings.info2_opeenvolgend);

        let _deleteDate;
        if (typeof toPlan.rooster_datum !== 'undefined' && toPlan.rooster_datum !== date && userID === toPlan.info_id) {
            _deleteDate = toPlan.rooster_datum;
        }

        let canPlan = true;

        // checks
        // if (isTrading === false && Constants.isEmpty(maxDays) === false && maxDays !== 0 && daysWorked >= maxDays && Constants.getArrayItem(userWorkDays, 'rooster_datum', date) === null) {
        //     const add = window.confirm(`Deze medewerker mag maximaal ${maxDays} dagen per week werken. Wil je deze medewerker toch inplannen?`);
        //     canPlan = add;
        // }
        if (Constants.isEmpty(maxEnd) === false && canPlan === true && Constants.isEmpty(userSettings.info2_max_eind) === false && dateObject.getTime() > maxEnd.getTime()) {
            const add = window.confirm(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken. Wil je deze medewerker toch inplannen?`);
            canPlan = add;
        }
        if (Constants.isEmpty(maxCumulative) === false && canPlan === true && maxCumulative !== 0 && this.canAddCumulative(maxCumulative, cumulative, userID, date, _deleteDate) === false) {
            const add = window.confirm(`Deze medewerker mag maximaal ${maxCumulative} dagen achter elkaar werken. Wil je deze medewerker toch inplannen?`);
            canPlan = add;
        }

        return canPlan;
        
    }

    checkInfoExtraEditDirectly(userSettings, userID, date, startString, endString) {

        if (typeof userID === 'undefined' || typeof userSettings === 'undefined') {
            return true;
        }

        const start = typeof startString !== 'undefined' ? startString : '00:00';
        const end = typeof endString !== 'undefined' ? endString : '00:00';
        const rData = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: end,
        };
        const dateObject = Constants.stringToDateTime(this.isNightShift2(rData), end.substr(0, 5));

        const rData2 = {
            rooster_datum: date,
            rooster_begin: start,
            rooster_eind: Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5),
        };
        const maxEnd = Constants.stringToDateTime(this.isNightShift2(rData2), Constants.isEmpty(userSettings.info2_max_eind) ? '00:00' : userSettings.info2_max_eind.substr(0,5));

        // checks
        if (Constants.isEmpty(maxEnd) === false && Constants.isEmpty(userSettings.info2_max_eind) === false && dateObject.getTime() > maxEnd.getTime()) {
            const add = window.confirm(`Deze medewerker mag maximaal tot ${userSettings.info2_max_eind.substr(0,5)} werken. Wil je deze medewerker toch inplannen?`);
            return add;
        }

        return true;
        
    }

    async getUserIDTime(userID, date, day) { // redmark

        let [peopleSchool, schoolHoliday, peopleUnavailable, peopleAvailable] = await Promise.all([
            APIGET.Request(`SELECT info_id, Schoolrooster.*`, `FROM Schoolrooster`, `LEFT JOIN Info ON info_id = schoolrooster_info_id`, `WHERE info_id = ${userID} AND schoolrooster_dag = ${day}`, null, null),
            APIGET.Request(`SELECT info_id, Schoolvakantie.*`, `FROM Schoolvakantie`, `LEFT JOIN Info ON info_id = schoolvakantie_info_id`, `WHERE info_id = ${userID} AND '${date}' BETWEEN schoolvakantie_begin AND schoolvakantie_eind`, null, null),
            APIGET.Request(`SELECT info_id, Onbeschikbaar.*`, `FROM Onbeschikbaar`, `LEFT JOIN Info ON info_id = onbeschikbaar_info_id`, `WHERE info_id = ${userID} AND onbeschikbaar_datum = '${date}'`, null, null),
            APIGET.Request(`SELECT info_id, Beschikbaar.*`, `FROM Beschikbaar`, `LEFT JOIN Info ON info_id = beschikbaar_info_id`, `WHERE info_id = ${userID} AND beschikbaar_datum = '${date}'`, null, null)
        ]);

        if(userID === null || typeof userID === 'undefined') {
            return null;
        }
    
        let data = [];

        if (schoolHoliday.length > 0) {
            peopleSchool = [];
        }

        for(let key in peopleUnavailable) {

            const user = peopleUnavailable[key];
    
            if(user.info_id === userID || user.info_id === userID.toString()) {
                data.push({start: user.onbeschikbaar_begin, end: user.onbeschikbaar_eind, available: 'unavailable'});
            }
    
        }

        for(let key in peopleAvailable) {

            const user = peopleAvailable[key];
    
            if(user.info_id === userID || user.info_id === userID.toString()) {
                data.push({start: user.beschikbaar_begin, end: user.beschikbaar_eind, available: 'available'});
            }
    
        }

        for(let key in peopleSchool) {

            const user = peopleSchool[key];
    
            if(user.info_id === userID || user.info_id === userID.toString()) {
                data.push({start: user.schoolrooster_begin, end: user.schoolrooster_eind, available: 'school'});
            }
    
        }

        return data.length <= 0 ? null : data;
    
      }

    async checkCorrectTimes(date, from, to, day) {

        const dateObject = Constants.stringToDate(`${date}`);
        dateObject.setSeconds(0, 0);
        
        const planStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${to.rooster_begin}`);
        const planEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${to.rooster_eind}`);
        planStart.setSeconds(0, 0);
        planEnd.setSeconds(0, 0);

        if (typeof to.vu_id !== 'undefined' && to.vu_id !== null) { // verlof

            const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${to.vu_begin}`);
            const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${to.vu_eind}`);
            availStart.setSeconds(0, 0);
            availEnd.setSeconds(0, 0);

            if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                return false;
            }
            if(planStart >= availStart && planStart <= availEnd) {
                return false;
            }
            if(planEnd >= availStart && planEnd <= availEnd) {
                return false;
            }
            if(planStart <= availStart && planEnd >= availEnd) {
                return false;
            }

        } else if(Data.data.appOptions.availability === 'SCHOOL') { //school

            const person = await this.getUserIDTime(from.info_id, date, day);

            if(person !== null) {

                for(const data of person) {

                    if (data.available === 'available') {

                        if(data.start === '00:00:00' && data.end === '00:00:00') {
                            return true;
                        }

                        const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.start}`);
                        const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.end}`);
                        availStart.setSeconds(0, 0);
                        availEnd.setSeconds(0, 0);

                        // console.log(availStart)
                        // console.log(planStart)
                        // console.log(availEnd)
                        // console.log(planEnd)

                        if(availStart > planStart) {
                            return false;
                        }
                        if(availEnd < planStart) {
                            return false;
                        }
                        if(availEnd < planEnd) {
                            return false;
                        }
                        if(availStart > planEnd) {
                            return false;
                        }

                        return true;

                    }

                    if(data.available === 'unavailable') {

                        if(data.start === '00:00:00' && data.end === '00:00:00') {

                            return false;

                        } else {

                            const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.start}`);
                            const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.end}`);
                            availStart.setSeconds(0, 0);
                            availEnd.setSeconds(0, 0);

                            if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                                return false;
                            }
                            if(planStart >= availStart && planStart <= availEnd) {
                                return false;
                            }
                            if(planEnd >= availStart && planEnd <= availEnd) {
                                return false;
                            }
                            if(planStart <= availStart && planEnd >= availEnd) {
                                return false;
                            }

                        }

                    }

                    if (data.available === 'school') {

                        const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.start}`);
                        const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.end}`);
                        availStart.setSeconds(0, 0);
                        availEnd.setSeconds(0, 0);

                        if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                            return false;
                        }
                        if(planStart >= availStart && planStart <= availEnd) {
                            return false;
                        }
                        if(planEnd >= availStart && planEnd <= availEnd) {
                            return false;
                        }
                        if(planStart <= availStart && planEnd >= availEnd) {
                            return false;
                        }

                    }

                }

            }

            // if(typeof from.onbeschikbaar_begin !== 'undefined') { //unavail

            //     if(from.onbeschikbaar_begin === '00:00:00' && from.onbeschikbaar_eind === '00:00:00') {
            //         return false;
            //     }
                
            //     const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.onbeschikbaar_begin}`);
            //     const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.onbeschikbaar_eind}`);
            //     availStart.setSeconds(0, 0);
            //     availEnd.setSeconds(0, 0);

            //     if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
            //         return false;
            //     }
            //     if(planStart >= availStart && planStart <= availEnd) {
            //         return false;
            //     }
            //     if(planEnd >= availStart && planEnd <= availEnd) {
            //         return false;
            //     }
            //     if(planStart <= availStart && planEnd >= availEnd) {
            //         return false;
            //     }

            // }

            // if(typeof from.schoolrooster_begin !== 'undefined') { //school

            //     const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.schoolrooster_begin}`);
            //     const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.schoolrooster_eind}`);
            //     availStart.setSeconds(0, 0);
            //     availEnd.setSeconds(0, 0);

            //     if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
            //         return false;
            //     }
            //     if(planStart >= availStart && planStart <= availEnd) {
            //         return false;
            //     }
            //     if(planEnd >= availStart && planEnd <= availEnd) {
            //         return false;
            //     }
            //     if(planStart <= availStart && planEnd >= availEnd) {
            //         return false;
            //     }

            // }

        } else if(Data.data.appOptions.availability === 'STANDARD') { // standaard beschikbaarheid

            if(typeof from.sb_beschikbaar !== 'undefined' && from.sb_beschikbaar === '2') { //unavail

                if(from.sb_begin === '00:00:00' && from.sb_eind === '00:00:00') {
                    return false;
                }

                const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.sb_begin}`);
                const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.sb_eind}`);
                availStart.setSeconds(0, 0);
                availEnd.setSeconds(0, 0);

                if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                    return false;
                }
                if(planStart >= availStart && planStart <= availEnd) {
                    return false;
                }
                if(planEnd >= availStart && planEnd <= availEnd) {
                    return false;
                }
                if(planStart <= availStart && planEnd >= availEnd) {
                    return false;
                }

            }

            if(typeof from.sb_beschikbaar !== 'undefined' && from.sb_beschikbaar === '1') { //avail

                if(from.sb_begin === '00:00:00' && from.sb_eind === '00:00:00') {
                    return true;
                }

                const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.sb_begin}`);
                const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.sb_eind}`);
                availStart.setSeconds(0, 0);
                availEnd.setSeconds(0, 0);

                if(availStart > planStart) {
                    return false;
                }
                if(availEnd < planStart) {
                    return false;
                }
                if(availEnd < planEnd) {
                    return false;
                }
                if(availStart > planEnd) {
                    return false;
                }

            }

        } else { //aything else

            if(typeof from.onbeschikbaar_begin !== 'undefined') { //unavail

                if(from.onbeschikbaar_begin === '00:00:00' && from.onbeschikbaar_eind === '00:00:00') {
                    return false;
                }

                const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.onbeschikbaar_begin}`);
                const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.onbeschikbaar_eind}`);
                availStart.setSeconds(0, 0);
                availEnd.setSeconds(0, 0);

                if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                    return false;
                }
                if(planStart >= availStart && planStart <= availEnd) {
                    return false;
                }
                if(planEnd >= availStart && planEnd <= availEnd) {
                    return false;
                }
                if(planStart <= availStart && planEnd >= availEnd) {
                    return false;
                }

            }

            if(typeof from.beschikbaar_begin !== 'undefined') { //avail

                if(from.beschikbaar_begin === '00:00:00' && from.beschikbaar_eind === '00:00:00') {
                    return true;
                }

                const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.beschikbaar_begin}`);
                const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.beschikbaar_eind}`);
                availStart.setSeconds(0, 0);
                availEnd.setSeconds(0, 0);

                if(availStart > planStart) {
                    return false;
                }
                if(availEnd < planStart) {
                    return false;
                }
                if(availEnd < planEnd) {
                    return false;
                }
                if(availStart > planEnd) {
                    return false;
                }

            }

        }

        return true;

    }

    async checkCorrectTimesDirectly(date, planData, start, end) {

        const dateObject = Constants.stringToDate(`${date}`);
        dateObject.setSeconds(0, 0);
        
        const planStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${start}`);
        const planEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${end}`);
        planStart.setSeconds(0, 0);
        planEnd.setSeconds(0, 0);

        if (typeof planData.vu_id !== 'undefined' && planData.vu_id !== null) { // verlof

            const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${planData.vu_begin}`);
            const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${planData.vu_eind}`);
            availStart.setSeconds(0, 0);
            availEnd.setSeconds(0, 0);

            if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                return false;
            }
            if(planStart >= availStart && planStart <= availEnd) {
                return false;
            }
            if(planEnd >= availStart && planEnd <= availEnd) {
                return false;
            }
            if(planStart <= availStart && planEnd >= availEnd) {
                return false;
            }

        } else if(Data.data.appOptions.availability === 'SCHOOL') { //school

            const person = await this.getUserIDTime(planData.info_id, date, dateObject.getDay());

            if(person !== null) {

                for(const data of person) {

                    if (data.available === 'available') {

                        if(data.start === '00:00:00' && data.end === '00:00:00') {
                            return true;
                        }

                        const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.start}`);
                        const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.end}`);
                        availStart.setSeconds(0, 0);
                        availEnd.setSeconds(0, 0);

                        if(availStart > planStart) {
                            return false;
                        }
                        if(availEnd < planStart) {
                            return false;
                        }
                        if(availEnd < planEnd) {
                            return false;
                        }
                        if(availStart > planEnd) {
                            return false;
                        }

                        return true;

                    }

                    if(data.available === 'unavailable') {

                        if(data.start === '00:00:00' && data.end === '00:00:00') {

                            return false;

                        } else {

                            const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.start}`);
                            const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.end}`);
                            availStart.setSeconds(0, 0);
                            availEnd.setSeconds(0, 0);

                            if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                                return false;
                            }
                            if(planStart >= availStart && planStart <= availEnd) {
                                return false;
                            }
                            if(planEnd >= availStart && planEnd <= availEnd) {
                                return false;
                            }
                            if(planStart <= availStart && planEnd >= availEnd) {
                                return false;
                            }

                        }

                    }

                    if (data.available === 'school') {

                        const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.start}`);
                        const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${data.end}`);
                        availStart.setSeconds(0, 0);
                        availEnd.setSeconds(0, 0);

                        if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                            return false;
                        }
                        if(planStart >= availStart && planStart <= availEnd) {
                            return false;
                        }
                        if(planEnd >= availStart && planEnd <= availEnd) {
                            return false;
                        }
                        if(planStart <= availStart && planEnd >= availEnd) {
                            return false;
                        }

                    }

                }

            }

            // let from = await APIGetUserEditSchool.Request(planData.info_id, dateObject.getDay()); // redmark

            // if (from === null) {
            //     from = await APIGetUserEditUnavailable.Request(planData.info_id, date); // redmark
            // }

            // if (from !== null) {

            //     from = from[0];

            //     if(typeof from.onbeschikbaar_begin !== 'undefined') { //unavail

            //         if(from.onbeschikbaar_begin === '00:00:00' && from.onbeschikbaar_eind === '00:00:00') {
            //             return false;
            //         }
                    
            //         const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.onbeschikbaar_begin}`);
            //         const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.onbeschikbaar_eind}`);
            //         availStart.setSeconds(0, 0);
            //         availEnd.setSeconds(0, 0);

            //         if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
            //             return false;
            //         }
            //         if(planStart >= availStart && planStart <= availEnd) {
            //             return false;
            //         }
            //         if(planEnd >= availStart && planEnd <= availEnd) {
            //             return false;
            //         }
            //         if(planStart <= availStart && planEnd >= availEnd) {
            //             return false;
            //         }

            //     }

            //     if(typeof from.schoolrooster_begin !== 'undefined') { //school

            //         const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.schoolrooster_begin}`);
            //         const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.schoolrooster_eind}`);
            //         availStart.setSeconds(0, 0);
            //         availEnd.setSeconds(0, 0);

            //         if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
            //             return false;
            //         }
            //         if(planStart >= availStart && planStart <= availEnd) {
            //             return false;
            //         }
            //         if(planEnd >= availStart && planEnd <= availEnd) {
            //             return false;
            //         }
            //         if(planStart <= availStart && planEnd >= availEnd) {
            //             return false;
            //         }

            //     }

            // }

        } else if (Data.data.appOptions.availability === 'STANDARD') {

            let from2 = await APIGET.Request(`SELECT *`, `FROM StandaardBeschikbaarheid`, null, `WHERE sb_info_id = ${planData.info_id} AND sb_dag = ${dateObject.getDay()} AND sb_beschikbaar = 1`, null, null);

            if (from2.length === 0) {
                from2 = await APIGET.Request(`SELECT *`, `FROM StandaardBeschikbaarheid`, null, `WHERE sb_info_id = ${planData.info_id} AND sb_dag = ${dateObject.getDay()} AND sb_beschikbaar = 2`, null, null);
            }

            if (from2.length > 0) {

                from2 = from2[0];

                if(typeof from2.sb_beschikbaar !== 'undefined' && from2.sb_beschikbaar === '2') { //unavail

                    if(from2.sb_begin === '00:00:00' && from2.sb_eind === '00:00:00') {
                        return false;
                    }
    
                    const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from2.sb_begin}`);
                    const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from2.sb_eind}`);
                    availStart.setSeconds(0, 0);
                    availEnd.setSeconds(0, 0);
    
                    if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                        return false;
                    }
                    if(planStart >= availStart && planStart <= availEnd) {
                        return false;
                    }
                    if(planEnd >= availStart && planEnd <= availEnd) {
                        return false;
                    }
                    if(planStart <= availStart && planEnd >= availEnd) {
                        return false;
                    }
    
                }

                if(typeof from2.sb_beschikbaar !== 'undefined' && from2.sb_beschikbaar === '1') { //avail

                    if(from2.sb_begin === '00:00:00' && from2.sb_eind === '00:00:00') {
                        return true;
                    }

                    const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from2.sb_begin}`);
                    const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from2.sb_eind}`);
                    availStart.setSeconds(0, 0);
                    availEnd.setSeconds(0, 0);

                    if(availStart > planStart) {
                        return false;
                    }
                    if(availEnd < planStart) {
                        return false;
                    }
                    if(availEnd < planEnd) {
                        return false;
                    }
                    if(availStart > planEnd) {
                        return false;
                    }

                }

            }

        } else { //aything else

            let from = await APIGetUserEditAvailable.Request(planData.info_id, date); // redmark

            if (from === null) {
                from = await APIGetUserEditUnavailable.Request(planData.info_id, date); // redmark
            }

            if (from !== null) {

                from = from[0];

                if(typeof from.onbeschikbaar_begin !== 'undefined') { //unavail

                    if(from.onbeschikbaar_begin === '00:00:00' && from.onbeschikbaar_eind === '00:00:00') {
                        return false;
                    }
    
                    const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.onbeschikbaar_begin}`);
                    const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.onbeschikbaar_eind}`);
                    availStart.setSeconds(0, 0);
                    availEnd.setSeconds(0, 0);
    
                    if((planStart >= availStart && planStart <= availEnd) && (planEnd >= availStart && planEnd <= availEnd)) {
                        return false;
                    }
                    if(planStart >= availStart && planStart <= availEnd) {
                        return false;
                    }
                    if(planEnd >= availStart && planEnd <= availEnd) {
                        return false;
                    }
                    if(planStart <= availStart && planEnd >= availEnd) {
                        return false;
                    }
    
                }

                if(typeof from.beschikbaar_begin !== 'undefined') { //avail

                    if(from.beschikbaar_begin === '00:00:00' && from.beschikbaar_eind === '00:00:00') {
                        return true;
                    }

                    const availStart = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.beschikbaar_begin}`);
                    const availEnd = new Date(`${dateObject.getFullYear()}-${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}-${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}T${from.beschikbaar_eind}`);
                    availStart.setSeconds(0, 0);
                    availEnd.setSeconds(0, 0);

                    if(availStart > planStart) {
                        return false;
                    }
                    if(availEnd < planStart) {
                        return false;
                    }
                    if(availEnd < planEnd) {
                        return false;
                    }
                    if(availStart > planEnd) {
                        return false;
                    }

                }

            }

        }

        return true;

    }

    async getBreakRule(startDate, startTime, endDate, endTime, schedule = null) {

        const start = Constants.stringToDateTime(startDate, startTime);
        const end = Constants.stringToDateTime(schedule !== null ? this.isNightShift2(schedule) : endDate, endTime);

        const totalMS = end.getTime() - start.getTime();
        const totalHours = totalMS / 1000 / 60 / 60;

        const breakRules = await APIGET.Request(`SELECT *`, `FROM PauzeRegel`, null, null, null, `ORDER BY pr_uren ASC`);

        let brk = 0;
        for (const br of breakRules) {

            if (totalHours >= parseFloat(br.pr_uren)) {
            brk = parseInt(br.pr_pauze);
            }

        }

        if (brk > 0) {
            brk = brk * 60 * 1000;
        }

        return {
            ms: brk,
            str: Constants.msToTime(brk),
        };

    }

    isNightShift(rData) {

        if (Constants.isEmpty(rData) === false) {

            const start = Constants.stringToDateTime(rData.rooster_datum, rData.rooster_begin);
            const end = Constants.stringToDateTime(rData.rooster_datum, rData.rooster_eind);

            if (end <= start) {
                return true;
            }

            return false;

        }

        return false;

    }

    isNightShift2(rData, hoursRegistration = false) {

        if (Constants.isEmpty(rData) === false) {

            const date = Constants.stringToDate(rData.rooster_datum);

            if (hoursRegistration === true) {

                const start = Constants.stringToDateTime(rData.rooster_datum, rData.uren_begin);
                const end = Constants.stringToDateTime(rData.rooster_datum, rData.uren_eind);

                if (end <= start) {
                    return Constants.dateToString(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1));
                    //return true;
                }

            } else {

                const start = Constants.stringToDateTime(rData.rooster_datum, rData.rooster_begin);
                const end = Constants.stringToDateTime(rData.rooster_datum, rData.rooster_eind);

                if (end <= start) {
                    return Constants.dateToString(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1));
                    //return true;
                }

            }

            return Constants.dateToString(date);

        }

        return null;

    }

    bonusIsNightShift(bData, schedule = false) {

        if (Constants.isEmpty(bData) === false) {

            let date = new Date();

            if (schedule === true) {

                date = Constants.stringToDate(bData.rooster_datum);

                const start = Constants.stringToDateTime(bData.rooster_datum, bData.t_begin);
                const end = Constants.stringToDateTime(bData.rooster_datum, bData.t_eind);

                if (end <= start) {
                    return Constants.dateToString(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1));
                    //return true;
                }

            } else {

                date = Constants.stringToDate(bData.t_datum);

                const start = Constants.stringToDateTime(bData.t_datum, bData.t_begin);
                const end = Constants.stringToDateTime(bData.t_datum, bData.t_eind);

                if (end <= start) {
                    return Constants.dateToString(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1));
                    //return true;
                }

            }

            return Constants.dateToString(date);

        }

        return null;

    }

    calcBonus2(rData, seconds, rooster_bonus, bonus) {

        let netSeconds = 0;
        let oldSeconds = seconds;

        let usedDate = false;
        let usedDay = false;

        const planStart = Constants.stringToDateTime(rData.rooster_datum, rData.rooster_begin.substr(0, 5));
        const planEnd = Constants.stringToDateTime(this.isNightShift2(rData), rData.rooster_eind.substr(0, 5));

        const planDay = planStart.getDay().toString();

        for (let b of bonus) {

            let hasBonus = false;

            b.rooster_datum = rData.rooster_datum;

            let bonusStart = Constants.stringToDateTime(b.rooster_datum, b.t_begin.substr(0, 5));
            let bonusEnd = Constants.stringToDateTime(this.bonusIsNightShift(b, true), b.t_eind.substr(0, 5));

            const factor = isNaN(parseFloat(b.t_factor)) ? 0 : parseFloat(b.t_factor);

            const key = `${parseFloat(b.t_factor) * 100}`;

            // DATE
            if (Constants.isEmpty(b.t_datum) === false) {

                if (usedDate === false && rData.rooster_datum === b.t_datum) {

                    bonusStart = Constants.stringToDateTime(b.t_datum, b.t_begin.substr(0, 5));
                    bonusEnd = Constants.stringToDateTime(this.bonusIsNightShift(b), b.t_eind.substr(0, 5));

                    usedDate = true;
                    hasBonus = true;

                }

            } else if (Constants.isEmpty(b.t_dag) === false) {

                if (usedDay === false && planDay === b.t_dag) {

                    usedDay = true;
                    hasBonus = true;

                }

            } else if (Constants.isEmpty(b.t_elkedag) === false) {

                hasBonus = true;

            }

            // CALC
            if (hasBonus === true) {

                if (planStart >= bonusStart && planEnd <= bonusEnd) {

                    netSeconds += oldSeconds;
                    rooster_bonus[key] += oldSeconds;
                    oldSeconds = 0;

                } else if (planStart < bonusStart && planEnd > bonusEnd) {

                    let bonusSeconds = (bonusEnd.getTime() - bonusStart.getTime()) / 1000;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds;
                    rooster_bonus[key] += bonusSeconds;
                    oldSeconds -= bonusSeconds;

                } else if (planStart < bonusStart && (planEnd > bonusStart && planEnd <= bonusEnd)) {

                    const normalSeconds = (bonusStart.getTime() - planStart.getTime()) / 1000;
                    let bonusSeconds = oldSeconds - normalSeconds;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds;
                    rooster_bonus[key] += bonusSeconds;
                    oldSeconds -= bonusSeconds;

                } else if (planEnd > bonusEnd && (planStart >= bonusStart && planStart < bonusEnd)) {

                    const normalSeconds = (planEnd.getTime() - bonusEnd.getTime()) / 1000;
                    let bonusSeconds = oldSeconds - normalSeconds;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds;
                    rooster_bonus[key] += bonusSeconds;
                    oldSeconds -= bonusSeconds;

                }

            }

            if (oldSeconds <= 0) {
                break;
            }
 
        }

        return rooster_bonus;

    }

    calcBonusActual2(rData, seconds, uren_bonus, bonus) {

        let netSeconds = 0;
        let oldSeconds = seconds;

        let usedDate = false;
        let usedDay = false;

        const planStart = Constants.stringToDateTime(rData.uren_begindatum, rData.uren_begin.substr(0, 5));
        const planEnd = Constants.stringToDateTime(rData.uren_einddatum, rData.uren_eind.substr(0, 5));

        const planDay = planStart.getDay().toString();

        for (let b of bonus) {

            let hasBonus = false;

            b.rooster_datum = rData.uren_begindatum;

            let bonusStart = Constants.stringToDateTime(b.rooster_datum, b.t_begin.substr(0, 5));
            let bonusEnd = Constants.stringToDateTime(this.bonusIsNightShift(b, true), b.t_eind.substr(0, 5));

            const factor = isNaN(parseFloat(b.t_factor)) ? 0 : parseFloat(b.t_factor);

            const key = `${parseFloat(b.t_factor) * 100}`;

            // DATE
            if (Constants.isEmpty(b.t_datum) === false) {

                if (usedDate === false && rData.uren_begindatum === b.t_datum) {

                    bonusStart = Constants.stringToDateTime(b.t_datum, b.t_begin.substr(0, 5));
                    bonusEnd = Constants.stringToDateTime(this.bonusIsNightShift(b), b.t_eind.substr(0, 5));

                    usedDate = true;
                    hasBonus = true;

                }

            } else if (Constants.isEmpty(b.t_dag) === false) {

                if (usedDay === false && planDay === b.t_dag) {

                    usedDay = true;
                    hasBonus = true;

                }

            } else if (Constants.isEmpty(b.t_elkedag) === false) {

                hasBonus = true;

            }

            // CALC
            if (hasBonus === true) {

                if (planStart >= bonusStart && planEnd <= bonusEnd) {

                    netSeconds += oldSeconds;
                    uren_bonus[key] += oldSeconds;
                    oldSeconds = 0;

                } else if (planStart < bonusStart && planEnd > bonusEnd) {

                    let bonusSeconds = (bonusEnd.getTime() - bonusStart.getTime()) / 1000;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds;
                    uren_bonus[key] += bonusSeconds;
                    oldSeconds -= bonusSeconds;

                } else if (planStart < bonusStart && (planEnd > bonusStart && planEnd <= bonusEnd)) {

                    const normalSeconds = (bonusStart.getTime() - planStart.getTime()) / 1000;
                    let bonusSeconds = oldSeconds - normalSeconds;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds;
                    uren_bonus[key] += bonusSeconds;
                    oldSeconds -= bonusSeconds;

                } else if (planEnd > bonusEnd && (planStart >= bonusStart && planStart < bonusEnd)) {

                    const normalSeconds = (planEnd.getTime() - bonusEnd.getTime()) / 1000;
                    let bonusSeconds = oldSeconds - normalSeconds;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds;
                    uren_bonus[key] += bonusSeconds;
                    oldSeconds -= bonusSeconds;

                }

            }

            if (oldSeconds <= 0) {
                break;
            }
 
        }

        return uren_bonus;

    }

    calcBonus(rData, seconds, bonus) {

        let netSeconds = 0;
        let oldSeconds = seconds;

        let usedDate = false;
        let usedDay = false;

        const planStart = Constants.stringToDateTime(rData.rooster_datum, rData.rooster_begin.substr(0, 5));
        const planEnd = Constants.stringToDateTime(this.isNightShift2(rData), rData.rooster_eind.substr(0, 5));

        const planDay = planStart.getDay().toString();

        for (let b of bonus) {

            let hasBonus = false;

            b.rooster_datum = rData.rooster_datum;

            let bonusStart = Constants.stringToDateTime(b.rooster_datum, b.t_begin.substr(0, 5));
            let bonusEnd = Constants.stringToDateTime(this.bonusIsNightShift(b, true), b.t_eind.substr(0, 5));

            const factor = isNaN(parseFloat(b.t_factor)) ? 0 : parseFloat(b.t_factor);

            // DATE
            if (Constants.isEmpty(b.t_datum) === false) {

                if (usedDate === false && rData.rooster_datum === b.t_datum) {

                    bonusStart = Constants.stringToDateTime(b.t_datum, b.t_begin.substr(0, 5));
                    bonusEnd = Constants.stringToDateTime(this.bonusIsNightShift(b), b.t_eind.substr(0, 5));

                    usedDate = true;
                    hasBonus = true;

                }

            } else if (Constants.isEmpty(b.t_dag) === false) {

                if (usedDay === false && planDay === b.t_dag) {

                    usedDay = true;
                    hasBonus = true;

                }

            } else if (Constants.isEmpty(b.t_elkedag) === false) {

                hasBonus = true;

            }

            // CALC
            if (hasBonus === true) {

                if (planStart >= bonusStart && planEnd <= bonusEnd) {

                    netSeconds += oldSeconds * factor;
                    oldSeconds = 0;

                } else if (planStart < bonusStart && planEnd > bonusEnd) {

                    let bonusSeconds = (bonusEnd.getTime() - bonusStart.getTime()) / 1000;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds * factor;
                    oldSeconds -= bonusSeconds;

                } else if (planStart < bonusStart && (planEnd > bonusStart && planEnd <= bonusEnd)) {

                    const normalSeconds = (bonusStart.getTime() - planStart.getTime()) / 1000;
                    let bonusSeconds = oldSeconds - normalSeconds;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds * factor;
                    oldSeconds -= bonusSeconds;

                } else if (planEnd > bonusEnd && (planStart >= bonusStart && planStart < bonusEnd)) {

                    const normalSeconds = (planEnd.getTime() - bonusEnd.getTime()) / 1000;
                    let bonusSeconds = oldSeconds - normalSeconds;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds * factor;
                    oldSeconds -= bonusSeconds;

                }

            }

            if (oldSeconds <= 0) {
                break;
            }
 
        }

        return netSeconds + oldSeconds;

    }

    calcBonusActual(rData, seconds, bonus) {

        let netSeconds = 0;
        let oldSeconds = seconds;

        let usedDate = false;
        let usedDay = false;

        const planStart = Constants.stringToDateTime(rData.uren_begindatum, rData.uren_begin.substr(0, 5));
        const planEnd = Constants.stringToDateTime(rData.uren_einddatum, rData.uren_eind.substr(0, 5));

        const planDay = planStart.getDay().toString();

        for (let b of bonus) {

            let hasBonus = false;

            b.rooster_datum = rData.uren_begindatum;

            let bonusStart = Constants.stringToDateTime(b.rooster_datum, b.t_begin.substr(0, 5));
            let bonusEnd = Constants.stringToDateTime(this.bonusIsNightShift(b, true), b.t_eind.substr(0, 5));

            const factor = isNaN(parseFloat(b.t_factor)) ? 0 : parseFloat(b.t_factor);

            // DATE
            if (Constants.isEmpty(b.t_datum) === false) {

                if (usedDate === false && rData.uren_begindatum === b.t_datum) {

                    bonusStart = Constants.stringToDateTime(b.t_datum, b.t_begin.substr(0, 5));
                    bonusEnd = Constants.stringToDateTime(this.bonusIsNightShift(b), b.t_eind.substr(0, 5));

                    usedDate = true;
                    hasBonus = true;

                }

            } else if (Constants.isEmpty(b.t_dag) === false) {

                if (usedDay === false && planDay === b.t_dag) {

                    usedDay = true;
                    hasBonus = true;

                }

            } else if (Constants.isEmpty(b.t_elkedag) === false) {

                hasBonus = true;

            }

            // CALC
            if (hasBonus === true) {

                if (planStart >= bonusStart && planEnd <= bonusEnd) {

                    netSeconds += oldSeconds * factor;
                    oldSeconds = 0;

                } else if (planStart < bonusStart && planEnd > bonusEnd) {

                    let bonusSeconds = (bonusEnd.getTime() - bonusStart.getTime()) / 1000;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds * factor;
                    oldSeconds -= bonusSeconds;

                } else if (planStart < bonusStart && (planEnd > bonusStart && planEnd <= bonusEnd)) {

                    const normalSeconds = (bonusStart.getTime() - planStart.getTime()) / 1000;
                    let bonusSeconds = oldSeconds - normalSeconds;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds * factor;
                    oldSeconds -= bonusSeconds;

                } else if (planEnd > bonusEnd && (planStart >= bonusStart && planStart < bonusEnd)) {

                    const normalSeconds = (planEnd.getTime() - bonusEnd.getTime()) / 1000;
                    let bonusSeconds = oldSeconds - normalSeconds;
                    bonusSeconds = (bonusSeconds > oldSeconds || bonusSeconds <= 0) ? oldSeconds : bonusSeconds;

                    netSeconds += bonusSeconds * factor;
                    oldSeconds -= bonusSeconds;

                }

            }

            if (oldSeconds <= 0) {
                break;
            }
 
        }

        return netSeconds + oldSeconds;

    }

}

// singleton
export default (new Plan());