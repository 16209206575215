import React from 'react';
import './login.css';
import '../../constants/DefaultStyle.css';
import '../../components/changeAccountModal/changeAccountModal.css';

import CryptoJS from 'crypto-js';
import Data from '../../constants/Data';
import RegisterCodeModal from './registerCodeModal';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import TeamsModal from '../../components/teamsModal/teamsModal';
import APIGETLOGIN from '../../classes/login/APIGETLOGIN';

class Login extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    this.errorText = '';
    
    this.state = {

      loading: false,
      autoLogin: false,

      toHome: false,

      saveAcc: localStorage.getItem('@saveAcc') === '0' ? false : true,

      storeCode: '',
      username: '',
      password: '',

      showError: false,

      showModal: false,
      showTeamsModal: false,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      teams: [],

      clockLogin: false,

      multiAccounts: Constants.isEmpty(localStorage.getItem('@multiAccounts')) === false ? JSON.parse(localStorage.getItem('@multiAccounts')) : [],

    };

  }

  checkMobile() {
    var check = false;
    (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  }

  async componentDidMount() {

    await this.autoLogin();

  }

  async autoLogin() {

    try {

      const _username = localStorage.getItem('@admin_username');
      const _password = localStorage.getItem('@admin_password');

      if (_username !== '' && _password !== '') {

        this.setState({username: _username});

        this.setState({autoLogin: true});

        if(await this.checkValidationsAutoLogin(_username, _password) === false) {
          this.setState({ autoLogin: false });
          return;
        }
    
        Data.set('loggedIn', true);
    
        this.props.history.push('/home');

      } else {

        this.setState({autoLogin: false});

      }

    } catch (error) {
      // Error retrieving data
      this.setState({autoLogin: false});
    }

  }

  async login(encrypted = false) {

    this.setState({loading: true});

    if(await this.checkValidations(false, encrypted) === false) {
      this.setState({loading: false});
      return;
    }

    Data.set('loggedIn', true);

    this.props.history.push('/home');

  }

  showError() {

    if(this.state.showError === true) {
      return <i className='errorText'>{this.errorText}</i>;
    } else {
      return null;
    }

  }

  async checkValidationsAutoLogin(_username, _password) {

    const loginSuccess = await APIGETLOGIN.Request(`SELECT *`, `FROM Account`, null, `WHERE account_username = '${_username.toLowerCase()}' AND account_password = '${_password}'`, null, null);

    if(loginSuccess === 'error') {
      await this.checkValidationsAutoLogin(_username, _password);
      return false;
    }

    if(loginSuccess === null || loginSuccess.length === 0) {
      this.errorText = 'Onjuiste gebruikersnaam en/of wachtwoord';
      this.setState({showError: true});
      return false;
    }

    Data.data.accountData = loginSuccess[0];

    return true;

  }

  async checkValidations(encrypted = false) {

    this.setState({ username: this.state.username.replace(/\s/g, '') });

    const loginSuccess = await APIGETLOGIN.Request(`SELECT *`, `FROM Account`, null, `WHERE account_username = '${this.state.username.replace(/\s/g, '').toLowerCase()}' AND account_password = '${CryptoJS.SHA512(this.state.password).toString()}'`, null, null);

    if(loginSuccess === 'error') {
      await this.checkValidations(this.state.username.replace(/\s/g, '').toLowerCase(), CryptoJS.SHA512(this.state.password).toString());
      return false;
    }

    if(loginSuccess === null || loginSuccess.length === 0) {
      this.errorText = 'Onjuiste gebruikersnaam en/of wachtwoord';
      this.setState({showError: true});
      return false;
    }

    Data.data.accountData = loginSuccess[0];

    localStorage.setItem('@admin_username', this.state.username.replace(/\s/g, '').toLowerCase());
    localStorage.setItem('@admin_password', encrypted === true ? this.state.password : CryptoJS.SHA512(this.state.password).toString());

    return true;

  }

  openRegisterCodeModal() {

    this.setState({
      showModal: true, 
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
    });

  }

  forgotUsernameAlert() {
    this.setState({
        alertTitle: 'Gebruikersnaam vergeten',
        alertBody: `Neem contact op met jouw leidinggevende om jouw gebruikersnaam op te vragen`,
        alertButtons: 1,
        alertButtonText: ['OK'],
        showAlert: true,
    });
  }

  closeAlert() {

    this.setState({showAlert: false});

  }

  handleKey(e) {

    if(e.keyCode === 13){
      e.preventDefault(); // Ensure it is only this code that runs

      this.login();

      return false;
    }

  }

  render() {

    return (
      <div>

        {this.state.autoLogin === true ?

          <div>
        
            <div className='lds-dual-ring loader' />

            {this.state.showTeamsModal === true ?
                
                <TeamsModal 
                  _closeModal={this.closeModal.bind(this)}
                  _teams={this.state.teams}
                  _mainStoreCode={this.state.storeCode}
                  _login={true}
                  _clock={this.state.clockLogin}
                  history={this.props.history}
                />
  
              :
  
                null
              
              }

          </div>

        :

          <div className='login'>

            {this.state.showAlert === true ?
                
              <BetterAlert 
                _closeAlert={this.closeAlert.bind(this)}
                _title={this.state.alertTitle}
                _body={this.state.alertBody}
                _buttons={this.state.alertButtons}
                _buttonText={this.state.alertButtonText}
                _buttonAction={this.state.alertButtonAction}
              />

            :

              null
            
            }

            {this.state.showModal === true ?
                
              <RegisterCodeModal 
                _closeModal={this.closeModal.bind(this)}
                history={this.props.history}
              />

            :

              null
            
            }

            {this.state.showTeamsModal === true ?
                
              <TeamsModal 
                _closeModal={this.closeModal.bind(this)}
                _teams={this.state.teams}
                _mainStoreCode={this.state.storeCode}
                _login={true}
                _clock={this.state.clockLogin}
                history={this.props.history}
              />

            :

              null
            
            }

            <div className='login_sub1'>

              <div className='login_sub1_outer'>

                <div className={'login_logoContainer'}>

                  <img src={`/android-chrome-512x512.png`} alt='logo' className='logo' />

                </div>

              </div>

              <div className='login_sub1_inner'>

                <div className='login_sub1_inner_sub1'>

                  <span className='login_subTitle'>Log in op jouw account</span>

                  <input 
                    onKeyDown={(e) => this.handleKey(e)} 
                    onChange={(event) => this.setState({ username: event.target.value, showError: false })} 
                    className='login_inputField' 
                    style={{marginTop: 10}}  
                    type="text"
                    placeholder='Gebruikersnaam of e-mail'
                  />

                  <input 
                    onKeyDown={(e) => this.handleKey(e)} 
                    onChange={(event) => this.setState({ password: event.target.value, showError: false })} 
                    className='login_inputField' 
                    style={{marginTop: 10}} 
                    type="password" 
                    placeholder='Wachtwoord'
                  />

                  <div className='login_errorSection'> 
                    {this.showError()}
                  </div>

                </div>

                <div className='login_sub1_inner_sub2'>

                  {this.state.loading === true ?
                    <div className='login_loginButton_loading'>
                      <div className="lds-dual-ring-button"></div>
                    </div>
                  :
                    <div className='login_loginButton' onClick={() => this.login()}>
                      <i className='fas fa-sign-in-alt login_buttonIcon' style={{color: 'white'}} />
                      <span>Inloggen Admin</span>
                    </div>
                  }

                </div>

              </div>

              <div className='login_sub1_outer'>

              </div>

            </div>

            <div className='login_sub2'>

            </div>

          </div>

        }

      </div>
    );
  }
  
}

export default Login;