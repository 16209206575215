import React from 'react';
import './home.css';
import '../../constants/DefaultStyle.css';
import '../home2/home2.css';
import '../../components/smartPlanModal/smartPlanModal.css';

import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import APIGetOwnSchedule from '../../classes/APIGetOwnSchedule';
import Colors from '../../constants/Colors';
import HomeModal from './homeModal';
import Constants from '../../constants/Constants';
import BetterModal from '../../components/modal/modal';
import BetterAlert from '../../components/alert/alert';
import APIGetDefaultDayShifts from '../../classes/APIGetDefaultDayShifts';
import APIGetDefaultShifts from '../../classes/APIGetDefaultShifts';
import APIGetFunctions from '../../classes/APIGetFunctions';
import MissingModal from '../../components/missingModal/missingModal';
import APIGetAllDefaultDayShifts from '../../classes/APIGetAllDefaultDayShifts';
import APIGetTeamsPlannerOwn from '../../classes/APIGetTeamsPlannerOwn';
import planClass from '../planner/plan.class';

class Home extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }
    
    this.ownScheduleObject = [];

    this.hours = [];
    
    this.state = {
        loading: true,
        ownScheduleState: [],
        errorScreen: false,
        noShifts: false,

        hours: [],

        showModal: false,
        modalMode: 0,
        modalData: [],

        showQuestionModal: false,
        tutorialModal: false,
        missingModalVisible: false,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,
        alertButtonAction2: null,

    };

    //componentWillMount here

    this.compWillMount();

  }

  compWillMount() {

  }

  async componentDidMount() {

    if(Data.data.loggedIn === false) {
      return;
    }

    this.teamPlanner = await APIGetTeamsPlannerOwn.Request(Data.data.accountData.info_id);

    await this.getOwnSchedule();
    this.getHours();

    // localStorage.setItem('@doneTutorial', '0');
    // localStorage.setItem('@tutPassChanged', '0');
    // localStorage.setItem('@tutDataChanged', '0');
    // localStorage.setItem('@tutSeenAdminOptions', '0');

    // const doneTutorial = localStorage.getItem('@doneTutorial') === '1';

    // if (doneTutorial === false) {

    //   Data.data.canUseNav = false;

    //   this.setState({
    //     alertTitle: 'Web-uitleg',
    //     alertBody: (
    //       <div>

    //         We zien dat je voor het eerst onze website gebruikt. Wil je een korte uitleg over de website volgen?

    //       </div>
    //     ),
    //     alertButtons: 2,
    //     alertButtonText: ['Annuleren', 'Web-uitleg starten'],
    //     alertButtonAction: this.startTutorial.bind(this),
    //     alertButtonAction2: this.cancelTutorial.bind(this),
    //     showAlert: true,
    //   });

    // } else {

    //   if (Data.data.accountData.account_rights === '2') {

    //       const shifts = await APIGetAllDefaultDayShifts.Request();
    //       const times = await APIGetDefaultShifts.Request();
    //       const functions = await APIGetFunctions.Request();

    //       if (shifts.length === 0 || times.length === 0 || functions.length === 0) {
    //           Data.data.canUseNav = false;
    //           this.openMissingModal();
    //           return;
    //       }

    //   }

    // }

    Data.data.canUseNav = true;

  }

  startTutorial() {

    Data.data.inTutorial = true;
    Data.data.tutorialStep = 1;

    this.setState({ tutorialModal: true });

    this.openQuestionModal();

  }

  cancelTutorial() {

      localStorage.setItem('@doneTutorial', '1');

      this.setState({ loading: true });

      this.componentDidMount();

  }

  openHomeModal(mode, data) {

    this.setState({
      showModal: true, 
      modalMode: mode,
      modalData: data,
    });

  }

  closeModal() {

    this.setState({ 
      showModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  async getOwnSchedule() {

    this.ownScheduleObject = await APIGetOwnSchedule.Request();

    if(this.ownScheduleObject === 'error') {
        
        this.ownScheduleObject = [];

        this.setState({ 
            errorScreen: true,
            loading: false,
        });
        return;
    }

    if(this.ownScheduleObject === null || this.ownScheduleObject === []) {
        this.ownScheduleObject = [];
        this.setState({
            noShifts: true,
            loading: false,
        });
        return;
    }

    this.ownScheduleObject.reverse(); //for web applications, this is better

    this.setState({ 
        ownScheduleState: this.ownScheduleObject,
        loading: false
    });


  }

  getHours() {

      let month = null;

      for(let key in this.ownScheduleObject) {

          const startTime = Constants.stringToDateTime(this.ownScheduleObject[key].rooster_datum, this.ownScheduleObject[key].rooster_begin);
          const endTime = Constants.stringToDateTime(planClass.isNightShift2(this.ownScheduleObject[key]), this.ownScheduleObject[key].rooster_eind);
          const _break = Constants.timeStringToMS(this.ownScheduleObject[key].rooster_pauze);

          //const hourDiff = new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_eind}`) - new Date(`${this.ownScheduleObject[key].rooster_datum}T${this.ownScheduleObject[key].rooster_begin}`);

          //const hourDiff = ((endTime - startTime) / 60 / 60 / 1000) - breakHours - breakMinutes;

          // const hours = Constants.round((endTime - startTime) / 60 / 60 / 1000);
          // const hoursNet = Constants.round(hours - breakHours - breakMinutes);
          // const hourDiff = Constants.round(hoursNet);

          const hourDiff = Constants.round(((endTime - startTime) - _break) / 60 / 60 / 1000); //

          if(month !== Constants.getMonthTZ(this.ownScheduleObject[key].rooster_datum)) {
              month = Constants.getMonthTZ(this.ownScheduleObject[key].rooster_datum);
              //this.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()] = 0 + (hourDiff / 60 / 60 / 1000);
              this.hours[Constants.getMonthTZ(this.ownScheduleObject[key].rooster_datum)] = 0 + hourDiff;
              continue;
          }

          //this.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()] = this.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()] + (hourDiff / 60 / 60 / 1000);
          this.hours[Constants.getMonthTZ(this.ownScheduleObject[key].rooster_datum)] = Constants.round(this.hours[Constants.getMonthTZ(this.ownScheduleObject[key].rooster_datum)] + hourDiff);

      }

      this.setState({
          hours: this.hours
      });

  }

  async reloadScreen() {

    this.setState({reloading: true});

    await this.getOwnSchedule();

    this.setState({reloading: false});

  }

  getDayName(date) {

      const dayNumber = Constants.stringToDate(date).getDay();

      const weekday = new Array(7);
      weekday[0] = 'Zondag';
      weekday[1] = 'Maandag';
      weekday[2] = 'Dinsdag';
      weekday[3] = 'Woensdag';
      weekday[4] = 'Donderdag';
      weekday[5] = 'Vrijdag';
      weekday[6] = 'Zaterdag';

      return weekday[dayNumber];

  }

  getShortDayName(date) {

      const dayNumber = Constants.stringToDate(date).getDay();

      const weekday = new Array(7);
      weekday[0] = 'Zo.';
      weekday[1] = 'Ma.';
      weekday[2] = 'Di.';
      weekday[3] = 'Wo.';
      weekday[4] = 'Do.';
      weekday[5] = 'Vr.';
      weekday[6] = 'Za.';

      return weekday[dayNumber];

  }

  getMonthName(date) {

      const month = Constants.stringToDate(date).getMonth();

      const monthNames = ['januari', 'februari', 'maart',
          'april', 'mei', 'juni', 'juli',
          'augustus', 'september', 'oktober', 'november',
          'december'
      ];

      return monthNames[month];

  }

  getShortMonthName(date) {

      const month = Constants.stringToDate(date).getMonth();

      const monthNames = ['jan.', 'feb.', 'mrt.',
          'apr.', 'mei', 'jun.', 'jul.',
          'aug.', 'sept.', 'okt.', 'nov.',
          'dec.'
      ];

      return monthNames[month];

  }

  getOwnScheduleBars() {

    let ownScheduleList = [];

    let month = null;
    let showMonth = false;

    for(let key in this.ownScheduleObject) {

        const monthNameCapitalized = this.getMonthName(this.ownScheduleObject[key].rooster_datum).charAt(0).toUpperCase() + this.getMonthName(this.ownScheduleObject[key].rooster_datum).substring(1);

        if(month !== monthNameCapitalized) {
            month = monthNameCapitalized;
            showMonth = true;
        } else {
            showMonth = false;
        }

        const date = Constants.stringToDate(this.ownScheduleObject[key].rooster_datum);

        let functionColor = this.ownScheduleObject[key].functie_kleur !== null ? this.ownScheduleObject[key].functie_kleur : 'EBEBEB';
        let functionName = this.ownScheduleObject[key].functie_naam !== null ? this.ownScheduleObject[key].functie_naam : 'Functie onbekend';

        if (showMonth === true && Constants.isTrue(Data.data.appOptions.opties_urenEigen)) {

          ownScheduleList.push(
            <div className='touchBoxHoursText'>
              {monthNameCapitalized}: {this.state.hours[new Date(`${this.ownScheduleObject[key].rooster_datum}`).getMonth()]} uren

            </div>
          );

        }

        ownScheduleList.push(

          <div key={key} className='home2_touchBoxBase' style={{marginTop: '1vw'}}>

          <div style={{cursor: new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date() ? 'default' : 'pointer'}} onClick={() => new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date() ? null : this.openHomeModal(0, this.ownScheduleObject[key])}>

            <div className='home2_touchContainer'
              style={new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date() ?
                        { 
                          borderBottomColor: Colors.color.pastBorderColor,
                          borderLeftColor: Colors.color.pastBorderColor,
                          backgroundColor: Colors.color.pastBackgroundColor,
                        }
                      :
                        { 
                          borderBottomColor: `#${this.ownScheduleObject[key].functie_kleur}`,
                          borderLeftColor: `#${this.ownScheduleObject[key].functie_kleur}`,
                          backgroundColor: Constants.hexToRgbA(`#${this.ownScheduleObject[key].functie_kleur}`, 0.2),
                        }
                    } 
            >

              <i className='far fa-chevron-right' style={{fontSize: '0.9vw', display: 'flex', position: 'absolute', right: '1vw', alignSelf: 'center', zIndex: 1}} />

              <div className='home2_initContainer' style={{backgroundColor: new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date() ? Colors.color.pastBorderColor : Constants.hexToRgbA(`#${this.ownScheduleObject[key].functie_kleur}`, 0.5)}}>

                {new Date(this.ownScheduleObject[key].rooster_datum + 'T23:59:59') < new Date() ?
                  <i className='far fa-ban' />
                : 
                  <span style={{color: Constants.getContrastPlan(`#${this.ownScheduleObject[key].functie_kleur}`)}}>{this.ownScheduleObject[key].functie_naam.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</span>
                }
                    
              </div>

              <div className='home2_infoContainer'>

                <div className='home2_infoRow'>
                  <span><b>{Constants.firstUppercase(Constants.getDayName(date))}</b></span>
                  <span style={{marginLeft: '0.4vw'}}>{date.getDate()} {Constants.getShortMonthName(date).toLowerCase()}</span>
                </div>

                <div className='home2_infoRow' style={{marginTop: '0.2vw'}}>
                  <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                    <span style={{color: Constants.stringToDateTime(planClass.isNightShift2(this.ownScheduleObject[key], true), this.ownScheduleObject[key].rooster_eind, true) < Constants.getDateTZD(new Date()) ? Colors.color.pastBorderColor : `#${functionColor}`}}><i className='far fa-clock' /></span>
                  </div>
                  <div style={{display: 'flex', flex: 15}}>
                    <span>{this.ownScheduleObject[key].rooster_begin.substring(0,5)} - {this.ownScheduleObject[key].rooster_eind.substring(0,5)}</span>
                  </div>
                </div>

                {this.ownScheduleObject[key].rooster_pauze !== '00:00:00' ?
                  <div className='home2_infoRow2' style={{marginTop: '0.2vw'}}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                      <span style={{color: Constants.stringToDateTime(planClass.isNightShift2(this.ownScheduleObject[key], true), this.ownScheduleObject[key].rooster_eind, true) < Constants.getDateTZD(new Date()) ? Colors.color.pastBorderColor : `#${functionColor}`}}><i className='far fa-coffee' /></span>
                    </div>
                    <div style={{display: 'flex', flex: 15}}>
                      <span>{this.ownScheduleObject[key].rooster_pauze.substring(0,5)}</span>
                    </div>
                  </div>
                : null}

                <div className='home2_infoRow2' style={{marginTop: '0.2vw'}}>
                  <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                    <span style={{color: Constants.stringToDateTime(planClass.isNightShift2(this.ownScheduleObject[key], true), this.ownScheduleObject[key].rooster_eind, true) < Constants.getDateTZD(new Date()) ? Colors.color.pastBorderColor : `#${functionColor}`}}><i className='far fa-briefcase' /></span>
                  </div>
                  <div style={{display: 'flex', flex: 15}}>
                    <span>{functionName}</span>
                  </div>
                </div>

                {Data.data.teams.length > 1 && Data.data.ownTeams.length > 0 ?
                  <div className='home2_infoRow2' style={{marginTop: '0.2vw'}}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                      <span style={{color: Constants.stringToDateTime(planClass.isNightShift2(this.ownScheduleObject[key], true), this.ownScheduleObject[key].rooster_eind, true) < Constants.getDateTZD(new Date()) ? Colors.color.pastBorderColor : `#${functionColor}`}}><i className='far fa-map-marker-alt' /></span>
                    </div>
                    <div style={{display: 'flex', flex: 15}}>
                      <span>{Constants.getTeamNameFromScheduleID(this.teamPlanner, this.ownScheduleObject[key].rooster_id)}</span>
                    </div>
                  </div>
                : null}

                {this.ownScheduleObject[key].bijz_id !== null ?
                  <div className='home2_infoRow3' style={{marginTop: '0.2vw'}}>
                    <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                      <span style={{color: Constants.stringToDateTime(planClass.isNightShift2(this.ownScheduleObject[key], true), this.ownScheduleObject[key].rooster_eind, true) < Constants.getDateTZD(new Date()) ? Colors.color.pastBorderColor : `#${functionColor}`}}><i className='far fa-sticky-note' /></span>
                    </div>
                    <div style={{display: 'flex', flex: 15}}>
                      <span style={{color: `#${this.ownScheduleObject[key].bijz_kleur}`}}>{this.ownScheduleObject[key].bijz_naam}</span>
                    </div>
                  </div>
                : null}

              </div>

            </div>

          </div>

      </div>

        );

    }

    return ownScheduleList;

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  openQuestionModal() {

    this.setState({ showQuestionModal: true });

  }

  closeQuestionModal() {

    this.setState({ showQuestionModal: false });

  }

  openMissingModal() {
    this.setState({ missingModalVisible: true });
  }

  closeMissingModal() {
      this.setState({ missingModalVisible: false });
  }

  render() {

    const ownScheduleBars = this.getOwnScheduleBars();

    return (
        <div className='home'>

          {this.state.loading === true ?
          
            <div className='lds-dual-ring loader' />

          :

            <div className='container'>

              {this.state.reloading === true ?
                        
                <div className='lds-dual-ring loader2' />

              :

                null

              }

              {this.state.showQuestionModal === true ?
                
                <BetterModal
                  _closeModal={this.closeQuestionModal.bind(this)}
                  
                  _header={this.state.tutorialModal === true ? 'Web-uitleg' : 'Informatie'}
                  _general={{
                      header: 'Mijn diensten',
                      content: (
                        <div>'Mijn diensten' is een handige plek waar je al jouw eigen diensten met de daarbij gewerkte uren terug kunt vinden van de afgelopen 3 maanden.</div>
                      ),
                  }}
                  _alineas={[
                      {
                        header: 'Ruilen',
                        content: (
                          <div>Om een dienst met iemand te ruilen klik je eerst op een dienst. Vervolgens klik je op 'Ruilen' waarna je beschikbare collega's kunt selecteren. Tot slot klik je op 'Dienst aanbieden'.<br/><br/>Je kunt een overzicht van jouw ruilverzoeken terug vinden in het menu onder 'Mijn account'.</div>
                        ),
                      }
                  ]}
                  _tutorialModal={this.state.tutorialModal}
                  _history={this.props.history}
                />

              :

                null
              
              }

              {this.state.missingModalVisible === true ?
                
                <MissingModal 
                  _closeModal={this.closeMissingModal.bind(this)}
                  _history={this.props.history}
                  _parent={this}
                />

              :

                null
              
              }

              {this.state.showAlert === true ?
                
                <BetterAlert
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  _buttonAction2={this.state.alertButtonAction2}
                />

              :

                null
              
              }

              {this.state.showModal === true ?
                
                <HomeModal 
                  _closeModal={this.closeModal.bind(this)}
                  _modalData={this.state.modalData}
                  _modalMode={this.state.modalMode}
                  _reloadScreen={this.reloadScreen.bind(this)}
                  history={this.props.history}
                />

              :

                null
              
              }

              {this.ownScheduleObject.length === 0 ?
                
                <div className={'textInMiddle'}>
                    <div>Geen diensten op dit moment</div>
                </div>

              :

                null
              
              }

              {ownScheduleBars}

            </div>
        
          }

        </div>
    );
  }
  
}

export default Home;