/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './home.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';

import APIGetAllAccountsTrade from '../../classes/APIGetAllAccountsTrade';
import APIAddTrade from '../../classes/APIAddTrade';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../constants/Colors';
import BetterAlert from '../../components/alert/alert';
import KodictNotifications from '../../constants/KodictNotifications';
import APIGetTeamsPlannerOwn from '../../classes/APIGetTeamsPlannerOwn';
import APIGetTeamUsers from '../../classes/APIGetTeamUsers';
import APIGetAllSkillsUsers from '../../classes/APIGetAllSkillsUsers';
import APIGetAllSkillSchedules from '../../classes/APIGetAllSkillSchedules';

class TradeShiftModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData2;
    this.mode = this.props._modalMode2;
    this.closeModal = this.props._closeModal2;
    this.reloadScreen = this.props._reloadScreen;

    this.date = new Date(this.modalData.rooster_datum);
    
    this.state = {

        loading: false,

        selectedPeople: [],
        allPeople: [],
        skillsUsers: {},
        skillsSchedules: {},

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

    };

  }

  async componentDidMount() {

    this.setState({loading: true});

    const allPeopleObject = await APIGetAllAccountsTrade.Request(this.modalData.rooster_datum);

    const skillSchedules = await APIGetAllSkillSchedules.Request(this.modalData.rooster_datum, this.modalData.rooster_datum);
    const skillSchedulesObj = Constants.convertToObjectWithArrays(skillSchedules, 'rooster_id');

    const skillsUsers = await APIGetAllSkillsUsers.Request();
    const skillsUsersObj = Constants.convertToObjectWithArrays(skillsUsers, 'info_id');

    let allPeopleTrade = [];

    if(allPeopleObject !== null) {

        // setting here.... else nobody can trade if no job has been given
        for (const person of allPeopleObject) {

            // trade without function
            if (Constants.isTrue(Data.data.appOptions.opties_ruilenGeenFunctie)) {

                if (this.modalData.functie_id === person.functie_id || person.functie_id === null) {

                    // check comp setting
                    if (Constants.isTrue(Data.data.appOptions.opties_ruilenComp)) {

                        if (typeof skillSchedulesObj[this.modalData.rooster_id] === 'undefined') {
                            allPeopleTrade.push(person);
                            continue;
                        }   

                        // check if user has comp
                        if (typeof skillsUsersObj[person.account_id] !== 'undefined') {

                            let userHasAllSkills = true;
                            for (const skill of skillSchedulesObj[this.modalData.rooster_id]) {
                                const userHasSkill = Constants.objectArrayContainsKey(skillsUsersObj[person.account_id], 'comp_naam', skill.comp_naam);
                                if (userHasSkill === false) {
                                    userHasAllSkills = false;
                                    break;
                                }
                            }

                            if (userHasAllSkills === true) {
                                allPeopleTrade.push(person);
                            }

                        }

                    } else {

                        allPeopleTrade.push(person);

                    }

                }

            } else {

                if (this.modalData.functie_id === person.functie_id) {

                    // check comp setting
                    if (Constants.isTrue(Data.data.appOptions.opties_ruilenComp)) {

                        if (typeof skillSchedulesObj[this.modalData.rooster_id] === 'undefined') {
                            allPeopleTrade.push(person);
                            continue;
                        }  

                        // check if user has comp
                        if (typeof skillsUsersObj[person.account_id] !== 'undefined') {

                            let userHasAllSkills = true;
                            for (const skill of skillSchedulesObj[this.modalData.rooster_id]) {
                                const userHasSkill = Constants.objectArrayContainsKey(skillsUsersObj[person.account_id], 'comp_naam', skill.comp_naam);
                                if (userHasSkill === false) {
                                    userHasAllSkills = false;
                                    break;
                                }
                            }

                            if (userHasAllSkills === true) {
                                allPeopleTrade.push(person);
                            }

                        }

                    } else {

                        allPeopleTrade.push(person);

                    }

                }

            }

        }

        if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

            const teamUsers = await APIGetTeamUsers.Request();
            const teamPlanner = await APIGetTeamsPlannerOwn.Request(Data.data.accountData.info_id);
            const teamID = Constants.getTeamIDFromScheduleID(teamPlanner, this.modalData.rooster_id);

            const allPeople = Constants.getTeamAccountsT(teamUsers, allPeopleTrade, parseInt(teamID));

            this.setState({ allPeople: allPeople });

        } else {

            this.setState({ allPeople: allPeopleTrade });

        }

    } else {

        this.setState({
            showError: true,
            errorText: 'Er is iets misgegaan tijdens het laden van de medewerkers. Probeer opnieuw.',
        });

    }

    this.setState({
        loading: false,
        skillsUsers: skillsUsersObj,
        skillsSchedules: skillSchedulesObj,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze geblokkeerde dag wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteBlocked.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  selectingUser(id) {

    this.setState({showError: false});

    if(Constants.arrayContains(this.state.selectedPeople, id)) {

        this.deselectUser(id);

    } else {

        this.selectUser(id);

    }

  }

  selectUser(id) {

      this.state.selectedPeople.push(id); // be sure to clear this when adding availability

      this.setState({ selectedPeople: this.state.selectedPeople });

  }

  deselectUser(id) {

      Constants.removeFromArray(this.state.selectedPeople, id);

      this.setState({ selectedPeople: this.state.selectedPeople });

  }

  getPeopleBars() {

    let people = [];

    for(let key in this.state.allPeople) {

        const person = this.state.allPeople[key];

        const userFullName = `${person.info_voornaam} ${(person.info_tussenvoegsel !== null && person.info_tussenvoegsel !== '') ? person.info_tussenvoegsel + " " + person.info_achternaam.charAt(0) : person.info_achternaam.charAt(0)}.`;

        people.push(

            <div 
                key={key} 
                className={'inputField2'} 
                style={{
                    alignItems: 'center',
                    marginTop: 5,
                    borderColor: Constants.arrayContains(this.state.selectedPeople, parseInt(person.account_id)) === true ? Colors.color.greenFilledIn : '#EBEBEB',
                    cursor: 'pointer',
                }}
                onClick={() => this.selectingUser(parseInt(person.account_id))}
            >

                {userFullName}

            </div>

        );

    }

    return people;

  }

  async tradeShift() {

    this.setState({loading: true});

    if(this.state.selectedPeople.length === 0) {
        this.setState({
            loading: false,
            showError: true,
            errorText: 'Je moet ten minste één medewerker selecteren',
        });
        return;
    }
    
    let added = false;

    for(let key in this.state.selectedPeople) {

        added = await APIAddTrade.Request(this.state.selectedPeople[key], this.modalData.rooster_id);

        const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`; //.charAt(0)

        await KodictNotifications.sendPushNotification(
            this.state.selectedPeople[key],
            'trade',
            'Ruilen',
            `${userFullName} heb je een ruilverzoek verzonden voor ${this.date.toLocaleDateString()} van ${this.modalData.rooster_begin.substring(0, 5)} tot ${this.modalData.rooster_eind.substring(0, 5)} '${this.modalData.functie_naam}'`
        );

        if(added === false) {
            break;
        }

    }

    if(added === true) {

        this.setState({loading: false, selectedPeople: []});
          
        this.props.history.push('/trade');

    } else {

        this.setState({
            loading: false,
            showError: true,
            errorText: 'Er is iets misgegaan tijdens het opslaan. Probeer opnieuw.',
        });

    }

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    const persons = this.getPeopleBars();

    return (
        <div id="tradeShiftModal" className="modal" onClick={(e) => e.target === document.getElementById("ztradeShiftModal") ? this.closeModal() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.closeModal() : null}>&times;</span>
            
            <p>Dienst aanbieden</p>
            
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div style={{textAlign: 'center'}}>

                        <div>Dienst van {Constants.getDayName(this.date).toLowerCase()} {this.date.getDate()} {Constants.getMonthName(this.date)} {this.date.getFullYear()}</div>

                        <div style={{marginTop: 3}}>{this.modalData.rooster_begin.substring(0, 5)} - {this.modalData.rooster_eind.substring(0, 5)}</div>

                        <div style={{marginTop: 3}}>aanbieden aan:</div>

                        <div style={{marginTop: 10, fontStyle: 'italic', fontSize: '1vw', marginBottom: 15}}>(Meerdere opties mogelijk)</div>

                    </div>

                    {persons}

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :

                        <div>

                            <div className='submitButton' onClick={() => this.tradeShift()}>Dienst aanbieden</div>

                        </div>

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default TradeShiftModal;