/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './shifts.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import APIAddDefaultDayShift from '../../../classes/APIAddDefaultDayShift';
import APIEditDefaultDayShift from '../../../classes/APIEditDefaultDayShift';
import APIDeleteDefaultDayShift from '../../../classes/APIDeleteDefaultDayShift';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';

class ShiftsModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        day: this.mode === 1 ? parseInt(this.modalData.dagdienst_dag) : null,

        startTime: null,
        startText: '00:00',

        endTime: null,
        endText: '00:00',

        break: false,
        breakTime: new Date().setHours(0, 0),
        breakText: '00:00',

        functionID: this.mode === 1 ? parseInt(this.modalData.functie_id) : null,

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if(this.mode === 0) { //ADDING

        const startTime = new Date();
        startTime.setHours(0, 0);
    
        const endTime = new Date();
        endTime.setHours(0, 0);
    
        this.setStartTime(startTime);
        this.setEndTime(endTime);
        
    }

    if(this.mode === 1) { //EDITING

      const startHour = parseInt(this.modalData.dagdienst_begin.substring(0, 2));
      const startMin = parseInt(this.modalData.dagdienst_begin.substring(3, 5));
      const endHour = parseInt(this.modalData.dagdienst_eind.substring(0, 2));
      const endMin = parseInt(this.modalData.dagdienst_eind.substring(3, 5));

      const startTime = new Date();
      startTime.setHours(startHour, startMin);
  
      const endTime = new Date();
      endTime.setHours(endHour, endMin);
  
      this.setStartTime(startTime);
      this.setEndTime(endTime);

      if(this.modalData.dagdienst_pauze.substring(0, 5) !== '00:00') {
        const breakHour = parseInt(this.modalData.dagdienst_pauze.substring(0, 2));
        const breakMin = parseInt(this.modalData.dagdienst_pauze.substring(3, 5));

        const breakTime = new Date();
        breakTime.setHours(breakHour, breakMin);

        this.setState({break: true});

        this.setBreakTime(breakTime);
    }

    }

  }

  setStartTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const startHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const startMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        startTime: date,
        startText: `${startHours}:${startMins}`,
        showError: false,
    });

  }

  setEndTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      return;
    }

    const endHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const endMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        endTime: date,
        endText: `${endHours}:${endMins}`,
        showError: false,
    });

  }

  setBreakTime(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }

    const endHours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
    const endMins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes().toString();

    this.setState({
        breakTime: date,
        breakText: `${endHours}:${endMins}`,
        showError: false,
    });

  }

  toggleSwitch() {

    this.setState({
        break: !this.state.break,
        breakTime: new Date().setHours(0, 0),
        breakText: '00:00',
        showError: false,
    });

  }

  selectDay(event) {

    const day = parseInt(event.target.value);

    this.setState({ day: day, showError: false, });

  }

  getDefaultTimes() {

    let defaultTimes = [];

    for(let key in this.props._modalTimes) {

        defaultTimes.push(
            <option value={key}>{this.props._modalTimes[key].dienst_begin.substring(0, 5)} - {this.props._modalTimes[key].dienst_eind.substring(0, 5)}</option>
        );

    }

    return defaultTimes;

  }

  selectTime(event) {

    const key = parseInt(event.target.value);

    const startHour = parseInt(this.props._modalTimes[key].dienst_begin.substring(0, 2));
    const startMin = parseInt(this.props._modalTimes[key].dienst_begin.substring(3, 5));
    const endHour = parseInt(this.props._modalTimes[key].dienst_eind.substring(0, 2));
    const endMin = parseInt(this.props._modalTimes[key].dienst_eind.substring(3, 5));

    const startTime = new Date();
    startTime.setHours(startHour);
    startTime.setMinutes(startMin);

    const endTime = new Date();
    endTime.setHours(endHour);
    endTime.setMinutes(endMin);

    this.setStartTime(startTime);
    this.setEndTime(endTime);

  }

  getFunctions() {

    let functions = [];

    for(let key in this.props._modalFunctions) {

        functions.push(
            <option value={this.props._modalFunctions[key].functie_id} selected={parseInt(this.props._modalFunctions[key].functie_id) === this.state.functionID}>{this.props._modalFunctions[key].functie_naam}</option>
        );

    }

    return functions;

  }

  selectFunction(event) {

    this.setState({
        functionID: parseInt(event.target.value),
        showError: false,
    });

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze standaard dienst wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteShift.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveShift() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIAddDefaultDayShift.Request(
      this.state.day,
      this.state.startText,
      this.state.endText,
      this.state.functionID,
      this.state.break === true ? this.state.breakText : '00:00',
      this.props._shiftTemp
    );

    if(added !== null) {

      if (this.props._fromTut === true) {

        this.goBack();

        return;

      }

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async editShift() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEditDefaultDayShift.Request(
      this.state.day,
      this.state.startText,
      this.state.endText,
      this.state.functionID,
      parseInt(this.modalData.dagdienst_id),
      this.state.break === true ? this.state.breakText : '00:00'
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

  async deleteShift() {

    this.setState({loading: true});

    const added = await APIDeleteDefaultDayShift.Request(
      parseInt(this.modalData.dagdienst_id),
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  checkValidations() {

    if(this.state.day === null) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen dag geselecteerd',
      });
      return false;
    }
    if(this.state.startTime.getTime() === this.state.endTime.getTime()) {
      this.setState({
        showError: true,
        errorText: 'Begintijd kan niet gelijk zijn aan eindtijd',
      });
      return false;
    }
    if(this.state.startTime.getTime() > this.state.endTime.getTime()) {
        this.setState({
          showError: true,
          errorText: 'Begintijd kan niet later zijn dan eindtijd',
        });
        return false;
    }
    if(this.state.functionID === null || isNaN(this.state.functionID)) {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen functie geselecteerd',
      });
      return false;
    }

    return true;

  }

  goBack() {

    if (this.props._fromTut === true) {

        this.props._reloadTutScreen();

    }

    this.props._closeModal();

  }

  render() {

    const defaultTimes = this.getDefaultTimes();
    const functions = this.getFunctions();

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="shiftsModal" className="modal" onClick={(e) => e.target === document.getElementById("zshiftsModal") ? this.goBack() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Dienst toevoegen</p>
            
            :
            this.mode === 1 ?

              <p>Dienst wijzigen</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                  <div>Dag:</div>

                  <select 
                      name="Selecteer een dag" 
                      className={'dropDownField'}
                      onChange={(event) => this.selectDay(event)}
                      style={{marginTop: 0}}
                  >
                    <option value={null} disabled selected hidden>Selecteer een dag</option>
                    <option value={'1'} selected={parseInt(this.modalData.dagdienst_dag) === 1}>Maandag</option>
                    <option value={'2'} selected={parseInt(this.modalData.dagdienst_dag) === 2}>Dinsdag</option>
                    <option value={'3'} selected={parseInt(this.modalData.dagdienst_dag) === 3}>Woensdag</option>
                    <option value={'4'} selected={parseInt(this.modalData.dagdienst_dag) === 4}>Donderdag</option>
                    <option value={'5'} selected={parseInt(this.modalData.dagdienst_dag) === 5}>Vrijdag</option>
                    <option value={'6'} selected={parseInt(this.modalData.dagdienst_dag) === 6}>Zaterdag</option>
                    <option value={'0'} selected={parseInt(this.modalData.dagdienst_dag) === 0}>Zondag</option>
                  </select>

                  <select 
                      name="Vaste tijden" 
                      className={'dropDownField'}
                      onChange={(event) => this.selectTime(event)}
                      style={{marginTop: 10}}
                  >
                      <option value="" selected={true} disabled hidden>Vaste tijden</option>
                      {defaultTimes}
                  </select>

                  <div style={{marginTop: 5}}>Van:</div>

                  <DatePicker
                      selected={this.state.startTime}
                      onChange={(date) => this.setStartTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Van"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      className={'inputFieldTime'}
                  />

                  <div style={{marginTop: 5}}>Tot:</div>

                  <DatePicker
                      selected={this.state.endTime}
                      onChange={(date) => this.setEndTime(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Tot"
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      className={'inputFieldTime'}
                  />

                  <div style={{display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 5}}>

                    Pauze

                    <label className="switch" style={{marginLeft: 25}}>
                        <input 
                        type="checkbox" 
                        checked={this.state.break} 
                        onClick={() => this.toggleSwitch()}
                        readOnly
                        />
                        <span className="slider round"></span>
                    </label>

                  </div>

                  {this.state.break === true ?

                    <DatePicker
                        selected={this.state.breakTime}
                        onChange={(date) => this.setBreakTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Pauze"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                        minTime={new Date().setHours(0, 0)}
                        maxTime={new Date().setHours(4, 0)}
                        className={'inputFieldTime'}
                    />

                  :

                    null

                  }

                  <div style={{marginTop: 10}}>Als:</div>

                  <select 
                      name="Functies" 
                      className={'dropDownField'}
                      onChange={(event) => this.selectFunction(event)}
                      style={{marginTop: 5}}
                  >
                      <option value={null} selected={true} disabled hidden>Selecteer een functie</option>
                      {functions}
                  </select>

                </div>
            
                <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 1 ?

                        <div>

                            <div className='submitButton' onClick={() => this.editShift()}>Opslaan</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.deleteAlert()}>Verwijderen</div>

                        </div>

                    :

                        <div className='submitButton' onClick={() => this.saveShift()}>Toevoegen</div>

                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default ShiftsModal;