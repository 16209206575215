/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './teamsModal.css';
import '../../constants/DefaultStyle.css';
import '../../pages/planner/planner.scss';

//js components & classes
import Colors from '../../constants/Colors';
import Data from '../../constants/Data';
import Constants from '../../constants/Constants';
import BetterAlert from '../alert/alert';
import APIGetOptions from '../../classes/APIGetOptions';
import APIEditAccount from '../../classes/APIEditAccount';
import APIEditAccountInfo from '../../classes/APIEditAccountInfo';

class TeamsModal extends React.Component {

  constructor(props) {

    super(props);

	this.props = props;
    
    this.state = {

		showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

    //componentWillMount here:

  }

  componentDidMount() {

  }

  getTeamsBars() {

    let teams = [];

    for(const team of this.props._teams) {

        teams.push(

			<option value={team.team_id} selected={Data.data.chosenTeam === parseInt(team.team_id)}>{team.team_naam}</option>

            // <div 
            //     key={team.team_id} 
            //     className={'inputField2'} 
            //     style={{
			// 		width: '75%',
			// 		marginLeft: '10%',
            //         alignItems: 'center',
            //         marginTop: '1vw',
            //         borderColor: '#EBEBEB',
            //         cursor: 'pointer',
            //     }}
            //     onClick={() => this.selectTeam(team)}
            // >

            //     {team.team_naam}

            // </div>

        );

    }

    return teams;

  }

  getTeamByID(id) {

	for (const team of Data.data.teams) {
		if (team.team_id === id) {
			return team;
		}
	}

	return null;

  }

  async selectTeam(event) {

	if (event.target.value === 'null') {
		return;
	}

	const team = this.getTeamByID(event.target.value);
	  
	Data.set('loggedIn', true);

	Data.data.chosenTeam = parseInt(team.team_id);
	Data.data.chosenTeamObj = team;

	if (typeof this.props._liveSwitch === 'undefined') {

		if (this.props._clock === true) {
			this.props.history.push('/time_registration');
		} else {
			this.props.history.push('/schedule');

			setTimeout(() => {
				this.props.history.push('/home');
			}, 1);
		}

	} else {

		if (this.props._liveSwitch === 'planner') {
			this.props._parent.reloadScreen2FullScreenTeam();
		} else if (this.props._liveSwitch === 'activeUsers') {
			this.props._parent.refresh();
		} else if (this.props._liveSwitch === 'finances') {
			this.props._parent.reloadData();
		} else if (this.props._liveSwitch === 'hours') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'schedule') {
			this.props._parent.setTeam(team);
			setTimeout(() => {
				this.props._parent.reloadScreen2FullScreen();
			}, 1);
		} else if (this.props._liveSwitch === 'tradeRequests') {
			this.props._parent.reloadScreen();
		} else if (this.props._liveSwitch === 'availableShifts') {
			this.props._parent.reloadScreen();
		} else if (this.props._liveSwitch === 'availabilities') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'absences') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'employees') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'plannerArrange') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'holidayRequests') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'requests') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'manage') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'reports') {
			this.props._parent.hardReloadScreen();
		} 

	}
	
	if(this.props._login !== true) {
		this.props._closeModal();
	}

  }

  selectTeamButton(team) {
	  
	Data.set('loggedIn', true);

	Data.data.chosenTeam = parseInt(team.team_id);
	Data.data.chosenTeamObj = team;

	if (typeof this.props._liveSwitch === 'undefined') {

		if (this.props._clock === true) {
			this.props.history.push('/time_registration');
		} else {
			this.props.history.push('/schedule');

			setTimeout(() => {
				this.props.history.push('/home');
			}, 1);
		}

	} else {

		if (this.props._liveSwitch === 'planner') {
			this.props._parent.reloadScreen2FullScreenTeam();
		} else if (this.props._liveSwitch === 'activeUsers') {
			this.props._parent.refresh();
		} else if (this.props._liveSwitch === 'finances') {
			this.props._parent.reloadData();
		} else if (this.props._liveSwitch === 'hours') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'schedule') {
			this.props._parent.setTeam(team);
			setTimeout(() => {
				this.props._parent.reloadScreen2FullScreen();
			}, 1);
		} else if (this.props._liveSwitch === 'tradeRequests') {
			this.props._parent.reloadScreen();
		} else if (this.props._liveSwitch === 'availableShifts') {
			this.props._parent.reloadScreen();
		} else if (this.props._liveSwitch === 'availabilities') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'absences') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'employees') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'plannerArrange') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'holidayRequests') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'requests') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'manage') {
			this.props._parent.componentDidMount();
		} else if (this.props._liveSwitch === 'reports') {
			this.props._parent.hardReloadScreen();
		} 

	}
	
	if(this.props._login !== true) {
		this.props._closeModal();
	}

  } 

	stopTutorialAlert() {

		this.setState({
			alertTitle: 'Web-uitleg afsluiten',
			alertBody: (
			  <div>
	
				Wil je stoppen met het volgen van de web-uitleg?
	
			  </div>
			),
			alertButtons: 2,
			alertButtonText: ['Doorgaan', 'Afsluiten'],
			alertButtonAction: this.stopTutorial.bind(this),
			showAlert: true,
		});
	
	}

	closeAlert() {

		this.setState({ showAlert: false });
	
	  }

  render() {

    return (
        <div id="teamsModal" className="teamsModal" onClick={(e) => e.target === document.getElementById("zteamsModal") ? this.props._closeModal() : null}>

			{this.state.showAlert === true ?
                
				<BetterAlert
					_closeAlert={this.closeAlert.bind(this)}
					_title={this.state.alertTitle}
					_body={this.state.alertBody}
					_buttons={this.state.alertButtons}
					_buttonText={this.state.alertButtonText}
					_buttonAction={this.state.alertButtonAction}
				/>

			:

			null
			
			}

        <div className="modal-content" style={{width: '25vw'}}>

          <div className="modal-header">

			{this.props._login !== true ?
            	<span className="modal-close" onClick={() => this.props._closeModal()}>&times;</span>
			: null}

            <span>Selecteer een team</span>

          </div>

            <div className="modal-body" style={{marginBottom: Data.data.chosenTeam !== null ? null : 0}}>

                {/* {this.getTeamsBars()} */}

				  <select
					  name="Selecteer een team"
					  className={'planner_dropdown'}
					  onChange={(event) => this.selectTeam(event)}
				  >
					  <option value={'null'} disabled>Teams</option>
					  	{this.props._login === true ?
							<option value={'null'} selected={'false'}>Selecteer een team...</option>
						: null}
					  {this.getTeamsBars()}
				  </select>

			</div>

			  {Data.data.chosenTeam !== null ?
				this.state.loading === true ?

					<div className='modal-bottom'>

					<div className='modal-loadingButtonText'>
						<div className="lds-dual-ring-button" />
					</div>

					</div>

				:

					<div className='modal-bottom'>

						<div className='modal-submitButton' onClick={() => this.selectTeamButton(Data.data.chosenTeamObj)}>
						<span>Verder</span>
						</div>

					</div>

			  : null}

        </div>

      </div>
    );
  }
  
}

export default TeamsModal;