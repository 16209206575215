import React from 'react';
import './demo.css';
import '../home2/home2.css';
import '../../constants/DefaultStyle.css';
import '../employees/employees.css';
import '../options/reports/reports.scss';
import '../options/hoursRegistration/hoursRegistration.css';
import '../../components/changeAccountModal/changeAccountModal.css';

import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import BetterAlert from '../../components/alert/alert';

import APIGETLOGIN from '../../classes/login/APIGETLOGIN';
import APIADDLOGIN from '../../classes/login/APIADDLOGIN';
import APIEDITLOGIN from '../../classes/login/APIEDITLOGIN';
import APIDELETELOGIN from '../../classes/login/APIDELETELOGIN';
import Constants from '../../constants/Constants';
import Colors from '../../constants/Colors';
import { Prompt } from 'react-router';

import CryptoJS from 'crypto-js';

import APIAddOrganization from '../../classes/APIAddOrganization';
import APISendWelcomeMail from '../../classes/APISendWelcomeMail';

import { toast } from 'react-toastify';
import APIEDIT from '../../classes/global/APIEDIT';
import ContractModal from './contractModal';

class Demo extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }  
    
    this.state = {

        loading: true,
        reloading: false,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showModal: false,
        showAddModal: false,
        modalMode: 0,
        modalData: null,
        modalDate: null,

        companies: [],
        companies2: [],
        servers: [],
        selectedCompany: null,
        companyInfo: null,
        oldCompanyInfo: null,

        commentsLead: [],
        comment: '',

        code: '',
        serverID: 1,

        menu: 'general',

        errorText: '',

    };

    //componentWillMount here

  }

  async componentDidMount() {

    // ***************** ADD TEAMS **********************
    //which db
    // const dbTeams = `testtest`;

    // // to add teams for first time, first do this and check team id where to add rooster
    // const teams = await APIGET.Request(`SELECT *`, `FROM Team`, null, null, null, null, dbTeams);
    // console.log(teams);

    // // then uncomment this
    // const rooster = await APIGET.Request(`SELECT *`, `FROM Rooster`, null, null, null, null, dbTeams);
    // for (const r of rooster) {
    //   await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, 1, ${r.rooster_id})`, dbTeams);
    // }
    // const omzet = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, null, null, null, dbTeams);
    // for (const o of omzet) {
    //   await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${o.omzetd_id}, 1)`, dbTeams);
    // }
    // const omzetV = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, null, null, null, dbTeams);
    // for (const o of omzetV) {
    //   await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${o.omzetv_id}, 1)`, dbTeams);
    // }
    // const shiftTemplates = await APIGET.Request(`SELECT *`, `FROM DagDienstSjabloon`, null, null, null, null, dbTeams);
    // for (const s of shiftTemplates) {
    //   await APIEDIT.Request(`UPDATE DagDienstSjabloon`, `SET dagdienstsjabloon_team_id = 1`, `WHERE dagdienstsjabloon_id >= 0`, dbTeams);
    // }
    // console.log('done');
    // ***************** ADD TEAMS **********************

    // ***************** COMBINING DB TEAMS **********************
    //which db
    // const dbTeams = `nypdoetnijm`;

    // // to add teams for first time, first do this and check team id where to add rooster
    // const teams = await APIGET.Request(`SELECT *`, `FROM Team`, null, null, null, null, dbTeams);
    // console.log(teams);

    // // then uncomment this
    // // which db to combine from
    // const dbTeams2 = `nypnijmegen`;
    // const addFrom = 200;
    // const whichTeam = 3;

    // const accountsC = await APIGET.Request(`SELECT *`, `FROM Account`, null, null, null, null, dbTeams2);
    // const infoC = await APIGET.Request(`SELECT *`, `FROM Info`, null, null, null, null, dbTeams2);
    // const infoExtraC = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null, dbTeams2);
    // const contractsC = await APIGET.Request(`SELECT *`, `FROM ContractN`, null, null, null, null, dbTeams2);
    // const roosterC = await APIGET.Request(`SELECT *`, `FROM Rooster`, null, null, null, null, dbTeams2);

    // for (const a of accountsC) {
    //   const userID = parseInt(a.account_id) + addFrom;
    //   await APIADD.Request(`INSERT INTO Account`, `VALUES (${userID}, '${a.account_username}stanna', '${a.account_password}', ${a.account_rights})`, dbTeams);
    // }
    // for (const a of infoC) {
    //   const userID = parseInt(a.info_id) + addFrom;
    //   const firstName = Constants.isEmpty(a.info_voornaam) ? 'NULL' : `'${a.info_voornaam}'`;
    //   const insertion = Constants.isEmpty(a.info_tussenvoegsel) ? 'NULL' : `'${a.info_tussenvoegsel}'`;
    //   const lastName = Constants.isEmpty(a.info_achternaam) ? 'NULL' : `'${a.info_achternaam}'`;
    //   const phone = Constants.isEmpty(a.info_telefoon) ? 'NULL' : `'${a.info_telefoon}'`;
    //   const email = Constants.isEmpty(a.info_email) ? 'NULL' : `'${a.info_email}'`;
    //   const comment = Constants.isEmpty(a.info_extra) ? 'NULL' : `'${a.info_extra}'`;
    //   const address = Constants.isEmpty(a.info_adres) ? 'NULL' : `'${a.info_adres}'`;
    //   const house = Constants.isEmpty(a.info_huisnummer) ? 'NULL' : `'${a.info_huisnummer}'`;
    //   const zip = Constants.isEmpty(a.info_postcode) ? 'NULL' : `'${a.info_postcode}'`;
    //   const birth = Constants.isEmpty(a.info_geboorte) ? 'NULL' : `'${a.info_geboorte}'`;
    //   const startdate = Constants.isEmpty(a.info_indienst) ? 'NULL' : `'${a.info_indienst}'`;
    //   const iban = Constants.isEmpty(a.info_ibnr) ? 'NULL' : `'${a.info_ibnr}'`;
    //   const idnr = Constants.isEmpty(a.info_idnr) ? 'NULL' : `'${a.info_idnr}'`;
    //   const city = Constants.isEmpty(a.info_plaats) ? 'NULL' : `'${a.info_plaats}'`;
    //   const phone2 = Constants.isEmpty(a.info_telefoon2) ? 'NULL' : `'${a.info_telefoon2}'`;
    //   const birthplace = Constants.isEmpty(a.info_geboorteplaats) ? 'NULL' : `'${a.info_geboorteplaats}'`;
    //   await APIADD.Request(`INSERT INTO Info`, `VALUES (${userID}, ${firstName}, ${insertion}, ${lastName}, ${phone}, ${email}, NULL, ${comment}, ${address}, ${house}, ${zip}, ${birth}, 1, ${startdate}, ${iban}, ${idnr}, ${city}, ${phone2}, ${birthplace})`, dbTeams);
    //   await APIADD.Request(`INSERT INTO TeamUsers`, `VALUES (NULL, ${whichTeam}, ${userID})`, dbTeams);
    // }
    // for (const a of infoExtraC) {
    //   const userID = parseInt(a.info2_info_id) + addFrom;
    //   await APIADD.Request(`INSERT INTO InfoExtra`, `VALUES (NULL, ${userID}, ${a.info2_max_dagen}, '${a.info2_max_eind}', ${a.info2_opeenvolgend}, ${a.info2_registreren}, ${a.info2_werkdag_maandag}, ${a.info2_werkdag_dinsdag}, ${a.info2_werkdag_woensdag}, ${a.info2_werkdag_donderdag}, ${a.info2_werkdag_vrijdag}, ${a.info2_werkdag_zaterdag}, ${a.info2_werkdag_zondag}, NULL, ${a.info2_werkweek}, NULL, NULL, ${a.info2_personeelsnummer})`, dbTeams);
    // }
    // for (const a of contractsC) {
    //   const userID = parseInt(a.contract_info_id) + addFrom;
    //   const contractStart = Constants.isEmpty(a.contract_begin) ? 'NULL' : `'${a.contract_begin}'`;
    //   const contractEnd = Constants.isEmpty(a.contract_eind) ? 'NULL' : `'${a.contract_eind}'`;
    //   await APIADD.Request(`INSERT INTO ContractN`, `VALUES (NULL, ${userID}, ${contractStart}, ${contractEnd}, ${a.contract_type}, ${a.contract_uren}, ${a.contract_max_uren}, ${a.contract_uurloon}, ${a.contract_maandloon}, ${a.contract_opbouw_verlof}, ${a.contract_werkdag_uren}, ${a.contract_vaste_dagen}, ${a.contract_maandag}, ${a.contract_dinsdag}, ${a.contract_woensdag}, ${a.contract_donderdag}, ${a.contract_vrijdag}, ${a.contract_zaterdag}, ${a.contract_zondag})`, dbTeams);
    // }
    // // for (const a of roosterC) {
    // //   const userID = parseInt(a.rooster_info_id) + addFrom;
    // //   await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${a.rooster_datum}', '${a.rooster_begin}', '${a.rooster_eind}', ${a.rooster_info_id}, '${a.info_email}', NULL, '${a.info_extra}', '${a.info_adres}', '${a.info_huisnummer}', '${a.info_postcode}', '${a.info_geboorte}', 1, '${a.info_indienst}', '${a.info_ibnr}', '${a.info_idnr}', '${a.info_plaats}', '${a.info_telefoon2}', '${a.info_geboorteplaats}')`, dbTeams);
    // //   await APIADD.Request(`INSERT INTO TeamUsers`, `VALUES (NULL, ${whichTeam}, ${userID})`);
    // // }

    // console.log('done');
    // ***************** COMBINING DB TEAMS **********************

    let [
      companies2,
      companies,
      servers,
      commentsLead,
    ] = await Promise.all([
      APIGETLOGIN.Request(`SELECT *`, `FROM Aanvraag`, null, null, null, `ORDER BY aanvraag_status_acp ASC, aanvraag_datum DESC`),
      APIGETLOGIN.Request(`SELECT *`, `FROM Aanvraag`, null, null, null, `ORDER BY aanvraag_naam`),
      APIGETLOGIN.Request(`SELECT *`, `FROM Servr`, null, null, null, `ORDER BY server_id`),
      APIGETLOGIN.Request(`SELECT CommentaarLead.*, account_username, account_id`, `FROM CommentaarLead`, `LEFT JOIN Account ON account_id = cl_account_id`, null, null, `ORDER BY cl_datum DESC, cl_tijd DESC`),
    ]);

    const commentsLead_obj = Constants.convertToObjectWithArrays(commentsLead, 'cl_aanvraag_id');

    console.log(commentsLead_obj)

    this.setState({ 
      servers: servers,
      serverID: parseInt(servers[0].server_id),
      companies: companies,
      companies2: companies2,
      commentsLead: commentsLead_obj,
      loading: false
    });

  }

  hasChanges() {

    for (const key in this.state.companyInfo) {

      if (this.state.oldCompanyInfo[key] !== this.state.companyInfo[key]) {
        return true;
      }

    }

    return false;

  }

  async reload() {

    this.setState({ reloading: true });

    let [
      companies2,
      companies,
      servers,
      commentsLead,
    ] = await Promise.all([
      APIGETLOGIN.Request(`SELECT *`, `FROM Aanvraag`, null, null, null, `ORDER BY aanvraag_status_acp ASC, aanvraag_datum DESC`),
      APIGETLOGIN.Request(`SELECT *`, `FROM Aanvraag`, null, null, null, `ORDER BY aanvraag_naam`),
      APIGETLOGIN.Request(`SELECT *`, `FROM Servr`, null, null, null, `ORDER BY server_id`),
      APIGETLOGIN.Request(`SELECT CommentaarLead.*, account_username, account_id`, `FROM CommentaarLead`, `LEFT JOIN Account ON account_id = cl_account_id`, null, null, `ORDER BY cl_datum DESC, cl_tijd DESC`),
    ]);

    const commentsLead_obj = Constants.convertToObjectWithArrays(commentsLead, 'cl_aanvraag_id');

    this.setState({ 
      servers: servers,
      serverID: parseInt(servers[0].server_id),
      companies: companies,
      companies2: companies2,
      commentsLead: commentsLead_obj,
      code: '',
      reloading: false
    });

  }

  getCompanySelects() {

    let toRender = [];

    for (const company of this.state.companies) {

      toRender.push(<option value={company.aanvraag_id} selected={this.state.selectedCompany === company.aanvraag_id}>{company.aanvraag_naam}</option>);

    }

    return toRender;

  }

  changeCompany(id) {

    const companyInfo = Constants.getArrayItem(this.state.companies, 'aanvraag_id', id);

    if (this.hasChanges()) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      }
    }

    if (companyInfo !== null) {

      this.setState({

        selectedCompany: id,
        companyInfo: { ...companyInfo },
        oldCompanyInfo: { ...companyInfo },
        menu: 'general',
        comment: '',

      });

    } else {

      this.setState({

        selectedCompany: null,
        companyInfo: null,
        oldCompanyInfo: null,
        menu: 'general',
        comment: '',

      });

    }

  }

  getCompanyButtonsMenu() {

    let toRender = [];

    for (const company of this.state.companies) {

      toRender.push(
        <div className={`employees_menuButton ${this.state.selectedCompany === company.aanvraag_id ? 'activeAv' : ''}`} onClick={() => this.changeCompany(company.aanvraag_id)}>
          <div>{company.aanvraag_naam}</div>
        </div>
      );

    }

    return toRender;

  }

  selectType(event, company) {

    const status = Constants.isEmpty(event.target.value) ? 'NULL' : `${event.target.value}`;

    APIEDITLOGIN.Request(`UPDATE Aanvraag`, `SET aanvraag_status_acp = ${status}`, `WHERE aanvraag_id = ${company.aanvraag_id}`);

  }

  selectType2(e, key) {

    let companyInfo = this.state.companyInfo;
    companyInfo[key] = e.target.value;

    this.setState({ companyInfo: companyInfo });

  }

  getCompanyBars() {

    let toRender = [];
    let count = 0;

    for (const company of this.state.companies2) {

      toRender.push(

        <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>

          {/* {contractEnding ?
            <Tooltip
              options
              html={(
                <div className='appoptions_tooltipContainer'>
                  <span>Contract verloopt bijna</span>
                </div>
              )}
              style={{position: 'absolute', left: Data.data.teams.length > 0 && Data.data.chosenTeam !== null && Constants.isEmpty(teamName) === true ? '-1.8vw' : '-0.6vw', marginBottom: 2, cursor: 'pointer'}}
              position={'top'}
              followCursor
              arrow
              theme='kodict'
            >
              <i style={{fontSize: '0.7vw', color: Colors.color.redFilledIn}} className='far fa-file-exclamation' onClick={() => this.selectUser(account.info_id, userFullName, false, 'contract')} />
            </Tooltip>
          : null}

          {Data.data.teams.length > 0 && Data.data.chosenTeam !== null && Constants.isEmpty(teamName) === true ?
            <Tooltip
              options
              html={(
                <div className='appoptions_tooltipContainer'>
                  <span>Medewerker heeft nog geen team toegewezen gekregen</span>
                </div>
              )}
              style={{position: 'absolute', left: '-0.8vw', marginBottom: 2, cursor: 'pointer'}}
              position={'top'}
              followCursor
              arrow
              theme='kodict'
            >
              <i style={{fontSize: '0.7vw', color: Colors.color.redFilledIn}} className='fas fa-exclamation-triangle' onClick={() => this.selectUser(account.info_id, userFullName, false, 'teams')} />
            </Tooltip>
          : null} */}

          <div className='hr_hoursBar' style={{ backgroundColor: count === 1 ? '#F9FBFC' : null, height: '2.5vw' }}>

          {/* onClick={() => this.changeCompany(company.aanvraag_id)} */}
            <div className='hr_hoursBar_sub1' style={{  }}>
              <span className="defaultLinkO" onClick={() => this.changeCompany(company.aanvraag_id)}><b>{company.aanvraag_naam}</b></span>
            </div>

            <div className='hr_hoursBar_sub2' style={{  }}>
              <span>{company.aanvraag_eigenaar}</span>
            </div>

            <div className='hr_hoursBar_sub1' style={{  }}>
              <span>{company.aanvraag_persoonlijk_email}</span>
            </div>

            <div className='hr_hoursBar_sub2' style={{  }}>
              <span>{company.aanvraag_persoonlijk_telefoon}</span>
            </div>

            <div className='hr_hoursBar_sub1' style={{  }}>
              <select 
                name="Selecteer een status" 
                className={'dropDownField'}
                onChange={(event) => this.selectType(event, company)}
              >
                <option value={'0'} selected={'0' === company.aanvraag_status_acp || null === company.aanvraag_status_acp}>Geen</option>
                <option value={'1'} hidden selected={'1' === company.aanvraag_status_acp}>Benaderd</option>
                <option value={'7'} selected={'7' === company.aanvraag_status_acp}>1e mail gestuurd</option>
                <option value={'8'} selected={'8' === company.aanvraag_status_acp}>Mail contact gehad</option>
                <option value={'9'} selected={'9' === company.aanvraag_status_acp}>Telefonisch contact gehad</option>
                <option value={'2'} selected={'2' === company.aanvraag_status_acp}>Demo in behandeling</option>
                <option value={'3'} selected={'3' === company.aanvraag_status_acp}>Demo gegeven</option>
                <option hidden value={'4'} selected={'4' === company.aanvraag_status_acp}>Proefperiode</option>
                <option value={'5'} selected={'5' === company.aanvraag_status_acp}>Actief</option>
                <option value={'6'} selected={'6' === company.aanvraag_status_acp}>Geen interesse</option>
              </select>
            </div>

            <div className='hr_hoursBar_sub3'>
              <span>{Constants.stringToDate(company.aanvraag_datum).toLocaleDateString()}</span>
            </div>

          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  declineCompanyAlert(id) {

    this.setState({
        alertTitle: 'Lead verwijderen',
        alertBody: (<span>Weet je zeker dat je deze lead wilt verwijderen?</span>),
        alertButtons: 2,
        alertButtonAction: this.declineCompany.bind(this, id),
        alertButtonText: ['Annuleren', 'Verwijderen'],
        showAlert: true,
    });
  
  }

  async declineCompany(id) {

    this.setState({ reloading: true });

    await APIDELETELOGIN.Request(`DELETE FROM Aanvraag`, `WHERE aanvraag_id = ${id}`);
    await this.reload();

    this.changeCompany(null);

    this.setState({ reloading: false });

  }

  selectMenu(menu) {

    if (this.state.menu === menu) {
      return;
    }

    // if (this.state.companyInfo !== this.state.oldCompanyInfo) {
    //   const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    //   if (leave === false) {
    //     return;
    //   }
    // }

    this.setState({ menu: menu });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async checkValidations() {

    if (Constants.isEmpty(this.state.code)) {
      this.setState({
        showError: true,
        errorText: 'Bedrijfscode moet ingevuld worden',
      });
      return false;
    }

    if(/^[a-zA-Z0-9]*$/.test(this.state.code) === false) {
      this.setState({
        showError: true,
        errorText: 'Bedrijfscode mag alleen letters en/of cijfers bevatten',
      });
      return false;
    }

    const storeCodes = await APIGETLOGIN.Request(`SELECT bedrijf_code`, `FROM Bedrijf`, null, null, null, null);

    if (storeCodes.length > 0) {

      for (const _storecode of storeCodes) {

        if (_storecode.bedrijf_code.toLowerCase() === this.state.code.toLowerCase()) {
          this.setState({
            showError: true,
            errorText: 'Deze bedrijfscode bestaat al',
          });
          return false;
        }

      }

    }

    return true;

  }

  async acceptCompanyAlert() {

    if (await this.checkValidations() === false) {
      return;
    }

    this.setState({
        alertTitle: 'Aanmelding goedkeuren',
        alertBody: (<span>Weet je zeker dat je deze aanmelding wilt accepteren?</span>),
        alertButtons: 2,
        alertButtonAction: this.addCompany.bind(this),
        alertButtonText: ['Annuleren', 'Accepteren'],
        showAlert: true,
    });
  
  }

  async addCompany() {

    this.setState({ reloading: true });

    const companyInfo = this.state.companyInfo;

    const name = Constants.isEmpty(companyInfo.aanvraag_naam) ? 'NULL' : `'${companyInfo.aanvraag_naam}'`;
    const owner = Constants.isEmpty(companyInfo.aanvraag_eigenaar) ? 'NULL' : `'${companyInfo.aanvraag_eigenaar}'`;
    const mail = Constants.isEmpty(companyInfo.aanvraag_persoonlijk_email) ? 'NULL' : `'${companyInfo.aanvraag_persoonlijk_email}'`;
    const phone = Constants.isEmpty(companyInfo.aanvraag_persoonlijk_telefoon) ? 'NULL' : `'${companyInfo.aanvraag_persoonlijk_telefoon}'`;
    const packagee = Constants.isEmpty(companyInfo.aanvraag_pakket) ? '1' : companyInfo.aanvraag_pakket;

    const storeCode = Constants.isEmpty(this.state.code) ? null : `'${this.state.code}'`;

    if (storeCode === null) {
      this.setState({
        showError: true,
        errorText: 'Bedrijfscode mag alleen letters en/of cijfers bevatten',
        reloading: false,
      });
      return;
    }

    const trial = new Date(new Date().setMonth(new Date().getMonth() + 3));
    const generatedPass = Constants.generateString();
    const passAdmin = CryptoJS.SHA512(generatedPass).toString();

    const addedStoreLoginServer = await APIADDLOGIN.Request(`INSERT INTO Bedrijf (bedrijf_code, bedrijf_naam, bedrijf_eigenaar, bedrijf_persoonlijk_email, bedrijf_persoonlijk_telefoon, bedrijf_proef_datum, bedrijf_status, bedrijf_server_id, pakket_id)`, `VALUES ('${this.state.code.toLowerCase()}', ${name}, ${owner}, ${mail}, ${phone}, '${Constants.dateToString(trial)}', 1, ${this.state.serverID}, 101)`);

    if (addedStoreLoginServer) {

      const storeID = await APIGETLOGIN.Request(`SELECT bedrijf_id`, `FROM Bedrijf`, null, null, null, `ORDER BY bedrijf_id DESC LIMIT 1`);

      if (Constants.isEmpty(this.state.commentsLead[this.state.selectedCompany]) === false) {
        for (const comment of this.state.commentsLead[this.state.selectedCompany]) {
          await APIADDLOGIN.Request(`INSERT INTO Commentaar`, `VALUES (NULL, ${comment.cl_account_id}, ${storeID[0].bedrijf_id}, '${comment.cl_commentaar}', '${comment.cl_datum}', '${comment.cl_tijd}')`);
        }
      } 

      const server = Constants.getArrayItem(this.state.servers, 'server_id', this.state.serverID.toString());

      if (server !== null) { 

        const addedToMainServer = await APIAddOrganization.Request(this.state.code.toLowerCase(), server.server_ip, passAdmin);

        if (addedToMainServer) {

          // SET PACKAGE
          Data.data.serverIP = server.server_ip;
          await APIEDIT.Request(`UPDATE Opties`, `SET opties_versie = ${(parseInt(packagee) - 1)}`, `WHERE opties_id > 0`, this.state.code.toLowerCase());
          Data.data.serverIP = '';

          await APIDELETELOGIN.Request(`DELETE FROM Aanvraag`, `WHERE aanvraag_id = ${companyInfo.aanvraag_id}`);
          await APISendWelcomeMail.Request(companyInfo.aanvraag_persoonlijk_email, generatedPass, this.state.code.toLowerCase());

          this.changeCompany(null);
          await this.reload();

          toast.success('😄 Aanmelding is goedgekeurd!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            icon: false, 
          });
          return;

        }

      }

    }

    toast.error('😔 Er is iets misgegaan', {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      icon: false, 
    });

    this.setState({ reloading: false });

  }

  saveCompanyAlert() {

    this.setState({
        alertTitle: 'Bedrijf opslaan',
        alertBody: (<span>Weet je zeker dat je deze informatie wilt opslaan?</span>),
        alertButtons: 2,
        alertButtonAction: this.saveCompany.bind(this),
        alertButtonText: ['Annuleren', 'Opslaan'],
        showAlert: true,
    });
  
  }

  async saveCompany() {

    this.setState({ reloading: true });

    const companyInfo = this.state.companyInfo;

    const name = Constants.isEmpty(companyInfo.aanvraag_naam) ? 'NULL' : `'${companyInfo.aanvraag_naam}'`;
    const owner = Constants.isEmpty(companyInfo.aanvraag_eigenaar) ? 'NULL' : `'${companyInfo.aanvraag_eigenaar}'`;
    const mail = Constants.isEmpty(companyInfo.aanvraag_persoonlijk_email) ? 'NULL' : `'${companyInfo.aanvraag_persoonlijk_email}'`;
    const phone = Constants.isEmpty(companyInfo.aanvraag_persoonlijk_telefoon) ? 'NULL' : `'${companyInfo.aanvraag_persoonlijk_telefoon}'`;
    const address = Constants.isEmpty(companyInfo.aanvraag_adres) ? 'NULL' : `'${companyInfo.aanvraag_adres}'`;
    const zip = Constants.isEmpty(companyInfo.aanvraag_postcode) ? 'NULL' : `'${companyInfo.aanvraag_postcode}'`;
    const place = Constants.isEmpty(companyInfo.aanvraag_plaats) ? 'NULL' : `'${companyInfo.aanvraag_plaats}'`;
    const packagee = Constants.isEmpty(companyInfo.aanvraag_pakket) ? 'NULL' : companyInfo.aanvraag_pakket;
    const state = Constants.isEmpty(companyInfo.aanvraag_status_acp) ? 'NULL' : companyInfo.aanvraag_status_acp;

    await APIEDITLOGIN.Request(`UPDATE Aanvraag`, `SET aanvraag_naam = ${name}, aanvraag_eigenaar = ${owner}, aanvraag_persoonlijk_email = ${mail}, aanvraag_persoonlijk_telefoon = ${phone}, aanvraag_adres = ${address}, aanvraag_postcode = ${zip}, aanvraag_plaats = ${place}, aanvraag_pakket = ${packagee}, aanvraag_status_acp = ${state}`, `WHERE aanvraag_id = ${this.state.companyInfo.aanvraag_id}`);

    this.setState({ oldCompanyInfo: companyInfo });

    this.reload();

  }

  editInfo(e, key) {

    let companyInfo = this.state.companyInfo;
    companyInfo[key] = e.target.value;

    this.setState({ companyInfo: companyInfo });

  }

  showError() {

    if(Constants.isEmpty(this.state.errorText) === false) {
      return <div className='errorText' style={{marginTop: '1vw', marginLeft: 0, marginBottom: 0}}>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  getServerOptions() {

    let toRender = [];

    for (const server of this.state.servers) {

      toRender.push(
        <option value={server.server_id} selected={this.state.serverID === parseInt(server.server_id)}>{server.server_ip}</option>
      );

    }

    return toRender;

  }

  deleteCommentAlert(comment) {

    this.setState({
        alertTitle: 'Opmerking verwijderen',
        alertBody: (<span>Weet je zeker dat je deze opmerking wilt verwijderen?</span>),
        alertButtons: 2,
        alertButtonAction: this.deleteComment.bind(this, comment),
        alertButtonText: ['Annuleren', 'Verwijderen'],
        showAlert: true,
    });
  
  }

  async deleteComment(comment) {

    this.setState({ reloading: true });

    const date = new Date();

    await APIDELETELOGIN.Request(`DELETE FROM CommentaarLead`, `WHERE cl_id = ${comment.cl_id}`);

    const commentsLead = await APIGETLOGIN.Request(`SELECT CommentaarLead.*, account_username, account_id`, `FROM CommentaarLead`, `LEFT JOIN Account ON account_id = cl_account_id`, null, null, `ORDER BY cl_datum DESC, cl_tijd DESC`);
    const commentsLead_obj = Constants.convertToObjectWithArrays(commentsLead, 'cl_aanvraag_id');

    this.setState({
      reloading: false,
      commentsLead: commentsLead_obj,
    });

  }

  renderComments() {

    let toRender = [];

    const comments = this.state.commentsLead[this.state.selectedCompany];

    if (Constants.isEmpty(comments) === false) {

      for (const comment of comments) {

        const date = Constants.stringToDateTime(comment.cl_datum, comment.cl_tijd);

        toRender.push(
          <div key={'comment'+comment.cl_id} className='comment_container'>

            {Data.data.accountData.account_id === comment.cl_account_id ?
              <i className='fas fa-minus-circle changeAccModal_accountBar_remove' onClick={() => this.deleteCommentAlert(comment)} />
            : null}

            <div className='comment_container_sub1'>

              <b>Naam</b>
              <span style={{ fontWeight: 600, marginTop: '0.5vw' }}>{comment.account_username}</span>
              <span style={{ fontStyle: 'italic', marginTop: '0.2vw', fontSize: '0.7vw' }}>{Constants.dateToString(date)}  {date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:{date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:{date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}</span>

            </div>

            <div className='comment_container_sub2'>

              <b>Opmerking</b>
              <span style={{ marginTop: '0.5vw' }}>{comment.cl_commentaar}</span>

            </div>

          </div>
        );

      }

    }

    return toRender;

  }

  async addComment() {

    if (Constants.isEmpty(this.state.comment) === true) {
      return;
    }

    this.setState({ reloading: true });

    const date = new Date();

    await APIADDLOGIN.Request(`INSERT INTO CommentaarLead`, `VALUES (NULL, ${Data.data.accountData.account_id}, ${this.state.selectedCompany}, '${this.state.comment}', '${Constants.dateToString(date)}', '${date.getHours() < 10 ? '0' + date.getHours(): date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes(): date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds(): date.getSeconds()}')`);

    const commentsLead = await APIGETLOGIN.Request(`SELECT CommentaarLead.*, account_username, account_id`, `FROM CommentaarLead`, `LEFT JOIN Account ON account_id = cl_account_id`, null, null, `ORDER BY cl_datum DESC, cl_tijd DESC`);
    const commentsLead_obj = Constants.convertToObjectWithArrays(commentsLead, 'cl_aanvraag_id');

    this.setState({
      reloading: false,
      commentsLead: commentsLead_obj,
      comment: '',
    });

  }

  openAddModal() {
    this.setState({ showAddModal: true });
  }

  closeAddModal() {
    this.setState({ showAddModal: false });
  }

  render() {

    return (

      <div>

      <Prompt
        when={this.hasChanges()}
        message='You have unsaved changes. Are you sure you want to leave?'
      />

      {this.state.showAddModal ?
        <ContractModal
          _closeModal={this.closeAddModal.bind(this)}
          _modalData={this.state.modalData}
          _modalMode={this.state.modalMode}
          _reloadScreen={this.reload.bind(this)}
        />
      : null}

      <Navbar ref={this.navBar} history={this.props.history} active={'demo'} fixed loading={this.state.loading} reloading={this.state.reloading} parent={this} />

      <div className='employees_main2'>
        {/* stickyTopMenuPanel */}
        <div className='employees_menu2' style={{width: '20%', height: 'calc(100vh - 6vw - 1px)' }}>

            <div className='employees_menuTitle'>
              <div>Leads</div>
            </div>

            <div className={`employees_menuButton ${this.state.selectedCompany === null ? 'activeAv' : ''}`} onClick={() => this.changeCompany(null)}>
              <div><span style={{fontSize: '0.8vw', fontWeight: 600}}>Overzicht alle leads</span></div>
            </div>

            <div style={{width: 'calc(100% - 0.5vw)', marginLeft: '0.5vw', marginBottom: '0.5vw', marginTop: '0.5vw'}}>
              <select
                name="Selecteer een medewerker"
                className={'hr_dropdown'}
                onChange={(event) => this.changeCompany(event.target.value)}
              >
                <option value={'null'} disabled>Selecteer een lead</option>
                <option value={'null'} selected={this.state.selectedCompany === null}>Alle leads</option>
                <option value={'null'} disabled>&nbsp;</option>
                {this.getCompanySelects()}
              </select>
            </div>

            {this.getCompanyButtonsMenu()}

        </div>

        {this.state.selectedCompany !== null && this.state.companyInfo !== null ?

          <div className='employees_menu2' style={{width: '13%'}}>

              <div className='employees_menuTitle'>
                <div>Menu</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'general' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('general')}>
                <div>Algemeen</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'accept' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('accept')}>
                <div>Aanmelden proef</div>
              </div>

              {/* <div className={`employees_menuButton ${this.state.menu === 'time' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('time')}>
                <div>Tijdzone {`&`} valuta</div>
              </div>

              <div className={`employees_menuButton ${this.state.menu === 'server' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('server')}>
                <div>Server</div>
              </div> */}

          </div>

        : null}

        <div className='employees_content2' style={{width: this.state.selectedCompany !== null && this.state.companyInfo !== null ? 'calc(67% - 2px)' : 'calc(80% - 1px)', fontSize: '0.8vw'}}>

          {this.state.loading === true ?
            
            <div className='lds-dual-ring loader' />

          :

            <div className='employees_container'>

              {this.state.reloading === true ?
                          
                <div className='lds-dual-ring loader2' />

              :

                null

              }

              {this.state.showAlert === true ?
                
                <BetterAlert
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  _buttonAction2={this.state.alertButtonAction2}
                />

              :

                null
              
              }

              {this.state.selectedCompany === null ?

                <div>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Leads</div>

                  <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                    <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.openAddModal()}>Toevoegen</div>

                  </div>

                  <div style={{ marginTop: '2vw' }}>

                    <div className='hr_hoursBar_title'>

                    {/* onClick={() => this.changeCompany(company.aanvraag_id)} */}
                      <div className='hr_hoursBar_sub1' style={{  }}>
                        <span><b>Bedrijfsnaam</b></span>
                      </div>

                      <div className='hr_hoursBar_sub2' style={{  }}>
                        <span>Eigenaar</span>
                      </div>

                      <div className='hr_hoursBar_sub1' style={{  }}>
                        <span>E-mail</span>
                      </div>

                      <div className='hr_hoursBar_sub2' style={{  }}>
                        <span>Telefoon</span>
                      </div>

                      <div className='hr_hoursBar_sub1' style={{  }}>
                        <span>Status</span>
                      </div>

                      <div className='hr_hoursBar_sub3'>
                        <span>Datum</span>
                      </div>

                    </div>

                    {this.getCompanyBars()}

                  </div>

                </div>

              :
              this.state.companyInfo !== null ?

                <div>

                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.changeCompany(null)}>
                    <i className='fas fa-chevron-left' style={{ fontSize: '1vw' }} />
                    <span style={{ marginLeft: '0.8vw', fontSize: '1vw', fontWeight: 600 }}>Terug</span>
                  </div>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginTop: '1vw'}}>{this.state.oldCompanyInfo.bedrijf_naam}</div>

                  {this.state.menu === 'general' ?
                  
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Algemene informatie</div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                        <div className='planner_new_publishButton' style={{marginLeft: 0, width: '5.5vw'}} onClick={() => this.saveCompanyAlert()}>Opslaan</div>

                        <div className='planner_new_deleteButton' style={{marginLeft: '0.6vw', width: '7vw', position: 'relative'}} onClick={() => this.declineCompanyAlert(this.state.companyInfo.aanvraag_id)}>Verwijderen</div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Status</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <select 
                              name="Selecteer een status" 
                              className={'dropDownField'}
                              onChange={(event) => this.selectType2(event, 'aanvraag_status_acp')}
                            >
                              <option value={'0'} selected={'0' === this.state.companyInfo.aanvraag_status_acp || null === this.state.companyInfo.aanvraag_status_acp}>Geen</option>
                              <option value={'1'} hidden selected={'1' === this.state.companyInfo.aanvraag_status_acp}>Benaderd</option>
                              <option value={'7'} selected={'7' === this.state.companyInfo.aanvraag_status_acp}>1e mail gestuurd</option>
                              <option value={'8'} selected={'8' === this.state.companyInfo.aanvraag_status_acp}>Mail contact gehad</option>
                              <option value={'9'} selected={'9' === this.state.companyInfo.aanvraag_status_acp}>Telefonisch contact gehad</option>
                              <option value={'2'} selected={'2' === this.state.companyInfo.aanvraag_status_acp}>Demo in behandeling</option>
                              <option value={'3'} selected={'3' === this.state.companyInfo.aanvraag_status_acp}>Demo gegeven</option>
                              <option value={'4'} hidden selected={'4' === this.state.companyInfo.aanvraag_status_acp}>Proefperiode</option>
                              <option value={'5'} selected={'5' === this.state.companyInfo.aanvraag_status_acp}>Actief</option>
                              <option value={'6'} selected={'6' === this.state.companyInfo.aanvraag_status_acp}>Geen interesse</option>
                            </select>

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '2vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Eigenaar</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={this.state.companyInfo.aanvraag_eigenaar}
                              onChange={(event) => this.editInfo(event, 'aanvraag_eigenaar')}
                              placeholder={'Eigenaar :'}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>E-mail</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={this.state.companyInfo.aanvraag_persoonlijk_email}
                              onChange={(event) => this.editInfo(event, 'aanvraag_persoonlijk_email')}
                              placeholder={'E-mailadres :'}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Telefoon</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={this.state.companyInfo.aanvraag_persoonlijk_telefoon}
                              onChange={(event) => this.editInfo(event, 'aanvraag_persoonlijk_telefoon')}
                              placeholder={'Telefoonnummer :'}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '2vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Bedrijfsnaam</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={this.state.companyInfo.aanvraag_naam}
                              onChange={(event) => this.editInfo(event, 'aanvraag_naam')}
                              placeholder={'Bedrijfsnaam :'}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Adres</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={this.state.companyInfo.aanvraag_adres}
                              onChange={(event) => this.editInfo(event, 'aanvraag_adres')}
                              placeholder={'Adres :'}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Postcode</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={this.state.companyInfo.aanvraag_postcode}
                              onChange={(event) => this.editInfo(event, 'aanvraag_postcode')}
                              placeholder={'Postcode :'}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Plaats</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={this.state.companyInfo.aanvraag_plaats}
                              onChange={(event) => this.editInfo(event, 'aanvraag_plaats')}
                              placeholder={'Plaats :'}
                            />

                          </div>

                      </div>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '4vw'}}>Opmerkingen</div>

                      <div>
                        {this.renderComments()}
                      </div>

                      <div className='modal-row2' style={{marginTop: '2vw'}}>

                          <div className='modal-rowSub1'>
                            <span><b>Opmerking plaatsen</b></span>
                            <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Max. 1000 tekens</span>
                          </div>

                          <div className='modal-rowSub2'>
                            <textarea
                              className={'inputFieldBig'}
                              onChange={(event) => this.setState({ comment: event.target.value })}
                              value={this.state.comment}
                              placeholder={'Opmerking'}
                              maxLength={1000}
                            />
                            <div className='planner_new_publishButton' style={{marginLeft: 0, marginTop: '0.5vw', width: '5.5vw', alignSelf: 'flex-end', justifySelf: 'flex-end'}} onClick={() => this.addComment()}>Plaatsen</div>
                          </div>

                      </div>

                    </div>
                
                  :
                  this.state.menu === 'accept' ?
                  
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Bedrijf aanmelden proefperiode</div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Pakket</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <select 
                                name="Selecteer een pakket" 
                                className={'dropDownField'}
                                onChange={(event) => this.editInfo(event, 'aanvraag_pakket')}
                            >
                                <option value="2" selected={this.state.companyInfo.aanvraag_pakket === '2'}>Personeelsplanning</option>
                                <option value="3" selected={this.state.companyInfo.aanvraag_pakket === '3'}>Personeelsplanning + inkloksysteem</option>
                            </select>

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '2vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Bedrijfscode</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={this.state.code}
                              onChange={(event) => this.setState({ code: event.target.value })}
                              placeholder={'Bedrijfscode :'}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Server</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <select 
                                name="Selecteer een server" 
                                className={'dropDownField'}
                                onChange={(event) => this.setState({ serverID: parseInt(event.target.value) })}
                            >
                                {this.getServerOptions()}
                            </select>

                          </div>

                      </div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                        <div className='planner_new_publishButton' style={{marginLeft: 0, width: '6.5vw'}} onClick={() => this.acceptCompanyAlert()}>Aanmelden</div>

                      </div>

                      <span style={{ fontSize: '0.6vw', fontStyle: 'italic' }}>Het aanmelden van een aanmelding 10-15 seconden duren</span>

                      {this.showError()}

                    </div>
                
                  // :
                  // this.state.menu === 'time' ?
                  
                  //   <div style={{ display: 'flex', flexDirection: 'column' }}>

                  //     <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Tijdzone informatie</div>

                  //   </div>
                
                  // :
                  // this.state.menu === 'server' ?
                  
                  //   <div style={{ display: 'flex', flexDirection: 'column' }}>

                  //     <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Server informatie</div>

                  //   </div>
                
                  : null}

                </div>

              :

                null

              }

            </div>

          }

        </div>

      </div>

    </div>
    );
  }
  
}

export default Demo;