import React from 'react';
import './trials.css';
import '../../constants/DefaultStyle.css';
import '../employees/employees.css';
import '../options/reports/reports.scss';

import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import BetterAlert from '../../components/alert/alert';

import APIGETLOGIN from '../../classes/login/APIGETLOGIN';
import APIADDLOGIN from '../../classes/login/APIADDLOGIN';
import APIEDITLOGIN from '../../classes/login/APIEDITLOGIN';
import APIDELETELOGIN from '../../classes/login/APIDELETELOGIN';
import Constants from '../../constants/Constants';
import Colors from '../../constants/Colors';
import { Prompt } from 'react-router';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import "react-datepicker/dist/react-datepicker.css";
import APIGET from '../../classes/global/APIGET';
import APIEDIT from '../../classes/global/APIEDIT';

import SectionModal from '../options/sections/sectionsModal';
import FunctionsModal from '../options/functions/functionsModal';

class Trials extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }  
    
    this.state = {

      loading: true,
      reloading: false,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,

      showModal: false,
      showSectionsModal: false,
      showFunctionsModal: false,
      modalMode: 0,
      modalData: null,
      modalDate: null,

      companies: [],
      companies2: [],
      selectedCompany: null,
      companyInfo: null,
      oldCompanyInfo: null,

      companyData: {},

      oldPackages: [],
      servers: [],

      selectedPackage: null,

      orderBy: 'ORDER BY bedrijf_proef_datum DESC',

      menu: 'general',

      comments: [],
      comment: '',

      checkedCompanies: {},

      timezoneList: {
        "Etc/GMT+12": "(GMT-12:00) International Date Line West",
        "Pacific/Midway": "(GMT-11:00) Midway Island, Samoa",
        "Pacific/Honolulu": "(GMT-10:00) Hawaii",
        "US/Alaska": "(GMT-09:00) Alaska",
        "America/Los_Angeles": "(GMT-08:00) Pacific Time (US & Canada)",
        "US/Arizona": "(GMT-07:00) Arizona",
        "America/Managua": "(GMT-06:00) Central America",
        "US/Central": "(GMT-06:00) Central Time (US & Canada)",
        "America/Bogota": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
        "US/Eastern": "(GMT-05:00) Eastern Time (US & Canada)",
        "Canada/Atlantic": "(GMT-04:00) Atlantic Time (Canada)",
        "America/Argentina/Buenos_Aires": "(GMT-03:00) Buenos Aires, Georgetown",
        "America/Noronha": "(GMT-02:00) Mid-Atlantic",
        "Atlantic/Azores": "(GMT-01:00) Azores",
        "Etc/Greenwich": "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
        "Europe/Amsterdam": "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        "Europe/Helsinki": "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        "Europe/Moscow": "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
        "Asia/Tehran": "(GMT+03:30) Tehran",
        "Asia/Yerevan": "(GMT+04:00) Yerevan",
        "Asia/Kabul": "(GMT+04:30) Kabul",
        "Asia/Yekaterinburg": "(GMT+05:00) Yekaterinburg",
        "Asia/Karachi": "(GMT+05:00) Islamabad, Karachi, Tashkent",
        "Asia/Calcutta": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        "Asia/Katmandu": "(GMT+05:45) Kathmandu",
        "Asia/Dhaka": "(GMT+06:00) Astana, Dhaka",
        "Asia/Rangoon": "(GMT+06:30) Yangon (Rangoon)",
        "Asia/Bangkok": "(GMT+07:00) Bangkok, Hanoi, Jakarta",
        "Asia/Hong_Kong": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        "Asia/Seoul": "(GMT+09:00) Seoul",
        "Australia/Adelaide": "(GMT+09:30) Adelaide",
        "Australia/Canberra": "(GMT+10:00) Canberra, Melbourne, Sydney",
        "Asia/Magadan": "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
        "Pacific/Auckland": "(GMT+12:00) Auckland, Wellington",
        "Pacific/Tongatapu": "(GMT+13:00) Nuku'alofa"
      },

      currencies: {
        "EUR": "Euro",
        "USD": "United States Dollar",
        "GBP": "British Pound Sterling",
        "JPY": "Japanese Yen",
        "NONE": "-------------------------",
        "AED": "United Arab Emirates Dirham",
        "AFN": "Afghan Afghani",
        "ALL": "Albanian Lek",
        "AMD": "Armenian Dram",
        "ANG": "Netherlands Antillean Guilder",
        "AOA": "Angolan Kwanza",
        "ARS": "Argentine Peso",
        "AUD": "Australian Dollar",
        "AWG": "Aruban Florin",
        "AZN": "Azerbaijani Manat",
        "BAM": "Bosnia-Herzegovina Convertible Mark",
        "BBD": "Barbadian Dollar",
        "BDT": "Bangladeshi Taka",
        "BGN": "Bulgarian Lev",
        "BHD": "Bahraini Dinar",
        "BIF": "Burundian Franc",
        "BMD": "Bermudan Dollar",
        "BND": "Brunei Dollar",
        "BOB": "Bolivian Boliviano",
        "BRL": "Brazilian Real",
        "BSD": "Bahamian Dollar",
        "BTC": "Bitcoin",
        "BTN": "Bhutanese Ngultrum",
        "BWP": "Botswanan Pula",
        "BYN": "Belarusian Ruble",
        "BZD": "Belize Dollar",
        "CAD": "Canadian Dollar",
        "CDF": "Congolese Franc",
        "CHF": "Swiss Franc",
        "CLF": "Chilean Unit of Account (UF)",
        "CLP": "Chilean Peso",
        "CNH": "Chinese Yuan (Offshore)",
        "CNY": "Chinese Yuan",
        "COP": "Colombian Peso",
        "CRC": "Costa Rican Colón",
        "CUC": "Cuban Convertible Peso",
        "CUP": "Cuban Peso",
        "CVE": "Cape Verdean Escudo",
        "CZK": "Czech Republic Koruna",
        "DJF": "Djiboutian Franc",
        "DKK": "Danish Krone",
        "DOP": "Dominican Peso",
        "DZD": "Algerian Dinar",
        "EGP": "Egyptian Pound",
        "ERN": "Eritrean Nakfa",
        "ETB": "Ethiopian Birr",
        "FJD": "Fijian Dollar",
        "FKP": "Falkland Islands Pound",
        "GEL": "Georgian Lari",
        "GGP": "Guernsey Pound",
        "GHS": "Ghanaian Cedi",
        "GIP": "Gibraltar Pound",
        "GMD": "Gambian Dalasi",
        "GNF": "Guinean Franc",
        "GTQ": "Guatemalan Quetzal",
        "GYD": "Guyanaese Dollar",
        "HKD": "Hong Kong Dollar",
        "HNL": "Honduran Lempira",
        "HRK": "Croatian Kuna",
        "HTG": "Haitian Gourde",
        "HUF": "Hungarian Forint",
        "IDR": "Indonesian Rupiah",
        "ILS": "Israeli New Sheqel",
        "IMP": "Manx pound",
        "INR": "Indian Rupee",
        "IQD": "Iraqi Dinar",
        "IRR": "Iranian Rial",
        "ISK": "Icelandic Króna",
        "JEP": "Jersey Pound",
        "JMD": "Jamaican Dollar",
        "JOD": "Jordanian Dinar",
        "KES": "Kenyan Shilling",
        "KGS": "Kyrgystani Som",
        "KHR": "Cambodian Riel",
        "KMF": "Comorian Franc",
        "KPW": "North Korean Won",
        "KRW": "South Korean Won",
        "KWD": "Kuwaiti Dinar",
        "KYD": "Cayman Islands Dollar",
        "KZT": "Kazakhstani Tenge",
        "LAK": "Laotian Kip",
        "LBP": "Lebanese Pound",
        "LKR": "Sri Lankan Rupee",
        "LRD": "Liberian Dollar",
        "LSL": "Lesotho Loti",
        "LYD": "Libyan Dinar",
        "MAD": "Moroccan Dirham",
        "MDL": "Moldovan Leu",
        "MGA": "Malagasy Ariary",
        "MKD": "Macedonian Denar",
        "MMK": "Myanma Kyat",
        "MNT": "Mongolian Tugrik",
        "MOP": "Macanese Pataca",
        "MRO": "Mauritanian Ouguiya (pre-2018)",
        "MRU": "Mauritanian Ouguiya",
        "MUR": "Mauritian Rupee",
        "MVR": "Maldivian Rufiyaa",
        "MWK": "Malawian Kwacha",
        "MXN": "Mexican Peso",
        "MYR": "Malaysian Ringgit",
        "MZN": "Mozambican Metical",
        "NAD": "Namibian Dollar",
        "NGN": "Nigerian Naira",
        "NIO": "Nicaraguan Córdoba",
        "NOK": "Norwegian Krone",
        "NPR": "Nepalese Rupee",
        "NZD": "New Zealand Dollar",
        "OMR": "Omani Rial",
        "PAB": "Panamanian Balboa",
        "PEN": "Peruvian Nuevo Sol",
        "PGK": "Papua New Guinean Kina",
        "PHP": "Philippine Peso",
        "PKR": "Pakistani Rupee",
        "PLN": "Polish Zloty",
        "PYG": "Paraguayan Guarani",
        "QAR": "Qatari Rial",
        "RON": "Romanian Leu",
        "RSD": "Serbian Dinar",
        "RUB": "Russian Ruble",
        "RWF": "Rwandan Franc",
        "SAR": "Saudi Riyal",
        "SBD": "Solomon Islands Dollar",
        "SCR": "Seychellois Rupee",
        "SDG": "Sudanese Pound",
        "SEK": "Swedish Krona",
        "SGD": "Singapore Dollar",
        "SHP": "Saint Helena Pound",
        "SLL": "Sierra Leonean Leone",
        "SOS": "Somali Shilling",
        "SRD": "Surinamese Dollar",
        "SSP": "South Sudanese Pound",
        "STD": "São Tomé and Príncipe Dobra (pre-2018)",
        "STN": "São Tomé and Príncipe Dobra",
        "SVC": "Salvadoran Colón",
        "SYP": "Syrian Pound",
        "SZL": "Swazi Lilangeni",
        "THB": "Thai Baht",
        "TJS": "Tajikistani Somoni",
        "TMT": "Turkmenistani Manat",
        "TND": "Tunisian Dinar",
        "TOP": "Tongan Pa'anga",
        "TRY": "Turkish Lira",
        "TTD": "Trinidad and Tobago Dollar",
        "TWD": "New Taiwan Dollar",
        "TZS": "Tanzanian Shilling",
        "UAH": "Ukrainian Hryvnia",
        "UGX": "Ugandan Shilling",
        "UYU": "Uruguayan Peso",
        "UZS": "Uzbekistan Som",
        "VEF": "Venezuelan Bolívar Fuerte (Old)",
        "VES": "Venezuelan Bolívar Soberano",
        "VND": "Vietnamese Dong",
        "VUV": "Vanuatu Vatu",
        "WST": "Samoan Tala",
        "XAF": "CFA Franc BEAC",
        "XAG": "Silver Ounce",
        "XAU": "Gold Ounce",
        "XCD": "East Caribbean Dollar",
        "XDR": "Special Drawing Rights",
        "XOF": "CFA Franc BCEAO",
        "XPD": "Palladium Ounce",
        "XPF": "CFP Franc",
        "XPT": "Platinum Ounce",
        "YER": "Yemeni Rial",
        "ZAR": "South African Rand",
        "ZMW": "Zambian Kwacha",
        "ZWL": "Zimbabwean Dollar"
      },

    };

    //componentWillMount here

  }

  async componentDidMount() {

    if (Data.data.loggedIn === false) {
      this.props.history.push('/');
    }

    // ***************** ADD TEAMS **********************
    //which db
    // const dbTeams = `testtest`;

    // // to add teams for first time, first do this and check team id where to add rooster
    // const teams = await APIGET.Request(`SELECT *`, `FROM Team`, null, null, null, null, dbTeams);
    // console.log(teams);

    // // then uncomment this
    // const rooster = await APIGET.Request(`SELECT *`, `FROM Rooster`, null, null, null, null, dbTeams);
    // for (const r of rooster) {
    //   await APIADD.Request(`INSERT INTO TeamRooster`, `VALUES (NULL, 1, ${r.rooster_id})`, dbTeams);
    // }
    // const omzet = await APIGET.Request(`SELECT *`, `FROM OmzetDagelijks`, null, null, null, null, dbTeams);
    // for (const o of omzet) {
    //   await APIADD.Request(`INSERT INTO TeamOmzet`, `VALUES (NULL, ${o.omzetd_id}, 1)`, dbTeams);
    // }
    // const omzetV = await APIGET.Request(`SELECT *`, `FROM OmzetVerwachting`, null, null, null, null, dbTeams);
    // for (const o of omzetV) {
    //   await APIADD.Request(`INSERT INTO TeamOmzetV`, `VALUES (NULL, ${o.omzetv_id}, 1)`, dbTeams);
    // }
    // const shiftTemplates = await APIGET.Request(`SELECT *`, `FROM DagDienstSjabloon`, null, null, null, null, dbTeams);
    // for (const s of shiftTemplates) {
    //   await APIEDIT.Request(`UPDATE DagDienstSjabloon`, `SET dagdienstsjabloon_team_id = 1`, `WHERE dagdienstsjabloon_id >= 0`, dbTeams);
    // }
    // console.log('done');
    // ***************** ADD TEAMS **********************

    // ***************** COMBINING DB TEAMS **********************
    //which db
    // const dbTeams = `nypdoetnijm`;

    // // to add teams for first time, first do this and check team id where to add rooster
    // const teams = await APIGET.Request(`SELECT *`, `FROM Team`, null, null, null, null, dbTeams);
    // console.log(teams);

    // // then uncomment this
    // // which db to combine from
    // const dbTeams2 = `nypnijmegen`;
    // const addFrom = 200;
    // const whichTeam = 3;

    // const accountsC = await APIGET.Request(`SELECT *`, `FROM Account`, null, null, null, null, dbTeams2);
    // const infoC = await APIGET.Request(`SELECT *`, `FROM Info`, null, null, null, null, dbTeams2);
    // const infoExtraC = await APIGET.Request(`SELECT *`, `FROM InfoExtra`, null, null, null, null, dbTeams2);
    // const contractsC = await APIGET.Request(`SELECT *`, `FROM ContractN`, null, null, null, null, dbTeams2);
    // const roosterC = await APIGET.Request(`SELECT *`, `FROM Rooster`, null, null, null, null, dbTeams2);

    // for (const a of accountsC) {
    //   const userID = parseInt(a.account_id) + addFrom;
    //   await APIADD.Request(`INSERT INTO Account`, `VALUES (${userID}, '${a.account_username}stanna', '${a.account_password}', ${a.account_rights})`, dbTeams);
    // }
    // for (const a of infoC) {
    //   const userID = parseInt(a.info_id) + addFrom;
    //   const firstName = Constants.isEmpty(a.info_voornaam) ? 'NULL' : `'${a.info_voornaam}'`;
    //   const insertion = Constants.isEmpty(a.info_tussenvoegsel) ? 'NULL' : `'${a.info_tussenvoegsel}'`;
    //   const lastName = Constants.isEmpty(a.info_achternaam) ? 'NULL' : `'${a.info_achternaam}'`;
    //   const phone = Constants.isEmpty(a.info_telefoon) ? 'NULL' : `'${a.info_telefoon}'`;
    //   const email = Constants.isEmpty(a.info_email) ? 'NULL' : `'${a.info_email}'`;
    //   const comment = Constants.isEmpty(a.info_extra) ? 'NULL' : `'${a.info_extra}'`;
    //   const address = Constants.isEmpty(a.info_adres) ? 'NULL' : `'${a.info_adres}'`;
    //   const house = Constants.isEmpty(a.info_huisnummer) ? 'NULL' : `'${a.info_huisnummer}'`;
    //   const zip = Constants.isEmpty(a.info_postcode) ? 'NULL' : `'${a.info_postcode}'`;
    //   const birth = Constants.isEmpty(a.info_geboorte) ? 'NULL' : `'${a.info_geboorte}'`;
    //   const startdate = Constants.isEmpty(a.info_indienst) ? 'NULL' : `'${a.info_indienst}'`;
    //   const iban = Constants.isEmpty(a.info_ibnr) ? 'NULL' : `'${a.info_ibnr}'`;
    //   const idnr = Constants.isEmpty(a.info_idnr) ? 'NULL' : `'${a.info_idnr}'`;
    //   const city = Constants.isEmpty(a.info_plaats) ? 'NULL' : `'${a.info_plaats}'`;
    //   const phone2 = Constants.isEmpty(a.info_telefoon2) ? 'NULL' : `'${a.info_telefoon2}'`;
    //   const birthplace = Constants.isEmpty(a.info_geboorteplaats) ? 'NULL' : `'${a.info_geboorteplaats}'`;
    //   await APIADD.Request(`INSERT INTO Info`, `VALUES (${userID}, ${firstName}, ${insertion}, ${lastName}, ${phone}, ${email}, NULL, ${comment}, ${address}, ${house}, ${zip}, ${birth}, 1, ${startdate}, ${iban}, ${idnr}, ${city}, ${phone2}, ${birthplace})`, dbTeams);
    //   await APIADD.Request(`INSERT INTO TeamUsers`, `VALUES (NULL, ${whichTeam}, ${userID})`, dbTeams);
    // }
    // for (const a of infoExtraC) {
    //   const userID = parseInt(a.info2_info_id) + addFrom;
    //   await APIADD.Request(`INSERT INTO InfoExtra`, `VALUES (NULL, ${userID}, ${a.info2_max_dagen}, '${a.info2_max_eind}', ${a.info2_opeenvolgend}, ${a.info2_registreren}, ${a.info2_werkdag_maandag}, ${a.info2_werkdag_dinsdag}, ${a.info2_werkdag_woensdag}, ${a.info2_werkdag_donderdag}, ${a.info2_werkdag_vrijdag}, ${a.info2_werkdag_zaterdag}, ${a.info2_werkdag_zondag}, NULL, ${a.info2_werkweek}, NULL, NULL, ${a.info2_personeelsnummer})`, dbTeams);
    // }
    // for (const a of contractsC) {
    //   const userID = parseInt(a.contract_info_id) + addFrom;
    //   const contractStart = Constants.isEmpty(a.contract_begin) ? 'NULL' : `'${a.contract_begin}'`;
    //   const contractEnd = Constants.isEmpty(a.contract_eind) ? 'NULL' : `'${a.contract_eind}'`;
    //   await APIADD.Request(`INSERT INTO ContractN`, `VALUES (NULL, ${userID}, ${contractStart}, ${contractEnd}, ${a.contract_type}, ${a.contract_uren}, ${a.contract_max_uren}, ${a.contract_uurloon}, ${a.contract_maandloon}, ${a.contract_opbouw_verlof}, ${a.contract_werkdag_uren}, ${a.contract_vaste_dagen}, ${a.contract_maandag}, ${a.contract_dinsdag}, ${a.contract_woensdag}, ${a.contract_donderdag}, ${a.contract_vrijdag}, ${a.contract_zaterdag}, ${a.contract_zondag})`, dbTeams);
    // }
    // // for (const a of roosterC) {
    // //   const userID = parseInt(a.rooster_info_id) + addFrom;
    // //   await APIADD.Request(`INSERT INTO Rooster`, `VALUES (NULL, '${a.rooster_datum}', '${a.rooster_begin}', '${a.rooster_eind}', ${a.rooster_info_id}, '${a.info_email}', NULL, '${a.info_extra}', '${a.info_adres}', '${a.info_huisnummer}', '${a.info_postcode}', '${a.info_geboorte}', 1, '${a.info_indienst}', '${a.info_ibnr}', '${a.info_idnr}', '${a.info_plaats}', '${a.info_telefoon2}', '${a.info_geboorteplaats}')`, dbTeams);
    // //   await APIADD.Request(`INSERT INTO TeamUsers`, `VALUES (NULL, ${whichTeam}, ${userID})`);
    // // }

    // console.log('done');
    // ***************** COMBINING DB TEAMS **********************

    let [
      companies,
      companies2,
      oldPackages,
      servers,
      comments,
    ] = await Promise.all([
      APIGETLOGIN.Request(`SELECT *`, `FROM Bedrijf`, `LEFT JOIN Servr ON bedrijf_server_id = server_id LEFT JOIN Pakket ON Bedrijf.pakket_id = Pakket.pakket_id`, `WHERE bedrijf_proef_datum IS NOT NULL AND bedrijf_archief <> 1`, null, `ORDER BY bedrijf_naam`),
      APIGETLOGIN.Request(`SELECT *`, `FROM Bedrijf`, `LEFT JOIN Servr ON bedrijf_server_id = server_id LEFT JOIN Pakket ON Bedrijf.pakket_id = Pakket.pakket_id`, `WHERE bedrijf_proef_datum IS NOT NULL AND bedrijf_archief <> 1`, null, this.state.orderBy), // -bedrijf_proef_datum DESC, 
      APIGETLOGIN.Request(`SELECT *`, `FROM Pakket`, null, null, null, null),
      APIGETLOGIN.Request(`SELECT *`, `FROM Servr`, null, null, null, `ORDER BY server_id`),
      APIGETLOGIN.Request(`SELECT Commentaar.*, account_username, account_id`, `FROM Commentaar`, `LEFT JOIN Account ON account_id = c_account_id`, null, null, `ORDER BY c_datum DESC, c_tijd DESC`),
    ]);

    const commentsLead_obj = Constants.convertToObjectWithArrays(comments, 'c_bedrijf_id');

    let companyData = {};

    for (const c of companies) {

      if (Constants.isEmpty(companyData[c.bedrijf_id]) === true) {
        companyData[c.bedrijf_id] = {
          employees: [],
          jobs: [],
          sections: [],
          logs: [],
          teams: [],
        };
      }

      Data.data.serverIP = c.server_ip;

      const [
        employees,
        jobs,
        sections,
        logs,
        teams,
      ] = await Promise.all([
        APIGET.Request(`SELECT *`, `FROM Info`, `LEFT JOIN InfoExtra ON info2_info_id = info_id`, null, `GROUP BY info_id`, `ORDER BY info_voornaam, info_achternaam`, c.bedrijf_code),
        APIGET.Request(`SELECT *`, `FROM Functie`, null, null, null, `ORDER BY functie_naam`, c.bedrijf_code),
        APIGET.Request(`SELECT *`, `FROM Afdeling`, null, null, null, `ORDER BY afdeling_naam`, c.bedrijf_code),
        APIGET.Request(`SELECT *`, `FROM Log`, null, `WHERE YEAR(log_datum) = ${new Date().getFullYear()}`, null, `ORDER BY log_datum DESC`, c.bedrijf_code),
        APIGET.Request(`SELECT *`, `FROM Team`, null, null, null, `ORDER BY team_naam`, c.bedrijf_code),
      ]);

      companyData[c.bedrijf_id].employees = employees;
      companyData[c.bedrijf_id].jobs = jobs;
      companyData[c.bedrijf_id].sections = sections;
      companyData[c.bedrijf_id].logs = logs;
      companyData[c.bedrijf_id].teams = teams;

      Data.data.serverIP = '';

    }

    // console.log(companyData)

    this.setState({ 
      companies: companies,
      companies2: companies2,
      oldPackages: oldPackages,
      servers: servers,
      companyData: companyData,
      comments: commentsLead_obj,
      loading: false
    });

  }

  hasChanges() {

    for (const key in this.state.companyInfo) {

      if (this.state.oldCompanyInfo[key] !== this.state.companyInfo[key]) {
        return true;
      }

    }

    return false;

  }

  async reload(menu = false) {

    this.setState({ reloading: true });

    let [
      companies,
      companies2,
      oldPackages,
      servers,
      comments,
    ] = await Promise.all([
      APIGETLOGIN.Request(`SELECT *`, `FROM Bedrijf`, `LEFT JOIN Servr ON bedrijf_server_id = server_id LEFT JOIN Pakket ON Bedrijf.pakket_id = Pakket.pakket_id`, `WHERE bedrijf_proef_datum IS NOT NULL AND bedrijf_archief <> 1`, null, `ORDER BY bedrijf_naam`),
      APIGETLOGIN.Request(`SELECT *`, `FROM Bedrijf`, `LEFT JOIN Servr ON bedrijf_server_id = server_id LEFT JOIN Pakket ON Bedrijf.pakket_id = Pakket.pakket_id`, `WHERE bedrijf_proef_datum IS NOT NULL AND bedrijf_archief <> 1`, null, this.state.orderBy), // -bedrijf_proef_datum DESC, 
      APIGETLOGIN.Request(`SELECT *`, `FROM Pakket`, null, null, null, null),
      APIGETLOGIN.Request(`SELECT *`, `FROM Servr`, null, null, null, `ORDER BY server_id`),
      APIGETLOGIN.Request(`SELECT Commentaar.*, account_username, account_id`, `FROM Commentaar`, `LEFT JOIN Account ON account_id = c_account_id`, null, null, `ORDER BY c_datum DESC, c_tijd DESC`),
    ]);

    const commentsLead_obj = Constants.convertToObjectWithArrays(comments, 'c_bedrijf_id');

    let companyData = {};

    for (const c of companies) {

      if (Constants.isEmpty(companyData[c.bedrijf_id]) === true) {
        companyData[c.bedrijf_id] = {
          employees: [],
          jobs: [],
          sections: [],
          logs: [],
          teams: [],
        };
      }

      Data.data.serverIP = c.server_ip;

      const [
        employees,
        jobs,
        sections,
        logs,
        teams,
      ] = await Promise.all([
        APIGET.Request(`SELECT *`, `FROM Info`, `LEFT JOIN InfoExtra ON info2_info_id = info_id`, null, `GROUP BY info_id`, `ORDER BY info_voornaam, info_achternaam`, c.bedrijf_code),
        APIGET.Request(`SELECT *`, `FROM Functie`, null, null, null, `ORDER BY functie_naam`, c.bedrijf_code),
        APIGET.Request(`SELECT *`, `FROM Afdeling`, null, null, null, `ORDER BY afdeling_naam`, c.bedrijf_code),
        APIGET.Request(`SELECT *`, `FROM Log`, null, `WHERE YEAR(log_datum) = ${new Date().getFullYear()}`, null, `ORDER BY log_datum DESC`, c.bedrijf_code),
        APIGET.Request(`SELECT *`, `FROM Team`, null, null, null, `ORDER BY team_naam`, c.bedrijf_code),
      ]);

      companyData[c.bedrijf_id].employees = employees;
      companyData[c.bedrijf_id].jobs = jobs;
      companyData[c.bedrijf_id].sections = sections;
      companyData[c.bedrijf_id].logs = logs;
      companyData[c.bedrijf_id].teams = teams;

      Data.data.serverIP = '';

    }

    if (menu === true) {
      Data.data.storeCode = this.state.companyInfo.bedrijf_code;
      Data.data.serverIP = this.state.companyInfo.server_ip;
    }

    this.setState({ 
      companies: companies,
      companies2: companies2,
      oldPackages: oldPackages,
      servers: servers,
      companyData: companyData,
      comments: commentsLead_obj,
      checkedCompanies: {},
      reloading: false,
    }, () => {

      if (Constants.isEmpty(this.state.companyInfo) === false && Constants.isEmpty(this.state.companyInfo.bedrijf_proef_datum) === true) {
        this.changeCompany(null);
      }

    });

  }

  getCompanySelects() {

    let toRender = [];

    for (const company of this.state.companies) {

      toRender.push(<option value={company.bedrijf_id} selected={this.state.selectedCompany === company.bedrijf_id}>{company.bedrijf_naam}</option>);

    }

    return toRender;

  }

  async changeCompany(id) {

    const companyInfo = Constants.getArrayItem(this.state.companies, 'bedrijf_id', id);

    if (this.hasChanges()) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      }
    }

    this.setState({ reloading: true });

    if (companyInfo !== null) {

      Data.data.serverIP = companyInfo.server_ip;
      Data.data.storeCode = companyInfo.bedrijf_code;
      const optionsDB = await APIGET.Request(`SELECT opties_versie`, `FROM Opties`, null, null, null, null, companyInfo.bedrijf_code);

      // console.log(optionsDB)

      let version = null;

      if (Constants.isEmpty(optionsDB) === false && optionsDB.length > 0) {
        version = optionsDB[0].opties_versie;
      }

      this.setState({

        selectedCompany: id,
        selectedPackage: version,
        companyInfo: { ...companyInfo },
        oldCompanyInfo: { ...companyInfo },
        menu: 'general',

      });

    } else {

      Data.data.serverIP = '';
      Data.data.storeCode = '';

      this.setState({

        selectedCompany: null,
        selectedPackage: null,
        companyInfo: null,
        oldCompanyInfo: null,
        menu: 'general',

      });

    }

    this.setState({ reloading: false });

  }

  getCompanyButtonsMenu() {

    let toRender = [];

    for (const company of this.state.companies) {

      toRender.push(
        <div className={`employees_menuButton ${this.state.selectedCompany === company.bedrijf_id ? 'activeAv' : ''}`} onClick={() => this.changeCompany(company.bedrijf_id)}>
          <div>{company.bedrijf_naam}</div>
        </div>
      );

    }

    return toRender;

  }

  checkCompany(company) {

    let checkedCompanies = this.state.checkedCompanies;

    if (Constants.isEmpty(checkedCompanies[company.bedrijf_id]) === false) {
      delete checkedCompanies[company.bedrijf_id];
    } else {
      checkedCompanies[company.bedrijf_id] = { ...company };
    }

    this.setState({ checkedCompanies: checkedCompanies });

  }

  checkAllCompanies(check) {

    let checkedCompanies = this.state.checkedCompanies;

    for (const company of this.state.companies) {
      if (check === false) {
        delete checkedCompanies[company.bedrijf_id];
      } else {
        checkedCompanies[company.bedrijf_id] = { ...company };
      }
    }

    this.setState({ checkedCompanies: checkedCompanies });

  }

  async archiveCompanies() {

    this.setState({ reloading: true });

    let promises = [];

    for (const companyKey in this.state.checkedCompanies) {
      promises.push(APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET bedrijf_archief = 1`, `WHERE bedrijf_id = ${this.state.checkedCompanies[companyKey].bedrijf_id}`));
    }

    await Promise.all(promises);

    this.reload();

  }

  getCompanyBars() {

    let toRender = [];
    let count = 0;

    for (const company of this.state.companies2) {

      if (company.bedrijf_naam.toLowerCase().startsWith('demo')) {
        continue;
      }

      const companyData = this.state.companyData[company.bedrijf_id];

      const trialDate = Constants.isEmpty(company.bedrijf_proef_datum) === false ? Constants.stringToDate(company.bedrijf_proef_datum) : null;

      const phone = Constants.isEmpty(company.bedrijf_persoonlijk_telefoon) ? company.bedrijf_telefoon : company.bedrijf_persoonlijk_telefoon;
      const mail = Constants.isEmpty(company.bedrijf_persoonlijk_email) ? company.bedrijf_email : company.bedrijf_persoonlijk_email;

      const prevWeek = new Date(new Date().setDate(new Date().getDate() - 7));
      const prevMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));

      let lastLogged = null;
      let loggedInWeek = false;
      let active = 2;

      // scenario's
      if (Constants.isEmpty(companyData) === false) {

        for (const l of companyData.logs) {
          const logDate = Constants.stringToDate(l.log_datum);
          if (logDate >= prevWeek) {
            loggedInWeek = true;
            break;
          }
        }

        if (companyData.logs.length > 0) {
          lastLogged = Constants.stringToDate(companyData.logs[0].log_datum);
        }

        if (loggedInWeek === false || (companyData.employees.length > 1 && companyData.jobs.length === 0) || (companyData.employees.length < 2 && companyData.jobs.length > 0)) {
          active = 1;
        }
        if (companyData.employees.length > 1 && companyData.jobs.length > 0 && loggedInWeek === true) {
          active = 2;
        }
        if (companyData.employees.length < 2 && companyData.jobs.length === 0) {
          active = 0;
        }

      // couldn't find data
      } else {
        active = 0;
      }

      toRender.push(

        <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>

          {/* {contractEnding ?
            <Tooltip
              options
              html={(
                <div className='appoptions_tooltipContainer'>
                  <span>Contract verloopt bijna</span>
                </div>
              )}
              style={{position: 'absolute', left: Data.data.teams.length > 0 && Data.data.chosenTeam !== null && Constants.isEmpty(teamName) === true ? '-1.8vw' : '-0.6vw', marginBottom: 2, cursor: 'pointer'}}
              position={'top'}
              followCursor
              arrow
              theme='kodict'
            >
              <i style={{fontSize: '0.7vw', color: Colors.color.redFilledIn}} className='far fa-file-exclamation' onClick={() => this.selectUser(account.info_id, userFullName, false, 'contract')} />
            </Tooltip>
          : null}

          {Data.data.teams.length > 0 && Data.data.chosenTeam !== null && Constants.isEmpty(teamName) === true ?
            <Tooltip
              options
              html={(
                <div className='appoptions_tooltipContainer'>
                  <span>Medewerker heeft nog geen team toegewezen gekregen</span>
                </div>
              )}
              style={{position: 'absolute', left: '-0.8vw', marginBottom: 2, cursor: 'pointer'}}
              position={'top'}
              followCursor
              arrow
              theme='kodict'
            >
              <i style={{fontSize: '0.7vw', color: Colors.color.redFilledIn}} className='fas fa-exclamation-triangle' onClick={() => this.selectUser(account.info_id, userFullName, false, 'teams')} />
            </Tooltip>
          : null} */}

          <div className='hr_hoursBar' style={{ backgroundColor: count === 1 ? '#F9FBFC' : null, height: '2.5vw' }}>

            <div className='hr_hoursBar_sub5' style={{ flex: 0.15, cursor: 'pointer' }} onClick={() => this.checkCompany(company)}>
              {Constants.isEmpty(this.state.checkedCompanies[company.bedrijf_id]) === false ?
                <p style={{ marginBlock: 0, marginLeft: 1 }}><i className='fas fa-square-check' style={{ color: Colors.color.ORIGINAL_MAINCOLOR_1 }} /></p>
              :
                <p style={{ marginBlock: 0, marginLeft: 1 }}><i className='fal fa-square' /></p>
              }
            </div>

            <div className='hr_hoursBar_sub1' onClick={() => this.changeCompany(company.bedrijf_id)} style={{  }}>
              <span className='defaultLinkO'><b>{company.bedrijf_naam}</b></span>
            </div>

            {/* <div className='hr_hoursBar_sub2' style={{  }}>
              <span>{company.bedrijf_eigenaar}</span>
            </div> */}

            <div className='hr_hoursBar_sub1' style={{  }}>
              <span>{mail}</span>
            </div>

            <div className='hr_hoursBar_sub2' style={{  }}>
              <span>{phone}</span>
            </div>

            <div className='hr_hoursBar_sub2' style={{  }}>
              {Constants.isEmpty(lastLogged) === false ?
                lastLogged < prevMonth ?
                  <span style={{ color: Colors.color.redFilledIn }}>{lastLogged.toLocaleDateString()}</span>
                : 
                lastLogged < prevWeek ?
                  <span style={{ color: Colors.color.orangeVacation }}>{lastLogged.toLocaleDateString()}</span>
                :
                  <span style={{ color: Colors.color.greenFilledIn }}>{lastLogged.toLocaleDateString()}</span>
              :
                <span>&nbsp;</span>
              }
            </div>

            <div className='hr_hoursBar_sub2' style={{  }}>
              {Constants.isEmpty(trialDate) === false ?
                <span style={{ color: Colors.color.redFilledIn }}>{trialDate.toLocaleDateString()}</span>
              :
                <span>&nbsp;</span>
              }
            </div>

            <div className='hr_hoursBar_sub1' style={{  }}>
              <select 
                name="Selecteer een status" 
                className={'dropDownField'}
                onChange={(event) => this.selectType(event, company)}
              >
                <option value={'0'} selected={'0' === company.bedrijf_status_acp || null === company.bedrijf_status_acp}>Geen</option>
                <option value={'1'} hidden selected={'1' === company.bedrijf_status_acp}>Benaderd</option>
                <option value={'7'} selected={'7' === company.bedrijf_status_acp}>1e mail gestuurd</option>
                <option value={'8'} selected={'8' === company.bedrijf_status_acp}>Mail contact gehad</option>
                <option value={'9'} selected={'9' === company.bedrijf_status_acp}>Telefonisch contact gehad</option>
                <option value={'2'} selected={'2' === company.bedrijf_status_acp}>Demo in behandeling</option>
                <option value={'3'} selected={'3' === company.bedrijf_status_acp}>Demo gegeven</option>
                <option hidden value={'4'} selected={'4' === company.bedrijf_status_acp}>Proefperiode</option>
                <option value={'5'} selected={'5' === company.bedrijf_status_acp}>Actief</option>
                <option value={'6'} selected={'6' === company.bedrijf_status_acp}>Geen interesse</option>
              </select>
            </div>

            <div className='hr_hoursBar_sub3' style={{ flex: 0.3 }}>
              <i className='fas fa-circle' style={{ color: active === 2 ? Colors.color.greenFilledIn : active === 1 ? Colors.color.orangeVacation : Colors.color.redFilledIn }} />
            </div>

          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  getCompanyInfoData() {

    let companies = 0;
    let employees = 0;
    let activeEmployees = 0;

    for (const company of this.state.companies2) {

      if (company.bedrijf_naam.toLowerCase().startsWith('demo')) {
        continue;
      }

      const companyData = this.state.companyData[company.bedrijf_id];

      const prevWeek = new Date(new Date().setDate(new Date().getDate() - 7));
      const prevMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));

      let lastLogged = null;
      let active = 2;

      // scenario's
      if (Constants.isEmpty(companyData) === false) {

        // for (const l of companyData.logs) {
        //   const logDate = Constants.stringToDate(l.log_datum);
        //   if (logDate >= prevWeek) {
        //     loggedInWeek = true;
        //     break;
        //   }
        // }

        if (companyData.logs.length > 0) {
          lastLogged = Constants.stringToDate(companyData.logs[0].log_datum);
        }

        if (companyData.employees.length < 2 && companyData.jobs.length === 0) {
          active = 0;
        } else if (companyData.employees.length < 2 && companyData.jobs.length > 0) {
          active = 1;
        } else if (companyData.employees.length > 1 && companyData.jobs.length === 0) {
          active = 1;
        } else if (companyData.employees.length > 1 && companyData.jobs.length > 0) {
          active = 2;
        }

        // data
        companies += 1;
        employees += (companyData.employees.length - 1); // remove owner

        for (const e of companyData.employees) {
          if (e.info2_status === '2') { // disabled
            continue;
          }
          activeEmployees += 1;
        }

        // remove owner
        activeEmployees -= 1;

      // couldn't find data
      } else {
        active = 0;
      }

    }

    return {
      companies: companies,
      employees: employees,
      activeEmployees: activeEmployees,
    };

  }

  getCompanyInfoData_selected() {

    let employees = 0;
    let activeEmployees = 0;

    const companyData = this.state.companyData[this.state.selectedCompany];

    const prevWeek = new Date(new Date().setDate(new Date().getDate() - 7));
    const prevMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));

    let lastLogged = null;
    let active = 2;

    // scenario's
    if (Constants.isEmpty(companyData) === false) {

      // for (const l of companyData.logs) {
      //   const logDate = Constants.stringToDate(l.log_datum);
      //   if (logDate >= prevWeek) {
      //     loggedInWeek = true;
      //     break;
      //   }
      // }

      if (companyData.logs.length > 0) {
        lastLogged = Constants.stringToDate(companyData.logs[0].log_datum);
      }

      if (companyData.employees.length < 2 && companyData.jobs.length === 0) {
        active = 0;
      } else if (companyData.employees.length < 2 && companyData.jobs.length > 0) {
        active = 1;
      } else if (companyData.employees.length > 1 && companyData.jobs.length === 0) {
        active = 1;
      } else if (companyData.employees.length > 1 && companyData.jobs.length > 0) {
        active = 2;
      }

      // data
      employees += (companyData.employees.length - 1); // remove owner

      for (const e of companyData.employees) {
        if (e.info2_status === '2') { // disabled
          continue;
        }
        activeEmployees += 1;
      }

      // remove owner
      activeEmployees -= 1;

    // couldn't find data
    } else {
      active = 0;
    }

    return {
      employees: employees,
      activeEmployees: activeEmployees,
      lastLogged: lastLogged,
    };

  }

  selectMenu(menu) {

    if (this.state.menu === menu) {
      return;
    }

    if (this.hasChanges()) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      }
    }

    const oldCompanyInfo = { ...this.state.oldCompanyInfo };

    this.setState({ menu: menu, companyInfo: oldCompanyInfo });

  }

  saveCompanyAlert() {

    this.setState({
        alertTitle: 'Bedrijf opslaan',
        alertBody: (<span>Weet je zeker dat je deze informatie wilt opslaan?</span>),
        alertButtons: 2,
        alertButtonAction: this.saveCompany.bind(this),
        alertButtonText: ['Annuleren', 'Opslaan'],
        showAlert: true,
    });
  
  }

  async saveCompany() {

    this.setState({ reloading: true });

    const companyInfo = this.state.companyInfo;

    const name = Constants.isEmpty(companyInfo.bedrijf_naam) ? 'NULL' : `'${companyInfo.bedrijf_naam}'`;
    const owner = Constants.isEmpty(companyInfo.bedrijf_eigenaar) ? 'NULL' : `'${companyInfo.bedrijf_eigenaar}'`;
    const mail = Constants.isEmpty(companyInfo.bedrijf_persoonlijk_email) ? 'NULL' : `'${companyInfo.bedrijf_persoonlijk_email}'`;
    const phone = Constants.isEmpty(companyInfo.bedrijf_persoonlijk_telefoon) ? 'NULL' : `'${companyInfo.bedrijf_persoonlijk_telefoon}'`;
    const packagee = Constants.isEmpty(companyInfo.pakket_id) ? 'NULL' : companyInfo.pakket_id;
    const serverID = Constants.isEmpty(companyInfo.bedrijf_server_id) ? 'NULL' : companyInfo.bedrijf_server_id;

    const trial = Constants.isEmpty(companyInfo.bedrijf_proef_datum) ? 'NULL' : `'${companyInfo.bedrijf_proef_datum}'`;
    const timezone = Constants.isEmpty(companyInfo.bedrijf_tijdzone) ? 'NULL' : `'${companyInfo.bedrijf_tijdzone}'`;
    const currency = Constants.isEmpty(companyInfo.bedrijf_valuta) ? 'NULL' : `'${companyInfo.bedrijf_valuta}'`;

    await APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET bedrijf_naam = ${name}, bedrijf_eigenaar = ${owner}, bedrijf_persoonlijk_email = ${mail}, bedrijf_persoonlijk_telefoon = ${phone}, pakket_id = ${packagee}, bedrijf_proef_datum = ${trial}, bedrijf_server_id = ${serverID}, bedrijf_valuta = ${currency}, bedrijf_tijdzone = ${timezone}`, `WHERE bedrijf_id = ${this.state.companyInfo.bedrijf_id}`);

    const serverInfo = Constants.getArrayItem(this.state.servers, 'server_id', companyInfo.bedrijf_server_id);

    if (Constants.isEmpty(serverInfo) === false) {
      Data.data.serverIP = serverInfo.server_ip;
      await APIEDIT.Request(`UPDATE Opties`, `SET opties_versie = ${this.state.selectedPackage}`, `WHERE opties_id >= 0`, companyInfo.bedrijf_code);
      Data.data.serverIP = '';
    }

    this.setState({ oldCompanyInfo: companyInfo });

    this.reload();

  }

  editInfo(e, key) {

    let companyInfo = this.state.companyInfo;
    companyInfo[key] = e.target.value;

    this.setState({ companyInfo: companyInfo });

  }

  setDate(date, key) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
      date = null;
    } else {
      date = Constants.dateToString(date);
    }

    let companyInfo = this.state.companyInfo;
    companyInfo[key] = date;

    this.setState({ companyInfo: companyInfo });

  }

  getOldPackageOptions() {

    let toRender = [];

    for (const packagee of this.state.oldPackages) {

      if (packagee.pakket_id === '101') {

        toRender.push(
          <option value={packagee.pakket_id} selected={this.state.companyInfo.pakket_id === packagee.pakket_id}>Betaling per medewerker</option>
        );

      } else {

        toRender.push(
          <option value={packagee.pakket_id} selected={this.state.companyInfo.pakket_id === packagee.pakket_id}>{parseInt(packagee.pakket_max) - 9} t/m {parseInt(packagee.pakket_max)} medewerkers</option>
        );

      }

    }

    return toRender;

  }

  getServerOptions() {

    let toRender = [];

    for (const server of this.state.servers) {

      toRender.push(
        <option value={server.server_id} selected={this.state.companyInfo.bedrijf_server_id === server.server_id}>{server.server_ip}</option>
      );

    }

    return toRender;

  }

  getTimezones() {

    let toRender = [];

    for (const key in this.state.timezoneList) {
      toRender.push(<option key={key} value={key} selected={this.state.companyInfo.bedrijf_tijdzone === key}>{this.state.timezoneList[key]}</option>);
    }

    return toRender;

  }

  getCurrencyList() {

    let toRender = [];

    for (const key in this.state.currencies) {
      toRender.push(<option key={key} value={key} disabled={key === 'NONE'} selected={this.state.companyInfo.bedrijf_valuta === key}>{this.state.currencies[key]}</option>);
    }

    return toRender;

  }

  getFunctionBars() {

    let toRender = [];
    let count = 0;

    if (Constants.isEmpty(this.state.companyData[this.state.selectedCompany]) === false) {

      for (const func of this.state.companyData[this.state.selectedCompany].jobs) {

        toRender.push(

          <div key={`function${func.functie_id}`} className='hr_hoursBar' style={{backgroundColor: Constants.hexToRgbA(`#${func.functie_kleur}`, 0.15), cursor: 'pointer'}} onClick={() => Data.data.accountData.account_rights === '3' ? this.showFunctionsModal(1, func) : null}>

            <div className='hr_hoursBar_sub1'>
              <span>{func.functie_naam}</span>
            </div>

            {Data.data.accountData.account_rights === '3' ?
              <div className='hr_hoursBar_sub3'>
                <i className='far fa-edit' />
              </div>
            : null}

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

    }

    return toRender;

  }

  getSectionBars() {

    let toRender = [];
    let count = 0;

    if (Constants.isEmpty(this.state.companyData[this.state.selectedCompany]) === false) {

      for (const afd of this.state.companyData[this.state.selectedCompany].sections) {

        toRender.push(

          <div key={`section${afd.afdeling_id}`} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null, cursor: 'pointer'}} onClick={() => Data.data.accountData.account_rights === '3' ? this.showSectionsModal(1, afd) : null}>

            <div className='hr_hoursBar_sub1'>
              <span>{afd.afdeling_naam}</span>
            </div>

            {Data.data.accountData.account_rights === '3' ?
              <div className='hr_hoursBar_sub3'>
                <i className='far fa-edit' />
              </div>
            : null}

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

    }

    return toRender;

  }

  getTeamBars() {

    let toRender = [];
    let count = 0;

    if (Constants.isEmpty(this.state.companyData[this.state.selectedCompany]) === false) {

      for (const team of this.state.companyData[this.state.selectedCompany].teams) {

        toRender.push(

          <div key={`team${team.team_id}`} className='hr_hoursBar' style={{ backgroundColor: count === 1 ? '#F9FBFC' : null }}>

            <div className='hr_hoursBar_sub1'>
              <span>{team.team_naam}</span>
            </div>

            {/* <div className='hr_hoursBar_sub3'>
              <i className='far fa-edit' />
            </div> */}

          </div>

        );

        if (count === 0) {
          count++;
        } else {
          count--;
        }

      }

    }

    return toRender;

  }

  getEmployeeBars() {

    let toRender = [];
    let count = 0;

    let active = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
    };

    let scheduled = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
    };

    if (Constants.isEmpty(this.state.companyData[this.state.selectedCompany]) === false) {

      for (const log of this.state.companyData[this.state.selectedCompany].logs) {

        const logDate = Constants.stringToDate(log.log_datum);

        if (active[logDate.getMonth()] === 0) {
          active[logDate.getMonth()] = parseInt(log.log_actieveMedewerkers);
        }

        if (scheduled[logDate.getMonth()] === 0) {
          scheduled[logDate.getMonth()] = parseInt(log.log_roosterMedewerkers);
        }

      }

    }

    // render months
    for (let i = 0; i < 12; i++) {

      const monthName = Constants.getMonthNameM(i);

      toRender.push(

        <div key={`month${i}`} className='hr_hoursBar' style={{backgroundColor: count === 1 ? '#F9FBFC' : null}}>

          <div className='hr_hoursBar_sub1'>
            <b>{Constants.firstUppercase(monthName)}</b>
          </div>

          <div className='hr_hoursBar_sub3'>
            {active[i] > 0 ?
              <b>{active[i]}</b>
            :
              <span>&nbsp;</span>
            }
          </div>

          <div className='hr_hoursBar_sub3'>
            {scheduled[i] > 0 ?
              <span>{scheduled[i]}</span>
            :
              <span>&nbsp;</span>
            }
          </div>


        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  deleteCommentAlert(comment) {

    this.setState({
        alertTitle: 'Opmerking verwijderen',
        alertBody: (<span>Weet je zeker dat je deze opmerking wilt verwijderen?</span>),
        alertButtons: 2,
        alertButtonAction: this.deleteComment.bind(this, comment),
        alertButtonText: ['Annuleren', 'Verwijderen'],
        showAlert: true,
    });
  
  }

  async deleteComment(comment) {

    this.setState({ reloading: true });

    const date = new Date();

    await APIDELETELOGIN.Request(`DELETE FROM Commentaar`, `WHERE c_id = ${comment.c_id}`);

    const commentsLead = await APIGETLOGIN.Request(`SELECT Commentaar.*, account_username, account_id`, `FROM Commentaar`, `LEFT JOIN Account ON account_id = c_account_id`, null, null, `ORDER BY c_datum DESC, c_tijd DESC`);
    const commentsLead_obj = Constants.convertToObjectWithArrays(commentsLead, 'c_bedrijf_id');

    this.setState({
      reloading: false,
      comments: commentsLead_obj,
    });

  }

  renderComments() {

    let toRender = [];

    const comments = this.state.comments[this.state.selectedCompany];

    if (Constants.isEmpty(comments) === false) {

      for (const comment of comments) {

        const date = Constants.stringToDateTime(comment.c_datum, comment.c_tijd);

        toRender.push(
          <div key={'comment'+comment.c_id} className='comment_container'>

            {Data.data.accountData.account_id === comment.c_account_id ?
              <i className='fas fa-minus-circle changeAccModal_accountBar_remove' onClick={() => this.deleteCommentAlert(comment)} />
            : null}

            <div className='comment_container_sub1'>

              <b>Naam</b>
              <span style={{ fontWeight: 600, marginTop: '0.5vw' }}>{comment.account_username}</span>
              <span style={{ fontStyle: 'italic', marginTop: '0.2vw', fontSize: '0.7vw' }}>{Constants.dateToString(date)}  {date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:{date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:{date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}</span>

            </div>

            <div className='comment_container_sub2'>

              <b>Opmerking</b>
              <span style={{ marginTop: '0.5vw' }}>{comment.c_commentaar}</span>

            </div>

          </div>
        );

      }

    }

    return toRender;

  }

  async addComment() {

    if (Constants.isEmpty(this.state.comment) === true) {
      return;
    }

    this.setState({ reloading: true });

    const date = new Date();

    await APIADDLOGIN.Request(`INSERT INTO Commentaar`, `VALUES (NULL, ${Data.data.accountData.account_id}, ${this.state.selectedCompany}, '${this.state.comment}', '${Constants.dateToString(date)}', '${date.getHours() < 10 ? '0' + date.getHours(): date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes(): date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds(): date.getSeconds()}')`);

    const commentsLead = await APIGETLOGIN.Request(`SELECT Commentaar.*, account_username, account_id`, `FROM Commentaar`, `LEFT JOIN Account ON account_id = c_account_id`, null, null, `ORDER BY c_datum DESC, c_tijd DESC`);
    const commentsLead_obj = Constants.convertToObjectWithArrays(commentsLead, 'c_bedrijf_id');

    this.setState({
      reloading: false,
      comments: commentsLead_obj,
      comment: '',
    });

  }

  selectType(event, company) {

    const status = Constants.isEmpty(event.target.value) ? 'NULL' : `${event.target.value}`;

    APIEDITLOGIN.Request(`UPDATE Bedrijf`, `SET bedrijf_status_acp = ${status}`, `WHERE bedrijf_id = ${company.bedrijf_id}`);

  }

  selectType2(e, key) {

    let companyInfo = this.state.companyInfo;
    companyInfo[key] = e.target.value;

    this.setState({ companyInfo: companyInfo });

  }

  async selectOrder(event) {

    const orderBy = event.target.value;

    const companies2 = await APIGETLOGIN.Request(`SELECT *`, `FROM Bedrijf`, `LEFT JOIN Servr ON bedrijf_server_id = server_id LEFT JOIN Pakket ON Bedrijf.pakket_id = Pakket.pakket_id`, `WHERE bedrijf_proef_datum IS NOT NULL`, null, orderBy);

    this.setState({ 
      orderBy: orderBy,
      companies2: companies2,
    });

  }

  showSectionsModal(mode, data) {

    this.setState({
      showSectionsModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  showFunctionsModal(mode, data) {

    this.setState({
      showFunctionsModal: true, 
      modalMode: mode,
      modalData: mode === 1 ? data : [],
    });

  }

  closeModal() {

    this.setState({
      showModal: false,
      showSectionsModal: false,
      showFunctionsModal: false,
      modalData: [],
      modalMode: 0,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  render() {

    const companyInfoData = this.getCompanyInfoData();
    const companyInfoData_selected = Constants.isEmpty(this.state.selectedCompany) === false ? this.getCompanyInfoData_selected() : null;

    return (

      <div>

      <Prompt
        when={this.hasChanges()}
        message='You have unsaved changes. Are you sure you want to leave?'
      />

      {this.state.showSectionsModal === true ?
                
        <SectionModal 
          _closeModal={this.closeModal.bind(this)}
          _modalData={this.state.modalData}
          _modalMode={this.state.modalMode}
          _functions={Constants.isEmpty(this.state.companyData[this.state.selectedCompany]) ? [] : this.state.companyData[this.state.selectedCompany].jobs}
          _reloadScreen={this.reload.bind(this, true)}
        />

      : null}

      {this.state.showFunctionsModal === true ?
                
        <FunctionsModal 
          _closeModal={this.closeModal.bind(this)}
          _modalData={this.state.modalData}
          _modalMode={this.state.modalMode}
          _reloadScreen={this.reload.bind(this, true)}
        />

      : null}

      <Navbar ref={this.navBar} history={this.props.history} active={'trials'} fixed loading={this.state.loading} reloading={this.state.reloading} parent={this} />

      <div className='employees_main2'>
        {/* stickyTopMenuPanel */}
        <div className='employees_menu2' style={{width: '20%', height: 'calc(100vh - 6vw - 1px)' }}>

            <div className='employees_menuTitle'>
              <div>Bedrijven</div>
            </div>

            <div className={`employees_menuButton ${this.state.selectedCompany === null ? 'activeAv' : ''}`} onClick={() => this.changeCompany(null)}>
              <div><span style={{fontSize: '0.8vw', fontWeight: 600}}>Overzicht alle bedrijven</span></div>
            </div>

            <div style={{width: 'calc(100% - 0.5vw)', marginLeft: '0.5vw', marginBottom: '0.5vw', marginTop: '0.5vw'}}>
              <select
                name="Selecteer een medewerker"
                className={'hr_dropdown'}
                onChange={(event) => this.changeCompany(event.target.value)}
              >
                <option value={'null'} disabled>Selecteer een bedrijf</option>
                <option value={'null'} selected={this.state.selectedCompany === null}>Alle bedrijven</option>
                <option value={'null'} disabled>&nbsp;</option>
                {this.getCompanySelects()}
              </select>
            </div>

            {this.getCompanyButtonsMenu()}

        </div>

        {this.state.selectedCompany !== null && this.state.companyInfo !== null ?

          <div className='employees_menu2' style={{width: '13%'}}>

            <div className='employees_menuTitle'>
              <div>Menu</div>
            </div>

            <div className='employees_menuSubTitle'>
              <div>Instellingen</div>
            </div>

            <div className={`employees_menuButton ${this.state.menu === 'general' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('general')}>
              <div>Algemeen</div>
            </div>

            <div className={`employees_menuButton ${this.state.menu === 'package' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('package')}>
              <div>Pakket</div>
            </div>

            <div className={`employees_menuButton ${this.state.menu === 'time' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('time')}>
              <div>Tijdzone {`&`} valuta</div>
            </div>

            <div className={`employees_menuButton ${this.state.menu === 'server' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('server')}>
              <div>Server</div>
            </div>

            <div className='employees_menuSubTitle'>
              <div>Bedrijf</div>
            </div>

            <div className={`employees_menuButton ${this.state.menu === 'employees' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('employees')}>
              <div>Medewerkers</div>
            </div>

            <div className={`employees_menuButton ${this.state.menu === 'sections' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('sections')}>
              <div>Afdelingen</div>
            </div>

            <div className={`employees_menuButton ${this.state.menu === 'jobs' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('jobs')}>
              <div>Functies</div>
            </div>

            <div className={`employees_menuButton ${this.state.menu === 'teams' ? 'activeAv' : ''}`} onClick={() => this.selectMenu('teams')}>
              <div>Teams</div>
            </div>

          </div>

        : null}

        <div className='employees_content2' style={{width: this.state.selectedCompany !== null && this.state.companyInfo !== null ? 'calc(67% - 2px)' : 'calc(80% - 1px)', fontSize: '0.8vw'}}>

          {this.state.loading === true ?
            
            <div className='lds-dual-ring loader' />

          :

            <div className='employees_container'>

              {this.state.reloading === true ?
                          
                <div className='lds-dual-ring loader2' />

              :

                null

              }

              {this.state.showAlert === true ?
                
                <BetterAlert
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  _buttonAction2={this.state.alertButtonAction2}
                />

              :

                null
              
              }

              {this.state.selectedCompany === null ?

                <div>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Bedrijven proefperiode</div>

                  <div style={{marginTop: '0.5vw'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <div style={{width: '13%'}}>
                        <span>Aantal bedrijven:</span>
                      </div>
                      <div style={{width: '87%'}}>
                        <b>{companyInfoData.companies}</b>
                      </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '0.2vw'}}>
                      <div style={{width: '13%'}}>
                        <span>Actieve medewerkers:</span>
                      </div>
                      <div style={{width: '87%'}}>
                        <b>{companyInfoData.activeEmployees}</b> / {companyInfoData.employees}
                      </div>
                    </div>
                    {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '0.2vw'}}>
                      <div style={{width: '13%'}}>
                        <span>Totaal medewerkers:</span>
                      </div>
                      <div style={{width: '87%'}}>
                        <b>{companyInfoData.employees}</b>
                      </div>
                    </div> */}
                    {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '0.2vw'}}>
                      <div style={{width: '13%'}}>
                        <span>Geblokkeerde medewerkers:</span>
                      </div>
                      <div style={{width: '87%'}}>
                        <b>{this.state.bannedEmployees.length}</b>
                      </div>
                    </div> */}

                    <div className='modal-row3_start' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_start'>
                            <span><b>Sorteer op</b></span>
                        </div>

                        <div className='modal-rowSub2_start'>

                          <select 
                            name="Selecteer een optie" 
                            className={'dropDownField'}
                            onChange={(event) => this.selectOrder(event)}
                          >
                            <option value={'ORDER BY bedrijf_proef_datum DESC'} selected={'ORDER BY bedrijf_proef_datum DESC' === this.state.orderBy}>Proefperiode aflopend</option>
                            <option value={'ORDER BY bedrijf_proef_datum ASC'} selected={'ORDER BY bedrijf_proef_datum ASC' === this.state.orderBy}>Proefperiode oplopend</option>
                            <option value={'ORDER BY bedrijf_status_acp ASC'} selected={'ORDER BY bedrijf_status_acp ASC' === this.state.orderBy}>Status</option>
                          </select>

                        </div>

                    </div>

                  </div>

                  <div style={{ marginTop: '2vw' }}>

                    <div className="planner_addButtons_container" style={{ marginTop: 0, marginBottom: 0, marginLeft: 0 }}>

                      <div className='defaultLinkO' style={{marginLeft: 0, fontSize: '0.8vw'}} onClick={() => this.checkAllCompanies(true)}>Alles selecteren</div>
                      <div className='defaultLinkO' style={{marginLeft: '1.2vw', fontSize: '0.8vw'}} onClick={() => this.checkAllCompanies(false)}>Alles deselecteren</div>

                      {Object.entries(this.state.checkedCompanies).length > 0 ?
                          <div style={{marginLeft: '1.2vw', fontSize: '0.8vw'}}>Met geselecteerd ({Object.entries(this.state.checkedCompanies).length}):</div>
                      : null}

                      {Object.entries(this.state.checkedCompanies).length > 0 ?
                        <div className='defaultLinkO' style={{marginLeft: '0.6vw', fontSize: '0.8vw'}} onClick={() => this.archiveCompanies()}>Archiveren</div>
                      : null}

                    </div>

                    <div className='hr_hoursBar_title' style={{ marginTop: '0.5vw' }}>

                      <div className='hr_hoursBar_sub5' style={{ flex: 0.15 }}>
                        <span>&nbsp;</span>
                      </div>

                      <div className='hr_hoursBar_sub1'>
                        <span>Bedrijfsnaam</span>
                      </div>

                      {/* <div className='hr_hoursBar_sub2' style={{  }}>
                        <span>Eigenaar</span>
                      </div> */}

                      <div className='hr_hoursBar_sub1' style={{  }}>
                        <span>E-mail</span>
                      </div>

                      <div className='hr_hoursBar_sub2' style={{  }}>
                        <span>Telefoon</span>
                      </div>

                      <div className='hr_hoursBar_sub2' style={{  }}>
                        <span>Laatst ingelogd</span>
                      </div>

                      <div className='hr_hoursBar_sub2' style={{  }}>
                        <span>Proefperiode</span>
                      </div>

                      <div className='hr_hoursBar_sub1' style={{  }}>
                        <span>Status</span>
                      </div>

                      <div className='hr_hoursBar_sub3' style={{ flex: 0.3 }}>
                        <span>Actief</span>
                      </div>

                    </div>

                    {this.getCompanyBars()}

                  </div>

                </div>

              :
              this.state.companyInfo !== null ?

                <div>

                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.changeCompany(null)}>
                    <i className='fas fa-chevron-left' style={{ fontSize: '1vw' }} />
                    <span style={{ marginLeft: '0.8vw', fontSize: '1vw', fontWeight: 600 }}>Terug</span>
                  </div>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold', marginTop: '1vw'}}>{this.state.oldCompanyInfo.bedrijf_naam}</div>

                  {this.state.menu === 'general' ?
                  
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Algemene informatie</div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                        <div className='planner_new_publishButton' style={{marginLeft: 0, width: '5vw'}} onClick={() => this.saveCompanyAlert()}>Opslaan</div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Actieve medewerkers</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <span><span style={{ fontWeight: 600 }}>{companyInfoData_selected.activeEmployees}</span> / {companyInfoData_selected.employees}</span>

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Laatst ingelogd</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            {Constants.isEmpty(companyInfoData_selected.lastLogged) === false ?
                              <span style={{ fontWeight: 600 }}>{companyInfoData_selected.lastLogged.toLocaleDateString()}</span>
                            : 
                              <span style={{ fontWeight: 600 }}>-</span>
                            }

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '2vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Bedrijfsnaam</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={this.state.companyInfo.bedrijf_naam}
                              onChange={(event) => this.editInfo(event, 'bedrijf_naam')}
                              placeholder={'Bedrijfsnaam :'}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Bedrijfscode</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <span style={{ fontWeight: 600 }}>{this.state.companyInfo.bedrijf_code}</span>

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Proefperiode t/m</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <DatePicker
                              selected={Constants.isEmpty(this.state.companyInfo.bedrijf_proef_datum) ? null : Constants.stringToDate(this.state.companyInfo.bedrijf_proef_datum)}
                              onChange={(date) => this.setDate(date, 'bedrijf_proef_datum')}
                              dateFormat="P"
                              className={'inputFieldTime'}
                              placeholderText={'Selecteer een datum'}
                              showWeekNumbers
                              locale={nl}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '2vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Eigenaar</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={this.state.companyInfo.bedrijf_eigenaar}
                              onChange={(event) => this.editInfo(event, 'bedrijf_eigenaar')}
                              placeholder={'Eigenaar :'}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>E-mail</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={100}
                              value={Constants.isEmpty(this.state.companyInfo.bedrijf_persoonlijk_email) ? '' : this.state.companyInfo.bedrijf_persoonlijk_email}
                              onChange={(event) => this.editInfo(event, 'bedrijf_persoonlijk_email')}
                              placeholder={'E-mailadres :'}
                            />

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Telefoon</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <input 
                              className={'inputField2'}
                              maxLength={50}
                              value={Constants.isEmpty(this.state.companyInfo.bedrijf_persoonlijk_telefoon) ? '' : this.state.companyInfo.bedrijf_persoonlijk_telefoon}
                              onChange={(event) => this.editInfo(event, 'bedrijf_persoonlijk_telefoon')}
                              placeholder={'Telefoonnummer :'}
                            />

                          </div>

                      </div>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '4vw'}}>Opmerkingen</div>

                      <div>
                        {this.renderComments()}
                      </div>

                      <div className='modal-row2' style={{marginTop: '2vw'}}>

                          <div className='modal-rowSub1'>
                            <span><b>Opmerking plaatsen</b></span>
                            <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Max. 1000 tekens</span>
                          </div>

                          <div className='modal-rowSub2'>
                            <textarea
                              className={'inputFieldBig'}
                              onChange={(event) => this.setState({ comment: event.target.value })}
                              value={this.state.comment}
                              placeholder={'Opmerking'}
                              maxLength={1000}
                            />
                            <div className='planner_new_publishButton' style={{marginLeft: 0, marginTop: '0.5vw', width: '5.5vw', alignSelf: 'flex-end', justifySelf: 'flex-end'}} onClick={() => this.addComment()}>Plaatsen</div>
                          </div>

                      </div>

                    </div>
                
                  :
                  this.state.menu === 'employees' ?

                    <div style={{width: '50%'}}>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Medewerkers</div>

                      {/* <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                        <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.showSectionsModal(0)}>Toevoegen</div>

                      </div> */}

                      <div className='modal-row_start' style={{marginTop: '1vw', marginBottom: '1vw'}}>

                          <div className='modal-rowSub1_start' style={{ flex: 4 }}>
                              <span><b>Actieve medewerkers</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <span><span style={{ fontWeight: 600 }}>{companyInfoData_selected.activeEmployees}</span> / {companyInfoData_selected.employees}</span>

                          </div>

                      </div>

                      <div className='hr_hoursBar_title'>

                        <div className='hr_hoursBar_sub1'>
                          <span>Maand</span>
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          <span>Actief</span>
                        </div>

                        <div className='hr_hoursBar_sub3'>
                          <span>Geroosterd</span>
                        </div>

                      </div>

                      {this.getEmployeeBars()}

                      {/* <div style={{ marginTop: '2vw' }}>

                        <div className='hr_hoursBar_title'>

                          <div className='hr_hoursBar_sub1'>
                            <span>jan</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>feb</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>mrt</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>apr</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>mei</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>jun</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>jul</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>aug</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>sep</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>okt</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>nov</span>
                          </div>

                          <div className='hr_hoursBar_sub1'>
                            <span>dec</span>
                          </div>

                        </div>



                      </div> */}

                    </div>

                  :
                  this.state.menu === 'sections' ?

                    <div style={{width: '50%'}}>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Afdelingen</div>

                      {Data.data.accountData.account_rights === '3' ?
                        <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                          <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.showSectionsModal(0)}>Toevoegen</div>

                        </div>
                      :
                        <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: 0}}></div>
                      }

                      {this.getSectionBars()}

                    </div>

                  :
                  this.state.menu === 'jobs' ?

                    <div style={{width: '50%'}}>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Functies</div>

                      {Data.data.accountData.account_rights === '3' ?
                        <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                          <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.showFunctionsModal(0)}>Toevoegen</div>

                        </div>
                      :
                        <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: 0}}></div>
                      }

                      {this.getFunctionBars()}

                    </div>

                  :
                  this.state.menu === 'teams' ?
                  
                    <div style={{width: '50%'}}>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Teams</div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: 0}}>

                        {/* <div className='planner_new_addButton' style={{marginLeft: 0}} onClick={() => this.showFunctionsModal(0)}>Toevoegen</div> */}

                      </div>

                      {this.getTeamBars()}

                    </div>
                
                  :
                  this.state.menu === 'time' ?
                  
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Tijdzone informatie</div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                        <div className='planner_new_publishButton' style={{marginLeft: 0, width: '5vw'}} onClick={() => this.saveCompanyAlert()}>Opslaan</div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Tijdzone</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <select 
                                name="Selecteer een tijdzone" 
                                className={'dropDownField'}
                                onChange={(event) => this.editInfo(event, 'bedrijf_tijdzone')}
                            >
                                {this.getTimezones()}
                            </select>

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Valuta</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <select 
                                name="Selecteer een valuta" 
                                className={'dropDownField'}
                                onChange={(event) => this.editInfo(event, 'bedrijf_valuta')}
                            >
                                {this.getCurrencyList()}
                            </select>

                          </div>

                      </div>

                    </div>
                
                  :
                  this.state.menu === 'server' ?
                  
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Server informatie</div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                        <div className='planner_new_publishButton' style={{marginLeft: 0, width: '5vw'}} onClick={() => this.saveCompanyAlert()}>Opslaan</div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Server</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <select 
                                name="Selecteer een server" 
                                className={'dropDownField'}
                                onChange={(event) => this.editInfo(event, 'bedrijf_server_id')}
                            >
                                {this.getServerOptions()}
                            </select>

                          </div>

                      </div>

                    </div>
                
                  :
                  this.state.menu === 'package' ?

                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      <div style={{fontSize: '1vw', fontWeight: 'bold', marginTop: '1vw'}}>Pakket informatie</div>

                      <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                        <div className='planner_new_publishButton' style={{marginLeft: 0, width: '5vw'}} onClick={() => this.saveCompanyAlert()}>Opslaan</div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Betaling medewerkers</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <select 
                                name="Selecteer een pakket" 
                                className={'dropDownField'}
                                onChange={(event) => this.editInfo(event, 'pakket_id')}
                            >
                                {this.getOldPackageOptions()}
                            </select>

                          </div>

                      </div>

                      <div className='modal-row_start' style={{marginTop: '1vw'}}>

                          <div className='modal-rowSub1_start'>
                              <span><b>Pakket</b></span>
                          </div>

                          <div className='modal-rowSub2_start'>

                            <select 
                                name="Selecteer een pakket" 
                                className={'dropDownField'}
                                onChange={(event) => this.setState({ selectedPackage: event.target.value })}
                            >
                                <option value="0" selected={this.state.selectedPackage === '0'}>Starter</option>
                                <option value="1" selected={this.state.selectedPackage === '1'}>Personeelsplanning</option>
                                <option value="2" selected={this.state.selectedPackage === '2'}>Personeelsplanning + inkloksysteem</option>
                            </select>

                          </div>

                      </div>

                    </div>

                  : null}

                </div>

              :

                null

              }

            </div>

          }

        </div>

      </div>

    </div>
    );
  }
  
}

export default Trials;