import APIGetNotificationInfo from '../classes/APIGetNotificationInfo';
import APIGetAllAccounts from '../classes/APIGetAllAccounts';
import APIGetTeamUsers from '../classes/APIGetTeamUsers';
import Constants from './Constants';

class KodictNotifications {

  async sendPushNotification(ida, type, title, body) {

    let pushNotificationAllowed = false;

    const notificationData = await APIGetNotificationInfo.Request(ida); //notif

    if(type === 'trade' && notificationData !== null && notificationData[0].pushtoken_enabled === "1" && notificationData[0].pushtoken_trades === "1") {
      pushNotificationAllowed = true;
    } else if(type === 'holiday' && notificationData !== null && notificationData[0].pushtoken_enabled === "1" && notificationData[0].pushtoken_holiday === "1") {
      pushNotificationAllowed = true;
    } else if(type === 'planner' && notificationData !== null && notificationData[0].pushtoken_enabled === "1" && notificationData[0].pushtoken_planner === "1") {
      pushNotificationAllowed = true;
    } else if(type === 'news' && notificationData !== null && notificationData[0].pushtoken_enabled === "1") {
      pushNotificationAllowed = true;
    }

    if(pushNotificationAllowed === true) {

      const message = {
        to: notificationData[0].pushtoken_token,
        sound: 'default',
        title: title,
        body: body,
        _displayInForeground: true,
        channelId: 'default2',
      };
      
      try { //https://cors-anywhere.herokuapp.com/

        await fetch('https://exp.host/--/api/v2/push/send', {
          mode: 'no-cors',
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(message),
      });
          
      } catch (error) {
          
      }

    }
    
  }

  async sendPushNotificationBulk(people, type, title, body) {

    let peopleToNotify = [];

    for (const userID of people) {

      const ida = userID;

      let pushNotificationAllowed = false;

      const notificationData = await APIGetNotificationInfo.Request(ida); //notif

      if(type === 'trade' && notificationData !== null && notificationData[0].pushtoken_enabled === "1" && notificationData[0].pushtoken_trades === "1") {
        pushNotificationAllowed = true;
      } else if(type === 'holiday' && notificationData !== null && notificationData[0].pushtoken_enabled === "1" && notificationData[0].pushtoken_holiday === "1") {
        pushNotificationAllowed = true;
      } else if(type === 'planner' && notificationData !== null && notificationData[0].pushtoken_enabled === "1" && notificationData[0].pushtoken_planner === "1") {
        pushNotificationAllowed = true;
      } else if(type === 'news' && notificationData !== null && notificationData[0].pushtoken_enabled === "1") {
        pushNotificationAllowed = true;
      }

      if (pushNotificationAllowed === true) {
        peopleToNotify.push(notificationData[0].pushtoken_token);
      }

    }

    //console.log(peopleToNotify)

    if(peopleToNotify.length > 0) {

      while(peopleToNotify.length) {

        const message = {
          to: peopleToNotify.slice(0, 91),
          sound: 'default',
          title: title,
          body: body,
          _displayInForeground: true,
          channelId: 'default2',
        };
        
        try { //https://cors-anywhere.herokuapp.com/

          await fetch('https://exp.host/--/api/v2/push/send', {
            mode: 'no-cors',
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Accept-encoding': 'gzip, deflate',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(message),
          });
            
        } catch (error) {
            
        }

        peopleToNotify.splice(0, 90);

      }

    }
    
  }

  async sendPushNotificationStaff(type, title, body, team) {

    const allAccounts = await APIGetAllAccounts.Request();

    if (team !== null && typeof team === 'number') {

      const teamUsers = await APIGetTeamUsers.Request();

      allAccounts = Constants.getTeamAccountsT(teamUsers, allAccounts, team);

    }

    for(const acc of allAccounts) {

      if(acc.account_rights === '2' || acc.account_rights === '1') {

        let pushNotificationAllowed = false;

        const notificationData = await APIGetNotificationInfo.Request(acc.account_id); //notif
    
        if(notificationData !== null && notificationData[0].pushtoken_enabled === "1") {
          pushNotificationAllowed = true;
        }
    
        if(pushNotificationAllowed === true) {
    
          const message = {
            to: notificationData[0].pushtoken_token,
            sound: 'default',
            title: title,
            body: body,
            _displayInForeground: true,
            channelId: 'default2',
          };
          try {

            await fetch('https://exp.host/--/api/v2/push/send', {
              mode: 'no-cors',
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Accept-encoding': 'gzip, deflate',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(message),
          });
              
          } catch (error) {
              
          }
    
        }

      }

    }
    
  }

  async sendPushNotificationAdmin(type, title, body) {

    const allAccounts = await APIGetAllAccounts.Request();

    for(const acc of allAccounts) {

      if(acc.account_rights === '2') {

        let pushNotificationAllowed = false;

        const notificationData = await APIGetNotificationInfo.Request(acc.account_id); //notif
    
        if(notificationData !== null && notificationData[0].pushtoken_enabled === "1") {
          pushNotificationAllowed = true;
        }
    
        if(pushNotificationAllowed === true) {
    
          const message = {
            to: notificationData[0].pushtoken_token,
            sound: 'default',
            title: title,
            body: body,
            _displayInForeground: true,
            channelId: 'default2',
          };
          try {

            await fetch('https://exp.host/--/api/v2/push/send', {
              mode: 'no-cors',
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Accept-encoding': 'gzip, deflate',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(message),
          });
              
          } catch (error) {
              
          }
    
        }

      }

    }
    
  }
  
}

// singleton
export default (new KodictNotifications());