/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './missingModal.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Colors from '../../constants/Colors';
import Data from '../../constants/Data';
import BetterAlert from '../alert/alert';
import APIGetDefaultDayShifts from '../../classes/APIGetDefaultDayShifts';
import APIGetDefaultShifts from '../../classes/APIGetDefaultShifts';
import APIGetFunctions from '../../classes/APIGetFunctions';
import AccountsModal from '../../pages/options/accounts/accountsModal';
import FunctionsModal from '../../pages/options/functions/functionsModal';
import TimesModal from '../../pages/options/times/timesModal';
import ShiftsModal from '../../pages/options/shifts/shiftsModal';

class MissingModal extends React.Component {

  constructor(props) {

    super(props);

	this.props = props;
	
	this.lastTutStep = Data.data.accountData.account_rights === '0' ? 3 : 4;
    this.isLastStep = Data.data.tutorialStep === this.lastTutStep;
    
    this.state = {

		showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
		alertButtonAction: null,
		
		loading: true,

		shifts: 0,
		times: 0,
		functions: 0,

		passChanged: false,
		seenAdminOptions: false,
		dataChanged: false,

		functionsObject: [],
		timesObject: [],

		error: false,
		errorMessage: 'Je hebt nog niet alle stappen doorlopen',

		showUserModal: false,
		showFunctionModal: false,
		showTimesModal: false,
		showShiftModal: false,

    };

    //componentWillMount here:

  }

  async componentDidMount() {

	const passChanged = localStorage.getItem('@tutPassChanged') === '1';
	const dataChanged = localStorage.getItem('@tutDataChanged') === '1';
	const seenAdminOptions = localStorage.getItem('@tutSeenAdminOptions') === '1';
	const shifts = await APIGetDefaultDayShifts.Request();
	const times = await APIGetDefaultShifts.Request();
	const functions = await APIGetFunctions.Request();

	this.setState({
		loading: false,
		shifts: shifts.length,
		times: times.length,
		functions: functions.length,
		passChanged: passChanged,
		dataChanged: dataChanged,
		seenAdminOptions: seenAdminOptions,
		functionsObject: functions,
		timesObject: times,
	});

  }

  async reloadTutScreen(openUp = true) {

	if (openUp === true) {
		this.props._parent.openMissingModal();
	}

    this.setState({
      loading: true,
    });

	const passChanged = localStorage.getItem('@tutPassChanged') === '1';
	const dataChanged = localStorage.getItem('@tutDataChanged') === '1';
	const seenAdminOptions = localStorage.getItem('@tutSeenAdminOptions') === '1';
	const shifts = await APIGetDefaultDayShifts.Request();
	const times = await APIGetDefaultShifts.Request();
	const functions = await APIGetFunctions.Request();

    this.setState({
      loading: false,
      shifts: shifts.length,
      times: times.length,
      functions: functions.length,
      passChanged: passChanged,
      dataChanged: dataChanged,
      seenAdminOptions: seenAdminOptions,
      functionsObject: functions,
      timesObject: times,
    });

  }

  goTo(route) {

    if (route === 'editAccount' && this.state.passChanged !== true) {
		this.setState({
			alertTitle: 'Oeps',
			alertBody: (
				<div>

					Je moet eerst jouw wachtwoord wijzigen

				</div>
			),
			alertButtons: 1,
			alertButtonText: ['OK'],
			showAlert: true,
		});
      return;
    }

    if (route === 'addShift' && (this.state.functions === 0 || this.state.times === 0)) {
		this.setState({
			alertTitle: 'Oeps',
			alertBody: (
				<div>

					Je moet eerst minimaal één functie en één vaste tijd toevoegen

				</div>
			),
			alertButtons: 1,
			alertButtonText: ['OK'],
			showAlert: true,
		});
      return;
	}

	if (route === 'adminOptions' && (this.state.functions === 0 || this.state.times === 0 || this.state.shifts === 0 || this.state.dataChanged === false || this.state.passChanged === false)) {
		this.setState({
			alertTitle: 'Oeps',
			alertBody: (
				<div>

					Je moet eerst de voorgaande stappen voltooien om door te gaan naar de instellingen van de app 

				</div>
			),
			alertButtons: 1,
			alertButtonText: ['OK'],
			showAlert: true,
		});
      return;
	}
	
	switch (route) {

		case 'changePass':
			this.props._history.push({
				pathname: `/password`,
				fromTut: true,
    		   	reloadTutScreen: this.reloadTutScreen.bind(this),
			});
			this.props._closeModal();
			break;

		case 'editAccount':
			this.setState({ showUserModal: true });
			break;

		case 'addFunction':
			this.setState({ showFunctionModal: true });
			break;

		case 'addTime':
			this.setState({ showTimesModal: true });
			break;

		case 'addShift':
			this.setState({ showShiftModal: true });
			break;

		case 'adminOptions':
			localStorage.setItem('@tutSeenAdminOptions', '1');
			this.setState({ seenAdminOptions: true });
			this.props._history.push({
				pathname: `/app_options`,
				fromTut: true,
    		   	reloadTutScreen: this.reloadTutScreen.bind(this, false),
			});
			break;

	}

    // this.props._history.navigate(route, { 
    //   _fromTut: true,
    //   _reloadTutScreen: this.reloadTutScreen.bind(this),
    //   _functions: this.state.functionsObject,
    //   _shifts: this.state.timesObject,
    //   _user: Data.data.accountData,
    // });

    this.setState({ error: false });

  }

  closeUserModal() {
	this.setState({ showUserModal: false });
  }

  closeFunctionModal() {
	this.setState({ showFunctionModal: false });
  }

  closeTimesModal() {
	this.setState({ showTimesModal: false });
  }

  closeShiftModal() {
	this.setState({ showShiftModal: false });
  }

	// stopTutorialAlert() {

	// 	this.setState({
	// 		alertTitle: 'Web-uitleg afsluiten',
	// 		alertBody: (
	// 		  <div>
	
	// 			Wil je stoppen met het volgen van de web-uitleg?
	
	// 		  </div>
	// 		),
	// 		alertButtons: 2,
	// 		alertButtonText: ['Doorgaan', 'Afsluiten'],
	// 		alertButtonAction: this.stopTutorial.bind(this),
	// 		showAlert: true,
	// 	});
	
	// }
	
	// async stopTutorial() {

	// 	Constants.cancelTutorial();

	// 	this.props._closeModal();

	// 	this.props._history.push(`/home`);

	// }

	// async nextStep() {

	// 	this.props._closeModal();

	// 	if (this.isLastStep === true) {

	// 		Constants.cancelTutorial();

	// 		clearInterval(this.props._navBar.intervalMenu);
	// 		this.props._navBar.closeMenu();

	// 		this.props._history.push(`/home`);

	// 		return;

	// 	}

	// 	const nextStep = Data.data.tutorialStep + 1;
	// 	Data.data.tutorialStep = Data.data.tutorialStep + 1;

	// 	if (nextStep === 2) { //from home to avail
	// 		if(Data.data.appOptions.availability === 'SCHOOL') {
	// 			this.props._history.push(`/availabilitySchool`);
	// 		} else {
	// 			this.props._history.push(`/availability`);
	// 		}
	// 	}
	// 	else if (nextStep === 3) { //from avail to planner or options
	// 		if(Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1') {
	// 			this.props._history.push(`/planner`);
	// 		} else {
	// 			this.props._navBar.openMenu();
	// 		}
	// 	}
	// 	else if (nextStep === 4) { // from planner to options
	// 		if(Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1') {
	// 			this.props._navBar.openMenu();
	// 		}
	// 	}

	// }

	closeAlert() {

		this.setState({ showAlert: false });
	
	  }

	  finishTut() {

		if (this.state.dataChanged !== true || this.state.functions === 0 || this.state.shifts === 0 || this.state.times === 0 || this.state.passChanged !== true || this.state.seenAdminOptions !== true) {
			this.setState({
				alertTitle: 'Oeps',
				alertBody: (
					<div>
	
						Je hebt nog niet alle stappen voltooid
	
					</div>
				),
				alertButtons: 1,
				alertButtonText: ['OK'],
				showAlert: true,
			});
		  return;
		}

		Data.data.canUseNav = true;

		this.props._history.push('/app_options');

		this.props._closeModal();
		
	  }

  render() {

    return (
        <div id="missingModal" className="missingModal" onClick={(e) => e.target === document.getElementById("zmissingModal") ? this.props._closeModal() : null}>

			{this.state.showAlert === true ?
                
				<BetterAlert
					_closeAlert={this.closeAlert.bind(this)}
					_title={this.state.alertTitle}
					_body={this.state.alertBody}
					_buttons={this.state.alertButtons}
					_buttonText={this.state.alertButtonText}
					_buttonAction={this.state.alertButtonAction}
				/>

			:

			null
			
			}

			{this.state.showUserModal === true ?
                
                <AccountsModal
                  _closeModal={this.closeUserModal.bind(this)}
                  _modalData={Data.data.accountData}
                  _modalMode={0}
				  _functions={this.state.functionsObject}
				  _fromTut={true}
    		   	  _reloadTutScreen={this.reloadTutScreen.bind(this, false)}
                />

              :

                null
              
              }

			  {this.state.showFunctionModal === true ?
                
                <FunctionsModal
					_closeModal={this.closeFunctionModal.bind(this)}
					_modalData={null}
					_modalMode={0}
					_fromTut={true}
					_reloadTutScreen={this.reloadTutScreen.bind(this, false)}
                />

              :

                null
              
              }

			  {this.state.showTimesModal === true ?
                
                <TimesModal
					_closeModal={this.closeTimesModal.bind(this)}
					_modalData={null}
					_modalMode={0}
					_fromTut={true}
					_reloadTutScreen={this.reloadTutScreen.bind(this, false)}
                />

              :

                null
              
              }

			  {this.state.showShiftModal === true ?
                
                <ShiftsModal
					_closeModal={this.closeShiftModal.bind(this)}
					_modalData={[]}
					_modalMode={0}
					_modalTimes={this.state.timesObject}
                  	_modalFunctions={this.state.functionsObject}
					_fromTut={true}
					_reloadTutScreen={this.reloadTutScreen.bind(this, false)}
                />

              :

                null
              
              }

        <div className="missingModal-content">

          <div className="missingModal-header">

			{/* {this.props._tutorialModal !== true ?
            	<span className="missingModal-close" onClick={() => this.props._closeModal()}>&times;</span>
			: null} */}

            <p>Eerste stappen</p>

          </div>

		  {this.state.loading === true ?

			<div style={{height: '40vh'}}>
          
		  		<div className='lds-dual-ring loader' />

			</div>

		  :

            <div className="missingModal-body" style={{paddingBottom: '5vw'}}>

                <span>Om de website optimaal te kunnen gebruiken zullen er eerst wat veranderingen gemaakt moeten worden. Deze opties vind je later ook terug in het menu.</span>

				<div className='missingModal-settingRow' style={{marginTop: '1vw'}}>

					<div className='missingModal-settingRowSub1'>

						<span style={{fontSize: '1.2vw'}}>Wachtwoord</span>

						{this.state.passChanged !== true ?

							<i className='fas fa-times' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.redFilledIn}} />

						: 

							<i className='fas fa-check' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.greenFilledIn}} />

						}

					</div>

					<div className='missingModal-settingRowSub2'>

						<span className='missingModal-editButton' onClick={() => this.goTo('changePass')}>Wijzigen</span>

					</div>

				</div>

				<div className='missingModal-settingRow' style={{marginTop: '1vw'}}>

					<div className='missingModal-settingRowSub1'>

						<span style={{fontSize: '1.2vw'}}>Gegevens</span>

						{this.state.dataChanged !== true ?

							<i className='fas fa-times' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.redFilledIn}} />

						: 

							<i className='fas fa-check' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.greenFilledIn}} />

						}

					</div>

					<div className='missingModal-settingRowSub2'>

						<span className='missingModal-editButton' onClick={() => this.goTo('editAccount')}>Wijzigen</span>

					</div>

				</div>

				<div className='missingModal-settingRow' style={{marginTop: '1vw'}}>

					<div className='missingModal-settingRowSub1'>

						<span style={{fontSize: '1.2vw'}}>{this.state.functions === 0 ? 'Geen' : this.state.functions} functie{this.state.functions === 0 || this.state.functions > 1 ? 's' : ''}</span>

						{this.state.functions === 0 ?

							<i className='fas fa-times' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.redFilledIn}} />

						: 

							<i className='fas fa-check' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.greenFilledIn}} />

						}

					</div>

					<div className='missingModal-settingRowSub2'>

						<span className='missingModal-editButton' onClick={() => this.goTo('addFunction')}>Instellen</span>

					</div>

				</div>

				<div className='missingModal-settingRow' style={{marginTop: '1vw'}}>

					<div className='missingModal-settingRowSub1'>

						<span style={{fontSize: '1.2vw'}}>{this.state.times === 0 ? 'Geen' : this.state.times} vaste tijd{this.state.times === 0 || this.state.times > 1 ? 'en' : ''}</span>

						{this.state.times === 0 ?

							<i className='fas fa-times' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.redFilledIn}} />

						: 

							<i className='fas fa-check' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.greenFilledIn}} />

						}

					</div>

					<div className='missingModal-settingRowSub2'>

						<span className='missingModal-editButton' onClick={() => this.goTo('addTime')}>Instellen</span>

					</div>

				</div>

				<div className='missingModal-settingRow' style={{marginTop: '1vw'}}>

					<div className='missingModal-settingRowSub1'>

						<span style={{fontSize: '1.2vw'}}>{this.state.shifts === 0 ? 'Geen' : this.state.shifts} standaard dienst{this.state.shifts === 0 || this.state.shifts > 1 ? 'en' : ''}</span>

						{this.state.shifts === 0 ?

							<i className='fas fa-times' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.redFilledIn}} />

						: 

							<i className='fas fa-check' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.greenFilledIn}} />

						}

					</div>

					<div className='missingModal-settingRowSub2'>

						<span className='missingModal-editButton' onClick={() => this.goTo('addShift')}>Instellen</span>

					</div>

				</div>

				<div className='missingModal-settingRow' style={{marginTop: '1vw'}}>

					<div className='missingModal-settingRowSub1'>

						<span style={{fontSize: '1.2vw'}}>Admin opties</span>

						{this.state.seenAdminOptions !== true ?

							<i className='fas fa-times' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.redFilledIn}} />

						: 

							<i className='fas fa-check' style={{fontSize: '1.5vw', marginLeft: '1vw', color: Colors.color.greenFilledIn}} />

						}

					</div>

					<div className='missingModal-settingRowSub2'>

						<span className='missingModal-editButton' onClick={() => this.goTo('adminOptions')}>Bekijken</span>

					</div>

				</div>

            </div>

			}

			<div className='missingModal-bottomNav'>
				
				<span className='missingModal-navButtonRed' style={{backgroundColor: 'transparent', cursor: 'default'}}>&nbsp;</span>

				<span className='missingModal-navButton' onClick={() => this.finishTut()}>Naar de app</span>

			</div>

        </div>

      </div>
    );
  }
  
}

export default MissingModal;