import React from 'react';
import './mailing.css';
import '../demo/demo.css';
import '../home2/home2.css';
import '../../constants/DefaultStyle.css';
import '../employees/employees.css';
import '../options/reports/reports.scss';
import '../options/hoursRegistration/hoursRegistration.css';

import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';
import BetterAlert from '../../components/alert/alert';
import Constants from '../../constants/Constants';
import Colors from '../../constants/Colors';
import {Prompt} from 'react-router';
import APISendBulkMail from '../../classes/APISendBulkMail';
import {toast} from 'react-toastify';
import EmailTemplateModal from "./emailTemplateModal";
import ServerSelectionDropdown from './serverSelectionDropdown';

class Mailing extends React.Component {
    state = {
        loading: true,
        reloading: false,
        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,
        mails: '',
        sentMails: [],
        errorMails: [],
        menu: 'general',
        errorText: '',
        templates: [],
        selectedTemplate: '',
        emailSubject: '',
        isTemplateModalOpen: false,
        selectedServers: [],
    };

    componentDidMount() {
        if (!Data.data.loggedIn) {
            this.props.history.push("/");
            return;
        }
        this.loadTemplates();
    }

    loadTemplates = async () => {
        try {
            const response = await fetch(`https://${APISendBulkMail.servers[0]}/php/mail/manage-templates.php`);
            const templates = await response.json();
            this.setState({templates, loading: false});
        } catch (error) {
            console.error('Fout bij het laden van sjablonen:', error);
            toast.error('Fout bij het laden van sjablonen');
            this.setState({loading: false});
        }
    }

    handleTemplateAction = async (action, data) => {
        this.setState({reloading: true});
        try {
            const actionPromises = APISendBulkMail.servers.map(server => fetch(`https://${server}/php/mail/manage-templates.php`, {
                method: action === 'upload' ? 'POST' : 'DELETE',
                ...(action === 'upload' ? {body: data} : {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: `template=${encodeURIComponent(data)}`
                })
            }));

            const results = await Promise.all(actionPromises);

            if (results.every(res => res.ok)) {
                toast.success(`Sjabloon succesvol ${action === 'upload' ? 'geüpload' : 'verwijderd'}`);
                await this.loadTemplates();
            } else {
                toast.error(`Fout bij het ${action === 'upload' ? 'uploaden' : 'verwijderen'} van sjabloon`);
            }
        } catch (error) {
            console.error(`Fout bij het ${action === 'upload' ? 'uploaden' : 'verwijderen'} van sjabloon:`, error);
            toast.error(`Fout bij het ${action === 'upload' ? 'uploaden' : 'verwijderen'} van sjabloon`);
        }
        this.setState({reloading: false});
    }

    uploadTemplate = (file) => {
        if (!file) return;
        const formData = new FormData();
        formData.append('template', file);
        this.handleTemplateAction('upload', formData);
    }

    removeTemplate = (templateNames) => {
        templateNames.forEach(name => this.handleTemplateAction('remove', name));
    }

    checkValidations = () => {
        const {selectedTemplate, emailSubject, mails} = this.state;
        if (Constants.isEmpty(selectedTemplate)) {
            this.setState({showError: true, errorText: 'Je hebt geen sjabloon geselecteerd'});
            return false;
        }
        if (Constants.isEmpty(emailSubject)) {
            this.setState({showError: true, errorText: 'Je hebt geen onderwerp ingevuld'});
            return false;
        }
        if (Constants.isEmpty(mails)) {
            this.setState({showError: true, errorText: 'Je hebt geen e-mailadressen ingevuld'});
            return false;
        }
        return true;
    }

    handleServerSelection = (selectedServers) => {
        this.setState({selectedServers});
    }

    sendEmails = async () => {
        if (!this.checkValidations()) return;

        this.setState({reloading: true});

        try {
            const result = await APISendBulkMail.Request(
                this.state.mails,
                this.state.selectedTemplate,
                this.state.emailSubject,
                this.state.selectedServers
            );

            if (result.success) {
                toast.success(`E-mails succesvol verzonden! ${result.totalSent} van ${result.totalSent + result.totalFailed} e-mails verzonden.`);
                if (result.failedEmails.length > 0) {
                    console.log('Mislukte e-mails:', result.failedEmails.join(', '));
                }
            } else {
                toast.error(`Fout bij het verzenden van e-mails. ${result.totalFailed} van ${result.totalSent + result.totalFailed} e-mails mislukt.`);
            }
        } catch (error) {
            console.error('Fout bij het verzenden van e-mails:', error);
            toast.error('Fout bij het verzenden van e-mails');
        }

        this.setState({reloading: false, mails: '', emailSubject: ''});
    }

    editMails = (e) => {
        const trimmed = e.target.value.replaceAll(/[\s,]+/g, ',');
        this.setState({mails: trimmed});
    }

    render() {
        const {
            loading,
            reloading,
            showAlert,
            alertTitle,
            alertBody,
            alertButtons,
            alertButtonText,
            alertButtonAction,
            mails,
            emailSubject,
            templates,
            selectedTemplate,
            isTemplateModalOpen,
            errorText,
            selectedServers
        } = this.state;

        const inputStyle = {
            width: '100%',
            boxSizing: 'border-box'
        };

        return (
            <div>
                <Prompt
                    when={mails !== '' || emailSubject !== ''}
                    message='Je hebt onopgeslagen wijzigingen. Weet je zeker dat je de pagina wilt verlaten?'
                />

                <Navbar ref={this.navBar} history={this.props.history} active={'mailing'} fixed
                        loading={loading} reloading={reloading} parent={this}/>

                <div className='employees_main2'>
                    <div className='employees_content2' style={{width: '100%', fontSize: '0.8vw'}}>
                        {loading ? (
                            <div className='lds-dual-ring loader'/>
                        ) : (
                            <div className='employees_container'>
                                {reloading && <div className='lds-dual-ring loader2'/>}

                                {showAlert && (
                                    <BetterAlert
                                        _closeAlert={() => this.setState({showAlert: false})}
                                        _title={alertTitle}
                                        _body={alertBody}
                                        _buttons={alertButtons}
                                        _buttonText={alertButtonText}
                                        _buttonAction={alertButtonAction}
                                    />
                                )}

                                <div>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Bulk e-mail</div>

                                        <div className='modal-row_start' style={{marginTop: '1vw'}}>
                                            <div className='modal-rowSub1_start'>
                                                <span><b>Sjablonen beheren:</b></span>
                                            </div>
                                            <div className='modal-rowSub2_start'>
                                                <button
                                                    onClick={() => this.setState({isTemplateModalOpen: true})}
                                                    className='dropDownField'
                                                    style={{...inputStyle, cursor: 'pointer'}}
                                                >
                                                    Sjabloonbeheer openen
                                                </button>
                                            </div>
                                        </div>

                                        <div className='modal-row_start' style={{marginTop: '1vw'}}>
                                            <div className='modal-rowSub1_start'>
                                                <span><b>E-mailsjabloon:</b></span>
                                            </div>
                                            <div className='modal-rowSub2_start'>
                                                <select
                                                    className='dropDownField'
                                                    onChange={(event) => this.setState({selectedTemplate: event.target.value})}
                                                    value={selectedTemplate}
                                                    style={inputStyle}
                                                >
                                                    <option value="" disabled>Selecteer een sjabloon</option>
                                                    {templates.map((template, index) => (
                                                        <option key={index}
                                                                value={template.name}>{template.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className='modal-row_start' style={{marginTop: '1vw'}}>
                                            <div className='modal-rowSub1_start'>
                                                <span><b>E-mailonderwerp:</b></span>
                                            </div>
                                            <div className='modal-rowSub2_start'>
                                                <input
                                                    type="text"
                                                    className='dropDownField'
                                                    value={emailSubject}
                                                    onChange={(event) => this.setState({emailSubject: event.target.value})}
                                                    placeholder='Voer e-mailonderwerp in'
                                                    style={inputStyle}
                                                />
                                            </div>
                                        </div>

                                        <div className='modal-row_start' style={{marginTop: '1vw'}}>
                                            <div className='modal-rowSub1_start'>
                                                <span><b>Selecteer Servers:</b></span>
                                            </div>
                                            <div className='modal-rowSub2_start'>
                                                <ServerSelectionDropdown
                                                    servers={APISendBulkMail.servers}
                                                    onSelectionChange={this.handleServerSelection}
                                                />
                                            </div>
                                        </div>

                                        <div className='modal-row_start' style={{marginTop: '1vw'}}>
                                            <div className='modal-rowSub1_start'>
                                                <span><b>E-mailadressen:</b></span>
                                                <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>E-mails scheiden d.m.v. een komma ( , )</span>
                                            </div>
                                            <div className='modal-rowSub2_start'>
                                                <textarea
                                                    className='dropDownField'
                                                    value={mails}
                                                    onChange={this.editMails}
                                                    placeholder='E-mailadressen:'
                                                    style={{...inputStyle, minHeight: '10vw'}}
                                                />
                                            </div>
                                        </div>

                                        <div className="planner_addButtons_container"
                                             style={{marginLeft: 0, marginTop: '2vw'}}>
                                            <div
                                                className='planner_new_publishButton'
                                                style={{marginLeft: 0, width: '6.5vw'}}
                                                onClick={this.sendEmails}
                                            >
                                                Versturen
                                            </div>
                                        </div>

                                        <span style={{
                                            fontSize: '0.6vw',
                                            fontStyle: 'italic',
                                            color: Colors.color.redFilledIn
                                        }}>Je kunt maximaal 5000 e-mails per dag versturen</span>

                                        {errorText && (
                                            <div className='errorText' style={{
                                                marginTop: '1vw',
                                                marginLeft: 0,
                                                marginBottom: 0
                                            }}>{errorText}</div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <EmailTemplateModal
                    isOpen={isTemplateModalOpen}
                    onClose={() => this.setState({isTemplateModalOpen: false})}
                    templates={templates}
                    onUpload={this.uploadTemplate}
                    onRemove={this.removeTemplate}
                />
            </div>
        );
    }
}

export default Mailing;