import React from 'react';
import './mailing.css';
import '../demo/demo.css';
import '../home2/home2.css';
import '../../constants/DefaultStyle.css';
import '../employees/employees.css';
import '../options/reports/reports.scss';
import '../options/hoursRegistration/hoursRegistration.css';

import Data from '../../constants/Data';

class Blank extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }  
    
    this.state = {

        loading: true,
        reloading: false,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        mailing: null,
        mails: '',

        sentMails: [],
        errorMails: [],

        menu: 'general',

        errorText: '',

    };

    //componentWillMount here

  }

  async componentDidMount() {
  
    this.setState({ 
      // servers: servers,
      // serverID: parseInt(servers[0].server_id),
      // companies: companies,
      loading: false
    });

  }

  render() {

    return (

      <div>

      </div>
    );
  }
  
}

export default Blank;