/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './functions.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';
import Constants from '../../../constants/Constants';

import APIAddFunction from '../../../classes/APIAddFunction';
import APIEditFunction from '../../../classes/APIEditFunction';
import APIDeleteFunction from '../../../classes/APIDeleteFunction';

import { SketchPicker, ChromePicker } from 'react-color';
import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';

class FunctionsModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    
    this.state = {

        loading: false,

        functionName: '',
        selectedColor: '#00C0FF',

        showError: false,
        errorText: '',

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

    };

  }

  componentDidMount() {

    if(this.mode === 0) { //ADDING FUNCTION


        
    }

    if(this.mode === 1) { //EDITING FUNCTION

      this.setState({
        functionName: this.modalData.functie_naam,
        selectedColor: '#' + this.modalData.functie_kleur,
      });

    }

  }

  deleteAlert() {

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: 'Weet je zeker dat je deze functie wilt verwijderen?',
        alertButtons: 2,
        alertButtonText: ['Annuleren', 'Verwijderen'],
        alertButtonAction: this.deleteFunction.bind(this),
        showAlert: true,
    });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  async saveFunction() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIAddFunction.Request(
      this.state.functionName, 
      this.state.selectedColor.substring(1, 7),
    );

    if(added === true) {

      if (this.props._fromTut === true) {

        this.goBack();

        return;

      }

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  async editFunction() {

    this.setState({loading: true});

    if(this.checkValidations() === false) {
      this.setState({loading: false});
      return;
    }

    const added = await APIEditFunction.Request(
      this.state.functionName, 
      this.state.selectedColor.substring(1, 7),
      parseInt(this.modalData.functie_id),
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  async deleteFunction() {

    this.setState({loading: true});

    const added = await APIDeleteFunction.Request(
      parseInt(this.modalData.functie_id),
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    }

  }

  checkValidations() {

    if(this.state.functionName === '') {
      this.setState({
        showError: true,
        errorText: 'Je hebt geen functienaam ingevoerd',
      });
      return false;
    }

    return true;

  }

  goBack() {

    if (this.props._fromTut === true) {

        this.props._reloadTutScreen();

    }

    this.props._closeModal();

  }

  render() {

    //const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam.charAt(0) : Data.data.accountData.info_achternaam.charAt(0)}.`;    

    return (
        <div id="functionsModal" className="modal" onClick={(e) => e.target === document.getElementById("zfunctionsModal") ? this.goBack() : null}>

        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>
            {this.mode === 0 ?
            
              <p>Functie toevoegen</p>
            
            :
            this.mode === 1 ?

              <p>Functie wijzigen</p>

            :

              null

            }
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form'>

                    <div className='modal-row'>

                        <div className='modal-rowSub1'>
                            <span><b>Functienaam</b></span>
                            <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>Hoofdlettergevoelig</span>
                        </div>

                        <div className='modal-rowSub2'>

                           <input 
                            className={'inputField2'}
                            maxLength={100}
                            value={this.state.functionName}
                            onChange={(event) => this.setState({ functionName: event.target.value })}
                            placeholder={'Functienaam'}
                          />

                        </div>

                    </div>

                    <div className='modal-row2' style={{marginTop: '1vw'}}>

                        <div className='modal-rowSub1_padding'>
                            <span><b>Functiekleur</b></span>
                            <div style={{width: '4vw', height: '2vw', marginTop: 10, borderRadius: 5, backgroundColor: this.state.selectedColor}}></div>
                        </div>

                        <div className='modal-rowSub2'>

                           <ChromePicker 
                            //presetColors={[]}
                            width={'100%' - 32}
                            color={this.state.selectedColor}
                            onChange={(color) => this.setState({selectedColor: color.hex})}
                            disableAlpha={true}
                          />

                        </div>

                    </div>

                  <div style={{height: '1vw', width: '100%'}} />

                </div>
            
                {/* <div className='plan_modal-submit'>

                    {this.state.loading === true ?

                        <div className='submitButtonLoading'><div className="lds-dual-ring-button"></div></div>

                    :
                    this.mode === 1 ?

                        <div>

                            <div className='submitButton' onClick={() => this.editFunction()}>Opslaan</div>

                            <div className='submitButton' style={{color: Colors.color.redFilledIn, marginTop: 10}} onClick={() => this.deleteAlert()}>Verwijderen</div>

                        </div>

                    :

                        <div className='submitButton' onClick={() => this.saveFunction()}>Toevoegen</div>

                    }

                    {this.showError()}

                </div> */}

            </div>

            {this.state.loading === true ?

              <div className='modal-bottom'>

                <div className='modal-loadingButtonText'>
                  <div className="lds-dual-ring-button" />
                </div>

              </div>

            :

              <div className='modal-bottom'>

                {this.showError()}

                {this.mode === 1 ?
                  <div className='modal-deleteButton' onClick={() => this.deleteAlert()}>
                    <span>Verwijderen</span>
                  </div>
                : null}

                {this.mode === 1 ?      
                  <div className='modal-submitButton' onClick={() => this.editFunction()}>
                    <span>Opslaan</span>
                  </div>
                : 
                  <div className='modal-submitButton' onClick={() => this.saveFunction()}>
                    <span>Toevoegen</span>
                  </div>
                }

              </div>

            }

        </div>

      </div>
    );
  }
  
}

export default FunctionsModal;