/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './alert.css';
import '../../constants/DefaultStyle.css';

//js components & classes
import Colors from '../../constants/Colors';
import Constants from '../../constants/Constants';

class BetterAlert extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;
    
    this.state = {

    };

    this.canClose = Constants.isEmpty(this.props._canClose) === true ? true : this.props._canClose;

    //componentWillMount here:

  }

  componentDidMount() {

  }

  action() {

    this.props._closeAlert();
    this.props._buttonAction();

  }

  action2() {

    this.props._closeAlert();
    this.props._buttonAction2();

  }

  render() {

    return (
        <div id="alertModal" className="alertModal" onClick={(e) => e.target === document.getElementById("zalertModal") ? this.props._closeAlert() : null}>

        <div className="alertModal-content">
          <div className="alertModal-header">
            {this.canClose === true ?
              <span className="alertModal-close" onClick={() => this.props._closeAlert()}>&times;</span>
            : null}
            <p>{this.props._title}</p>
          </div>
            <div className="alertModal-body">

                <div>
                  {this.props._body}
                </div>

                {this.props._buttons === 10 ?
                  <div style={{marginTop: '1vw'}}>

                    <div className='defaultLinkO' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}} onClick={() => this.props.history.push('/registration_codes')}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 4}}>
                        <span>Beheer registratiecodes</span>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', flex: 1}}>
                        <i className='fas fa-chevron-right' />
                      </div>
                    </div>

                    <div className='defaultLinkO' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '0.5vw'}} onClick={() => this.props.history.push('/employees')}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 4}}>
                        <span>Beheer medewerkers</span>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', flex: 1}}>
                        <i className='fas fa-chevron-right' />
                      </div>
                    </div>

                    <div className='defaultLinkO' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '0.5vw'}} onClick={() => window.open('mailto:info@kodict.nl')}>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 4}}>
                        <span>Upgrade pakket</span>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', flex: 1}}>
                        <i className='fas fa-chevron-right' />
                      </div>
                    </div>

                  </div>
                : null}

                {/* {this.props._buttons === 1 ?
                
                    <div style={{marginTop: '3vw'}} className='submitButton' onClick={() => this.props._closeAlert()}>

                        {this.props._buttonText[0]}

                    </div>
                
                :
                this.props._buttons === 2 ?
                    
                    <div className='inline2'>

                        <div style={{marginTop: '3vw'}} className='submitButtonHalf' onClick={() => typeof this.props._buttonAction2 === 'undefined' || this.props._buttonAction2 === null ? this.props._closeAlert() : this.action2()}>

                            {this.props._buttonText[0]}

                        </div>

                        <div style={{marginTop: '3vw', color: Colors.color.redFilledIn}} className='submitButtonHalf' onClick={() => this.action()}>

                            {this.props._buttonText[1]}

                        </div>

                    </div>
                
                :
                this.props._buttons === 3 ? // 2 actions
                    
                    <div>

                        <div style={{marginTop: '3vw',}} className='submitButton' onClick={() => this.props._closeAlert()}>

                            {this.props._buttonText[0]}

                        </div>

                        <div style={{marginTop: 5, color: Colors.color.redFilledIn}} className='submitButton' onClick={() => this.action()}>

                            {this.props._buttonText[1]}

                        </div>

                        <div style={{marginTop: 5, color: Colors.color.redFilledIn}} className='submitButton' onClick={() => this.action2()}>

                            {this.props._buttonText[2]}

                        </div>

                    </div>
                
                :

                    <div style={{marginTop: '3vw',}} className='submitButton' onClick={() => this.props._closeAlert()}>

                        {this.props._buttonText[0]}

                    </div>
            
                } */}

            </div>

            {this.props._buttons === 1 ?
              <div className='alertModal-bottom'>
                <div className='modal-cancelButton' onClick={() => Constants.isEmpty(this.props._buttonAction) === false ? this.props._buttonAction() : this.props._closeAlert()}>
                  <span>{this.props._buttonText[0]}</span>
                </div>
              </div>
            :
            this.props._buttons === 2 ?
              <div className='alertModal-bottom'>
                <div className='modal-cancelButton' style={{marginRight: '1.2vw'}} onClick={() => this.props._closeAlert()}>
                  <span>{this.props._buttonText[0]}</span>
                </div>
                <div className='modal-normalButton' onClick={() => this.action()}>
                  <span>{this.props._buttonText[1]}</span>
                </div>
              </div>
            :
            this.props._buttons === 10 ? // max employees alert
              <div className='alertModal-bottom'>
                <div className='modal-cancelButton' onClick={() => Constants.isEmpty(this.props._buttonAction) === false ? this.props._buttonAction() : this.props._closeAlert()}>
                  <span>{this.props._buttonText[0]}</span>
                </div>
              </div>
            :
              <div className='alertModal-bottom'>
                <div className='modal-cancelButton' onClick={() => this.props._closeAlert()}>
                  <span>{this.props._buttonText[0]}</span>
                </div>
              </div>
            }

        </div>

      </div>
    );
  }
  
}

export default BetterAlert;