import moment from 'moment-timezone';
import Data from './Data';

import GlobalAPIGetTime from '../classes/GlobalAPIGetTime';
import timesModal from '../pages/options/times/timesModal';
import APIGET from '../classes/global/APIGET';
import planClass from '../pages/planner/plan.class';

import React from 'react';

class Constants {

    // types LOGGER
    LOGGER_PLANNER = 'PLANNER';
    LOGGER_AVAILABILITY = 'AVAILABILITY';
    LOGGER_UNAVAILABILITY = 'UNAVAILABILITY';

    // changes LOGGER
    LOGGER_ADD = 'ADD';
    LOGGER_EDIT = 'EDIT';
    LOGGER_DEL = 'DEL';
    LOGGER_PUBL = 'PUBL';
    LOGGER_UNPUBL = 'UNPUBL';

    cancelTutorial() {

        Data.data.inTutorial = false;
        Data.data.tutorialStep = 0;
    
        localStorage.setItem('@doneTutorial', '1');
    
    }

    arrayEquals(array, array2) {
        // if the other array is a falsy value, return
        if (!array2) {
            return false;
        }

        // compare lengths - can save a lot of time 
        if (array.length != array2.length) {
            return false;
        }

        for (var i = 0, l=array.length; i < l; i++) {
            // Check if we have nested arrays
            if (array[i] instanceof Array && array2[i] instanceof Array) {
                // recurse into the nested arrays
                if (!this.arrayEquals(array[i], array2[i])) {
                    return false;      
                } 
            }           
            else if (array[i] != array2[i]) { 
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;   
            }           
        }       
        return true;
    }

    arrayContains(array, item) {

        let i;

        for (i = 0; i < array.length; i++) {

            if (array[i] === item) {

                return true;

            }

        }
    
        return false;
    }

    objectArrayContains(array, item) {

        if(typeof array === 'undefined') {
            return false;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            for (let key in array[i]) {

                if (array[i][key] === item) {
                    return true;
                }

            }

        }
    
        return false;
    }

    objectArrayContainsKey(array, key, item) {

        if(typeof array === 'undefined') {
            return false;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if(typeof array[i] === 'undefined') {
                return false;
            }

            if (array[i][key] === item) {
                return true;
            }

        }
    
        return false;
    }

    objectArrayContainsKey2(array, key, item, key2, item2) {

        if(typeof array === 'undefined') {
            return false;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if(typeof array[i] === 'undefined') {
                return false;
            }

            if (array[i][key] === item && array[i][key2] === item2) {
                return true;
            }

        }
    
        return false;
    }

    getArrayItem(array, key, item) {

        if(typeof array === 'undefined') {
            return null;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if(typeof array[i] === 'undefined') {
                return null;
            }

            if (array[i][key] === item) {
                return array[i];
            }

        }
    
        return null;
    }

    getArrayItem2(array, key, item, key2, item2) {

        if(typeof array === 'undefined') {
            return null;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if(typeof array[i] === 'undefined') {
                return null;
            }

            if (array[i][key] === item && array[i][key2] === item2) {
                return array[i];
            }

        }
    
        return null;
    }

    defaultAlert(parent, title, body) {

        parent.setState({
            alertTitle: title,
            alertBody: body,
            alertButtons: 1,
            alertButtonText: ['OK'],
            alertButtonAction: null,
            showAlert: true,
        });

    }

    removeFromArray(array, item) {

        let index = array.indexOf(item);

        if (index > -1) {

          array.splice(index, 1);

        }

    }

    removeFromArray2(array, key, item) {

        if(typeof array === 'undefined') {
            return;
        }

        let i;

        for (i = 0; i < array.length; i++) {

            if(typeof array[i] === 'undefined') {
                return;
            }

            if (array[i][key] === item) {

                const index = array.indexOf(array[i]);

                if (index > -1) {
                    array.splice(index, 1);
                }

            }

        }

    }

    sortNumber(a, b) {

        return a - b;
  
    }

    // async getDateNow() {
    //     const string = await GlobalAPIGetTime.Request();
    //     if (typeof string !== 'string') {
    //         return new Date();
    //     }
    //     const splitted = string.split('T');
    //     return this.stringToDateTime(splitted[0], splitted[1]);
    // }
    async getDateNow() {
        return new Date();
    }
    async getDateNow2() {
        const now = new Date();
        const splitted = now.split('T');
        return this.stringToDateTime(splitted[0], splitted[1].substr(0, 8));
    }
    getDateNowStatic() {
        return moment().utc(Data.data.utcOffset).toDate();
    }
    getDate(date, time) {
        return moment(`${date}T${time}`).toDate();
    }
    getDateTZ(date, time) {
        return moment(`${date}T${time}`).utc(Data.data.utcOffset).toDate();
    }
    getDateTZD(date) {
        return moment(`${date}`).utc(Data.data.utcOffset).toDate();
    }
    getDateTZMS(ms) {
        return moment(ms).utc(Data.data.utcOffset).toDate();
    }
    getMonthTZ(date) {
        return moment(`${date}`).utc(Data.data.utcOffset).month();
        //return moment(`${date}`).tz('Europe/Amsterdam').month();
    }
    getHoursTZ(date, time) {
        return moment(`${date}T${time}`).utc(Data.data.utcOffset).hours();
        //return moment(`${date}T${time}`).tz('Europe/Amsterdam').hours();
    }
    getMinutesTZ(date, time) {
        return moment(`${date}T${time}`).utc(Data.data.utcOffset).minutes();
        //return moment(`${date}T${time}`).tz('Europe/Amsterdam').minutes();
    }
    getSecondsTZ(date, time) {
        return moment(`${date}T${time}`).utc(Data.data.utcOffset).seconds();
        //return moment(`${date}T${time}`).tz('Europe/Amsterdam').seconds();
    }
    getHoursTZD(date) {
        return moment(`${date}`).utc(Data.data.utcOffset).hours();
        //return moment(`${date}T${time}`).tz('Europe/Amsterdam').hours();
    }
    getMinutesTZD(date) {
        return moment(`${date}`).utc(Data.data.utcOffset).minutes();
        //return moment(`${date}T${time}`).tz('Europe/Amsterdam').minutes();
    }
    getSpecificDate(dateString) {
        return new Date(Date.parse(dateString));
    }

    formatDate(date) {
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        let year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    stringToDate(dateString) {
        if (typeof dateString !== 'string') {
            dateString = this.dateToString(dateString);
        }
        const [yyyy, mm, dd] = dateString.split("-");
        return new Date(yyyy, parseInt(mm) - 1, dd);
    };

    stringToDateTime(dateString, timeString) {
        if (typeof dateString !== 'string') {
            dateString = this.dateToString(dateString);
        }
        if (typeof timeString !== 'string') {
            return new Date();
        }
        const [yyyy, mm, dd] = dateString.split("-");
        const [hh, MM, ss = 0] = timeString.split(":");
        return new Date(yyyy, parseInt(mm) - 1, dd, hh, MM, ss, 0);
    };

    dateToString(date) {
        if (typeof date === 'string') {
            date = this.stringToDate(date);
        }
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        let year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    dateToStringUTC(date) {
        if (typeof date === 'string') {
            date = this.stringToDate(date);
        }
        let month = '' + (date.getUTCMonth() + 1);
        let day = '' + date.getUTCDate();
        let year = date.getUTCFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    getAge(now, birthday) { // birthday is a date
        const ageDifMs = now.getTime() - birthday.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    getDayName(date, lower = false) {

        const dayNumber = new Date(date).getDay();
    
        const weekday = new Array(7);
        weekday[0] = 'Zondag';
        weekday[1] = 'Maandag';
        weekday[2] = 'Dinsdag';
        weekday[3] = 'Woensdag';
        weekday[4] = 'Donderdag';
        weekday[5] = 'Vrijdag';
        weekday[6] = 'Zaterdag';

        if (lower) {
            return weekday[dayNumber].toLowerCase();
        }
    
        return weekday[dayNumber];
    
    }
    
    getShortDayName(date, lower = false) {

        const dayNumber = new Date(date).getDay();

        const weekday = new Array(7);
        weekday[0] = 'Zo';
        weekday[1] = 'Ma';
        weekday[2] = 'Di';
        weekday[3] = 'Wo';
        weekday[4] = 'Do';
        weekday[5] = 'Vr';
        weekday[6] = 'Za';

        if (lower) {
            return weekday[dayNumber].toLowerCase();
        }

        return weekday[dayNumber];
    
    }
    
    getMonthName(date, lower = false) {

        const month = new Date(date).getMonth();

        const monthNames = ['januari', 'februari', 'maart',
            'april', 'mei', 'juni', 'juli',
            'augustus', 'september', 'oktober', 'november',
            'december'
        ];

        if (lower) {
            return monthNames[month].toLowerCase();
        }

        return monthNames[month];

    }

    getMonthNameM(month, lower = false) {

        const monthNames = ['januari', 'februari', 'maart',
            'april', 'mei', 'juni', 'juli',
            'augustus', 'september', 'oktober', 'november',
            'december'
        ];

        if (lower) {
            return monthNames[month].toLowerCase();
        }

        return monthNames[month];

    }

    getShortMonthNameM(month, lower = false) {

        const monthNames = ['jan', 'feb', 'mrt',
            'apr', 'mei', 'jun', 'jul.',
            'aug', 'sept', 'okt', 'nov',
            'dec'
        ];

        if (lower) {
            return monthNames[month].toLowerCase();
        }

        return monthNames[month];

    }
    
    getShortMonthName(date, lower = false) {

        const month = new Date(date).getMonth();

        const monthNames = ['jan', 'feb', 'mrt',
            'apr', 'mei', 'jun', 'jul',
            'aug', 'sept', 'okt', 'nov',
            'dec'
        ];

        if (lower) {
            return monthNames[month].toLowerCase();
        }

        return monthNames[month];

    }

    getDaysInMonth(month, year) {

        return new Date(year, month, 0).getDate();

    }

    getDaysInMonth2(date) {

        const date2 = new Date(date);
        date2.setDate(0);

        return date2.getDate();

    }

    convertDateToUTC(date) { 
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
    }

    // msToTime2(ms) {
    //     const milliseconds = ms % 1000
    //     const seconds = Math.floor((ms / 1000) % 60)
    //     const minutes = Math.floor((ms / (60 * 1000)) % 60)
    //     const hours = Math.floor((ms / (3600 * 1000)) % 3600)
    //     return `${hours < 10 ? '0' + hours.toString() : hours.toString()}:${minutes < 10 ? '0' + minutes.toString() : minutes.toString()}:${
    //       seconds < 10 ? '0' + seconds.toString() : seconds.toString()
    //     }.${milliseconds}`
    // }

    msToTime(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
          z = z || 2;
          return ('00' + n).slice(-z);
        }
      
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;
      
        return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
    }

    msToTime2(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
          z = z || 2;
          return ('00' + n).slice(-z);
        }
      
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;
      
        return (hrs < 10 ? pad(hrs) : hrs) + ':' + pad(mins);
    }

    msToTimeClock(s) {

        // Pad to 2 or 3 digits, default is 2
        function pad(n, z) {
          z = z || 2;
          return ('00' + n).slice(-z);
        }
      
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;
      
        return (hrs < 10 ? pad(hrs) : hrs) + ':' + pad(mins) + ':' + pad(secs);
    }

    timeStringToFloat(time, r = true) {
        if (time === null) {
            return NaN;
        }
        const hoursMinutes = time.split(/[.:]/);
        const hours = parseInt(hoursMinutes[0]);
        const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        const seconds = hoursMinutes[2] ? parseInt(hoursMinutes[2], 10) : 0;
        const hoursMins = hours + (minutes / 60) + (seconds / 60 / 60);
        return r ? Math.round((hoursMins + Number.EPSILON) * 100) / 100 : hoursMins;
    }

    timeStringToMS(time) {
        if (time === null) {
            return NaN;
        }
        const hoursMinutes = time.split(/[.:]/);
        const hours = parseInt(hoursMinutes[0]);
        const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
        const seconds = hoursMinutes[2] ? parseInt(hoursMinutes[2], 10) : 0;
        const hoursMins = hours + (minutes / 60) + (seconds / 60 / 60);
        return hoursMins * 1000 * 60 * 60;
    }

    firstUppercase(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    isTablet() {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        return isTablet;
    }

    round(num, fixed = false, digits = 2) {
        if (fixed === true) {
            return (Math.round((num + Number.EPSILON) * 100) / 100).toLocaleString('nl-NL', { minimumFractionDigits: digits, maximumFractionDigits: digits });
        } else {
            return Math.round((num + Number.EPSILON) * 100) / 100;
        }
    }

    digitsToLocale(num, digits = 2) {
        return num.toLocaleString('nl-NL', { minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    roundBig(value, decimals) {
        return Number(Math.round(value + Number.EPSILON+'e'+decimals)+'e-'+decimals);
    }

    generateString(length = 6) {
        var result           = '';
        var characters       = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    getContrast(hexcolor) {

        if (hexcolor === null) {
            return 'black';
        }

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
    
        // Convert to RGB value
        const r = parseInt(hexcolor.substr(0,2),16);
        const g = parseInt(hexcolor.substr(2,2),16);
        const b = parseInt(hexcolor.substr(4,2),16);
    
        // Get YIQ ratio
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
        // Check contrast
        return (yiq >= 140) ? 'black' : 'white';
    
    };

    getContrastPlan(hexcolor) {

        if (hexcolor === null) {
            return 'black';
        }

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
    
        // Convert to RGB value
        const r = parseInt(hexcolor.substr(0,2),16);
        const g = parseInt(hexcolor.substr(2,2),16);
        const b = parseInt(hexcolor.substr(4,2),16);
    
        // Get YIQ ratio
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
        // Check contrast
        return (yiq >= 50) ? '#2d3748' : 'white';
    
    };

    getContrastHex(hexcolor) {

        if (hexcolor === null) {
            return '000000';
        }

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
    
        // Convert to RGB value
        const r = parseInt(hexcolor.substr(0,2),16);
        const g = parseInt(hexcolor.substr(2,2),16);
        const b = parseInt(hexcolor.substr(4,2),16);
    
        // Get YIQ ratio
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
        // Check contrast
        return (yiq >= 140) ? '000000' : 'FFFFFF';
    
    };

    hexToRgbA(hex, opacity){
        let c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
        }
        throw new Error('Bad Hex');
    }

    convertToObject(array, key) {

        let obj = {};
    
        for (const i in array) {
    
          obj[array[i][key]] = array[i];
    
        }
    
        return obj;
    
      }

      convertToObjectPrefix(array, key, prefix) {

        let obj = {};
    
        for (const i in array) {
    
          obj[prefix + array[i][key]] = array[i];
    
        }
    
        return obj;
    
      }

      convertToObjectWithArrays(array, key) {

        let obj = {};
    
        for (const i in array) {

          if (typeof obj[array[i][key]] === 'undefined') {
              obj[array[i][key]] = [];
          }

          obj[array[i][key]].push(array[i]);
    
        }
    
        return obj;
    
      }

      convertToObjectWithArraysPrefix(array, key, prefix) {

        let obj = {};
    
        for (const i in array) {

          if (typeof obj[prefix + array[i][key]] === 'undefined') {
              obj[prefix + array[i][key]] = [];
          }

          obj[prefix + array[i][key]].push(array[i]);
    
        }
    
        return obj;
    
      }
    
      getTeamDataChosen() {
    
        for (const team of Data.data.teams) {
    
          if (parseInt(team.team_id) === Data.data.chosenTeam) {
            return {
              name: team.team_naam,
              phone: team.team_telefoon
            };
          }
    
        }
    
      }

    getTeamNameFromTeamID(teamID) {

        for (const team of Data.data.teams) {

          if (team.team_id === teamID) {
            return team.team_naam;
          }

        }

        return '';

      }

      getUserIsInTeam(teamUsers, userID, teamID) {

        let found = false;

        for (const user of teamUsers) {

            if (user.teamu_info_id === userID && teamID === user.teamu_team_id) {
                found = true;
                break;
            }

        }

        return found;

      }

      getTeamIDFromUserID(users, userID) { // needs rework to array (user can have more teams)

        let teamID = null;
    
        for (const teamUser of users) {
    
          if(teamUser.teamu_info_id === userID) {
    
            teamID = teamUser.teamu_team_id;
            break;
    
          }
    
        }

        return teamID;//

      }
    
      getTeamNameFromUserID(users, userID) {
    
        let teamID = [];
    
        for (const schedule of users) {
    
          if(schedule.teamu_info_id === userID) {
    
            teamID.push(schedule.teamu_team_id);
    
          }
    
        }
    
        let names = [];
    
        if (teamID !== null && teamID.length > 0) {
    
          for (const team of Data.data.teams) {
    
            for (const id of teamID) {
    
              if (team.team_id === id) {
                names.push(team.team_naam);
              }
    
            }
    
          }
    
        }
    
        if (names.length > 0) {
          return names;
        } else {
          return '';
        }
    
      }
    
      getTeamNameFromScheduleID(schedules, scheduleID) {
    
        let teamID = null;
    
        for (const schedule of schedules) {
    
          if(schedule.teamr_rooster_id === scheduleID) {
    
            teamID = schedule.teamr_team_id;
            break;
    
          }
    
        }
    
        if (teamID !== null) {
    
          for (const team of Data.data.teams) {
    
            if (team.team_id === teamID) {
              return team.team_naam;
            }
    
          }
    
        }
    
        return '';
    
      }
    
      getTeamIDFromScheduleID(schedules, scheduleID) {
    
        for (const schedule of schedules) {
    
          if(schedule.teamr_rooster_id === scheduleID) {
    
            return schedule.teamr_team_id;
    
          }
    
        }
    
        return null;
    
      }
    
      getTeamAccounts(teamUsers, allAccounts) {
    
        if(allAccounts === null) {
          return [];
        }
    
        let newAccounts = [];
        let teamAccounts = [];
    
        for (const teamAcc of teamUsers) {
            if (parseInt(teamAcc.teamu_team_id) === Data.data.chosenTeam){
                teamAccounts.push(teamAcc);
            }
        }
    
        for (const acc of allAccounts) {
    
            for (const teamAcc of teamAccounts) {
    
                if (acc.info_id === teamAcc.teamu_info_id) {
                    newAccounts.push(acc);
                }
    
            }
    
        }
    
        return newAccounts;
    
      }

      getTeamAccountsContract(teamUsers, allAccounts) {
    
        if(allAccounts === null) {
          return [];
        }
    
        let newAccounts = [];
        let teamAccounts = [];
    
        for (const teamAcc of teamUsers) {
            if (parseInt(teamAcc.teamu_team_id) === Data.data.chosenTeam){
                teamAccounts.push(teamAcc);
            }
        }
    
        for (const acc of allAccounts) {
    
            for (const teamAcc of teamAccounts) {
    
                if (acc.contract_info_id === teamAcc.teamu_info_id) {
                    newAccounts.push(acc);
                }
    
            }
    
        }
    
        return newAccounts;
    
      }
    
      getTeamAccountsT(teamUsers, allAccounts, team) {
    
        if(allAccounts === null) {
          return [];
        }
    
        let newAccounts = [];
        let teamAccounts = [];
    
        for (const teamAcc of teamUsers) {
            if (parseInt(teamAcc.teamu_team_id) === team){
                teamAccounts.push(teamAcc);
            }
        }
    
        for (const acc of allAccounts) {
    
            for (const teamAcc of teamAccounts) {
    
                if (acc.account_id === teamAcc.teamu_info_id) {
                    newAccounts.push(acc);
                }
    
            }
    
        }
    
        return newAccounts;
    
      }
    
      getTeamPlanner(teamPlanning, allPlannings) {
    
        if(allPlannings === null) {
          return [];
        }
    
        let newAccounts = [];
        let teamAccounts = [];
    
        for (const teamAcc of teamPlanning) {
            if (parseInt(teamAcc.teamr_team_id) === Data.data.chosenTeam){
                teamAccounts.push(teamAcc);
            }
        }
    
        for (const acc of allPlannings) {
    
            for (const teamAcc of teamAccounts) {
    
                if (acc.rooster_id === teamAcc.teamr_rooster_id) {
                    newAccounts.push(acc);
                }
    
            }
    
        }
    
        return newAccounts;
    
      }
    
      getTeamPlannerT(teamPlanning, allPlannings, team) {
    
        if(allPlannings === null) {
          return [];
        }
    
        let newAccounts = [];
        let teamAccounts = [];
    
        for (const teamAcc of teamPlanning) {
            if (parseInt(teamAcc.teamr_team_id) === team){
                teamAccounts.push(teamAcc);
            }
        }
    
        for (const acc of allPlannings) {
    
            for (const teamAcc of teamAccounts) {
    
                if (acc.rooster_id === teamAcc.teamr_rooster_id) {
                    newAccounts.push(acc);
                }
    
            }
    
        }
    
        return newAccounts;
    
      }

      getTeamPlannerT2(teamPlanning, allPlannings, team) {

        if(allPlannings === null) {
          return [];
        }

        let newAccounts = [];
        let teamAccounts = [];

        for (const teamAcc of teamPlanning) {
            if (parseInt(teamAcc.teamr_team_id) === team){
                teamAccounts.push(teamAcc);
            }
        }

        for (const acc of allPlannings) {

            for (const teamAcc of teamAccounts) {

                if (acc.rs_rooster_id_to === teamAcc.teamr_rooster_id) {
                    newAccounts.push(acc);
                }

            }

        }

        return newAccounts;

      }
    
      getMissingPeopleTeam(people, teamUsers) {
    
        let missing = [];
    
        for (const user of people) {
    
          let found = false;
    
          for (const teamUser of teamUsers) {
    
            if (user.info_id === teamUser.teamu_info_id) {
              found = true;
              break;
            }
    
          } 
    
          if (found === false) {
            missing.push(user);
          }
    
        }
    
        return missing;
    
      }

    charCount(string, word) {
        var length = typeof string === "string" && typeof word === "string" && word.length,
            loop = length,
            index = 0,
            count = 0;
    
        while (loop) {
            index = string.indexOf(word, index);
            if (index !== -1) {
                count += 1;
                index += length;
            } else {
                loop = false;
            }
        }
    
        return count;
    }

    stringDiffer(a, b)
    {
        var i = 0;
        var j = 0;
        var result = "";
        
        while (j < b.length)
        {
            if (a[i] != b[j] || i == a.length)
                result += b[j];
            else
                i++;
            j++;
        }
        return result.toString();
    }

    isTrue(data) {

        return data === true || data === '1';

    }

    isEmpty(data) {
        return typeof data === 'undefined' || data === undefined || data === null || data === '' || data === 'NULL' || data === 'null' || data === 'undefined' || data === '0000-00-00';
    }

    personWorksDay(data, day) {
        if (typeof data === 'undefined') {
            return false;
        }
        
        return day === 0 && data.info2_werkdag_zondag === '1'
            || day === 1 && data.info2_werkdag_maandag === '1'
            || day === 2 && data.info2_werkdag_dinsdag === '1'
            || day === 3 && data.info2_werkdag_woensdag === '1'
            || day === 4 && data.info2_werkdag_donderdag === '1'
            || day === 5 && data.info2_werkdag_vrijdag === '1'
            || day === 6 && data.info2_werkdag_zaterdag === '1';
    }

    personHasWorkDays(data) {
        if (typeof data === 'undefined') {
            return false;
        }
        else if (this.isEmpty(data.info2_werkdag_maandag) === true && this.isEmpty(data.info2_werkdag_dinsdag) === true && this.isEmpty(data.info2_werkdag_woensdag) === true && this.isEmpty(data.info2_werkdag_donderdag) === true && this.isEmpty(data.info2_werkdag_vrijdag) === true && this.isEmpty(data.info2_werkdag_zaterdag) === true && this.isEmpty(data.info2_werkdag_zondag) === true) {
            return false;
        }
        else if (data.info2_werkdag_maandag === '0' && data.info2_werkdag_dinsdag === '0' && data.info2_werkdag_woensdag === '0' && data.info2_werkdag_donderdag === '0' && data.info2_werkdag_vrijdag === '0' && data.info2_werkdag_zaterdag === '0' && data.info2_werkdag_zondag === '0') {
            return false;
        } else {
            return true;
        }
    }

    hasGeneralWorkDays() {
        const data = Data.data.appOptions;
        if (typeof data === 'undefined') {
            return false;
        }
        else if (data.opties_verlof_maandag === '0' && data.opties_verlof_dinsdag === '0' && data.opties_verlof_woensdag === '0' && data.opties_verlof_donderdag === '0' && data.opties_verlof_vrijdag === '0' && data.opties_verlof_zaterdag === '0' && data.opties_verlof_zondag === '0') {
            return false;
        } else {
            return true;
        }
    }

    getRoundedDate(minutes, d=this.getDateNowStatic()) {

        let ms = 1000 * 60 * minutes; // convert minutes to ms
        let roundedDate = new Date(Math.round(d.getTime() / ms) * ms);
      
        return roundedDate;

    }

    endFirstWeek(firstDate, firstDay) {
        if (! firstDay) {
            return 7 - firstDate.getDay();
        }
        if (firstDate.getDay() < firstDay) {
            return firstDay - firstDate.getDay();
        } else {
            return 7 - firstDate.getDay() + firstDay;
        }
      }

    getWeeksStartAndEndInMonth(month, year) {
        let weeks = [],
            firstDate = new Date(year, month, 1),
            lastDate = new Date(year, month + 1, 0),
            numDays = this.getDaysInMonth(month + 1, year);

        let start = 1;
        let end = this.endFirstWeek(firstDate, 2);
        while (start <= numDays) {
          if (start === 1 && end === 1) {
            start = end + 1;
            end = end + 7;
            end = start === 1 && end === 8 ? 1 : end;
            if (end > numDays) {
                end = numDays;
            }
            continue;
          }
          if (start - 1 !== end) {
            const week = this.getWeekNumber2(new Date(year, month, start));
            weeks.push({week: week, month: month, year: year, start: start === 1 ? start : start - 1, end: end === this.getDaysInMonth(month + 1, year) ? end : end - 1});
          }
          start = end + 1;
          end = end + 7;
          end = start === 1 && end === 8 ? 1 : end;
          if (end > numDays) {
              end = numDays;
          }
        }
        return weeks;
      } 

    getWeekNumber() {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        const week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                              - 3 + (week1.getDay() + 6) % 7) / 7);
      }
    
      getWeekNumber2(today) {
        let date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        const week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                              - 3 + (week1.getDay() + 6) % 7) / 7);
      }
    
      getWeekYear() {
        let date = new Date();
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        return date.getFullYear();
      }
    
      getWeekYear2(today) {
        let date = today;
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        return date.getFullYear();
      }
    
      weeksInYear(year) {
        let month = 11, day = 31, week = 0;
      
        // Find week that 31 Dec is in. If is first week, reduce date until
        // get previous week.
        do {
          const d = new Date(year, month, day--);
          week = this.alt(d)[1];
        } while (week === 1);
      
        return week;
      }
    
      alt(d) {
        // Copy date so don't modify original
        d = new Date(+d);
        d.setHours(0,0,0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay()||7));
        // Get first day of year
        var yearStart = new Date(d.getFullYear(),0,1);
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7)
        // Return array of year and week number
        return [d.getFullYear(), weekNo];
      }
    
    //   getMonday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').startOf('isoWeek').utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getTuesday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 1);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(2).utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getWednesday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 2);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(3).utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getThursday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 3);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(4).utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getFriday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 4);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(5).utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getSaturday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 5);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').day(6).utc(Data.data.utcOffset).toDate();
    //   }
    
    //   getSunday(w, y) {
    //     const simple = new Date(y, 0, 1 + (w - 1) * 7);
    //     const dow = simple.getDay();
    //     let ISOweekStart = simple;
    //     if (dow <= 4) {
    //         ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    //     } else {
    //         ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    //     }
    //     ISOweekStart.setDate(ISOweekStart.getDate() + 6);
    //     return ISOweekStart;
    //     //return moment(`${y}`).add(w, 'weeks').endOf('isoWeek').utc(Data.data.utcOffset).toDate();
    //   }

    getMonday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        return ISOweekStart;
      }

      getTuesday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 1);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 1);
        return ISOweekStart;
      }

      getWednesday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 2);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 2);
        return ISOweekStart;
      }

      getThursday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 3);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 3);
        return ISOweekStart;
      }

      getFriday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 4);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 4);
        return ISOweekStart;
      }

      getSaturday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 5);
        //ISOweekStart.setDate(ISOweekStart.getDate() + 5);
        return ISOweekStart;
      }

      getSunday(w, y) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        const dow = simple.getDay();
        let ISOweekStart = simple;
        if (dow <= 4) {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() - simple.getDay() + 1);
            //ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart = new Date(simple.getFullYear(), simple.getMonth(), simple.getDate() + 8 - simple.getDay());
            //ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        ISOweekStart = new Date(ISOweekStart.getFullYear(), ISOweekStart.getMonth(), ISOweekStart.getDate() + 6);
        return ISOweekStart;
      }

      getDateWeekday(day, w, y) {

        let weekDate = new Date();

        if (day === 1) {
            weekDate = this.getMonday(w, y);
        } else if (day === 2) {
            weekDate = this.getTuesday(w, y);
        } else if (day === 3) {
            weekDate = this.getWednesday(w, y);
        } else if (day === 4) {
            weekDate = this.getThursday(w, y);
        } else if (day === 5) {
            weekDate = this.getFriday(w, y);
        } else if (day === 6) {
            weekDate = this.getSaturday(w, y);
        } else if (day === 0) {
            weekDate = this.getSunday(w, y);
        }

        return weekDate;

    }

      dateInWeek(date, w, y) {
        const dateObj = this.stringToDate(date);

        const monday = this.getMonday(w, y);
        const tuesday = this.getTuesday(w, y);
        const wednesday = this.getWednesday(w, y);
        const thursday = this.getThursday(w, y);
        const friday = this.getFriday(w, y);
        const saturday = this.getSaturday(w, y);
        const sunday = this.getSunday(w, y);

        if ((dateObj.getDate() === monday.getDate() && dateObj.getMonth() === monday.getMonth() && dateObj.getFullYear() === monday.getFullYear())
          || (dateObj.getDate() === tuesday.getDate() && dateObj.getMonth() === tuesday.getMonth() && dateObj.getFullYear() === tuesday.getFullYear())
          || (dateObj.getDate() === wednesday.getDate() && dateObj.getMonth() === wednesday.getMonth() && dateObj.getFullYear() === wednesday.getFullYear())
          || (dateObj.getDate() === thursday.getDate() && dateObj.getMonth() === thursday.getMonth() && dateObj.getFullYear() === thursday.getFullYear())
          || (dateObj.getDate() === friday.getDate() && dateObj.getMonth() === friday.getMonth() && dateObj.getFullYear() === friday.getFullYear())
          || (dateObj.getDate() === saturday.getDate() && dateObj.getMonth() === saturday.getMonth() && dateObj.getFullYear() === saturday.getFullYear())
          || (dateObj.getDate() === sunday.getDate() && dateObj.getMonth() === sunday.getMonth() && dateObj.getFullYear() === sunday.getFullYear())) {
              return true;
      }

      return false;
    }

    toISOString(date) {

        if (date === null || date === undefined || typeof date === 'undefined') {
          return null;
        }

        return `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;

    }

    getDaysInYear(year) {
    
        return this.isLeapYear(year) ? 366 : 365;

    }

    isLeapYear(year) {
        return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
    }

    isValidDateString(dateString) {

        return typeof dateString !== 'undefined' && dateString !== null && dateString !== 'null' && dateString !== 'NULL' && dateString !== '' && dateString !== '0000-00-00';

    }

    chunkArray(arr, size, out) {
        out = out || [];
        if (!arr.length) return out;
        out.push(arr.slice(0, size));
        return this.chunkArray(arr.slice(size), size, out);
    }

    checkDuplicatesArray(arr, key, remove){
    
        for(let i=0;i<arr.length-1;i++){
        
            for(let j=i+1;j<arr.length;j++){
            
                if(arr[i][key]==arr[j][key]){

                    if (remove === true) {
                        this.removeFromArray(arr, arr[i]);
                    }   

                    return true;
                
                }
            
            }
        
        }
    
        return false;
    
    }

    sortArray(a, b, key, DESC) {
        return (a[key] > b[key]) ? DESC ? -1 : 1 : DESC ? 1 : -1;
    }

    sortArray2(a, b, key, key2, DESC) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && (a[key2] > b[key2])) ? DESC ? -1 : 1 : DESC ? 1 : -1;
    }

    sortArray3(a, b, key, key2, key3, DESC) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] > b[key3])) ? DESC ? -1 : 1 : DESC ? 1 : -1;
    }

    sortArray3nameASC(a, b, key, key2, key3) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] > b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] > b[key3])) ? 1 : -1;
    }

    sortArray3nameDESC(a, b, key, key2, key3) {
        return (a[key] > b[key]) || ((a[key] === b[key]) && a[key2] < b[key2]) || ((a[key] === b[key]) && (a[key2] === b[key2]) && (a[key3] < b[key3])) ? -1 : 1;
    }

    calcPercentInt(first, second) {

        return this.round((first / second) * 100) === Infinity ? 0 : this.round((first / second) * 100);

    }

    addSlashes(string) {
        return string.replace(/\\/g, '\\\\').
            replace(/\u0008/g, '\\b').
            replace(/\t/g, '\\t').
            replace(/\n/g, '\\n').
            replace(/\f/g, '\\f').
            replace(/\r/g, '\\r').
            replace(/'/g, '\\\'').
            replace(/"/g, '\\"');
    }

    async urltoFile(url, filename, mimeType) {
        return (await fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    }

    getUserPlannerBreak(id, date, schedules) {

        let breakTime = '00:00';

        for(const schedule of schedules) {

          if(schedule.info_id === id && schedule.rooster_datum === date) {

            if(breakTime !== '00:00') {

              const oldBreakHours = this.getHoursTZ(schedule.rooster_datum, breakTime);
              const oldBreakMinutes = this.getMinutesTZ(schedule.rooster_datum, breakTime);
              const breakHours = this.getHoursTZ(schedule.rooster_datum, schedule.rooster_pauze);
              const breakMinutes = this.getMinutesTZ(schedule.rooster_datum, schedule.rooster_pauze);

              const totalBreakHours = oldBreakHours + breakHours;
              const totalBreakMinutes = (oldBreakMinutes + breakMinutes);

              const newBreakDateAndTime = this.getDateTZ(schedule.rooster_datum, breakTime);
              newBreakDateAndTime.setTime((totalBreakHours *60*60*1000) + (totalBreakMinutes *60*1000));

              const newBreakHours = newBreakDateAndTime.getUTCHours();
              const newBreakMinutes = newBreakDateAndTime.getUTCMinutes();

              breakTime = `${newBreakHours < 10 ? '0' + newBreakHours : newBreakHours}:${newBreakMinutes < 10 ? '0' + newBreakMinutes : newBreakMinutes}`;

            } else {

              breakTime = schedule.rooster_pauze.substring(0, 5);

            }

          }

        }

        return breakTime;

      }

    getBreakRule(startDate, startTime, endDate, endTime, schedule = null, breakRules) {

        const start = this.stringToDateTime(startDate, startTime);
        const end = this.stringToDateTime(schedule !== null ? planClass.isNightShift2(schedule) : endDate, endTime);

        const totalMS = end.getTime() - start.getTime();
        const totalHours = totalMS / 1000 / 60 / 60;

        let brk = 0;
        for (const br of breakRules) {

            if (totalHours >= parseFloat(br.pr_uren)) {
            brk = parseInt(br.pr_pauze);
            }

        }

        if (brk > 0) {
            brk = brk * 60 * 1000;
        }

        return {
            ms: brk,
            str: this.msToTime(brk),
        };

    }

    weeksBetween(d1, d2) {
        return Math.floor(Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000)));
    }

    getCurrentContract(contracts, dateNow = new Date()) {

        let contract = null;

        if (this.isEmpty(contracts)) {
            return null;
        }

        if (this.isEmpty(dateNow)) {
            dateNow = new Date();
        }

        if (typeof dateNow === 'string') {
            dateNow = this.stringToDate(dateNow);
        }

        for (const c of contracts) {

            if (this.isEmpty(c.contract_begin) === true && this.isEmpty(c.contract_eind) === true) {

                contract = c;
                break;

            } else if (this.isEmpty(c.contract_begin) === false && this.isEmpty(c.contract_eind) === true) {

                const contractStart = this.stringToDate(c.contract_begin);

                if (contractStart.getTime() <= dateNow.getTime()) {
                    contract = c;
                    break;
                }

            } else if (this.isEmpty(c.contract_begin) === true && this.isEmpty(c.contract_eind) === false) {

                const contractEnd = this.stringToDate(c.contract_eind);

                if (contractEnd.getTime() >= dateNow.getTime()) {
                    contract = c;
                    break;
                }

            } else if (this.isEmpty(c.contract_begin) === false && this.isEmpty(c.contract_eind) === false) {

                const contractStart = this.stringToDate(c.contract_begin);
                const contractEnd = this.stringToDate(c.contract_eind);

                if (contractStart.getTime() <= dateNow.getTime() && contractEnd.getTime() >= dateNow.getTime()) {
                    contract = c;
                    break;
                }

            }

        }

        return contract;

    }

    getAnyContract_type(contracts, type) {

        if (type === '0') {

            if (this.isEmpty(contracts)) {
                return true;
            }

        } else {

            if (this.isEmpty(contracts)) {
                return false;
            }

        }

        for (const c of contracts) {

            if (type === '0') {

                if (c.contract_type === type || this.isEmpty(c.contract_type)) {
                    return true;
                }
                 
            } else {

                if (c.contract_type === type) {
                    return true;
                }

            }

        }

        return false;

    }

    getAnyContract_leaveBalance(contracts) {

        if (this.isEmpty(contracts)) {
            return false;
        }

        for (const c of contracts) {

            if (c.contract_opbouw_verlof === '1') {
                return true;
            }

        }

        return false;

    }

    // getContractPeriod(contracts, start, end) {

    //     let contract = null;

    //     if (typeof start === 'string') {
    //         start = this.stringToDate(start);
    //     }
    //     if (typeof end === 'string') {
    //         end = this.stringToDate(end);
    //     }

    //     for (const c of contracts) {

    //         if (this.isEmpty(c.contract_begin) === true && this.isEmpty(c.contract_eind) === true) {

    //             contract = c;
    //             break;

    //         } else if (this.isEmpty(c.contract_begin) === false && this.isEmpty(c.contract_eind) === true) {

    //             const contractStart = this.stringToDate(c.contract_begin);

    //             if (contractStart.getTime() <= date.getTime()) {
    //                 contract = c;
    //                 break;
    //             }

    //         } else if (this.isEmpty(c.contract_begin) === true && this.isEmpty(c.contract_eind) === false) {

    //             const contractEnd = this.stringToDate(c.contract_eind);

    //             if (contractEnd.getTime() >= date.getTime()) {
    //                 contract = c;
    //                 break;
    //             }

    //         } else if (this.isEmpty(c.contract_begin) === false && this.isEmpty(c.contract_eind) === false) {

    //             const contractStart = this.stringToDate(c.contract_begin);
    //             const contractEnd = this.stringToDate(c.contract_eind);

    //             if (contractStart.getTime() <= date.getTime() && contractEnd.getTime() >= date.getTime()) {
    //                 contract = c;
    //                 break;
    //             }

    //         }

    //     }

    //     return contract;

    // }

    async getLeaveBalance(contracts, dateNow, userID, leaveBalances, leaveTypeBalances, contractLeaveBalances, absAmount, leaveCorrectionsData, correctionIndividual = false) {

        let totalLeaveBalances = {};
        totalLeaveBalances[new Date().getFullYear().toString()] = [];

        //iterate

        if (this.isEmpty(contracts)) {
            return null;
        }

        for (const contract of contracts) {

            if (contract.contract_type === '0') { // flex

                const currentYear = dateNow.getFullYear();

                const contractStart = this.isEmpty(contract.contract_begin) === false ? this.stringToDate(contract.contract_begin) : this.stringToDate(`${currentYear}-01-01`);
                const contractEnd = this.isEmpty(contract.contract_eind) === false ? this.stringToDate(contract.contract_eind) : this.stringToDate(`${currentYear}-12-31`);

                // calc hours
                let weekStart = 0;
                let weekEnd = 0;

                let weeks = 0;
                let hours = 0;
                let totalHoursYear = 0;

                if (contractStart.getFullYear() === contractEnd.getFullYear()) {

                    const yearKey = contractStart.getFullYear().toString();

                    if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                        totalLeaveBalances[yearKey] = [];
                    }

                    if (this.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) { // DAADWERKELIJK

                        let where = ``;
                        let rightJoin = ``;
                        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {

                            if (Data.data.appOptions.hours === 'CLOCK') {
                                rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
                                where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                            } else {
                                rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
                                where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                            }

                        }

                        const allHoursObjects = await APIGET.Request(
                            `SELECT *`,
                            `FROM Uren`,
                            (`LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                            (`WHERE uren_begindatum BETWEEN '${this.dateToString(contractStart)}' AND '${this.dateToString(contractEnd)}' AND uit_id IS NULL AND uren_goedgekeurd = 1 AND uren_info_id = ${userID}`) + where,
                            null,
                            null,
                        );

                        for (const data of allHoursObjects) {

                            const start = this.stringToDateTime(data.uren_begindatum, data.uren_begin);
                            const end = this.stringToDateTime(data.uren_einddatum, data.uren_eind);
                            const brk = this.timeStringToMS(data.uren_pauze) / 1000;

                            const net = ((end.getTime() - start.getTime()) / 1000);

                            totalHoursYear += (net - brk) / 60 / 60;
  
                        }

                    } else { // PLANNING

                        let where = ``;
                        let rightJoin = ``;
                        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                            rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
                            where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
                        }

                        const allHoursObjects = await APIGET.Request(
                            `SELECT *`,
                            `FROM Rooster`,
                            (`LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                            (`WHERE rooster_datum BETWEEN '${this.dateToString(contractStart)}' AND '${this.dateToString(contractEnd)}' AND uit_id IS NULL AND rooster_publiceren = 1 AND rooster_info_id = ${userID}`) + where,
                            null,
                            null,
                        );

                        for (const data of allHoursObjects) {

                            const start = this.stringToDateTime(data.rooster_datum, data.rooster_begin);
                            const end = this.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
                            const brk = this.timeStringToMS(data.rooster_pauze) / 1000;

                            const net = ((end.getTime() - start.getTime()) / 1000);

                            totalHoursYear += (net - brk) / 60 / 60;
  
                        }

                    }

                    let balances = [];
        
                    for (const balance of leaveBalances) {
        
                        let b = {
                            substract: 0,
                            balance: 0,
                            total: 0,
                            correction: 0,
                            ...balance
                        };
        
                        const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);
        
                        if (factor === null) {
                            b.balance = 0;
                        } else {
                            b.balance = (totalHoursYear * parseFloat(factor.cvb_vb_factor));
                        }
        
                        balances.push(b);
        
                    }

                    // return total balances
                    if (totalLeaveBalances[yearKey].length > 0) {

                        for (const blnc of balances) {

                            let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                            if (userBalance !== null) {

                                userBalance.substract += blnc.substract;
                                userBalance.balance += blnc.balance;
                                userBalance.total += blnc.total;

                            }

                        }

                    } else {

                        totalLeaveBalances[yearKey] = balances;

                    }

                } else {

                    for (let i = contractStart.getFullYear(); i <= contractEnd.getFullYear(); i++) {

                        totalHoursYear = 0;

                        const yearKey = i.toString();

                        if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                            totalLeaveBalances[yearKey] = [];
                        }

                        let contractHoursStart = contractStart;
                        let contractHoursEnd = contractEnd;

                        if (i === contractStart.getFullYear()) {

                            contractHoursStart = contractStart;
                            contractHoursEnd = new Date(i, 11, 31);

                        } else if (i === contractEnd.getFullYear()) {

                            contractHoursStart = new Date(i, 0, 1);
                            contractHoursEnd = contractEnd;

                        } else { // there is a whole year between start and end date contract

                            contractHoursStart = new Date(i, 0, 1);
                            contractHoursEnd = new Date(i, 11, 31);

                        }

                        if (this.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) { // DAADWERKELIJK

                            let where = ``;
                            let rightJoin = ``;
                            if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {

                                if (Data.data.appOptions.hours === 'CLOCK') {
                                    rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
                                    where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                                } else {
                                    rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
                                    where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                                }

                            }

                            const allHoursObjects = await APIGET.Request(
                                `SELECT *`,
                                `FROM Uren`,
                                (`LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                                (`WHERE uren_begindatum BETWEEN '${this.dateToString(contractHoursStart)}' AND '${this.dateToString(contractHoursEnd)}' AND uit_id IS NULL AND uren_goedgekeurd = 1 AND uren_info_id = ${userID}`) + where,
                                null,
                                null,
                            );

                            for (const data of allHoursObjects) {

                                const start = this.stringToDateTime(data.uren_begindatum, data.uren_begin);
                                const end = this.stringToDateTime(data.uren_einddatum, data.uren_eind);
                                const brk = this.timeStringToMS(data.uren_pauze) / 1000;

                                const net = ((end.getTime() - start.getTime()) / 1000);

                                totalHoursYear += (net - brk) / 60 / 60;
  
                            }

                        } else { // PLANNING

                            let where = ``;
                            let rightJoin = ``;
                            if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                                rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
                                where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
                            }

                            const allHoursObjects = await APIGET.Request(
                                `SELECT *`,
                                `FROM Rooster`,
                                (`LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                                (`WHERE rooster_datum BETWEEN '${this.dateToString(contractHoursStart)}' AND '${this.dateToString(contractHoursEnd)}' AND uit_id IS NULL AND rooster_publiceren = 1 AND rooster_info_id = ${userID}`) + where,
                                null,
                                null,
                            );

                            for (const data of allHoursObjects) {

                                const start = this.stringToDateTime(data.rooster_datum, data.rooster_begin);
                                const end = this.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
                                const brk = this.timeStringToMS(data.rooster_pauze) / 1000;

                                const net = ((end.getTime() - start.getTime()) / 1000);

                                totalHoursYear += (net - brk) / 60 / 60;
  
                            }

                        }

                        let balances = [];
        
                        for (const balance of leaveBalances) {
        
                            let b = {
                                substract: 0,
                                balance: 0,
                                total: 0,
                                correction: 0,
                                ...balance
                            };
        
                            const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);
        
                            if (factor === null) {
                                b.balance = 0;
                            } else {
                                b.balance = (totalHoursYear * parseFloat(factor.cvb_vb_factor));
                            }
        
                            balances.push(b);
        
                        }

                        // return total balances
                        if (totalLeaveBalances[yearKey].length > 0) {

                            for (const blnc of balances) {

                                let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                                if (userBalance !== null) {

                                    userBalance.substract += blnc.substract;
                                    userBalance.balance += blnc.balance;
                                    userBalance.total += blnc.total;

                                }

                            }

                        } else {

                            totalLeaveBalances[yearKey] = balances;

                        }

                    }

                }
        
                // // finish vacationdays total balance
                // for (const balance of balances) {
                //     vacationSaldo += balance.total;
                // }

            } else if (contract.contract_type === '1') { // min-max

                const currentYear = dateNow.getFullYear();

                const contractStart = this.isEmpty(contract.contract_begin) === false ? this.stringToDate(contract.contract_begin) : this.stringToDate(`${currentYear}-01-01`);
                const contractEnd = this.isEmpty(contract.contract_eind) === false ? this.stringToDate(contract.contract_eind) : this.stringToDate(`${currentYear}-12-31`);

                // calc hours
                let weekStart = 0;
                let weekEnd = 0;

                let weeks = 0;
                let hours = 0;
                let hoursContract = 0;
                let totalHoursYear = 0;
                let totalHoursYearWorked = 0;
                let totalHoursYearContract = 0;

                if (contractStart.getFullYear() === contractEnd.getFullYear()) {

                    const yearKey = contractStart.getFullYear().toString();

                    if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                        totalLeaveBalances[yearKey] = [];
                    }

                    if (this.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) { // DAADWERKELIJK

                        let where = ``;
                        let rightJoin = ``;
                        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {

                            if (Data.data.appOptions.hours === 'CLOCK') {
                                rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
                                where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                            } else {
                                rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
                                where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                            }

                        }

                        const allHoursObjects = await APIGET.Request(
                            `SELECT *`,
                            `FROM Uren`,
                            (`LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                            (`WHERE uren_begindatum BETWEEN '${this.dateToString(contractStart)}' AND '${this.dateToString(contractEnd)}' AND uit_id IS NULL AND uren_goedgekeurd = 1 AND uren_info_id = ${userID}`) + where,
                            null,
                            null,
                        );

                        for (const data of allHoursObjects) {

                            const start = this.stringToDateTime(data.uren_begindatum, data.uren_begin);
                            const end = this.stringToDateTime(data.uren_einddatum, data.uren_eind);
                            const brk = this.timeStringToMS(data.uren_pauze) / 1000;

                            const net = ((end.getTime() - start.getTime()) / 1000);

                            totalHoursYearWorked += (net - brk) / 60 / 60;

                        }

                    } else { // PLANNING

                        let where = ``;
                        let rightJoin = ``;
                        if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                            rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
                            where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
                        }

                        const allHoursObjects = await APIGET.Request(
                            `SELECT *`,
                            `FROM Rooster`,
                            (`LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                            (`WHERE rooster_datum BETWEEN '${this.dateToString(contractStart)}' AND '${this.dateToString(contractEnd)}' AND uit_id IS NULL AND rooster_publiceren = 1 AND rooster_info_id = ${userID}`) + where,
                            null,
                            null,
                        );

                        for (const data of allHoursObjects) {

                            const start = this.stringToDateTime(data.rooster_datum, data.rooster_begin);
                            const end = this.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
                            const brk = this.timeStringToMS(data.rooster_pauze) / 1000;

                            const net = ((end.getTime() - start.getTime()) / 1000);

                            totalHoursYearWorked += (net - brk) / 60 / 60;

                        }

                    }

                    // weekStart = this.getWeekNumber2(contractStart) >= 52 ? 0 : this.getWeekNumber2(contractStart);
                    // weekEnd = this.getWeekNumber2(contractEnd) <= 1 ? 52 : this.getWeekNumber2(contractEnd);

                    // weeks = weekEnd - weekStart;
                    weeks = this.weeksBetween(contractStart, contractEnd);
                    hoursContract = parseFloat(contract.contract_uren);
                    totalHoursYearContract = hoursContract * weeks;

                    if (totalHoursYearWorked < totalHoursYearContract) {
                        totalHoursYear = totalHoursYearContract;
                    } else {
                        totalHoursYear = totalHoursYearWorked;
                    }

                    let balances = [];
    
                    for (const balance of leaveBalances) {
    
                        let b = {
                            substract: 0,
                            balance: 0,
                            total: 0,
                            correction: 0,
                            ...balance
                        };
    
                        const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);
    
                        if (factor === null) {
                            b.balance = 0;
                        } else {
                            b.balance = (totalHoursYear * parseFloat(factor.cvb_vb_factor));
                        }
    
                        balances.push(b);
    
                    }

                    // return total balances
                    if (totalLeaveBalances[yearKey].length > 0) {

                        for (const blnc of balances) {

                            let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                            if (userBalance !== null) {

                                userBalance.substract += blnc.substract;
                                userBalance.balance += blnc.balance;
                                userBalance.total += blnc.total;

                            }

                        }

                    } else {

                        totalLeaveBalances[yearKey] = balances;

                    }

                } else {

                    for (let i = contractStart.getFullYear(); i <= contractEnd.getFullYear(); i++) {

                        totalHoursYear = 0;
                        totalHoursYearWorked = 0;
                        totalHoursYearContract = 0;

                        const yearKey = i.toString();

                        if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                            totalLeaveBalances[yearKey] = [];
                        }

                        let contractHoursStart = contractStart;
                        let contractHoursEnd = contractEnd;

                        if (i === contractStart.getFullYear()) {

                            contractHoursStart = contractStart;
                            contractHoursEnd = new Date(i, 11, 31);

                        } else if (i === contractEnd.getFullYear()) {

                            contractHoursStart = new Date(i, 0, 1);
                            contractHoursEnd = contractEnd;

                        } else { // there is a whole year between start and end date contract

                            contractHoursStart = new Date(i, 0, 1);
                            contractHoursEnd = new Date(i, 11, 31);

                        }

                        if (this.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) === true) { // DAADWERKELIJK

                            let where = ``;
                            let rightJoin = ``;
                            if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {

                                if (Data.data.appOptions.hours === 'CLOCK') {
                                    rightJoin += ` LEFT JOIN TeamKloktijd ON teamk_kloktijd_id = uren_kloktijd_id`;
                                    where += ` AND (teamk_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                                } else {
                                    rightJoin += ` LEFT JOIN TeamRooster ON teamr_rooster_id = uren_rooster_id`;
                                    where += ` AND (teamr_team_id = ${Data.data.chosenTeamObj.team_id} OR uren_team_id = ${Data.data.chosenTeamObj.team_id})`;
                                }

                            }

                            const allHoursObjects = await APIGET.Request(
                                `SELECT *`,
                                `FROM Uren`,
                                (`LEFT JOIN AfwRooster ON afwr_rooster_id = uren_rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                                (`WHERE uren_begindatum BETWEEN '${this.dateToString(contractHoursStart)}' AND '${this.dateToString(contractHoursEnd)}' AND uit_id IS NULL AND uren_goedgekeurd = 1 AND uren_info_id = ${userID}`) + where,
                                null,
                                null,
                            );

                            for (const data of allHoursObjects) {

                                const start = this.stringToDateTime(data.uren_begindatum, data.uren_begin);
                                const end = this.stringToDateTime(data.uren_einddatum, data.uren_eind);
                                const brk = this.timeStringToMS(data.uren_pauze) / 1000;

                                const net = ((end.getTime() - start.getTime()) / 1000);

                                totalHoursYearWorked += (net - brk) / 60 / 60;

                            }

                        } else { // PLANNING

                            let where = ``;
                            let rightJoin = ``;
                            if (Data.data.teams.length > 0 && Data.data.chosenTeam !== null) {
                                rightJoin = ` LEFT JOIN TeamRooster ON teamr_rooster_id = rooster_id`;
                                where += ` AND teamr_team_id = ${Data.data.chosenTeamObj.team_id}`;
                            }

                            const allHoursObjects = await APIGET.Request(
                                `SELECT *`,
                                `FROM Rooster`,
                                (`LEFT JOIN AfwRooster ON afwr_rooster_id = rooster_id LEFT JOIN Uitzondering ON uit_afw_id = afwr_afw_id`) + rightJoin,
                                (`WHERE rooster_datum BETWEEN '${this.dateToString(contractHoursStart)}' AND '${this.dateToString(contractHoursEnd)}' AND uit_id IS NULL AND rooster_publiceren = 1 AND rooster_info_id = ${userID}`) + where,
                                null,
                                null,
                            );

                            for (const data of allHoursObjects) {

                                const start = this.stringToDateTime(data.rooster_datum, data.rooster_begin);
                                const end = this.stringToDateTime(planClass.isNightShift2(data), data.rooster_eind);
                                const brk = this.timeStringToMS(data.rooster_pauze) / 1000;

                                const net = ((end.getTime() - start.getTime()) / 1000);

                                totalHoursYearWorked += (net - brk) / 60 / 60;

                            }

                        }

                        if (i === contractStart.getFullYear()) {

                            weekStart = (this.getWeekNumber2(contractStart) - 1);
                            weekEnd = 52;

                        } else if (i === contractEnd.getFullYear()) {

                            weekStart = 0;
                            weekEnd = this.getWeekNumber2(contractEnd);

                        } else { // there is a whole year between start and end date contract

                            weekStart = 0;
                            weekEnd = 52;

                        }

                        weeks = weekEnd - weekStart;
                        hoursContract = parseFloat(contract.contract_uren);
                        totalHoursYearContract = hoursContract * weeks;

                        if (totalHoursYearWorked < totalHoursYearContract) {
                            totalHoursYear = totalHoursYearContract;
                        } else {
                            totalHoursYear = totalHoursYearWorked;
                        }

                        let balances = [];
    
                        for (const balance of leaveBalances) {
    
                            let b = {
                                substract: 0,
                                balance: 0,
                                total: 0,
                                correction: 0,
                                ...balance
                            };
    
                            const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);
    
                            if (factor === null) {
                                b.balance = 0;
                            } else {
                                b.balance = (totalHoursYear * parseFloat(factor.cvb_vb_factor));
                            }
    
                            balances.push(b);
    
                        }

                        // return total balances
                        if (totalLeaveBalances[yearKey].length > 0) {

                            for (const blnc of balances) {

                                let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                                if (userBalance !== null) {

                                    userBalance.substract += blnc.substract;
                                    userBalance.balance += blnc.balance;
                                    userBalance.total += blnc.total;

                                }

                            }

                        } else {

                            totalLeaveBalances[yearKey] = balances;

                        }

                    }

                }
    
                // // finish vacationdays total balance
                // for (const balance of balances) {
                //     vacationSaldo += balance.total;
                // }

            } else if (contract.contract_type === '2') { // vast

                const currentYear = dateNow.getFullYear();

                const contractStart = this.isEmpty(contract.contract_begin) === false ? this.stringToDate(contract.contract_begin) : this.stringToDate(`${currentYear}-01-01`);
                const contractEnd = this.isEmpty(contract.contract_eind) === false ? this.stringToDate(contract.contract_eind) : this.stringToDate(`${currentYear}-12-31`);

                // calc hours
                let weekStart = 0;
                let weekEnd = 0;

                let weeks = 0;
                let hours = 0;
                let totalHoursYear = 0;

                if (contractStart.getFullYear() === contractEnd.getFullYear()) {

                    const yearKey = contractStart.getFullYear().toString();

                    if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                        totalLeaveBalances[yearKey] = [];
                    }

                    // weekStart = this.getWeekNumber2(contractStart) >= 52 ? 1 : this.getWeekNumber2(contractStart);
                    // weekEnd = this.getWeekNumber2(contractEnd) <= 1 ? 53 : this.getWeekNumber2(contractEnd);

                    // weeks = weekEnd - weekStart;
                    weeks = this.weeksBetween(contractStart, contractEnd);
                    hours = parseFloat(contract.contract_uren);
                    totalHoursYear = hours * weeks;

                    let balances = [];
        
                    for (const balance of leaveBalances) {
        
                        let b = {
                            substract: 0,
                            balance: 0,
                            total: 0,
                            correction: 0,
                            ...balance
                        };
        
                        const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);
        
                        if (factor === null) {
                            b.balance = 0;
                        } else {
                            b.balance = (totalHoursYear * parseFloat(factor.cvb_vb_factor));
                        }
        
                        balances.push(b);
        
                    }

                    // return total balances
                    if (totalLeaveBalances[yearKey].length > 0) {

                        for (const blnc of balances) {

                            let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                            if (userBalance !== null) {

                                userBalance.substract += blnc.substract;
                                userBalance.balance += blnc.balance;
                                userBalance.total += blnc.total;

                            }

                        }

                    } else {

                        totalLeaveBalances[yearKey] = balances;

                    }

                } else {

                    for (let i = contractStart.getFullYear(); i <= contractEnd.getFullYear(); i++) {

                        const yearKey = i.toString();

                        if (typeof totalLeaveBalances[yearKey] === 'undefined') {
                            totalLeaveBalances[yearKey] = [];
                        }

                        if (i === contractStart.getFullYear()) {

                            weekStart = (this.getWeekNumber2(contractStart) - 1);
                            weekEnd = 52;

                        } else if (i === contractEnd.getFullYear()) {

                            weekStart = 0;
                            weekEnd = this.getWeekNumber2(contractEnd);

                        } else { // there is a whole year between start and end date contract

                            weekStart = 0;
                            weekEnd = 52;

                        }

                        weeks = weekEnd - weekStart;
                        hours = parseFloat(contract.contract_uren);
                        totalHoursYear = hours * weeks;

                        let balances = [];
        
                        for (const balance of leaveBalances) {
        
                            let b = {
                                substract: 0,
                                balance: 0,
                                total: 0,
                                correction: 0,
                                ...balance
                            };
        
                            const factor = this.getArrayItem(contractLeaveBalances[contract.contract_id], 'cvb_vb_id', balance.vb_id);
        
                            if (factor === null) {
                                b.balance = 0;
                            } else {
                                b.balance = (totalHoursYear * parseFloat(factor.cvb_vb_factor));
                            }
        
                            balances.push(b);
        
                        }

                        // return total balances
                        if (totalLeaveBalances[yearKey].length > 0) {

                            for (const blnc of balances) {

                                let userBalance = this.getArrayItem(totalLeaveBalances[yearKey], 'vb_id', blnc.vb_id);

                                if (userBalance !== null) {

                                    userBalance.substract += blnc.substract;
                                    userBalance.balance += blnc.balance;
                                    userBalance.total += blnc.total;

                                }

                            }

                        } else {

                            totalLeaveBalances[yearKey] = balances;

                        }

                    }

                }
        
                // // finish vacationdays total balance
                // for (const balance of balances) {
                //     vacationSaldo += balance.total;
                // }

            }

        }

        let absAmountsUsed = [];
        let correctionsUsed = [];
        let startingTotalYear = {};
        let substractYear = {};
        let correctionYear = {};
        for (const yearKey in totalLeaveBalances) {

            for (let balance of totalLeaveBalances[yearKey]) {

                const typeBalances = leaveTypeBalances[balance.vb_id];
            
                // get substraction // VerlofSaldo
                if (typeof typeBalances !== 'undefined') {

                    if (typeof absAmount[userID] !== 'undefined') {
            
                        for (const abs of absAmount[userID]) {

                            if (this.stringToDate(abs.vakantie_begin).getFullYear() === parseInt(yearKey)) {
                    
                                // this type of VerlofSaldo can substract from given VerlofBalans?
                                if (this.objectArrayContainsKey(typeBalances, 'vtb_vt_id', abs.vs_vt_id) === true && this.arrayContains(absAmountsUsed, abs.vs_id) === false) {
                                    if (this.getCurrentContract(contracts, this.stringToDate(abs.vakantie_begin)) !== null || this.getCurrentContract(contracts, this.stringToDate(abs.vakantie_eind)) !== null) {
                                        balance.substract += parseFloat(abs.vs_aantal);
                                        absAmountsUsed.push(abs.vs_id);
                                    }
                                }

                            }
                    
                        }

                    }

                    if (balance.substract !== 0) {

                        if (typeof startingTotalYear[balance.vb_id] === 'undefined') {
                            startingTotalYear[balance.vb_id] = 0;
                        }
                        if (typeof substractYear[balance.vb_id] === 'undefined') {
                            substractYear[balance.vb_id] = 0;
                        }

                        balance.total = (balance.balance - balance.substract);
                        balance.total2 = (balance.balance - balance.substract) + startingTotalYear[balance.vb_id];
                        startingTotalYear[balance.vb_id] = balance.total2;
                        balance.substract2 = balance.substract + substractYear[balance.vb_id];
                        substractYear[balance.vb_id] = balance.substract2;

                    } else {

                        if (typeof startingTotalYear[balance.vb_id] === 'undefined') {
                            startingTotalYear[balance.vb_id] = 0;
                        }
                        if (typeof substractYear[balance.vb_id] === 'undefined') {
                            substractYear[balance.vb_id] = 0;
                        }

                        balance.total = balance.balance;
                        balance.total2 = balance.balance + startingTotalYear[balance.vb_id];
                        startingTotalYear[balance.vb_id] = balance.total2;
                        balance.substract2 = balance.substract + substractYear[balance.vb_id];
                        substractYear[balance.vb_id] = balance.substract2;

                    }
            
                }

                // FIX TOTAL2
                if (typeof balance.total2 === 'undefined') {

                    if (typeof startingTotalYear[balance.vb_id] === 'undefined') {
                        startingTotalYear[balance.vb_id] = 0;
                    }

                    balance.total2 = balance.balance + startingTotalYear[balance.vb_id];
                    startingTotalYear[balance.vb_id] = balance.total2;

                }
                if (balance.total === 0) {
                    balance.total = balance.balance;
                }

                // CORRECTIONS
                if (correctionIndividual === true) {

                    if (this.isEmpty(leaveCorrectionsData) === false) {
                
                        for (const correction of leaveCorrectionsData) {

                            const date = this.stringToDate(correction.cv_datum);
                            const year = date.getFullYear();

                            if (year === parseInt(yearKey) && balance.vb_id === correction.cv_vb_id) {
                                balance.correction += parseFloat(correction.cv_aantal);
                            }
                        
                        }

                        if (balance.correction !== 0) {

                            if (typeof correctionYear[balance.vb_id] === 'undefined') {
                                correctionYear[balance.vb_id] = 0;
                            }

                            balance.total = (balance.total + balance.correction);
                            balance.correction2 = balance.correction + correctionYear[balance.vb_id];
                            balance.total2 = (balance.total2 + balance.correction2);
                            correctionYear[balance.vb_id] = balance.correction2;

                        } else {

                            if (typeof correctionYear[balance.vb_id] === 'undefined') {
                                correctionYear[balance.vb_id] = 0;
                            }

                            balance.correction2 = balance.correction + correctionYear[balance.vb_id];
                            balance.total2 = (balance.total2 + balance.correction2);
                            correctionYear[balance.vb_id] = balance.correction2;

                        }

                    }

                } else {

                    if (this.isEmpty(leaveCorrectionsData) === false && this.isEmpty(leaveCorrectionsData[userID]) === false) {
                
                        for (const correction of leaveCorrectionsData[userID]) {

                            const date = this.stringToDate(correction.cv_datum);
                            const year = date.getFullYear();

                            if (year === parseInt(yearKey) && balance.vb_id === correction.cv_vb_id) {
                                balance.correction += parseFloat(correction.cv_aantal);
                            }
                        
                        }

                        if (balance.correction !== 0) {

                            if (typeof correctionYear[balance.vb_id] === 'undefined') {
                                correctionYear[balance.vb_id] = 0;
                            }

                            balance.total = (balance.total + balance.correction);
                            balance.total2 = (balance.total2 + balance.correction);
                            balance.correction2 = balance.correction + correctionYear[balance.vb_id];
                            correctionYear[balance.vb_id] = balance.correction2;

                        } else {

                            if (typeof correctionYear[balance.vb_id] === 'undefined') {
                                correctionYear[balance.vb_id] = 0;
                            }

                            balance.correction2 = balance.correction + correctionYear[balance.vb_id];
                            balance.total2 = (balance.total2 + balance.correction2);
                            correctionYear[balance.vb_id] = balance.correction2;

                        }

                    }

                }

            }

        }

        let totals = [];
        let total_totalLeaveBalances = {};

        // make sure theres a new fresh list for total, so it wont interrupt the seperate balances list
        for (const yearKey in totalLeaveBalances) {

            if (typeof total_totalLeaveBalances[yearKey] === 'undefined') {
                total_totalLeaveBalances[yearKey] = [];
            }

            for (const balance of totalLeaveBalances[yearKey]) {
                
                total_totalLeaveBalances[yearKey].push({
                    balance: balance.balance,
                    substract: balance.substract,
                    total: balance.total,
                    vb_factor: balance.vb_factor,
                    vb_id: balance.vb_id,
                    vb_naam: balance.vb_naam,
                });

            }

        }

        // create totals list
        for (const yearKey in total_totalLeaveBalances) {

            for (const balance of total_totalLeaveBalances[yearKey]) {

                let userBalance = this.getArrayItem(totals, 'vb_id', balance.vb_id);

                if (userBalance !== null) {

                    userBalance.substract += balance.substract;
                    userBalance.balance += balance.balance;
                    userBalance.total += balance.total;

                } else {

                    totals.push(balance);

                }

            }

        }

        return {
            sep: totalLeaveBalances,
            totals: totals,
        };

    }

    getFieldNameValue(fieldName, user, contract) {

        let toReturn = ``;

        // EMPLOYEE
        if (fieldName === 'first_name') {
            toReturn = `${user.info_voornaam}`;
        } else if (fieldName === 'last_name') {
            toReturn = `${user.info_achternaam}`;
        } else if (fieldName === 'insertion') {
            toReturn = `${user.info_tussenvoegsel}`;
        } else if (fieldName === 'initials') {
            toReturn = `${user.info_voornaam.charAt(0)}`;
        } else if (fieldName === 'birth_date') {
            if (this.isEmpty(user.info_geboorte) === false) {
                const birthdate = this.stringToDate(user.info_geboorte);
                toReturn = `${birthdate.toLocaleDateString()}`;
            } 
        } else if (fieldName === 'birth_place') {
            toReturn = `${user.info_geboorteplaats}`;
        } else if (fieldName === 'phone') {
            toReturn = `${user.info_telefoon}`;
        } else if (fieldName === 'email') {
            toReturn = `${user.info_email}`;
        } else if (fieldName === 'job') {
            toReturn = `${user.functie_naam}`;
        } else if (fieldName === 'address') {
            toReturn = `${user.info_adres} ${user.info_huisnummer}`;
        } else if (fieldName === 'zip') {
            toReturn = `${user.info_postcode}`;
        } else if (fieldName === 'city') {
            toReturn = `${user.info_plaats}`;
        } else if (fieldName === 'startDate') {
            if (contract !== null && this.isEmpty(contract.contract_begin) === false) {
                const startdate = this.stringToDate(contract.contract_begin);
                toReturn = `${startdate.toLocaleDateString()}`;
            } else {
                toReturn = `${new Date().toLocaleDateString()}`;
            }
        } else if (fieldName === 'endDate') {
            if (contract !== null && this.isEmpty(contract.contract_eind) === false) {
                const enddate = this.stringToDate(contract.contract_eind);
                toReturn = `${enddate.toLocaleDateString()}`;
            }
        } else if (fieldName === 'citizen_number') {
            toReturn = `${user.bsn}`;
        } else if (fieldName === 'bank_number') {
            toReturn = `${user.iban}`;
        } else if (fieldName === 'contract_hours') {
            if (contract !== null && this.isEmpty(contract.contract_uren) === false) {
                toReturn = `${contract.contract_uren}`;
            }
        } else if (fieldName === 'contract_max_hours') {
            if (contract !== null && this.isEmpty(contract.contract_max_uren) === false) {
                toReturn = `${contract.contract_max_uren}`;
            }
        } else if (fieldName === 'contract_hour_salary') {
            if (contract !== null && this.isEmpty(contract.contract_uurloon) === false) {
                toReturn = `${contract.contract_uurloon}`;
            }
        } else if (fieldName === 'contract_salary') {
            if (contract !== null && this.isEmpty(contract.contract_maandloon) === false) {
                toReturn = `${contract.contract_maandloon}`;
            }
        } else if (fieldName === 'last_initials') {
            toReturn = `${user.info_achternaam} ${user.info_voornaam.charAt(0)}`;
        } else if (fieldName === 'birth_date_day') {
            if (this.isEmpty(user.info_geboorte) === false) {
                const birthdate = this.stringToDate(user.info_geboorte);
                toReturn = `${birthdate.getDate() < 10 ? '0' + birthdate.getDate() : birthdate.getDate()}`;
            } 
        } else if (fieldName === 'birth_date_month') {
            if (this.isEmpty(user.info_geboorte) === false) {
                const birthdate = this.stringToDate(user.info_geboorte);
                toReturn = `${(birthdate.getMonth() + 1) < 10 ? '0' + (birthdate.getMonth() + 1) : (birthdate.getMonth() + 1)}`;
            } 
        } else if (fieldName === 'birth_date_year') {
            if (this.isEmpty(user.info_geboorte) === false) {
                const birthdate = this.stringToDate(user.info_geboorte);
                toReturn = `${birthdate.getFullYear()}`;
            } 
        } else if (fieldName === 'full_name') {
            const userFullName = `${user.info_voornaam} ${(user.info_tussenvoegsel !== null && user.info_tussenvoegsel !== '') ? user.info_tussenvoegsel + " " + user.info_achternaam : user.info_achternaam}`;    
            toReturn = `${userFullName}`;
        
        // OWNER
        } else if (fieldName === 'owner') {
            const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`;    
            toReturn = `${userFullName}`;
        } else if (fieldName === 'owner_job') {
            toReturn = `${Data.data.accountData.functie_naam}`;
        } else if (fieldName === 'owner_first_name') {
            toReturn = `${Data.data.accountData.info_voornaam}`;
        } else if (fieldName === 'owner_last_name') {
            toReturn = `${Data.data.accountData.info_achternaam}`;
        } else if (fieldName === 'owner_insertion') {
            toReturn = `${Data.data.accountData.info_tussenvoegsel}`;
        } else if (fieldName === 'owner_initials') {
            toReturn = `${Data.data.accountData.info_voornaam.charAt(0)}`;
        } else if (fieldName === 'owner_birth_date') {
            if (this.isEmpty(Data.data.accountData.info_geboorte) === false) {
                const birthdate = this.stringToDate(Data.data.accountData.info_geboorte);
                toReturn = `${birthdate.toLocaleDateString()}`;
            } 
        } else if (fieldName === 'owner_birth_place') {
            toReturn = `${Data.data.accountData.info_geboorteplaats}`;
        } else if (fieldName === 'owner_phone') {
            toReturn = `${Data.data.accountData.info_telefoon}`;
        } else if (fieldName === 'owner_email') {
            toReturn = `${Data.data.accountData.info_email}`;
        } else if (fieldName === 'owner_address') {
            toReturn = `${Data.data.accountData.info_adres} ${Data.data.accountData.info_huisnummer}`;
        } else if (fieldName === 'owner_zip') {
            toReturn = `${Data.data.accountData.info_postcode}`;
        } else if (fieldName === 'owner_city') {
            toReturn = `${Data.data.accountData.info_plaats}`;
        }

        // COMPANY
        if (Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {
            if (fieldName === 'company_name') {
                toReturn = `${Data.data.chosenTeamObj.team_naam}`;
            } else if (fieldName === 'company_address') {
                toReturn = `${Data.data.chosenTeamObj.team_adres}`;
            } else if (fieldName === 'company_zip') {
                toReturn = `${Data.data.chosenTeamObj.team_postcode}`;
            } else if (fieldName === 'company_city') {
                toReturn = `${Data.data.chosenTeamObj.team_plaats}`;
            } else if (fieldName === 'company_phone') {
                toReturn = `${Data.data.chosenTeamObj.team_telefoon}`;
            } else if (fieldName === 'company_number') {
                toReturn = `${Data.data.chosenTeamObj.team_kvk}`;
            }
        } else {
            if (fieldName === 'company_name') {
                toReturn = `${Data.data.storeName}`;
            } else if (fieldName === 'company_address') {
                toReturn = `${Data.data.storeAddress}`;
            } else if (fieldName === 'company_zip') {
                toReturn = `${Data.data.storeZip}`;
            } else if (fieldName === 'company_city') {
                toReturn = `${Data.data.storeCity}`;
            } else if (fieldName === 'company_phone') {
                toReturn = `${Data.data.storePhone}`;
            } else if (fieldName === 'company_number') {
                toReturn = `${Data.data.storeKvK}`;
            }
        }

        if (this.isEmpty(toReturn)) {
            toReturn = '';
        }

        return toReturn;

    }

    getNationalHoliday(date, publicHolidays) {

        if (this.isTrue(Data.data.appOptions.opties_feestdagen) === false && Data.data.accountData.account_rights === '0') {
            return null;
        }

        const dateObject = this.stringToDate(date);

        // NON DUTCH
        if ((this.isEmpty(publicHolidays) === false && publicHolidays.length > 0) || Data.data.storeLang !== 'nl-NL') {

            if ((this.isEmpty(publicHolidays) === true || publicHolidays.length === 0)) {
                return null;
            }

            for (const ph of publicHolidays) {
                if (this.dateToString(date) === ph.f_datum) {
                    return ph.f_naam;
                }
            }

        // DUTCH
        } else {

            if(dateObject.getDate() === 1 && dateObject.getMonth() === 0) {
                return <span>Nieuwjaar</span>;
            }
            if(dateObject.getDate() === 14 && dateObject.getMonth() === 1) {
                return <span>Valentijn</span>;
            }
            if(dateObject.getDate() === 2 && dateObject.getMonth() === 3) { //change
                return <span>Goede vrijdag</span>;
            }
            if(dateObject.getDate() === 4 && dateObject.getMonth() === 3) { //change
                return <span>1e Paasdag</span>;
            }
            if(dateObject.getDate() === 5 && dateObject.getMonth() === 3) { //change
                return <span>2e Paasdag</span>;
            }
            if(dateObject.getDate() === 27 && dateObject.getMonth() === 3) {
                return <span>Koningsdag</span>;
            }
            if(dateObject.getDate() === 4 && dateObject.getMonth() === 4) {
                return <span>Dodenherdenking</span>;
            }
            if(dateObject.getDate() === 5 && dateObject.getMonth() === 4) {
                return <span>Bevrijdingsdag</span>;
            }
            if(dateObject.getDate() === 9 && dateObject.getMonth() === 4) { //change
                return <span>Moederdag</span>;
            }
            if(dateObject.getDate() === 13 && dateObject.getMonth() === 4) { //change
                return <span>Hemelvaart</span>;
            }
            if(dateObject.getDate() === 23 && dateObject.getMonth() === 4) { //change
                return <span>1e Pinksterdag</span>;
            }
            if(dateObject.getDate() === 24 && dateObject.getMonth() === 4) { //change
                return <span>2e Pinksterdag</span>;
            }
            if(dateObject.getDate() === 20 && dateObject.getMonth() === 5) { //change
                return <span>Vaderdag</span>;
            }
            if(dateObject.getDate() === 21 && dateObject.getMonth() === 8) { //change
                return <span>Prinsjesdag</span>;
            }
            if(dateObject.getDate() === 31 && dateObject.getMonth() === 9) {
                return <span>Halloween</span>;
            }
            if(dateObject.getDate() === 11 && dateObject.getMonth() === 10) {
                return <span>Sint Maarten</span>;
            }
            if(dateObject.getDate() === 5 && dateObject.getMonth() === 11) {
                return <span>Sinterklaas</span>;
            }
            if(dateObject.getDate() === 24 && dateObject.getMonth() === 11) {
                return <span>Kerstavond</span>;
            }
            if(dateObject.getDate() === 25 && dateObject.getMonth() === 11) {
                return <span>1e Kerstdag</span>;
            }
            if(dateObject.getDate() === 26 && dateObject.getMonth() === 11) {
                return <span>2e Kerstdag</span>;
            }
            if(dateObject.getDate() === 31 && dateObject.getMonth() === 11) {
                return <span>Oudjaar</span>;
            }

        }

        return null;

    }

    swapInArray(arr, from, to) {
        arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
    }

}

// singleton
export default (new Constants());