


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APICheckStoreCode {

    async Request(storecode) {

        storecode = storecode.toLowerCase();
        
        const hash = CryptoJS.MD5(storecode + Data.data.secretKey).toString();

        let response = null;

        try {
            response = await fetch(`https://login-t01.tiemdo.com/phpcalls/checkStoreCode.php/`, {
                method: 'post',
                body: new URLSearchParams(`storecode=${storecode}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData !== null) {
            return sqlData;

        } else if (sqlData === null) {
            return null;

        } else {
            return null; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APICheckStoreCode());