


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIAddTrade {

    async Request(id2, rid) {

        const username = Data.data.accountData.account_username;

        const id = Data.data.accountData.account_id;
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        try {
            response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/addTrade.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${Data.data.storeCode}&user=${username}&id=${id}&id2=${id2}&rid=${rid}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.text(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData === "1") {
            return true;

        } else if (sqlData === "0") {
            return false;

        } else {
            return false; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIAddTrade());