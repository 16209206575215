/* eslint-disable no-loop-func */
import React from 'react';

//styles
import './accounts.css';
import '../../../constants/DefaultStyle.css';

//js components & classes
import Data from '../../../constants/Data';

import APIEditAccount from '../../../classes/APIEditAccount';
import APIEditAccountInfo from '../../../classes/APIEditAccountInfo';
import APICheckUsernameAvailibility from '../../../classes/APICheckUsernameAvailibility';
import APIDeleteAccount from '../../../classes/APIDeleteAccount';

import Colors from '../../../constants/Colors';
import BetterAlert from '../../../components/alert/alert';

import DatePicker from 'react-datepicker';
import { nl } from 'date-fns/locale';

import CryptoJS from 'crypto-js';
import APIGetAdminAccountInfo from '../../../classes/APIGetAdminAccountInfo';
import Constants from '../../../constants/Constants';
import APIGetAllAccounts from '../../../classes/APIGetAllAccounts';
import APIGetAccountInfo from '../../../classes/APIGetAccountInfo';

class AccountsModal extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.secureData = [{info_ibnr: '', info_idnr: ''}];

    this.modalData = this.props._modalData;
    this.mode = this.props._modalMode;
    this.closeModal = this.props._closeModal;
    this.reloadScreen = this.props._reloadScreen;
    this.functions = this.props._functions;

    const today = new Date();
    this.modalData.info_indienst = this.modalData.info_indienst === null || this.modalData.info_indienst === '0000-00-00' ? `${today.getFullYear()}-${parseInt(today.getMonth() + 1) < 10 ? '0' + parseInt(today.getMonth() + 1) : parseInt(today.getMonth() + 1)}-${parseInt(today.getDate()) < 10 ? '0' + parseInt(today.getDate()) : parseInt(today.getDate())}` : this.modalData.info_indienst;
    this.modalData.info_extra = this.modalData.info_extra === null ? '' : this.modalData.info_extra;
    this.modalData.info_plaats = this.modalData.info_plaats === null || this.modalData.info_plaats === 'null' ? '' : this.modalData.info_plaats;
    this.modalData.info_telefoon2 = this.modalData.info_telefoon2 === null || this.modalData.info_telefoon2 === 'null' ? '' : this.modalData.info_telefoon2;
    this.modalData.info_geboorteplaats = this.modalData.info_geboorteplaats === null || this.modalData.info_geboorteplaats === 'null' ? '' : this.modalData.info_geboorteplaats;

    this.state = {

        loading: false,

        username: this.modalData.account_username,
        password1: '',
        password2: '',

        firstName: this.modalData.info_voornaam,
        insertion: this.modalData.info_tussenvoegsel,
        lastName: this.modalData.info_achternaam,

        birthDate: new Date(this.modalData.info_geboorte),
        birthText: new Date(this.modalData.info_geboorte).toISOString().substring(0, 10),
        birthplace: this.modalData.info_geboorteplaats,

        address: this.modalData.info_adres,
        addressNumber: this.modalData.info_huisnummer,
        zipCode: this.modalData.info_postcode,
        city: this.modalData.info_plaats,

        email: this.modalData.info_email,
        phone: this.modalData.info_telefoon,
        phone2: this.modalData.info_telefoon2,

        functionID: isNaN(parseInt(this.modalData.functie_id)) === true ? null : parseInt(this.modalData.functie_id),
        rights: parseInt(this.modalData.account_rights),

        iban: '',
        bsn: '',

        comment: this.modalData.info_extra,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        showError: false,
        errorText: '',

        startDate: new Date(this.modalData.info_indienst),
        startDateText: new Date(this.modalData.info_indienst).toISOString().substring(0, 10),

        showPassContainer: false,

    };

  }

  async componentDidMount() {

    if((Data.data.appOptions.bsn === true || Data.data.appOptions.iban === true) && Data.data.accountData.account_rights === '2') {

        const secureData = await APIGetAdminAccountInfo.Request(this.modalData.account_id);

        this.setState({
            iban: secureData[0].info_ibnr === null || secureData[0].info_ibnr === 'null' ? '' : secureData[0].info_ibnr,
            bsn: secureData[0].info_idnr === null || secureData[0].info_idnr === 'null' ? '' : secureData[0].info_idnr,
        });

        this.secureData = secureData;

    }

  }

  setStartDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }
  
      const dateObject = new Date(date);

      //dateObject.setDate(dateObject.getDate() + 1);
  
      const dateText = dateObject.toISOString().substring(0, 10);
  
      this.setState({
          startDate: date,
          startDateText: dateText,
      });

  }

  setBirthDate(date) {

    if(date === null || (typeof date !== 'object' && typeof date.getMonth !== 'function')) {
        return;
      }
  
      const dateObject = new Date(date);

      //dateObject.setDate(dateObject.getDate() + 1);
  
      const dateText = dateObject.toISOString().substring(0, 10);
  
      this.setState({
          birthDate: date,
          birthText: dateText,
          showError: false,
      });

  }

  async editAlert() {

    this.setState({loading: true});

    if(await this.checkValidations() === false) {
        this.setState({loading: false});
        return;
    }

    this.setState({loading: false});

    if(this.skipAlert() === false) {

        let saveObject = this.checkForChanges();
    
        let changes = [];

        for(let key in saveObject) {

            changes.push(<div><br />{saveObject[key].dataChanged}: <br />{saveObject[key].dataOld} {'->'} {saveObject[key].dataNew}</div>);

        }

        this.setState({
            alertTitle: 'Wijzigen',
            alertBody: (
                <div>
                    Weet je zeker dat je de volgende gewijzigde gegevens wilt opslaan?
                    {changes}
                </div>
            ),
            alertButtons: 2,
            alertButtonText: ['Annuleren', 'Opslaan'],
            alertButtonAction: this.editAccount.bind(this),
            showAlert: true,
        });

    } else {

        if (this.props._fromTut === true) {

            this.goBack();

            return;

        }

        this.closeModal();
        this.reloadScreen();
        return;

    }

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async editAdminAccount() {

    this.setState({loading: true});

    // ADD TO MAIN STORE ADMIN ACCOUNT

    const storecodeadmin = localStorage.getItem('@storecode');

    if(this.state.password2 === '') {
        await APIEditAccount.Request(
            this.modalData.account_username === this.state.username ? this.modalData.account_username : this.state.username,
            null,
            parseInt(this.modalData.account_rights) === this.state.rights ? parseInt(this.modalData.account_rights) : this.state.rights,
            parseInt(this.modalData.account_id),
            storecodeadmin
        );
      } else {
          await APIEditAccount.Request(
            this.modalData.account_username === this.state.username ? this.modalData.account_username : this.state.username,
            CryptoJS.SHA512(this.state.password2).toString(),
            parseInt(this.modalData.account_rights) === this.state.rights ? parseInt(this.modalData.account_rights) : this.state.rights,
            parseInt(this.modalData.account_id),
            storecodeadmin
          );
      }
  
      await APIEditAccountInfo.Request(
        this.modalData.account_username === this.state.username ? this.modalData.account_username : this.state.username,
        parseInt(this.modalData.account_id),
        this.modalData.info_voornaam === this.state.firstName ? this.modalData.info_voornaam : this.state.firstName,
        this.modalData.info_tussenvoegsel === this.state.insertion ? this.modalData.info_tussenvoegsel : this.state.insertion,
        this.modalData.info_achternaam === this.state.lastName ? this.modalData.info_achternaam : this.state.lastName,
        this.modalData.info_telefoon === this.state.phone ? this.modalData.info_telefoon : this.state.phone,
        this.modalData.info_email === this.state.email ? this.modalData.info_email : this.state.email,
        this.modalData.functie_id === this.state.functionID ? this.modalData.functie_id : this.state.functionID,
        this.modalData.info_extra === this.state.comment ? this.modalData.info_extra : this.state.comment,
        this.modalData.info_adres === this.state.address ? this.modalData.info_adres : this.state.address,
        this.modalData.info_huisnummer=== this.state.addressNumber ? this.modalData.info_huisnummer : this.state.addressNumber,
        this.modalData.info_postcode === this.state.zipCode ? this.modalData.info_postcode : this.state.zipCode,
        this.modalData.info_geboorte === this.state.birthText ? this.modalData.info_geboorte : this.state.birthText,
        this.modalData.info_indienst === this.state.startDateText ? this.modalData.info_indienst : this.modalData.info_indienst === '0000-00-00' || this.modalData.info_indienst === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.state.startDateText,
        this.secureData[0].info_ibnr === this.state.iban ? this.secureData[0].info_ibnr : this.state.iban,
        this.secureData[0].info_idnr === this.state.bsn ? this.secureData[0].info_idnr : this.state.bsn,
        this.modalData.info_plaats === this.state.city ? this.modalData.info_plaats : this.state.city,
        this.modalData.info_telefoon2 === this.state.phone2 ? this.modalData.info_telefoon2 : this.state.phone2,
        this.modalData.info_geboorteplaats === this.state.birthplace ? this.modalData.info_geboorteplaats : this.state.birthplace,
        storecodeadmin
      );

    // EDIT ADMIN ACCOUNT ON ALL TEAMS

    for(const team of Data.data.teams) { 

      if(this.state.password2 === '') {
        await APIEditAccount.Request(
            this.modalData.account_username === this.state.username ? this.modalData.account_username : this.state.username,
            null,
            parseInt(this.modalData.account_rights) === this.state.rights ? parseInt(this.modalData.account_rights) : this.state.rights,
            parseInt(this.modalData.account_id),
            team.bedrijf_code
        );
      } else {
          await APIEditAccount.Request(
            this.modalData.account_username === this.state.username ? this.modalData.account_username : this.state.username,
            CryptoJS.SHA512(this.state.password2).toString(),
            parseInt(this.modalData.account_rights) === this.state.rights ? parseInt(this.modalData.account_rights) : this.state.rights,
            parseInt(this.modalData.account_id),
            team.bedrijf_code
          );
      }
  
      await APIEditAccountInfo.Request(
        this.modalData.account_username === this.state.username ? this.modalData.account_username : this.state.username,
        parseInt(this.modalData.account_id),
        this.modalData.info_voornaam === this.state.firstName ? this.modalData.info_voornaam : this.state.firstName,
        this.modalData.info_tussenvoegsel === this.state.insertion ? this.modalData.info_tussenvoegsel : this.state.insertion,
        this.modalData.info_achternaam === this.state.lastName ? this.modalData.info_achternaam : this.state.lastName,
        this.modalData.info_telefoon === this.state.phone ? this.modalData.info_telefoon : this.state.phone,
        this.modalData.info_email === this.state.email ? this.modalData.info_email : this.state.email,
        this.modalData.functie_id === this.state.functionID ? this.modalData.functie_id : this.state.functionID,
        this.modalData.info_extra === this.state.comment ? this.modalData.info_extra : this.state.comment,
        this.modalData.info_adres === this.state.address ? this.modalData.info_adres : this.state.address,
        this.modalData.info_huisnummer=== this.state.addressNumber ? this.modalData.info_huisnummer : this.state.addressNumber,
        this.modalData.info_postcode === this.state.zipCode ? this.modalData.info_postcode : this.state.zipCode,
        this.modalData.info_geboorte === this.state.birthText ? this.modalData.info_geboorte : this.state.birthText,
        this.modalData.info_indienst === this.state.startDateText ? this.modalData.info_indienst : this.modalData.info_indienst === '0000-00-00' || this.modalData.info_indienst === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.state.startDateText,
        this.secureData[0].info_ibnr === this.state.iban ? this.secureData[0].info_ibnr : this.state.iban,
        this.secureData[0].info_idnr === this.state.bsn ? this.secureData[0].info_idnr : this.state.bsn,
        this.modalData.info_plaats === this.state.city ? this.modalData.info_plaats : this.state.city,
        this.modalData.info_telefoon2 === this.state.phone2 ? this.modalData.info_telefoon2 : this.state.phone2,
        this.modalData.info_geboorteplaats === this.state.birthplace ? this.modalData.info_geboorteplaats : this.state.birthplace,
        team.bedrijf_code
      );
  
    }

    //CHange local account data
    if(Data.data.accountData.account_id === this.modalData.account_id) {

        Data.data.accountData = await APIGetAccountInfo.Request(this.modalData.account_id);

        localStorage.setItem('@username', Data.data.accountData.account_username);
        localStorage.setItem('@password', Data.data.accountData.account_password);
        
    }

    if (this.props._fromTut === true) {

        localStorage.setItem('@tutDataChanged', '1');

        this.goBack();

        return;

    }

    this.editedAlert();

    this.closeModal();

    this.reloadScreen();

  }

  async editAccount() {

    this.setState({loading: true});

    let skipAccount = false;
    let accountAdded = false;

    if(this.modalData.account_username === this.state.username
        && this.modalData.account_rights === this.state.rights
        && this.state.password2 === '') {

            skipAccount = true;

    } else {

        if(this.state.password2 === '') {
            accountAdded = await APIEditAccount.Request(
                this.modalData.account_username === this.state.username ? this.modalData.account_username : this.state.username,
                null,
                parseInt(this.modalData.account_rights) === this.state.rights ? parseInt(this.modalData.account_rights) : this.state.rights,
                parseInt(this.modalData.account_id)
            );
        } else {
            accountAdded = await APIEditAccount.Request(
                this.modalData.account_username === this.state.username ? this.modalData.account_username : this.state.username,
                CryptoJS.SHA512(this.state.password2).toString(),
                parseInt(this.modalData.account_rights) === this.state.rights ? parseInt(this.modalData.account_rights) : this.state.rights,
                parseInt(this.modalData.account_id)
            );
        }

    }

    if(accountAdded === true || skipAccount === true) {

        const accountInfoAdded = await APIEditAccountInfo.Request(
            this.modalData.account_username === this.state.username ? this.modalData.account_username : this.state.username,
            parseInt(this.modalData.account_id),
            this.modalData.info_voornaam === this.state.firstName ? this.modalData.info_voornaam : this.state.firstName,
            this.modalData.info_tussenvoegsel === this.state.insertion ? this.modalData.info_tussenvoegsel : this.state.insertion,
            this.modalData.info_achternaam === this.state.lastName ? this.modalData.info_achternaam : this.state.lastName,
            this.modalData.info_telefoon === this.state.phone ? this.modalData.info_telefoon : this.state.phone,
            this.modalData.info_email === this.state.email ? this.modalData.info_email : this.state.email,
            this.modalData.functie_id === this.state.functionID ? this.modalData.functie_id : this.state.functionID,
            this.modalData.info_extra === this.state.comment ? this.modalData.info_extra : this.state.comment,
            this.modalData.info_adres === this.state.address ? this.modalData.info_adres : this.state.address,
            this.modalData.info_huisnummer=== this.state.addressNumber ? this.modalData.info_huisnummer : this.state.addressNumber,
            this.modalData.info_postcode === this.state.zipCode ? this.modalData.info_postcode : this.state.zipCode,
            this.modalData.info_geboorte === this.state.birthText ? this.modalData.info_geboorte : this.state.birthText,
            this.modalData.info_indienst === this.state.startDateText ? this.modalData.info_indienst : this.modalData.info_indienst === '0000-00-00' || this.modalData.info_indienst === null ? `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` : this.state.startDateText,
            this.secureData[0].info_ibnr === this.state.iban ? this.secureData[0].info_ibnr : this.state.iban,
            this.secureData[0].info_idnr === this.state.bsn ? this.secureData[0].info_idnr : this.state.bsn,
            this.modalData.info_plaats === this.state.city ? this.modalData.info_plaats : this.state.city,
            this.modalData.info_telefoon2 === this.state.phone2 ? this.modalData.info_telefoon2 : this.state.phone2,
            this.modalData.info_geboorteplaats === this.state.birthplace ? this.modalData.info_geboorteplaats : this.state.birthplace,
        );

        if(accountInfoAdded === true) {

            //CHange local account data
            if(Data.data.accountData.account_id === this.modalData.account_id) {

                Data.data.accountData = await APIGetAccountInfo.Request(this.modalData.account_id);

                localStorage.setItem('@username', Data.data.accountData.account_username);
                localStorage.setItem('@password', Data.data.accountData.account_password);
                
            }

            if (this.props._fromTut === true) {

                localStorage.setItem('@tutDataChanged', '1');

                this.goBack();

                return;

            }

            this.editedAlert();

            this.closeModal();

            this.reloadScreen();

            return;

        } else {

            // console.log('error');
      
            this.setState({loading: false});

        }

    } else {

        // console.log('error');
      
        this.setState({loading: false});

    }
  }

  editedAlert() {
    this.setState({
        alertTitle: 'Gewijzigd',
        alertBody: `${this.state.username} is gewijzigd!`,
        alertButtons: 1,
        alertButtonText: ['OK'],
        showAlert: true,
    });
  }

  deleteAlert() {

    if (this.props._fromTut === true) {
        this.setState({
            alertTitle: 'Oeps',
            alertBody: `Je kunt op dit moment niet verwijderen`,
            alertButtons: 1,
            alertButtonText: ['OK'],
            showAlert: true,
        });
        return;
    }

    if (this.userData.account_id === '1') {
        this.setState({
            alertTitle: 'Oeps',
            alertBody: `Je kunt geen beheerder verwijderen`,
            alertButtons: 1,
            alertButtonText: ['OK'],
            showAlert: true,
        });
        return;
    }

    this.setState({
        alertTitle: 'Verwijderen',
        alertBody: `Weet je zeker dat je ${this.state.username} wilt verwijderen? Deze actie kan niet meer ongedaan worden gemaakt`,
        alertButtons: 2,
        alertButtonAction: this.deleteAccount.bind(this),
        alertButtonText: ['Annuleren', 'Verwijderen'],
        showAlert: true,
    });
  }

  async deleteAccount() {

    this.setState({loading: true});

    const added = await APIDeleteAccount.Request(
        parseInt(this.modalData.account_id),
    );

    if(added === true) {

      this.closeModal();

      this.reloadScreen();

      return;

    } else {

      // console.log('error');

      this.setState({loading: false});

    }

  }

// async editAccount() {

//     const accepted = await APIAcceptTrade.Request(this.modalData.ruildienst_info_id, this.modalData.ruildienst_info_id_other, this.modalData.ruildienst_rooster_id);

//     if(accepted === true) {

//         const traded = await APIAcceptTradeDelete.Request(this.modalData.ruildienst_info_id, Data.data.accountData.account_id, this.modalData.ruildienst_rooster_id);

//         if(traded === true) {

//             this.closeModal();

//             this.reloadScreen();
      
//             return;

//         } else {

//             // console.log('error');
      
//             this.setState({loading: false});
      
//         }

//     } else {

//         // console.log('error');
  
//         this.setState({loading: false});
  
//     }
    
//   }

skipAlert() {

    let skip = true;

    if(this.modalData.account_username !== this.state.username) {
        skip = false;
    }
    if(parseInt(this.modalData.account_rights) !== this.state.rights) {
        skip = false;
    }
    if(this.state.password2 !== '') {
        skip = false;
    }
    if(this.modalData.info_voornaam !== this.state.firstName) {
        skip = false;
    }
    if(this.modalData.info_tussenvoegsel !== this.state.insertion) {
        skip = false;
    }
    if(this.modalData.info_achternaam !== this.state.lastName) {
        skip = false;
    }
    if(this.modalData.info_geboorte !== this.state.birthText) {
        skip = false;
    }
    if(this.modalData.info_adres !== this.state.address) {
        skip = false;
    }
    if(this.modalData.info_huisnummer !== this.state.addressNumber) {
        skip = false;
    }
    if(this.modalData.info_postcode !== this.state.zipCode) {
        skip = false;
    }
    if(this.modalData.info_plaats !== this.state.city) {
        skip = false;
    }
    if(this.modalData.info_email !== this.state.email) {
        skip = false;
    }
    if(this.modalData.info_telefoon !== this.state.phone) {
        skip = false;
    }
    if(this.modalData.info_telefoon2 !== this.state.phone2) {
        skip = false;
    }
    if(this.modalData.info_geboorteplaats !== this.state.birthplace) {
        skip = false;
    }
    if(isNaN(this.state.functionID) === false && isNaN(parseInt(this.modalData.functie_id)) === false && (parseInt(this.modalData.functie_id) !== parseInt(this.state.functionID))) {
        skip = false;
    }
    if(this.modalData.info_indienst !== this.state.startDateText && this.modalData.info_indienst !== null) {
        skip = false;
    }
    if(this.secureData[0].info_ibnr !== this.state.iban && this.secureData[0].info_ibnr !== null && this.secureData[0].info_ibnr !== 'null') {
        skip = false;
    }
    if(this.secureData[0].info_idnr !== this.state.bsn && this.secureData[0].info_idnr !== null && this.secureData[0].info_idnr !== 'null') {
        skip = false;
    }
    if(this.modalData.info_extra !== this.state.comment) {
        skip = false;
    }

    return skip;

}

checkForChanges() {

    let saveObject = [];

    if(this.modalData.account_username !== this.state.username) {
        saveObject.push({
            dataChanged: 'Gebruikersnaam',
            dataOld: this.modalData.account_username,
            dataNew: this.state.username
        });
    }
    if(parseInt(this.modalData.account_rights) !== this.state.rights) {
        saveObject.push({
            dataChanged: 'Accountrechten',
            dataOld: parseInt(this.modalData.account_rights) === 2 ? 'Administrator' : parseInt(this.modalData.account_rights) === 1 ? 'Planner' : 'Medewerker',
            dataNew: this.state.rights === 2 ? 'Administrator' : this.state.rights === 1 ? 'Planner' : 'Medewerker',
        });
    }
    if(this.state.password2 !== '') {
        saveObject.push({
            dataChanged: 'Wachtwoord',
            dataOld: '***',
            dataNew: this.state.password2
        });
    }
    if(this.modalData.info_voornaam !== this.state.firstName) {
        saveObject.push({
            dataChanged: 'Voornaam',
            dataOld: this.modalData.info_voornaam,
            dataNew: this.state.firstName
        });
    }
    if(this.modalData.info_tussenvoegsel !== this.state.insertion) {
        saveObject.push({
            dataChanged: 'Tussenvoegsel',
            dataOld: this.modalData.info_tussenvoegsel === '' ? 'Geen' : this.modalData.info_tussenvoegsel,
            dataNew: this.state.insertion
        });
    }
    if(this.modalData.info_achternaam !== this.state.lastName) {
        saveObject.push({
            dataChanged: 'Achternaam',
            dataOld: this.modalData.info_achternaam,
            dataNew: this.state.lastName
        });
    }
    if(this.modalData.info_geboorte !== this.state.birthText) {
        saveObject.push({
            dataChanged: 'Geboortedatum',
            dataOld: this.modalData.info_geboorte,
            dataNew: this.state.birthText
        });
    }
    if(this.modalData.info_geboorteplaats !== this.state.birthplace) {
        saveObject.push({
            dataChanged: 'Geboorteplaats',
            dataOld: this.modalData.info_geboorteplaats,
            dataNew: this.state.birthplace
        });
    }
    if(this.modalData.info_adres !== this.state.address) {
        saveObject.push({
            dataChanged: 'Straatnaam',
            dataOld: this.modalData.info_adres,
            dataNew: this.state.address
        });
    }
    if(this.modalData.info_huisnummer !== this.state.addressNumber) {
        saveObject.push({
            dataChanged: 'Huisnummer',
            dataOld: this.modalData.info_huisnummer,
            dataNew: this.state.addressNumber
        });
    }
    if(this.modalData.info_postcode !== this.state.zipCode) {
        saveObject.push({
            dataChanged: 'Postcode',
            dataOld: this.modalData.info_postcode,
            dataNew: this.state.zipCode
        });
    }
    if(this.modalData.info_plaats !== this.state.city) {
        saveObject.push({
            dataChanged: 'Plaats',
            dataOld: this.modalData.info_plaats,
            dataNew: this.state.city
        });
    }
    if(this.modalData.info_email !== this.state.email) {
        saveObject.push({
            dataChanged: 'E-mail',
            dataOld: this.modalData.info_email,
            dataNew: this.state.email
        });
    }
    if(this.modalData.info_telefoon !== this.state.phone) {
        saveObject.push({
            dataChanged: 'Telefoon',
            dataOld: this.modalData.info_telefoon,
            dataNew: this.state.phone
        });
    }
    if(this.modalData.info_telefoon2 !== this.state.phone2) {
        saveObject.push({
            dataChanged: 'Tweede telefoon',
            dataOld: this.modalData.info_telefoon2,
            dataNew: this.state.phone2
        });
    }
    if(this.secureData[0].info_ibnr !== this.state.iban && this.secureData[0].info_ibnr !== null && this.secureData[0].info_ibnr !== 'null') {
        saveObject.push({
            dataChanged: 'IBAN',
            dataOld: this.secureData[0].info_ibnr === null ? '' : this.secureData[0].info_ibnr,
            dataNew: this.state.iban
        });
    }
    if(this.secureData[0].info_idnr !== this.state.bsn && this.secureData[0].info_idnr !== null && this.secureData[0].info_idnr !== 'null') {
        saveObject.push({
            dataChanged: 'BSN-nummer',
            dataOld: this.secureData[0].info_idnr === null ? '' : this.secureData[0].info_idnr,
            dataNew: this.state.bsn
        });
    }
    if(isNaN(this.state.functionID) === false && isNaN(parseInt(this.modalData.functie_id)) === false && (parseInt(this.modalData.functie_id) !== parseInt(this.state.functionID))) {
        let functionName = '';
        for(let key in this.functions) {
            if(parseInt(this.functions[key].functie_id) === this.state.functionID) {
                functionName = this.functions[key].functie_naam;
            }
        }
        saveObject.push({
            dataChanged: 'Functie',
            dataOld: this.modalData.functie_naam === null ? 'Geen' : this.modalData.functie_naam,
            dataNew: functionName
        });
    }
    if(this.modalData.info_indienst !== this.state.startDateText && this.modalData.info_indienst !== null) {
        saveObject.push({
            dataChanged: 'In dienst',
            dataOld: this.modalData.info_indienst,
            dataNew: this.state.startDateText
        });
    }
    if(this.modalData.info_extra !== this.state.comment) {
        saveObject.push({
            dataChanged: 'Extra',
            dataOld: this.modalData.info_extra === '' ? 'Geen' : this.modalData.info_extra,
            dataNew: this.state.comment
        });
    }

    return saveObject;

}

showError() {

    if(this.state.showError === true) {
      return <div className='errorText'>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

async checkValidations() {

    if(this.state.username === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen gebruikersnaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9]*$/.test(this.state.username) === false) {
        this.setState({
            showError: true,
            errorText: 'Gebruikersnaam mag alleen letters en/of cijfers bevatten',
        });
        return false;
    }

    if(this.modalData.account_username !== this.state.username) {

        const userNameExists = await APICheckUsernameAvailibility.Request(this.state.username);

        if(userNameExists === true) {
            this.setState({
                showError: true,
                errorText: 'Deze gebruikersnaam bestaat al',
            });
            return false;
        }

    }

    if(this.state.password1 !== '' && this.state.password2 === '') {
        this.setState({
            showError: true,
            errorText: 'Je moet het wachtwoord nog bevestigen',
        });
        return false;
    }

    if((this.state.password1 !== '' && this.state.password2 !== '') && this.state.password1 !== this.state.password2) {
        this.setState({
            showError: true,
            errorText: 'Wachtwoorden komen niet overeen',
        });
        return false;
    }

    if(this.state.firstName === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen voornaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.firstName) === false) {
        this.setState({
            showError: true,
            errorText: 'Voornaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.insertion !== '' && /^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.insertion) === false) {
        this.setState({
            showError: true,
            errorText: 'Tussenvoegsel mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.lastName === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen achternaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.lastName) === false) {
        this.setState({
            showError: true,
            errorText: 'Achternaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.birthDate === null || this.state.birthText === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen geboortedatum geselecteerd',
        });
        return false;
    }
    if(this.state.birthplace === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen geboorteplaats ingevoerd',
        });
        return false;
    }
    if(this.state.address === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen straatnaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.address) === false) {
        this.setState({
            showError: true,
            errorText: 'Straatnaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.addressNumber === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen huisnummer ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-()]*$/.test(this.state.addressNumber) === false) {
        this.setState({
            showError: true,
            errorText: 'Huisnummer mag alleen letters, cijfers en -() bevatten',
        });
        return false;
    }
    if(this.state.zipCode === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen postcode ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.zipCode) === false) {
        this.setState({
            showError: true,
            errorText: 'Postcode mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.city === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen plaatsnaam ingevoerd',
        });
        return false;
    }
    if(/^[a-zA-Z0-9\u00C0-\u017F\s-]*$/.test(this.state.city) === false) {
        this.setState({
            showError: true,
            errorText: 'Plaatsnaam mag alleen letters, cijfers en - bevatten',
        });
        return false;
    }
    if(this.state.email === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen e-mailadres ingevoerd',
        });
        return false;
    }
    // eslint-disable-next-line no-useless-escape
    if(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.state.email) === false) {
        this.setState({
            showError: true,
            errorText: 'Geen geldige e-mailadres',
        });
        return false;
    }
    if(this.state.phone === '') {
        this.setState({
            showError: true,
            errorText: 'Je hebt geen telefoonnummer ingevoerd',
        });
        return false;
    }
    // eslint-disable-next-line no-useless-escape
    if(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.phone) === false) {
        this.setState({
            showError: true,
            errorText: 'Geen geldige telefoonnummer',
        });
        return false;
    }

    return true;

  }

  getFunctionBars() {

    let functions = [];

    for(let key in this.functions) {

        functions.push(
            <option value={this.functions[key].functie_id} selected={parseInt(this.functions[key].functie_id) === this.state.functionID}>{this.functions[key].functie_naam}</option>
        );

    }

    return functions;

  }

  selectFunction(event) {

    this.setState({
        functionID: isNaN(parseInt(event.target.value)) === true ? null : parseInt(event.target.value),
        showError: false,
    });

  }

  selectRight(event) {

    this.setState({
        rights: parseInt(event.target.value),
        showError: false,
    });

  }

    goBack() {

        if (this.props._fromTut === true) {

            this.props._reloadTutScreen();

        }

        this.props._closeModal();

    }

  render() {

    const userFullName = `${this.modalData.info_voornaam} ${(this.modalData.info_tussenvoegsel !== null && this.modalData.info_tussenvoegsel !== '') ? this.modalData.info_tussenvoegsel + " " + this.modalData.info_achternaam : this.modalData.info_achternaam}`;

    const functions = this.getFunctionBars();

    return (
        <div id="accountsModal" className="modal" onClick={(e) => e.target === document.getElementById("zaccountsModal") ? this.goBack() : null}>

        <div className="modal-content" style={{width: '80%'}}>
          <div className="modal-header">
            <span className="modal-close" onClick={() => this.state.loading === false ? this.goBack() : null}>&times;</span>
            <p>{userFullName}</p>
          </div>
            <div className="modal-body" style={{paddingBottom: Constants.isTablet() === true ? '80%' : 0}}>

                {this.state.showAlert === true ?
                    
                    <BetterAlert
                        _closeAlert={this.closeAlert.bind(this)}
                        _title={this.state.alertTitle}
                        _body={this.state.alertBody}
                        _buttons={this.state.alertButtons}
                        _buttonText={this.state.alertButtonText}
                        _buttonAction={this.state.alertButtonAction}
                    />

                :

                    null
                
                }

                <div className='plan_modal-form' style={{marginBottom: 40}}>

                    <div className='accounts_modal_row'>

                        <div className='accounts_modal_col' style={{paddingLeft: '1vw', paddingRight: '0.5vw'}}>

                            <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Inloggegevens</div>

                            <div>Gebruikersnaam: *</div>

                            <input 
                                className={'inputField2'}
                                maxLength={30}
                                value={this.state.username}
                                onChange={(event) => this.setState({ username: event.target.value, showError: false })}
                                placeholder={'Gebruikersnaam'}
                                autoComplete={'new-password'}
                            />

                            <div className='accounts_modal_passButton' onClick={() => this.setState({showPassContainer: !this.state.showPassContainer})}>Wijzig wachtwoord</div>

                            {this.state.showPassContainer === true ?

                                <div className='accounts_modal_passContainer'>

                                    <div>Wachtwoord: *</div>

                                    <input 
                                        className={'inputField2'}
                                        type={'password'}
                                        value={this.state.password1}
                                        onChange={(event) => this.setState({ password1: event.target.value, showError: false })}
                                        placeholder={'Nieuw wachtwoord'}
                                        autoComplete={'new-password'}
                                    />

                                    <div style={{marginTop: 10}}>Bevestig wachtwoord: *</div>

                                    <input 
                                        className={'inputField2'}
                                        type={'password'}
                                        value={this.state.password2}
                                        onChange={(event) => this.setState({ password2: event.target.value, showError: false })}
                                        placeholder={'Bevestig wachtwoord'}
                                        autoComplete={'new-password'}
                                    />

                                </div>

                            : null}

                        </div>

                        <div className='accounts_modal_col' style={{paddingLeft: '0.5vw', paddingRight: '1vw'}}>

                            <div style={{fontSize: '2vw', fontWeight: '500', marginBottom: 25}}>Persoonsgegevens</div>

                            <div>Voornaam: *</div>

                            <input 
                                className={'inputField2'}
                                maxLength={50}
                                value={this.state.firstName}
                                onChange={(event) => this.setState({ firstName: event.target.value, showError: false })}
                                placeholder={'Voornaam'}
                                style={{textTransform: 'capitalize'}}
                            />

                            <div style={{marginTop: 10}}>Tussenvoegsel:</div>

                            <input 
                                className={'inputField2'}
                                maxLength={50}
                                value={this.state.insertion}
                                onChange={(event) => this.setState({ insertion: event.target.value, showError: false })}
                                placeholder={'Tussenvoegsel'}
                            />

                            <div style={{marginTop: 10}}>Achternaam: *</div>

                            <input 
                                className={'inputField2'}
                                maxLength={50}
                                value={this.state.lastName}
                                onChange={(event) => this.setState({ lastName: event.target.value, showError: false })}
                                placeholder={'Achternaam'}
                                style={{textTransform: 'capitalize'}}
                            />

                            <div style={{marginTop: 10}}>Geboortedatum: *</div>

                            <DatePicker
                                selected={this.state.birthDate}
                                onChange={(date) => this.setBirthDate(date)}
                                dateFormat="dd-MM-yyyy"
                                className={'inputFieldTime'}
                                placeholderText={'Selecteer jouw geboortedatum'}
                                showWeekNumbers
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                locale={nl}
                            />

                            <div style={{marginTop: 10}}>Geboorteplaats: *</div>

                            <input 
                                className={'inputField2'}
                                maxLength={100}
                                value={this.state.birthplace}
                                onChange={(event) => this.setState({ birthplace: event.target.value, showError: false })}
                                placeholder={'Geboorteplaats'}
                                style={{textTransform: 'capitalize'}}
                            />

                            <div style={{marginTop: 10}}>Straatnaam: *</div>

                            <input 
                                className={'inputField2'}
                                maxLength={80}
                                value={this.state.address}
                                onChange={(event) => this.setState({ address: event.target.value, showError: false })}
                                placeholder={'Straatnaam'}
                                style={{textTransform: 'capitalize'}}
                            />

                            <div style={{marginTop: 10}}>Huisnummer: *</div>

                            <input 
                                className={'inputField2'}
                                maxLength={10}
                                value={this.state.addressNumber}
                                onChange={(event) => this.setState({ addressNumber: event.target.value, showError: false })}
                                placeholder={'Huisnummer + toevoeging'}
                            />

                            <div style={{marginTop: 10}}>Postcode: *</div>

                            <input 
                                className={'inputField2'}
                                maxLength={20}
                                value={this.state.zipCode}
                                onChange={(event) => this.setState({ zipCode: event.target.value, showError: false })}
                                placeholder={'Postcode'}
                                style={{textTransform: this.state.zipCode === '' ? 'none' : 'uppercase'}}
                            />

                            <div style={{marginTop: 10}}>Plaats: *</div>

                            <input 
                                className={'inputField2'}
                                maxLength={50}
                                value={this.state.city}
                                onChange={(event) => this.setState({ city: event.target.value, showError: false })}
                                placeholder={'Plaatsnaam'}
                            />

                            <div style={{marginTop: 10}}>E-mail: *</div>

                            <input 
                                className={'inputField2'}
                                maxLength={50}
                                type={'email'}
                                value={this.state.email}
                                onChange={(event) => this.setState({ email: event.target.value, showError: false })}
                                placeholder={'E-mail'}
                            />

                            <div style={{marginTop: 10}}>Telefoon: *</div>

                            <input 
                                className={'inputField2'}
                                maxLength={12}
                                type={'tel'}
                                value={this.state.phone}
                                onChange={(event) => this.setState({ phone: event.target.value, showError: false })}
                                placeholder={'bv. 06 of 074'}
                            />

                            <div style={{marginTop: 10}}>Tweede telefoon:</div>

                            <input 
                                className={'inputField2'}
                                maxLength={12}
                                type={'tel'}
                                value={this.state.phone2}
                                onChange={(event) => this.setState({ phone2: event.target.value })}
                                placeholder={'Optioneel'}
                            />

                            {Data.data.appOptions.iban === true && Data.data.accountData.account_rights === '2' ?

                            <div>

                                <div style={{marginTop: 10}}>IBAN:</div>

                                <input 
                                    className={'inputField2'}
                                    maxLength={25}
                                    value={this.state.iban}
                                    onChange={(event) => this.setState({ iban: event.target.value })}
                                    placeholder={'bv. NL85 ABNA #########'}
                                />

                            </div>

                            : null}

                            {Data.data.appOptions.bsn === true && Data.data.accountData.account_rights === '2' ?

                                <div>

                                    <div style={{marginTop: 10}}>BSN-nummer:</div>

                                    <input 
                                        className={'inputField2'}
                                        maxLength={15}
                                        value={this.state.bsn}
                                        onChange={(event) => this.setState({ bsn: event.target.value, })}
                                        placeholder={'Persoonsnummer'}
                                    />

                                </div>

                            : null}

                            <div style={{marginTop: 10}}>Functie:</div>

                            <select 
                                name="Selecteer een functie" 
                                className={'dropDownField'}
                                style={{marginTop: 5}} 
                                onChange={(event) => this.selectFunction(event)}
                            >
                                <option value={null} selected>Selecteer een functie</option>
                                {functions}
                            </select>

                            <div style={{marginTop: 10}}>Accountrechten: *</div>

                            <select 
                                name="Selecteer een accountrecht" 
                                className={'dropDownField'}
                                style={{marginTop: 5}} 
                                onChange={(event) => this.selectRight(event)}
                            >
                                <option value={'0'} selected={this.state.rights === 0}>Medewerker</option>
                                <option value={'1'} selected={this.state.rights === 1}>Planner</option>
                                <option value={'2'} selected={this.state.rights === 2}>Administrator</option>
                            </select>

                            <div style={{marginTop: 10}}>Datum in dienst:</div>

                            <DatePicker
                                selected={this.state.startDate}
                                onChange={(date) => this.setStartDate(date)}
                                dateFormat="dd-MM-yyyy"
                                className={'inputFieldTime'}
                                placeholderText={'Selecteer een datum'}
                                showWeekNumbers
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                locale={nl}
                            />

                        </div>

                    </div>

                    

                    

                    <div style={{marginTop: 10}}>Aanvullende informatie: (max. 250 tekens)</div>

                    <textarea  
                        className={'inputFieldBig'}
                        maxLength={250}
                        value={this.state.comment}
                        onChange={(event) => this.setState({ comment: event.target.value })}
                    />

                    {this.state.loading === true ?

                        <div className='submitButtonLoading' style={{marginTop: 40}}><div className="lds-dual-ring-button"></div></div>

                    :

                        <div>

                            <div className='submitButton' style={{marginTop: 40}} onClick={() => this.editAlert()}>Opslaan</div>

                            <div className='submitButton' style={{marginTop: 10, color: Colors.color.redFilledIn}} onClick={() => this.deleteAlert()}>Verwijderen</div>
                        
                        </div>
                    }

                    {this.showError()}

                </div>

            </div>

        </div>

      </div>
    );
  }
  
}

export default AccountsModal;