import React from 'react';
import './mailingNews.css';
import '../demo/demo.css';
import '../home2/home2.css';
import '../../constants/DefaultStyle.css';
import '../employees/employees.css';
import '../options/reports/reports.scss';
import '../options/hoursRegistration/hoursRegistration.css';

import Data from '../../constants/Data';
import Navbar from '../../components/navbar/navbar';

import BetterAlert from '../../components/alert/alert';

import APIGETLOGIN from '../../classes/login/APIGETLOGIN';
import APIADDLOGIN from '../../classes/login/APIADDLOGIN';
import APIEDITLOGIN from '../../classes/login/APIEDITLOGIN';
import APIDELETELOGIN from '../../classes/login/APIDELETELOGIN';
import Constants from '../../constants/Constants';
import Colors from '../../constants/Colors';
import { Prompt } from 'react-router';

import CryptoJS from 'crypto-js';

import { toast } from 'react-toastify';
import APIEDIT from '../../classes/global/APIEDIT';
import { parse } from '@fortawesome/fontawesome-svg-core';
import APISendBulkMail from '../../classes/APISendBulkMail';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import APISendNewsMail from '../../classes/APISendNewsMail';

class MailingNews extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }  
    
    this.state = {

        loading: true,
        reloading: false,

        showAlert: false,
        alertTitle: '',
        alertBody: '',
        alertButtons: 0,
        alertButtonText: [],
        alertButtonAction: null,

        mailing: null,
        mails: '',

        companies: [],

        sentMails: [],
        errorMails: [],

        menu: 'general',

        errorText: '',

        defaultEditorValue: {},
        editorValueHTML: '',
        editorValueDELTA: {},
        editorLength: 0,

        week: '',

        tiemdoNews: [],
        selectedNews: null,
        pinned: false,

    };

    //componentWillMount here
    this.handleChangeEditor = this.handleChangeEditor.bind(this);

  }

  async componentDidMount() {

    const tiemdoNews = await APIGETLOGIN.Request(`SELECT *`, `FROM Nieuws`, null, null, null, `ORDER BY nieuws_pinned DESC, nieuws_id DESC`);

    // const companies = await APIGETLOGIN.Request(`SELECT *`, `FROM Bedrijf`, null, `WHERE bedrijf_nieuwsbrief = 1`, null, null);

    // let mails = 'jordy-9999@hotmail.com,';

    // for (const c of companies) {

    //   const mail = Constants.isEmpty(c.bedrijf_persoonlijk_email) ? c.bedrijf_email : c.bedrijf_persoonlijk_email;

    //   if (Constants.isEmpty(mail) === false) {
    //     mails += `${mail},`;
    //   }

    // }

    this.setState({ 
      //mails: mails,
      // companies: companies,
      tiemdoNews: tiemdoNews,
      loading: false
    });

  }

  hasChanges() {

    for (const key in this.state.companyInfo) {

      if (this.state.oldCompanyInfo[key] !== this.state.companyInfo[key]) {
        return true;
      }

    }

    return false;

  }

  async reload() {

    // this.setState({ reloading: true });

    // let [
    //   companies,
    //   servers,
    // ] = await Promise.all([
    //   APIGETLOGIN.Request(`SELECT *`, `FROM Aanvraag`, null, null, null, `ORDER BY aanvraag_id ASC`),
    //   APIGETLOGIN.Request(`SELECT *`, `FROM Servr`, null, null, null, `ORDER BY server_id`),
    // ]);

    // this.setState({ 
    //   servers: servers,
    //   serverID: parseInt(servers[0].server_id),
    //   companies: companies,
    //   code: '',
    //   reloading: false
    // });

    this.props.history.push("/home");

  }

  getCompanySelects() {

    let toRender = [];

    for (const company of this.state.companies) {

      toRender.push(<option value={company.aanvraag_id} selected={this.state.selectedCompany === company.aanvraag_id}>{company.aanvraag_naam}</option>);

    }

    return toRender;

  }

  changeCompany(id) {

    const companyInfo = Constants.getArrayItem(this.state.companies, 'aanvraag_id', id);

    if (this.hasChanges()) {
      const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (leave === false) {
        return;
      }
    }

    if (companyInfo !== null) {

      this.setState({

        selectedCompany: id,
        companyInfo: { ...companyInfo },
        oldCompanyInfo: { ...companyInfo },
        menu: 'general',

      });

    } else {

      this.setState({

        selectedCompany: null,
        companyInfo: null,
        oldCompanyInfo: null,
        menu: 'general',

      });

    }

  }

  getCompanyButtonsMenu() {

    let toRender = [];

    for (const company of this.state.companies) {

      toRender.push(
        <div className={`employees_menuButton ${this.state.selectedCompany === company.aanvraag_id ? 'activeAv' : ''}`} onClick={() => this.changeCompany(company.aanvraag_id)}>
          <div>{company.aanvraag_naam}</div>
        </div>
      );

    }

    return toRender;

  }

  getCompanyBars() {

    let toRender = [];
    let count = 0;

    for (const company of this.state.companies) {

      toRender.push(

        <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>

          {/* {contractEnding ?
            <Tooltip
              options
              html={(
                <div className='appoptions_tooltipContainer'>
                  <span>Contract verloopt bijna</span>
                </div>
              )}
              style={{position: 'absolute', left: Data.data.teams.length > 0 && Data.data.chosenTeam !== null && Constants.isEmpty(teamName) === true ? '-1.8vw' : '-0.6vw', marginBottom: 2, cursor: 'pointer'}}
              position={'top'}
              followCursor
              arrow
              theme='kodict'
            >
              <i style={{fontSize: '0.7vw', color: Colors.color.redFilledIn}} className='far fa-file-exclamation' onClick={() => this.selectUser(account.info_id, userFullName, false, 'contract')} />
            </Tooltip>
          : null}

          {Data.data.teams.length > 0 && Data.data.chosenTeam !== null && Constants.isEmpty(teamName) === true ?
            <Tooltip
              options
              html={(
                <div className='appoptions_tooltipContainer'>
                  <span>Medewerker heeft nog geen team toegewezen gekregen</span>
                </div>
              )}
              style={{position: 'absolute', left: '-0.8vw', marginBottom: 2, cursor: 'pointer'}}
              position={'top'}
              followCursor
              arrow
              theme='kodict'
            >
              <i style={{fontSize: '0.7vw', color: Colors.color.redFilledIn}} className='fas fa-exclamation-triangle' onClick={() => this.selectUser(account.info_id, userFullName, false, 'teams')} />
            </Tooltip>
          : null} */}

          <div className='hr_hoursBar' style={{ backgroundColor: count === 1 ? '#F9FBFC' : null, height: '2.5vw' }}>

          {/* onClick={() => this.changeCompany(company.aanvraag_id)} */}
            <div className='hr_hoursBar_sub1' style={{  }}>
              <span><b>{company.aanvraag_naam}</b></span>
            </div>

            <div className='hr_hoursBar_sub2' style={{  }}>
              <span>{company.aanvraag_eigenaar}</span>
            </div>

            <div className='hr_hoursBar_sub1' style={{  }}>
              <span className="defaultLinkO" onClick={() => window.open(`mailto:${company.aanvraag_persoonlijk_email}`)}>{company.aanvraag_persoonlijk_email}</span>
            </div>

            <div className='hr_hoursBar_sub2' style={{  }}>
              <span className="defaultLinkO" onClick={() => window.open(`tel:${company.aanvraag_persoonlijk_telefoon}`)}>{company.aanvraag_persoonlijk_telefoon}</span>
            </div>

            <div className='hr_hoursBar_sub2' style={{  }}>
              {company.aanvraag_pakket === '1' ?
                <span>Starter</span>
              : company.aanvraag_pakket === '2' ?
                <span>Regular</span>
              : company.aanvraag_pakket === '3' ?
                <span>Enterprise</span>
              :
                <span>&nbsp;</span>
              }
            </div>

            <div className='hr_hoursBar_sub3'>

              <div className='hr_acceptButton' onClick={() => { this.changeCompany(company.aanvraag_id); setTimeout(() => { this.selectMenu('accept'); }, 5); }}>
                <i className='far fa-check' />
              </div>

              <div className='hr_declineButton' style={{marginLeft: '6%'}} onClick={() => this.declineCompanyAlert(company.aanvraag_id)}>
                <i className='far fa-times' />
              </div>

            </div>

            <div className='hr_hoursBar_sub3' style={{cursor: 'pointer', flex: 0.2}} onClick={() => this.changeCompany(company.aanvraag_id)}>
              <i className='far fa-edit' />
            </div>

          </div>

        </div>

      );

      if (count === 0) {
        count++;
      } else {
        count--;
      }

    }

    return toRender;

  }

  declineCompanyAlert(id) {

    this.setState({
        alertTitle: 'Aanmelding afwijzen',
        alertBody: (<span>Weet je zeker dat je deze aanmelding wilt afwijzen?</span>),
        alertButtons: 2,
        alertButtonAction: this.declineCompany.bind(this, id),
        alertButtonText: ['Annuleren', 'Afwijzen'],
        showAlert: true,
    });
  
  }

  async declineCompany(id) {

    this.setState({ reloading: true });

    await APIDELETELOGIN.Request(`DELETE FROM Aanvraag`, `WHERE aanvraag_id = ${id}`);
    await this.reload();

    this.setState({ reloading: false });

  }

  selectMenu(menu) {

    if (this.state.menu === menu) {
      return;
    }

    // if (this.state.companyInfo !== this.state.oldCompanyInfo) {
    //   const leave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    //   if (leave === false) {
    //     return;
    //   }
    // }

    this.setState({ menu: menu });

  }

  closeAlert() {

    this.setState({ showAlert: false });

  }

  async checkValidations() {

    // if (Constants.isEmpty(this.state.mailing)) {
    //   this.setState({
    //     showError: true,
    //     errorText: 'Je hebt nog geen mail geselecteerd',
    //   });
    //   return false;
    // }

    // if (Constants.isEmpty(this.state.mails)) {
    //   this.setState({
    //     showError: true,
    //     errorText: 'Je hebt geen e-mails ingevuld',
    //   });
    //   return false;
    // }

    return true;

  }

  async acceptCompanyAlert() {

    if (await this.checkValidations() === false) {
      return;
    }

    this.setState({
        alertTitle: 'Nieuwsmail versturen',
        alertBody: (<span>Weet je zeker dat je deze mail wilt versturen?</span>),
        alertButtons: 2,
        alertButtonAction: this.addCompany.bind(this),
        alertButtonText: ['Annuleren', 'Versturen'],
        showAlert: true,
    });
  
  }

  async addCompany() {

    this.setState({ loading: true });

    let sentMails = [];

    // for (const c of this.state.companies) {

    //   const mail = Constants.isEmpty(c.bedrijf_persoonlijk_email) ? c.bedrijf_email : c.bedrijf_persoonlijk_email;

    //   if (Constants.isEmpty(mail) === false) {
        
    //     const s = await APISendNewsMail.Request(mail, c.bedrijf_code, this.state.week, this.state.editorValueHTML);
    //     sentMails.push({ email: mail, success: s });

    //     this.setState({ sentMails: sentMails });

    //   }

    // }

    await APIADDLOGIN.Request(`INSERT INTO Nieuws`, `VALUES (NULL, '${this.state.week}', '${Constants.addSlashes(JSON.stringify(this.state.defaultEditorValue))}', '${Constants.dateToString(new Date())}', ${this.state.pinned ? 1 : 0})`);

    const sent = true;

    if (Constants.isEmpty(sent) === true) {

      toast.error('😔 Er is iets misgegaan', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        icon: false, 
      });
      return;

    }

    toast.success(`😄 Nieuws verzonden!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      icon: false, 
    });

    await this.componentDidMount();
    this.forceUpdate();

    this.editor.value = {};
    this.setState({ loading: true }, () => {
      this.setState({ 
        defaultEditorValue: {},
        editorValueHTML: '',
        editorValueDELTA: {},
        editorLength: 0, 
        week: '',
      }, () => {
        this.setState({ 
          loading: false, 
          mailing: null, 
          mails: '',
         });
      });
    });

    // this.setState({ 
    //   defaultEditorValue: {},
    //   editorValueHTML: '',
    //   editorValueDELTA: {},
    //   editorLength: 0,

    //   loading: false, 
    //   mailing: null, 
    //   mails: '',
    // });

  }

  async editNewsAlert() {

    if (await this.checkValidations() === false) {
      return;
    }

    this.setState({
        alertTitle: 'Nieuwsmail wijzigen',
        alertBody: (<span>Weet je zeker dat je deze mail wilt wijzigen?</span>),
        alertButtons: 2,
        alertButtonAction: this.editNews.bind(this),
        alertButtonText: ['Annuleren', 'Wijzigen'],
        showAlert: true,
    });
  
  }

  async editNews() {

    this.setState({ loading: true });

    await APIEDITLOGIN.Request(`UPDATE Nieuws`, `SET nieuws_titel = '${this.state.week}', nieuws_tekst = '${Constants.addSlashes(JSON.stringify(this.state.defaultEditorValue))}', nieuws_pinned = ${this.state.pinned ? 1 : 0}`, `WHERE nieuws_id = ${this.state.selectedNews.nieuws_id}`);

    const sent = true;

    if (Constants.isEmpty(sent) === true) {

      toast.error('😔 Er is iets misgegaan', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        icon: false, 
      });
      return;

    }

    toast.success(`😄 Nieuws gewijzigd!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      icon: false, 
    });

    await this.componentDidMount();
    this.forceUpdate();

    this.editor.value = {};
    this.setState({ loading: true }, () => {
      this.setState({ 
        defaultEditorValue: {},
        editorValueHTML: '',
        editorValueDELTA: {},
        editorLength: 0, 
        week: '',
        selectedNews: null,
        pinned: false,
      }, () => {
        this.setState({ 
          loading: false, 
          mailing: null, 
          mails: '',
         });
      });
    });

  }

  async deleteNewsAlert() {

    if (await this.checkValidations() === false) {
      return;
    }

    this.setState({
        alertTitle: 'Nieuwsmail verwijderen',
        alertBody: (<span>Weet je zeker dat je deze mail wilt verwijderen?</span>),
        alertButtons: 2,
        alertButtonAction: this.deleteNews.bind(this),
        alertButtonText: ['Annuleren', 'Verwijderen'],
        showAlert: true,
    });
  
  }

  async deleteNews() {

    this.setState({ loading: true });

    await APIDELETELOGIN.Request(`DELETE FROM Nieuws`, `WHERE nieuws_id = ${this.state.selectedNews.nieuws_id}`);

    const sent = true;

    if (Constants.isEmpty(sent) === true) {

      toast.error('😔 Er is iets misgegaan', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        icon: false, 
      });
      return;

    }

    toast.success(`😄 Nieuws verwijderd!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      icon: false, 
    });

    await this.componentDidMount();
    this.forceUpdate();

    this.editor.value = {};
    this.setState({ loading: true }, () => {
      this.setState({ 
        defaultEditorValue: {},
        editorValueHTML: '',
        editorValueDELTA: {},
        editorLength: 0, 
        week: '',
        selectedNews: null,
        pinned: false,
      }, () => {
        this.setState({ 
          loading: false, 
          mailing: null, 
          mails: '',
         });
      });
    });

  }

  saveCompanyAlert() {

    this.setState({
        alertTitle: 'Bedrijf opslaan',
        alertBody: (<span>Weet je zeker dat je deze informatie wilt opslaan?</span>),
        alertButtons: 2,
        alertButtonAction: this.saveCompany.bind(this),
        alertButtonText: ['Annuleren', 'Opslaan'],
        showAlert: true,
    });
  
  }

  async saveCompany() {

    this.setState({ reloading: true });

    const companyInfo = this.state.companyInfo;

    const name = Constants.isEmpty(companyInfo.aanvraag_naam) ? 'NULL' : `'${companyInfo.aanvraag_naam}'`;
    const owner = Constants.isEmpty(companyInfo.aanvraag_eigenaar) ? 'NULL' : `'${companyInfo.aanvraag_eigenaar}'`;
    const mail = Constants.isEmpty(companyInfo.aanvraag_persoonlijk_email) ? 'NULL' : `'${companyInfo.aanvraag_persoonlijk_email}'`;
    const phone = Constants.isEmpty(companyInfo.aanvraag_persoonlijk_telefoon) ? 'NULL' : `'${companyInfo.aanvraag_persoonlijk_telefoon}'`;
    const packagee = Constants.isEmpty(companyInfo.aanvraag_pakket) ? 'NULL' : companyInfo.aanvraag_pakket;

    await APIEDITLOGIN.Request(`UPDATE Aanvraag`, `SET aanvraag_naam = ${name}, aanvraag_eigenaar = ${owner}, aanvraag_persoonlijk_email = ${mail}, aanvraag_persoonlijk_telefoon = ${phone}, aanvraag_pakket = ${packagee}`, `WHERE aanvraag_id = ${this.state.companyInfo.aanvraag_id}`);

    this.setState({ oldCompanyInfo: companyInfo });

    this.reload();

  }

  editMails(e) {

    let value = e.target.value;

    let trimmed = value;

    // if (trimmed.includes(',') === false) {
    trimmed = value.replaceAll(/[\s,]+/g, ',');
    // }

    this.setState({ mails: trimmed });

  }

  editMailing(e) {

    let value = e.target.value;

    if (value === 'null') {
      value = null;
    } else {
      value = parseInt(value);
    }

    this.setState({ mailing: value });

  }

  editInfo(e, key) {

    let companyInfo = this.state.companyInfo;
    companyInfo[key] = e.target.value;

    this.setState({ companyInfo: companyInfo });

  }

  showError() {

    if(Constants.isEmpty(this.state.errorText) === false) {
      return <div className='errorText' style={{marginTop: '1vw', marginLeft: 0, marginBottom: 0}}>{this.state.errorText}</div>;
    } else {
      return null;
    }

  }

  getServerOptions() {

    let toRender = [];

    for (const server of this.state.servers) {

      toRender.push(
        <option value={server.server_id} selected={this.state.serverID === parseInt(server.server_id)}>{server.server_ip}</option>
      );

    }

    return toRender;

  }

  renderMails() {

    let toRender = [];

    for (const m of this.state.sentMails) {

      if (m.success !== true) {
        toRender.push(
          <div>
            <span>{m.email}</span>
          </div>
        );
      }

    }

    return toRender;

  }

  handleChangeEditor(html, delta, who, editor) {

    this.setState({ editorValueHTML: editor.getHTML(), editorValueDELTA: editor.getContents(), defaultEditorValue: editor.getContents(), editorLength: editor.getLength() });

  }

  showNewsList() {

    let toRender = [];

    for (const news of this.state.tiemdoNews) {

      const title = news.nieuws_titel;

      toRender.push(

        <div className='home2_button_new2' onClick={() => this.selectNews(news)}>

          <div>
            {Constants.isTrue(news.nieuws_pinned) ?
              <div style={{fontSize: '0.8vw', fontWeight: 600 }}><i className='far fa-thumbtack' style={{ transform: "rotate(45deg)", color: Colors.color.ORIGINAL_MAINCOLOR_1 }} /> {title}</div>
            :
              <div style={{fontSize: '0.8vw', fontWeight: 600 }}>{title}</div>
            }
            <div style={{ color: Colors.color.muted, fontSize: '0.6vw' }}>{Constants.stringToDate(news.nieuws_datum).toLocaleDateString()}</div>
          </div>
          <i style={{fontSize: '1.5vw'}} className='fal fa-angle-right' />

        </div>

      );

    }

    return toRender;

  }

  selectNews(news) {

    const deltaObj = JSON.parse(news.nieuws_tekst);

    this.editor.value = deltaObj;
    this.setState({ loading: true, selectedNews: news }, () => {
      this.setState({ defaultEditorValue: deltaObj, week: news.nieuws_titel, pinned: Constants.isTrue(news.nieuws_pinned) }, () => {
        this.setState({ loading: false });
      });
    });

  }

  clearEditor() {

    this.editor.value = {};
    this.setState({ loading: true, selectedNews: null }, () => {
      this.setState({ 
        defaultEditorValue: {},
        editorValueHTML: '',
        editorValueDELTA: {},
        editorLength: 0, 
        week: '',
        pinned: false,
      }, () => {
        this.setState({ 
          loading: false,
         });
      });
    });

  }

  toggleSwitch() {
    this.setState({ pinned: !this.state.pinned });
  }

  render() {

    const errorMails = this.renderMails();

    let editorModules  = {
      toolbar: [
        [{ 'color': [] }, { 'background': [] }],
        [{ 'size': [] }],
        [{ 'header': [] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        [{ 'align': [] }],
        ['link'],
        [{ 'direction': 'rtl' }], 
        ['clean']
      ],
    };

    return (

      <div>

      <Prompt
        when={this.hasChanges()}
        message='You have unsaved changes. Are you sure you want to leave?'
      />

      <Navbar ref={this.navBar} history={this.props.history} active={'news'} fixed loading={this.state.loading} reloading={this.state.reloading} parent={this} />

      <div className='employees_main2'>

        <div className='employees_content2' style={{width: '100%', fontSize: '0.8vw'}}>

          {this.state.loading === true ?
            
            <div className='lds-dual-ring loader' />

          :

            <div className='employees_container'>

              {this.state.reloading === true ?
                          
                <div className='lds-dual-ring loader2' />

              :

                null

              }

              {this.state.showAlert === true ?
                
                <BetterAlert
                  _closeAlert={this.closeAlert.bind(this)}
                  _title={this.state.alertTitle}
                  _body={this.state.alertBody}
                  _buttons={this.state.alertButtons}
                  _buttonText={this.state.alertButtonText}
                  _buttonAction={this.state.alertButtonAction}
                  _buttonAction2={this.state.alertButtonAction2}
                />

              :

                null
              
              }

              <div>
                
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                  <div style={{fontSize: '1.5vw', fontWeight: 'bold'}}>Nieuwsbericht</div>

                  {/* <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                    <div className='planner_new_publishButton' style={{marginLeft: 0, width: '5vw'}} onClick={() => this.saveCompanyAlert()}>Opslaan</div>

                  </div> */}

                  {/* <div className='modal-row_start' style={{marginTop: '1vw'}}>

                      <div className='modal-rowSub1_start'>
                          <span><b>Soort mail:</b></span>
                      </div>

                      <div className='modal-rowSub2_start'>

                        <select 
                            name="Selecteer een pakket" 
                            className={'dropDownField'}
                            onChange={(event) => this.editMailing(event)}
                        >
                            <option value="null" selected={this.state.mailing === null} disabled>Selecteer een mail</option>
                            <option value="0" selected={this.state.mailing === 0}>Onderzoek | Marktonderzoek</option>
                            <option value="1" selected={this.state.mailing === 1}>Marketing | Voordelen</option>
                            <option value="2" selected={this.state.mailing === 2}>Marketing | Powerpoint</option>
                        </select>

                      </div>

                  </div> */}

                  <div className='modal-row_start' style={{marginTop: '1vw'}}>

                      <div className='modal-rowSub1_start'>
                          <span><b>Titel:</b></span>
                      </div>

                      <div className='modal-rowSub2_start'>

                        <input
                          className={'inputField2'}
                          onChange={(event) => this.setState({ week: event.target.value })}
                          value={this.state.week}
                          placeholder={'Nieuwstitel'}
                        />

                      </div>

                  </div>

                  <div className='modal-row_start' style={{marginTop: '1vw'}}>

                      <div className='modal-rowSub1_start'>
                          <span><b>Tekst:</b></span>
                          {/* <span style={{fontStyle: 'italic', fontSize: '0.6vw'}}>E-mails scheiden d.m.v. een komma ( , )</span> */}
                      </div>

                      <div className='modal-rowSub2_start'>

                        <ReactQuill
                          ref={(ref) => this.editor=ref}
                          defaultValue={this.state.defaultEditorValue}
                          onChange={this.handleChangeEditor} 
                          modules={editorModules}
                          // readOnly={!this.state.editorOn}
                        />

                      </div>

                  </div>

                  <div className='modal-row_start' style={{marginTop: '1vw'}}>

                      <div className='modal-rowSub1_start'>
                        <span><b>Vastmaken:</b></span>
                      </div>

                      <div className='modal-rowSub2_start'>

                        <label className="switch">
                            <input 
                              type="checkbox" 
                              checked={this.state.pinned} 
                              onClick={() => this.toggleSwitch()}
                              readOnly
                            />
                            <span className="slider round"></span>
                        </label>

                      </div>

                  </div>

                  {Constants.isEmpty(this.state.selectedNews) === true ?
                  
                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div className='planner_new_publishButton' style={{marginLeft: 0, width: '6.5vw'}} onClick={() => this.acceptCompanyAlert()}>Versturen</div>
                    
                    </div>

                  :

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '2vw'}}>

                      <div className='planner_new_publishButton' style={{marginLeft: 0, width: '6vw'}} onClick={() => this.editNewsAlert()}>Wijzigen</div>

                      <div className='planner_new_dangerButton' style={{width: '7vw'}} onClick={() => this.deleteNewsAlert()}>Verwijderen</div>
                    
                    </div>

                  }


                  {/* <span style={{ fontSize: '0.6vw', fontStyle: 'italic', color: Colors.color.redFilledIn }}>Je kunt maximaal 5000 mails per dag sturen</span> */}

                  {this.showError()}

                  <div style={{ marginTop: '2vw', display: 'flex', flexDirection: 'column' }}>

                    <span style={{ fontSize: '0.8vw', fontWeight: 700 }}>Verzonden nieuwsberichten</span>

                    <div className="planner_addButtons_container" style={{marginLeft: 0, marginTop: '1vw'}}>

                      <div className='planner_new_addButton' style={{marginLeft: 0, width: '8vw'}} onClick={() => this.clearEditor()}>Nieuw bericht</div>

                    </div>

                    {this.showNewsList()}
                                        
                  </div>

                </div>

              </div>

            </div>

          }

        </div>

      </div>

    </div>
    );
  }
  
}

export default MailingNews;