import React from 'react';
import './navbar.css';
import '../../constants/DefaultStyle.css';

import Data from '../../constants/Data';
import BetterAlert from '../alert/alert';
import APIGetAllAccounts from '../../classes/APIGetAllAccounts';
import APIGetAllRegistrationCodes from '../../classes/APIGetAllRegistrationCodes';
import APIGetHolidayCount from '../../classes/APIGetHolidayCount';
import APIGetLocations from '../../classes/APIGetLocations';
import packageJson from '../../../package.json';
import Constants from '../../constants/Constants';
import BetterModal from '../modal/modal';
import TeamsModal from '../teamsModal/teamsModal';
import APIGetTeamPlanner from '../../classes/APIGetTeamPlanner';
import APIGetTeamUsers from '../../classes/APIGetTeamUsers';
import APIGetAvailableShiftsAdmin from '../../classes/APIGetAvailableShiftsAdmin';
import APIGET from '../../classes/global/APIGET';
import ChangeAccountModal from '../changeAccountModal/changeAccountModal';
import IdleTimer from 'react-idle-timer';
import Colors from '../../constants/Colors';
import APIGetAllContracts from '../../classes/APIGetAllContracts';
import RegistrationCodeSendModal from '../registrationCodeSendModal/registrationCodeSendModal';
import APIGETLOGIN from '../../classes/login/APIGETLOGIN';

class Navbar extends React.Component {

  constructor(props) {

    super(props);

    this.props = props;

    if(Data.data.loggedIn === false) {
      this.props.history.push("/");
    }

    this.teamPlanner = [];
    
    this.state = {

      sideMenuActive: false,

      active: '',
      menuActive: this.props.menuActive,

      showAlert: false,
      alertTitle: '',
      alertBody: '',
      alertButtons: 0,
      alertButtonText: [],
      alertButtonAction: null,
      alertCanClose: true,

      navMenuActive: false,
      navMenuActive2: false,

      holidaysCount: 0,
      tradeRequestsCount: 0,
      otherTradesCount: 0,
      replaceCount: 0,
      availabilityRequestsCount: 0,

      whatsNewNotification: false,

      subMenu: '',

      newNotifications: false,

      timeNow: new Date(),

      adminBadge: false,

      showTeamsModal: false,

      showQuestionModal: false,
      tutorialModal: false,

      userNotification: false,
      contractEnding: false,
      requestNotification: false,

      isChrome: (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) || typeof InstallTrigger !== 'undefined' || navigator.userAgent.indexOf("Chrome") !== -1,

      chromeBannerDismissed: Constants.isEmpty(localStorage.getItem('@chromeBannerDismissed')) === true ? false : localStorage.getItem('@chromeBannerDismissed') === '1',

      showChangeAccModal: false,
      showRegistrationModal: false,

      demoBadge: false,

      activeBadge: 0,
      trialsBadge: 0,
      leadsBadge: 0,
      archiveBadge: 0,

    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.idleTimer = null
    // this.handleOnAction = this.handleOnAction.bind(this);
    // this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);

    //componentWillMount here:

  }

  async componentDidMount() {

    document.addEventListener('mousedown', this.handleClickOutside);

    // check notifications

    // check whats new versions
    this.checkNewDemo();

    const [
      companies,
      leads,
      archives,
    ] = await Promise.all([
      APIGETLOGIN.Request(`SELECT *`, `FROM Bedrijf`, null, `WHERE bedrijf_archief <> 1`, null, null),
      APIGETLOGIN.Request(`SELECT *`, `FROM Aanvraag`, null, null, null, null),
      APIGETLOGIN.Request(`SELECT *`, `FROM Bedrijf`, null, `WHERE bedrijf_archief = 1`, null, null),
    ]);

    let active = 0;
    let trials = 0;

    for (const c of companies) {

      if (c.bedrijf_naam.toLowerCase().startsWith('demo')) {
        continue;
      }

      if (Constants.isEmpty(c.bedrijf_proef_datum) === false) {
        trials += 1;
      } else {
        active += 1;
      }

    }

    this.setState({
      activeBadge: active,
      trialsBadge: trials,
      leadsBadge: leads.length,
      archiveBadge: archives.length,
    })

    // this.interval = setInterval(() => {
    //     this.searchNotifications();
    //     //this.checkWhatsNew();
    // }, 5000);

  }

  componentWillUnmount() {
    //clearInterval(this.interval);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

   /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.state.active === 'active') {
      this.setState({active: ''});
    }
  }

  // handleOnAction (event) {
  //   console.log('user did something', event)
  // }

  // handleOnActive (event) {
  //   console.log('user is active', event)
  //   console.log('time remaining', this.idleTimer.getRemainingTime())
  // }

  handleOnIdle (event) {
    this.setState({
      alertTitle: 'Inactief',
      alertBody: (<div>
        <span>Je bent langer dan <b>15 minuten</b> inactief geweest. Om ervoor te zorgen dat verzoeken, verlof en beschikbaarheid van medewerkers up-to-date zijn, dien je dit venster te verversen.</span><br/><br/>
        <span>Klik op de knop <b>Verversen</b> om Tiempo opnieuw te laden</span>
      </div>),
      alertButtons: 1,
      alertButtonText: ['Verversen'],
      // alertButtons: 2,
      // alertButtonText: ['Terug', 'Upgrade'],
      alertButtonAction: () => window.location.reload(true),
      showAlert: true,
      alertCanClose: false,
    });
  }

  async checkNewDemo() {

    const requests = await APIGETLOGIN.Request(`SELECT *`, `FROM Aanvraag`, null, null, null, null);

    if (requests.length > 0) {
      this.setState({ demoBadge: true });
    }

  }

  checkWhatsNew() {

    if(Data.data.accountData.account_rights !== '2') {
      return;
    }

    const currentWhatsNew = Data.data.whatsNewVersion;
    const userWhatsNew = localStorage.getItem('@whatsNewVersion');

    this.setState({ whatsNewNotification: currentWhatsNew !== userWhatsNew });

  }

  applyWhatsNewVersion() {

    const currentWhatsNew = Data.data.whatsNewVersion;

    localStorage.setItem('@whatsNewVersion', currentWhatsNew);

    const userWhatsNew = localStorage.getItem('@whatsNewVersion');

    this.setState({ whatsNewNotification: currentWhatsNew !== userWhatsNew });

  }

  async getReplaceCount() {

    let count = await APIGetAvailableShiftsAdmin.Request();

    if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

        this.teamPlanner2 = [];

        for (const trade of count) {

            const obj = await APIGetTeamPlanner.Request(trade.rooster_datum);

            for (const teampl of obj) {
                this.teamPlanner2 = [...this.teamPlanner2, teampl];
            }

        }

        const oldTrades = count;
        count = [];

        for (const team of Data.data.ownTeams) {

            const arr = Constants.getTeamPlannerT(this.teamPlanner2, oldTrades, parseInt(team.team_id));

            for (const teampl of arr) {
                count = [...count, teampl];
            }

        }

    }

    const availablesPlanner = await APIGET.Request(`SELECT sbg_shb_id, shb_team_id`, `FROM SBGeaccepteerd`, `LEFT JOIN ShiftBeschikbaar ON shb_id = sbg_shb_id`, `WHERE shb_datum >= '${this.state.timeNow.toISOString().substr(0, 10)}'`, null, null);
    const availablesPlannerObj = Constants.convertToObjectWithArrays(availablesPlanner, 'sbg_shb_id');
    let count2 = 0;

    if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {
      for (const team of Data.data.ownTeams) {
        for (const key in availablesPlannerObj) {
          if (availablesPlannerObj[key][0].shb_team_id === team.team_id) {
            count2++;
          }
        }
      }
    } else {
      count2 = Object.keys(availablesPlannerObj).length;
    }

    this.setState({replaceCount: count.length + count2});

    return count.length + count2;

  }

  async searchNotifications() {

    if(typeof Data.data.accountData.account_id !== 'undefined') {

        let otherTradesCount = 0;
        let tradeRequestsCount = 0;
        let holidaysCount = 0;
        let replaceCount = 0;
        const availabilityRequestsCount = (await APIGET.Request(`SELECT sbw_id`, `FROM StandaardBeschikbaarheidWijziging`, null, null, null, null)).length;

        this.setState({ availabilityRequestsCount: availabilityRequestsCount});

        if (Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1') {
          replaceCount = await this.getReplaceCount();
        }

        let notificationsReceived = parseInt(localStorage.getItem('@notificationsReceived'));
        let notificationsChecked = localStorage.getItem('@notificationsChecked');

        const otherTrades = await APIGET.Request(
          `SELECT rs_id, rs_geaccepteerd, rs_info_id_from, rs_info_id_to, rs_rooster_id_to, rs_rooster_id_from, user1.info_voornaam AS v1, user2.info_voornaam AS v2, plan1.rooster_id AS pi1, plan1.rooster_datum AS pd1, plan1.rooster_begin AS pb1, plan1.rooster_eind AS pe1, f1.functie_naam AS fn1, plan2.rooster_id AS pi2, plan2.rooster_datum AS pd2, plan2.rooster_begin AS pb2, plan2.rooster_eind AS pe2, f2.functie_naam AS fn2`,
          `FROM Ruilshift`,
          `LEFT JOIN Info AS user1 ON user1.info_id = rs_info_id_from` +
          ` LEFT JOIN Info AS user2 ON user2.info_id = rs_info_id_to` +
          ` LEFT JOIN Rooster AS plan1 ON plan1.rooster_id = rs_rooster_id_from` +
          ` LEFT JOIN Rooster AS plan2 ON plan2.rooster_id = rs_rooster_id_to` +
          ` LEFT JOIN Functie AS f1 ON f1.functie_id = plan1.rooster_functie_id` +
          ` LEFT JOIN Functie AS f2 ON f2.functie_id = plan2.rooster_functie_id`,
          `WHERE rs_info_id_to = ${Data.data.accountData.info_id} AND plan2.rooster_datum BETWEEN (NOW() - INTERVAL 1 MONTH) AND (NOW() + INTERVAL 1 YEAR)`,
          null,
          `ORDER BY rs_geaccepteerd, plan2.rooster_datum, plan2.rooster_begin, plan2.rooster_eind`
        );

        let indexOtherTrades = 0;
        if(otherTrades !== null && otherTrades !== 'error') {
            
          for (const trade of otherTrades) {

            if(trade.rs_geaccepteerd === '0') {
              indexOtherTrades++;
            }

          }

          otherTradesCount = indexOtherTrades;
          this.setState({otherTradesCount: indexOtherTrades});
        }

        if(Data.data.accountData.account_rights === '1' || Data.data.accountData.account_rights === '2') {

            let tradeRequests = await APIGET.Request(
              `SELECT rs_id, rs_geaccepteerd, rs_info_id_from, rs_info_id_to, rs_rooster_id_to, rs_rooster_id_from, user1.info_voornaam AS v1, user2.info_voornaam AS v2, plan1.rooster_id AS pi1, plan1.rooster_datum AS pd1, plan1.rooster_begin AS pb1, plan1.rooster_eind AS pe1, f1.functie_naam AS fn1, plan2.rooster_id AS pi2, plan2.rooster_datum AS pd2, plan2.rooster_begin AS pb2, plan2.rooster_eind AS pe2, f2.functie_naam AS fn2`,
              `FROM Ruilshift`,
              `LEFT JOIN Info AS user1 ON user1.info_id = rs_info_id_from` +
              ` LEFT JOIN Info AS user2 ON user2.info_id = rs_info_id_to` +
              ` LEFT JOIN Rooster AS plan1 ON plan1.rooster_id = rs_rooster_id_from` +
              ` LEFT JOIN Rooster AS plan2 ON plan2.rooster_id = rs_rooster_id_to` +
              ` LEFT JOIN Functie AS f1 ON f1.functie_id = plan1.rooster_functie_id` +
              ` LEFT JOIN Functie AS f2 ON f2.functie_id = plan2.rooster_functie_id`,
              `WHERE (rs_geaccepteerd = 1 OR rs_geaccepteerd = 2 OR rs_geaccepteerd = 4) AND plan1.rooster_datum BETWEEN (NOW() - INTERVAL 1 MONTH) AND (NOW() + INTERVAL 1 YEAR)`,
              null,
              `ORDER BY rs_geaccepteerd, plan1.rooster_datum, plan1.rooster_begin, plan1.rooster_eind`
            );

            let indexTrades = 0;
            if(tradeRequests !== null && tradeRequests !== 'error') {

                if (Data.data.teams.length > 1 && Data.data.ownTeams.length > 0) {

                  this.teamPlanner = [];
      
                  for (const trade of tradeRequests) {
      
                      const obj = await APIGetTeamPlanner.Request(trade.pd1);
      
                      for (const teampl of obj) {
                          this.teamPlanner = [...this.teamPlanner, teampl];
                      }

                      if (trade.pd1 !== trade.pd2) {

                        const obj2 = await APIGetTeamPlanner.Request(trade.pd2);
  
                        for (const teampl of obj2) {
                            this.teamPlanner = [...this.teamPlanner, teampl];
                        }
  
                      }
      
                  }
      
                  const oldTrades = tradeRequests;
                  tradeRequests = [];
      
                  for (const team of Data.data.ownTeams) {
      
                      const arr = Constants.getTeamPlannerT2(this.teamPlanner, oldTrades, parseInt(team.team_id));
      
                      for (const teampl of arr) {
                        tradeRequests = [...tradeRequests, teampl];
                      }
      
                  }
      
                }

                for (const trade of tradeRequests) {

                    if(trade.rs_geaccepteerd === '1') {
                        indexTrades++;
                    }

                }

                tradeRequestsCount = indexTrades;
                this.setState({tradeRequestsCount: indexTrades});

            }

        }

        if(Data.data.accountData.account_rights === '2') {

            const holidayRequests = await APIGetHolidayCount.Request();

            if(holidayRequests !== null && holidayRequests !== 'error') {

                holidaysCount = parseInt(holidayRequests);
                this.setState({holidaysCount: holidaysCount});

            }

        }

        const totalCount = otherTradesCount + tradeRequestsCount + holidaysCount + replaceCount + availabilityRequestsCount;

        if(totalCount > 0 && totalCount !== notificationsReceived && notificationsChecked === 'true') {
          localStorage.setItem('@notificationsChecked', 'false');
          notificationsChecked = 'false';
        }

        if(totalCount > 0 && totalCount !== notificationsReceived) {
          localStorage.setItem('@notificationsReceived', totalCount.toString());
          notificationsReceived = totalCount;
        }

        if(notificationsChecked === 'true' && totalCount === notificationsReceived) {
          this.setState({
            newNotifications: false,
          });
        }

        if(totalCount > 0 && notificationsChecked !== 'true') {

          localStorage.setItem('@notificationsReceived', totalCount.toString());

            this.setState({
                newNotifications: true,
                requestNotification: true,
            });
            
        }

        if (Data.data.accountData.account_rights === '2') {

          const people = await APIGetAllAccounts.Request();
          const contracts = await APIGET.Request(`SELECT *`, `FROM ContractN`, `LEFT JOIN Info ON contract_info_id = info_id LEFT JOIN Functie ON info_functie_id = functie_id`, `WHERE contract_id >= 0`, null, `ORDER BY contract_begin DESC, contract_eind DESC`);
          const contractsObj = Constants.convertToObjectWithArrays(contracts, 'contract_info_id');

          let contractEnding = false;
          for (const _acc of people) {
            const contract = Constants.getCurrentContract(contractsObj[_acc.info_id], new Date());
            if (contract !== null && Constants.isEmpty(contract.contract_eind) === false && (Constants.stringToDate(contract.contract_eind) - new Date() <= 5259600000)) {

              let _contract_date_check = Constants.stringToDate(contract.contract_eind);
              _contract_date_check = _contract_date_check.setDate(_contract_date_check.getDate() + 7); // better check for contract higher than current end date
              const contractAfter = Constants.getCurrentContract(contractsObj[_acc.info_id], new Date(_contract_date_check));
              if (contractAfter !== null) {
                continue;
              }

              contractEnding = true;
              break;
            }
          }

          if (contractEnding === true) {
            this.setState({
                contractEnding: true,
            });
          }

          if (contractEnding === false && Data.data.teams.length > 1 && Data.data.chosenTeam !== null) {

            const teamUsers = await APIGetTeamUsers.Request();

            const missingTeamPeople = Constants.getMissingPeopleTeam(people, teamUsers);

            if (missingTeamPeople.length > 0) {
              this.setState({
                  userNotification: true,
              });
            } else {
              this.setState({
                userNotification: false,
              });
            }

          }

          if (Constants.isTrue(Data.data.appOptions.opties_verlofsaldo)) {

            const leaveBalances = await APIGET.Request(`SELECT *`, `FROM VerlofBalans`, null, null, null, null);
            const leaveTypes = await APIGET.Request(`SELECT *`, `FROM VerlofType`, null, null, null, null);
            const leaveTypeBalances = await APIGET.Request(`SELECT *`, `FROM VerlofTypeBalans`, `LEFT JOIN VerlofBalans ON vtb_vb_id = vb_id LEFT JOIN VerlofType ON vtb_vt_id = vt_id`, null, null, null);

            let correctTypes = true;
            for (const lt of leaveTypes) {
              if (Constants.objectArrayContainsKey(leaveTypeBalances, 'vtb_vt_id', lt.vt_id) === false) {
                correctTypes = false;
                break;
              }
            }

            if (leaveBalances.length === 0 || leaveTypes.length === 0 || correctTypes === false) {
              this.setState({ adminBadge: true });
              return;
            }

          }

          if (Data.data.appOptions.clock === true && Constants.isTrue(Data.data.appOptions.opties_klokMobiel)) {

              const locations = await APIGetLocations.Request();

              if (locations === null) {
                  this.setState({
                      adminBadge: true,
                      newNotifications: true,
                  });
              } else {
                  this.setState({
                      adminBadge: false,
                  });
              }
          } else {
              this.setState({
                  adminBadge: false,
              });
          }

        }
        
    }

  }

  async allowRegistration() {

    let count = 0;

    const employeesObject = await APIGetAllAccounts.Request();

    if(employeesObject !== null) {
        count += employeesObject.length;
    }

    const registrationCodesObject = await APIGetAllRegistrationCodes.Request();

    if(registrationCodesObject !== null) {
        count += registrationCodesObject.length;
    }

    if(count >= (Data.data.maxEployees + 1)) {
        this.setState({
          alertTitle: 'Mislukt',
          alertBody: (<span>Je hebt het maximale aantal registratiecodes en medewerkers van <b>{Data.data.maxEployees}</b> bereikt</span>),
          alertButtons: 10,
          alertButtonText: ['OK'],
          // alertButtonText: ['Terug', 'Upgrade'],
          // alertButtonAction: () => this.openUpgradeMenu(),
          showAlert: true,
        });
        return false;
    }

    return true;

  } 

  async openRegistrationCodeModal() {

    if(this.props.loading === true || this.props.reloading === true) {
      return;
    }   

    if(await this.allowRegistration() === false) {
      this.setState({ navMenuActive: false });
      return;
    } 

    this.setState({ navMenuActive: false, navMenuActive2: false, showRegistrationModal: true });

  }

  async navigate(route) {

    // if(this.props.loading === true || this.props.reloading === true) {
    //   return;
    // }

    if (this.props.active === route) {
      this.props.history.push(`/blank`);
      setTimeout(() => {
        this.props.history.push(`/${route}`);
      }, 1);
      return;
    }

    if (Data.data.canUseNav === false) {
      // this.setState({
      //   alertTitle: 'Oeps!',
      //   alertBody: `Maak eerst de rondleiding af`,
      //   alertButtons: 1,
      //   alertButtonText: ['OK'],
      //   showAlert: true,
      // });
      return;
    }

    if((route === 'register_user' || route === 'registration_code') && await this.allowRegistration() === false) {
      this.setState({ navMenuActive: false, navMenuActive2: false, });
      return;
    }

    if(this.state.active === 'active') {
      this.setState({ active: '' });
    }

    this.props.history.push(`/${route}`);

  }

  logoutAlert() {
    this.setState({
        alertTitle: 'Uitloggen',
        alertBody: `Weet je zeker dat je wilt uitloggen?`,
        alertButtons: 2,
        alertButtonAction: this.logout.bind(this),
        alertButtonText: ['Annuleren', 'Uitloggen'],
        showAlert: true,
    });
  }

  closeAlert() {

    this.setState({ showAlert: false, alertCanClose: true, });

  }

  logout() {

    Data.data.accountData = [];

    localStorage.setItem('@admin_username', '');
    localStorage.setItem('@admin_password', '');

    this.props.history.push("/");

  }

  getAccountPacket() {

    const max = Data.data.maxEployees;

    if (Data.data.trial instanceof Date && max === 250) {
      return 'proefpakket';
    } else if (max === 250) {
      return 'maximaal';
    } else if (max === 10) {
      return '1-10 medewerkers';
    } else if (max === 20) {
      return '11-20 medewerkers';
    } else if (max === 30) {
      return '21-30 medewerkers';
    } else if (max === 40) {
      return '31-40 medewerkers';
    } else if (max === 50) {
      return '41-50 medewerkers';
    } else if (max > 50) {
      return 'plus pakket';
    }

  }

  renderTrialData() {
    if(Data.data.trial !== null) {
      const timeNow = this.state.timeNow;

      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = timeNow;
      const secondDate = Data.data.trial;

      const diffDays = Math.abs((secondDate - firstDate) / oneDay);

      if (isNaN(diffDays) === true) {
        return(
          <div style={{marginTop: 8, fontSize: 14}}>
            <i>proeftijd is verlopen</i>
          </div>
        );
      }

      return(
        <div style={{marginTop: 8, fontSize: 14}}>
          <i>Proeftijd verloopt over: {(diffDays) >= 2 ? Math.trunc(diffDays) + ' dagen' : (diffDays) < 1 ? Math.trunc((diffDays) * 24) + ' uren' : Math.trunc(diffDays) + ' dag'}</i>
        </div>
      );
    } else {
      return(null);
    }
  }

  openRegistrationMenu() {

    this.setState({
      active: 'active',
      navMenuActive: true,
      navMenuActive2: true,
      subMenu: 'REGISTRATIE',
    });

  }

  openUpgradeMenu() {

    this.setState({
      active: 'active',
      navMenuActive: true,
      navMenuActive2: true,
      subMenu: 'UPGRADE',
    });

  }

  openMenu() {

    this.setState({ tutorialModal: true });

    this.openQuestionModal();

    this.showWholeMenu();

    this.intervalMenu = setInterval(() => {

      this.showWholeMenu();
      
    }, 20000);

  }

  closeMenu() {

    this.setState({
      active: '',
      navMenuActive: false,
      navMenuActive2: false,
      subMenu: '',
    });

  }

  showWholeMenu() {

    this.setState({
      active: 'active',
      navMenuActive: true,
      navMenuActive2: false,
      subMenu: '',
    });

    setTimeout(() => {

      this.setState({
        active: 'active',
        navMenuActive: true,
        navMenuActive2: true,
        subMenu: 'MANAGEMENT',
      });
      
    }, 2500);

    setTimeout(() => {

      this.setState({
        active: 'active',
        navMenuActive: true,
        navMenuActive2: false,
        subMenu: '',
      });
      
    }, 5000);

    setTimeout(() => {

      this.setState({
        active: 'active',
        navMenuActive: true,
        navMenuActive2: true,
        subMenu: 'REGISTRATIE',
      });
      
    }, 7500);

    setTimeout(() => {

      this.setState({
        active: 'active',
        navMenuActive: true,
        navMenuActive2: false,
        subMenu: '',
      });
      
    }, 10000);

    setTimeout(() => {

      this.setState({
        active: 'active',
        navMenuActive: true,
        navMenuActive2: true,
        subMenu: 'MIJN ACCOUNT',
      });
      
    }, 12500);

    setTimeout(() => {

      this.setState({
        active: 'active',
        navMenuActive: true,
        navMenuActive2: false,
        subMenu: '',
      });
      
    }, 15000);

    setTimeout(() => {

      this.setState({
        active: 'active',
        navMenuActive: true,
        navMenuActive2: true,
        subMenu: 'INSTELLINGEN',
      });
      
    }, 17500);

  }

  openQuestionModal() {

    this.setState({ showQuestionModal: true });

  }

  closeQuestionModal() {

    this.setState({ showQuestionModal: false });

  }

  openTeamsModal() {

    this.setState({ showTeamsModal: true });

  }

  closeTeamsModal() {

    this.setState({ showTeamsModal: false });

  }

  getTeamData() {

    let toReturn = [];

    for (const team of Data.data.ownTeams) {

        toReturn.push(
          <div style={{marginLeft: 20, marginBottom: 8, color: '#2d3748', fontSize: 14}}>{team.team_naam}</div>
        );

    }

    return toReturn;
    
  }

  navigateImport() {

    if (this.props.history.location.pathname === '/employees') {

      this.props.history.push('/home');

      setTimeout(() => {
        this.props.history.push({pathname: '/employees'}); 
      }, 1);

    } else {

      this.props.history.push({pathname: '/employees'}); 

    }

  }

  navigateMyAcc() {

    if (this.props.history.location.pathname === '/employees') {

      this.props.history.push('/home');

      setTimeout(() => {
        this.props.history.push({pathname: '/employees', selected: Data.data.accountData.info_id, selectedName: Data.data.accountData.info_voornaam, menu: 'info'}); 
      }, 1);

    } else {

      this.props.history.push({pathname: '/employees', selected: Data.data.accountData.info_id, selectedName: Data.data.accountData.info_voornaam, menu: 'info'}); 

    }

    localStorage.setItem('@notificationsChecked', 'true');

  }

  closeChromeBanner() {

    localStorage.setItem('@chromeBannerDismissed', '1');

    this.setState({chromeBannerDismissed: true});

  }

  openChangeAccModal() {
    this.setState({ showChangeAccModal: true });
  }

  closeChangeAccModal() {
    this.setState({ showChangeAccModal: false });
  }

  closeModal() {
    this.setState({ showRegistrationModal: false });
  }

  render() {

    const userFullName = `${Data.data.accountData.info_voornaam} ${(Data.data.accountData.info_tussenvoegsel !== null && Data.data.accountData.info_tussenvoegsel !== '') ? Data.data.accountData.info_tussenvoegsel + " " + Data.data.accountData.info_achternaam : Data.data.accountData.info_achternaam}`; //.charAt(0)

    let greeting = 'Goedendag';

    const currentHour = new Date().getHours();

    if(currentHour >= 0 && currentHour < 12) {
        greeting = 'Goedemorgen';
    }
    if(currentHour >= 12 && currentHour < 18) {
        greeting = 'Goedemiddag';
    }
    if(currentHour >= 18 && currentHour <= 23) {
        greeting = 'Goedenavond';
    }

    return (
      <div>

        {/* <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 15} // 1000 * 60 * 15 = 15 mins
          //onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          //onAction={this.handleOnAction}
          debounce={250}
        /> */}

        {this.state.showChangeAccModal ?
          <ChangeAccountModal 
            history={this.props.history} 
            _closeModal={this.closeChangeAccModal.bind(this)} 
          />
        : null}

        {this.state.showRegistrationModal ?
          <RegistrationCodeSendModal 
            history={this.props.history} 
            _closeModal={this.closeModal.bind(this)} 
          />
        : null}

        <div className={`navbar_menuBackground2 ${this.state.navMenuActive2 === true ? 'active' : ''}`} id="menuBackground2" onClick={(e) => e.target === document.getElementById("menuBackground2") ? this.setState({navMenuActive2: false}) : null}>

        <div id={'navbar_menu2'} className={`${this.state.navMenuActive2 === true ? 'active' : ''}`}>

          <i className={'fas fa-arrow-alt-left navbar_menuClose2'} onClick={() => this.setState({navMenuActive2: !this.state.navMenuActive2})}></i>

          <div className={'navbar_menuContainer2'}>

            <div className='navbar_menuHeader'>

              <div style={{fontSize: 20, color: '#47B7EB', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontWeight: 'bold'}}>
                <i className={this.state.subMenu === 'MIJN BEDRIJF' ? "far fa-building" : this.state.subMenu === 'MANAGEMENT' ? "far fa-wrench" : this.state.subMenu === 'VERZOEKEN' ? "far fa-hands-helping" : this.state.subMenu === 'REGISTRATIE' ? 'far fa-user-plus' : this.state.subMenu === 'MIJN ACCOUNT' ? 'far fa-user-cog' : this.state.subMenu === 'SUPPORT' ? 'far fa-user-headset' : this.state.subMenu === 'UPGRADE' ? 'far fa-shopping-basket' : 'fal fa-cog'} style={{marginRight: 8}}></i>
                {this.state.subMenu.charAt(0) + this.state.subMenu.substring(1).toLowerCase()}
              </div>

            </div>

            {this.state.subMenu === 'MIJN BEDRIJF' ?

              <div>

                {/* {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'work_week' ? "active2" : ''}`} onClick={() => this.navigate('work_week')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-car-building drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Werkdagen
                    </div>
                  </div>
                :
                  null            
                } */}
                {/* {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'salaries' ? "active2" : ''}`} onClick={() => this.navigate('salaries')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-coins drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Uurlonen
                    </div>
                  </div>
                :
                  null            
                } */}
                {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'sections' ? "active2" : ''}`} onClick={() => this.navigate('sections')}>
                    <div className='navbar_menuButton2_sub1'>
                      <i className="fal fa-network-wired drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                      Afdelingen
                    </div>
                  </div>
                : null}
                {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'functions' ? "active2" : ''}`} onClick={() => this.navigate('functions')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-briefcase drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Functies
                    </div>
                  </div>
                : null}
                <div className={`navbar_menuButton2 ${this.props.active === 'shifts' ? "active2" : ''}`} onClick={() => this.navigate('shifts')}>
                  <div className='navbar_menuButton2_sub1'>
                  <i className="fal fa-calendar-week drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                      Diensten
                    </div>
                </div>
                <div className={`navbar_menuButton2 ${this.props.active === 'times' ? "active2" : ''}`} onClick={() => this.navigate('times')}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-clock drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Standaard tijden
                    </div>
                </div>
                <div className={`navbar_menuButton2 ${this.props.active === 'breaks' ? "active2" : ''}`} onClick={() => this.navigate('breaks')}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-coffee drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Pauzes
                    </div>
                </div>

              </div>

            :
            this.state.subMenu === 'MANAGEMENT' ?

              <div>

                 {Data.data.appOptions.availability !== 'STANDARD' && Data.data.appOptions.availability !== 'NONE' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'deadlines' ? "active2" : ''}`} onClick={() => this.navigate('deadlines')}>
                  <div className='navbar_menuButton2_sub1'>
                  <i className="fal fa-stopwatch drpdwnicon"></i>
                      </div>
                      <div className='navbar_menuButton2_sub2'>
                      Deadlines
                      </div>
                  </div>
                : null}
                {Data.data.appOptions.availability !== 'STANDARD' && Data.data.appOptions.availability !== 'NONE' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'blocked_days' ? "active2" : ''}`} onClick={() => this.navigate('blocked_days')}>
                  <div className='navbar_menuButton2_sub1'>
                  <i className="fal fa-calendar-times drpdwnicon"></i>
                      </div>
                      <div className='navbar_menuButton2_sub2'>
                      Geblokkeerde datums
                      </div>
                  </div>
                : null}
                {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'particularities' ? "active2" : ''}`} onClick={() => this.navigate('particularities')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-calendar-star drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Bijzonderheidstypes
                    </div>
                  </div>
                : null}
                {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'absencetypes' ? "active2" : ''}`} onClick={() => this.navigate('absencetypes')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-user-minus drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Afwezigheidstypes
                    </div>
                  </div>
                : null}
                {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'skills' ? "active2" : ''}`} onClick={() => this.navigate('skills')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-user-graduate drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Competenties
                    </div>
                  </div>
                : null}
                {Data.data.accountData.account_rights === '2' && (Data.data.appOptions.availability === 'AVAILABILITY' || Data.data.appOptions.availability === 'UNAVAILABILITY' || Data.data.appOptions.availability === 'STANDARD') ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'availabilities' ? "active2" : ''}`} onClick={() => this.navigate('availabilities')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-user-check drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Beschikbaarheid medewerkers
                    </div>
                  </div>
                : null}
                {/* {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'absences' ? "active2" : ''}`} onClick={() => this.navigate('absences')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-user-chart drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Verlofdagen medewerkers
                    </div>
                  </div>
                : null} */}

              </div>

            :
            this.state.subMenu === 'VERZOEKEN' ?

              <div>
            
                {Data.data.accountData.account_rights === '2' && Data.data.appOptions.availability === 'STANDARD' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'standard_availability_requests' ? "active2" : ''}`} onClick={() => this.navigate('standard_availability_requests')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-user-check drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Beschikbaarheid wijzigingen
                    </div>
                    {this.state.availabilityRequestsCount > 0 ?
                      <div className={'navbar_notificationSubMenu'}>
                        {this.state.availabilityRequestsCount}
                      </div>
                    : null}
                  </div>
                : null}
                <div className={`navbar_menuButton2 ${this.props.active === 'trade_requests' ? "active2" : ''}`} onClick={() => this.navigate('trade_requests')}>
                  <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-handshake drpdwnicon"></i>
                  </div>
                  <div className='navbar_menuButton2_sub2'>
                    Ruilverzoeken
                  </div>
                  {this.state.tradeRequestsCount > 0 ?
                    <div className={'navbar_notificationSubMenu'}>
                      {this.state.tradeRequestsCount}
                    </div>
                  : null}
                </div>
                {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'holiday_requests' ? "active2" : ''}`} onClick={() => this.navigate('holiday_requests')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-umbrella-beach drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Verlofverzoeken
                    </div>
                    {this.state.holidaysCount > 0 ?
                      <div className={'navbar_notificationSubMenu'}>
                        {this.state.holidaysCount}
                      </div>
                    : null}
                  </div>
                : null}
                <div className={`navbar_menuButton2 ${this.props.active === 'available_shifts_requests' ? "active2" : ''}`} onClick={() => this.navigate('available_shifts_requests')}>
                  <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-praying-hands drpdwnicon"></i>
                  </div>
                  <div className='navbar_menuButton2_sub2'>
                    Dienstovernames
                  </div>
                  {this.state.replaceCount > 0 ?
                    <div className={'navbar_notificationSubMenu'}>
                      {this.state.replaceCount}
                    </div>
                  : null}
                </div>

              </div>

            :
            this.state.subMenu === 'REGISTRATIE' ?

              <div>
            
                <div className={`navbar_menuButton2`} onClick={() => this.navigateImport()}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-file-import drpdwnicon" style={{fontSize: 18}}></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Medewerkers importeren
                    </div>
                </div>
                <div className={`navbar_menuButton2 ${this.props.active === 'register_user' ? "active2" : ''}`} onClick={() => this.navigate('register_user')}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-user-plus drpdwnicon" style={{fontSize: 18}}></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Medewerker registreren
                    </div>
                </div>
                <div className={`navbar_menuButton2 ${this.props.active === 'registration_code' ? "active2" : ''}`} onClick={() => this.openRegistrationCodeModal()}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-share-alt drpdwnicon" style={{fontSize: 18}}></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Registratiecode versturen
                    </div>
                </div>
                <div className={`navbar_menuButton2 ${this.props.active === 'registration_codes' ? "active2" : ''}`} onClick={() => this.navigate('registration_codes')}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-code drpdwnicon" style={{fontSize: 18}}></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Alle registratiecodes
                    </div>
                </div>

              </div>

            :
            this.state.subMenu === 'MIJN ACCOUNT' ?

              <div>
            
                <div className={`navbar_menuButton2 ${this.props.active === 'account' ? "active2" : ''}`} onClick={() => this.navigate('account')}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-user-circle drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Mijn gegevens
                    </div>
                </div>
                {/* {Constants.isTrue(Data.data.appOptions.opties_urenEigen) ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'my_hours' ? "active2" : ''}`} onClick={() => this.navigate('my_hours')}>
                    <div className='navbar_menuButton2_sub1'>
                      <i className="fal fa-history drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                      Mijn gewerkte uren
                    </div>
                  </div>
                : null}
                <div className={`navbar_menuButton2 ${this.props.active === 'holidays' ? "active2" : ''}`} onClick={() => this.navigate('holidays')}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-umbrella-beach drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Mijn verlof
                    </div>
                </div>
                <div className={`navbar_menuButton2 ${this.props.active === 'trade' ? "active2" : ''}`} onClick={() => this.navigate('trade')}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-handshake drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Ruilen
                    </div>
                  {this.state.otherTradesCount > 0 ?
                    <div className={'navbar_notificationSubMenu'}>
                      {this.state.otherTradesCount}
                    </div>
                  : null}
                </div>
                <div className={`navbar_menuButton2 ${this.props.active === 'all_deadlines' ? "active2" : ''}`} onClick={() => this.navigate('all_deadlines')}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-stopwatch drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Alle deadlines
                    </div>
                </div> */}

                <div className={`navbar_menuButton2 ${this.props.active === 'password' ? "active2" : ''}`} onClick={() => this.navigate('password')}>
                  <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-key drpdwnicon"></i>
                  </div>
                  <div className='navbar_menuButton2_sub2'>
                    Wachtwoord wijzigen
                  </div>
                </div>

              </div>

            :
            this.state.subMenu === 'INSTELLINGEN' ?

              <div>
            
                {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton2 ${this.props.active === 'app_options' ? "active2" : ''}`} onClick={() => this.navigate('app_options')}>
                    <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-sliders-h drpdwnicon"></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Configuratie
                    </div>
                    {this.state.adminBadge === true ?
                      <div className={'navbar_notificationSubMenu2'}>
                        <i className="fas fa-exclamation"></i>
                      </div>
                    : null}
                  </div>
                :
                  null            
                }
                <div className={`navbar_menuButton2 ${this.props.active === 'password' ? "active2" : ''}`} onClick={() => this.navigate('password')}>
                  <div className='navbar_menuButton2_sub1'>
                    <i className="fal fa-key drpdwnicon"></i>
                  </div>
                  <div className='navbar_menuButton2_sub2'>
                    Wachtwoord wijzigen
                  </div>
                </div>

              </div>

            :
            this.state.subMenu === 'SUPPORT' ?

              <div>

                <div className={`navbar_menuButton2`} onClick={() => window.open('mailto:info@kodict.nl')}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-envelope drpdwnicon" style={{fontSize: 18}}></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Mail Kodict
                    </div>
                </div>
                <div className={`navbar_menuButton2`} onClick={() => window.open('https://m.me/kodictnl', '_blank')}>
                <div className='navbar_menuButton2_sub1'>
                <i className="fal fa-comments drpdwnicon" style={{fontSize: 18}}></i>
                    </div>
                    <div className='navbar_menuButton2_sub2'>
                    Chat met Kodict
                    </div>
                </div>

              </div>

            :
            this.state.subMenu === 'UPGRADE' ?

              <div>

                <div style={{marginLeft: 20, marginBottom: 20, marginTop: 5, color: '#2d3748', fontSize: 14}}>Jouw huidig pakket: {this.getAccountPacket()}</div>

                <div className={`navbar_menuButton2`} onClick={() => window.open('https://kodict.nl/site/buy/1', '_blank')}>
                  <i className="fal fa-users drpdwnicon"></i>1-10 medewerkers
                </div>
                <div className={`navbar_menuButton2`} onClick={() => window.open('https://kodict.nl/site/buy/2', '_blank')}>
                  <i className="fal fa-users drpdwnicon"></i>11-20 medewerkers
                </div>
                <div className={`navbar_menuButton2`} onClick={() => window.open('https://kodict.nl/site/buy/3', '_blank')}>
                  <i className="fal fa-users drpdwnicon"></i>21-30 medewerkers
                </div>
                <div className={`navbar_menuButton2`} onClick={() => window.open('https://kodict.nl/site/buy/4', '_blank')}>
                  <i className="fal fa-users drpdwnicon"></i>31-40 medewerkers
                </div>
                <div className={`navbar_menuButton2`} onClick={() => window.open('https://kodict.nl/site/buy/5', '_blank')}>
                  <i className="fal fa-users drpdwnicon"></i>41-50 medewerkers
                </div>
                <div className={`navbar_menuButton2`} onClick={() => window.open('mailto:info@kodict.nl')}>
                  <i className="fal fa-users drpdwnicon"></i>51+ medewerkers
                </div>

              </div>

            :

              null

            }

          </div>

        </div>

        </div>

        {/* SUB */}

        <div className={`navbar_menuBackground ${this.state.navMenuActive === true ? 'active' : ''}`} id="menuBackground" onClick={(e) => e.target === document.getElementById("menuBackground") ? this.setState({navMenuActive: false}) : null}>

          <div id={'navbar_menu'} className={`${this.state.navMenuActive === true ? 'active' : ''}`}>

            {/* <i className={'fas fa-times navbar_menuClose'} onClick={() => this.setState({navMenuActive: !this.state.navMenuActive})}></i>

            <i className={'fas fa-question navbar_menuQuestion'} onClick={() => this.openQuestionModal()}></i> */}

            <div className={'navbar_menuContainer'}>

              <div className='navbar_menuHeader'>

                <div className='navbar_menuAccount'>

                  <div style={{pointerEvents: 'none', width: 200, marginBottom: 30}}>

                    {Data.data.storeLogo !== null ?
                      <img src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${Data.data.storeLogo}`} className={'navbar_menuImg'} alt='logo' />
                    : 
                      <img className={'navbar_menuImg'} src={`https://kodict.nl/site/assets/imgs/tiempokodict_dark.png?v=${Data.data.logo_version}`} alt='logo'/>
                    }

                  </div>
                  
                  <div><span>{greeting}, <b>{userFullName}</b>!</span></div>

                  {Data.data.teams.length > 1 ?
                    Data.data.chosenTeam !== null ?
                      <div style={{marginTop: 5, fontSize: 14}}>{Constants.getTeamDataChosen().name}</div>
                    :
                      this.getTeamData()
                  :
                    <div style={{marginTop: 5, fontSize: 14}}>{Data.data.storeName}</div>
                  }

                  <div>
                    {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
                      this.renderTrialData()
                    : null}
                  </div>

                  {Data.data.multiAccounts.length > 1 ?
                    <div className='navbar_menu_accChange' onClick={() => this.openChangeAccModal()}>
                      <span>Wissel van account</span>
                      <i className='far fa-arrow-right' />
                    </div>
                  : null}

                </div>

              </div>

              <div>
            
                {/* {(Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1') && Data.data.ownTeams.length > 1 ?
                  <div className={`navbar_menuButton`} onClick={() => this.openTeamsModal()}>
                    <div className='navbar_menuButton_sub1'>
                      <i className="fal fa-house-leave drpdwnicon" style={{fontSize: 26}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                      Overschakelen
                    </div>
                  </div>
                : null} */}
                {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ? //{this.setState({navMenuActive2: true, subMenu: 'MIJN BEDRIJF'}); localStorage.setItem('@notificationsChecked', 'true')}
                  <div className={`navbar_menuButton ${this.props.active === 'manage' || this.props.active === 'functions' || this.props.active === 'salaries' || this.props.active === 'work_week' || this.props.active === 'shifts' || this.props.active === 'times' || this.props.active === 'sections' || this.props.active === 'breaks' ? "active2" : ''}`} onClick={() => this.navigate('manage')}>
                    <div className='navbar_menuButton_sub1'>
                    <i className="fal fa-building drpdwnicon" style={{fontSize: 20}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Mijn bedrijf
                    </div>
                  </div>
                : null}
                {/* {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
                  <div className={`navbar_menuButton ${this.props.active === 'deadlines' || this.props.active === 'blocked_days' || this.props.active === 'availabilities' || this.props.active === 'absencetypes' || this.props.active === 'particularities' || this.props.active === 'skills' ? "active2" : ''}`} onClick={() => {this.setState({navMenuActive2: true, subMenu: 'MANAGEMENT'}); localStorage.setItem('@notificationsChecked', 'true')}}>
                    <div className='navbar_menuButton_sub1'>
                    <i className="fal fa-wrench drpdwnicon" style={{fontSize: 26}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Management
                    </div>
                  </div>
                : null} */}
                {/* {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
                  <div className={`navbar_menuButton ${this.props.active === 'trade_requests' || this.props.active === 'holiday_requests' || this.props.active === 'available_shifts_requests' || this.props.active === 'standard_availability_requests' ? "active2" : ''}`} onClick={() => {this.setState({navMenuActive2: true, subMenu: 'VERZOEKEN'}); localStorage.setItem('@notificationsChecked', 'true')}}>
                    {this.state.holidaysCount > 0 || this.state.tradeRequestsCount > 0 || this.state.replaceCount > 0 ?
                      <div className={'navbar_notificationMenu'}/>
                    : null}
                    <div className='navbar_menuButton_sub1'>
                    <i className="fal fa-hands-helping drpdwnicon" style={{fontSize: 26}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Verzoeken
                    </div>
                  </div>
                : null} */}
                {Data.data.accountData.account_rights === '2' || Data.data.accountDataExtra.info2_registreren === '1' ?
                  <div className={`navbar_menuButton ${this.props.active === 'register_user' || this.props.active === 'registration_code' || this.props.active === 'registration_codes' ? "active2" : ''}`} onClick={() => this.setState({navMenuActive2: true, subMenu: 'REGISTRATIE'})}>
                    <div className='navbar_menuButton_sub1'>
                    <i className="fal fa-user-plus drpdwnicon" style={{fontSize: 20}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Registratie
                    </div>
                  </div>
                : null}
                {/* {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton ${this.props.active === 'hour_registration' ? "active" : ''}`} onClick={() => this.navigate('hour_registration')}>
                    <i className="fal fa-user-clock drpdwnicon" style={{fontSize: 26}}></i>Urenregistratie
                  </div>
                : null} */}
                {/* {Data.data.accountData.account_rights !== '2' ?
                  <div className={`navbar_menuButton ${this.props.active === 'accounts' ? "active2" : ''}`} onClick={() => this.navigate('accounts')}>
                    <div className='navbar_menuButton_sub1'>
                    <i className="fal fa-users drpdwnicon" style={{fontSize: 26}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Medewerkers
                    </div>
                  </div>
                : null} */}

                {/* this.setState({navMenuActive2: true, subMenu: 'MIJN ACCOUNT'}); */}
                {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton ${this.props.active === 'account' || this.props.active === 'my_hours' || this.props.active === 'holidays' || this.props.active === 'trade' || this.props.active === 'all_deadlines' ? "active2" : ''}`} onClick={() => this.navigateMyAcc()}>
                    {this.state.newNotifications === true && this.state.otherTradesCount > 0 ?
                      <div className={'navbar_notificationMenu'}/>
                    : null}
                    <div className='navbar_menuButton_sub1'>
                    <i className="fal fa-user-cog drpdwnicon" style={{fontSize: 20}}></i>
                      </div>
                      <div className='navbar_menuButton_sub2'>
                      Mijn account
                      </div>
                  </div>
                : null}
                {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?
                  <div className={`navbar_menuButton ${this.props.active === 'history' ? "active2" : ''}`} onClick={() => this.navigate('history')}>
                    <div className='navbar_menuButton_sub1'>
                    <i className="fal fa-history drpdwnicon" style={{fontSize: 20}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Logboek
                    </div>
                  </div>
                : null}
                {/* <div className={`navbar_menuButton ${this.props.active === 'app_options' || this.props.active === 'password' ? "active2" : ''}`} onClick={() => this.setState({navMenuActive2: true, subMenu: 'INSTELLINGEN'})}>
                  {this.state.adminBadge === true ?
                    <div className={'navbar_notificationMenu'}/>
                  : null}
                  <div className='navbar_menuButton_sub1'>
                  <i className="fal fa-cog drpdwnicon" style={{fontSize: 26}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Instellingen
                    </div>
                </div> */}
                {Data.data.accountData.account_rights === '2' ?
                  <div className={`navbar_menuButton ${this.props.active === 'app_options' ? "active2" : ''}`} onClick={() => {this.navigate('app_options'); localStorage.setItem('@notificationsChecked', 'true')}}>
                    <div className='navbar_menuButton_sub1'>
                    <i className="fal fa-cogs drpdwnicon" style={{fontSize: 20}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Configuratie
                    </div>
                  </div>
                : null}

                {Data.data.accountData.account_rights === '2' ?
                <div>
                  {/* {Data.data.trial !== null && Data.data.trial !== '' ?
                    <div style={{marginTop: '25px', fontSize: '20px', backgroundColor: Colors.color.redFilledIn}} className={`navbar_menuButton`} onClick={() => this.setState({navMenuActive2: true, subMenu: 'UPGRADE'})}>
                      <div className='navbar_menuButton_sub1'>
                      <i className="fal fa-shopping-basket drpdwnicon" style={{fontSize: 21}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Koop een pakket
                    </div>
                    </div>
                  :
                    <div style={{marginTop: '20px', fontSize: '20px'}} className={`navbar_menuButton`} onClick={() => this.setState({navMenuActive2: true, subMenu: 'UPGRADE'})}>
                      <div className='navbar_menuButton_sub1'>
                      <i className="fal fa-shopping-basket drpdwnicon" style={{fontSize: 21}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Upgraden
                    </div>
                    </div>
                  } */}
                  {/* onClick={() => {window.open('https://kodict.nl/site/changelog.html', '_blank'); this.applyWhatsNewVersion();}} */}
                  {/* <div style={{fontSize: '20px'}} className={`navbar_menuButton ${this.props.active === 'whats_new' ? "active2" : ''}`} onClick={() => this.props.history.push('/whats_new')}>

                    <div className='navbar_menuButton_sub1'>
                    <i className="fal fa-newspaper drpdwnicon" style={{fontSize: 21}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Wat is er nieuw?
                    </div>
                  </div> */}
                  <div style={{marginTop: '25px', fontSize: '18px'}} className={`navbar_menuButton`} onClick={() => this.setState({navMenuActive2: true, subMenu: 'SUPPORT'})}>
                  <div className='navbar_menuButton_sub1'>
                  <i className="fal fa-user-headset drpdwnicon" style={{fontSize: 18}}></i>
                    </div>
                    <div className='navbar_menuButton_sub2'>
                    Support
                    </div>
                  </div>
                </div>
                : null}

              </div>

              <div className={'navbar_menuFooter'}>

                <p>Versie {packageJson.version}</p>
                <a href={'https://kodict.nl/site/terms.html'} target={'_blank'}>Algemene voorwaarden</a>
                <a href={'https://kodict.nl/site/privacy.html'} target={'_blank'}>Privacyverklaring</a>
                <p>©2019-{this.state.timeNow.getFullYear()}, Kodict</p>

              </div>

            </div>

          </div>

        </div>

        {this.state.showQuestionModal === true ?
                
          <BetterModal
            _closeModal={this.closeQuestionModal.bind(this)}
            
            _header={this.state.tutorialModal === true ? 'Web-uitleg' : 'Informatie'}
            _general={{
              header: 'Menu',
              content: (
                  <div>Binnen het menu kun je alle extra mogelijkheden vinden. De belangrijkste mogelijkheden worden hieronder uitgelegd.</div>
              ),
            }}
            _alineas={Data.data.accountData.account_rights === '2' ?
                [{
                  header: (<div style={{marginBottom: '-1.5vw', fontSize: '1.2vw'}}><u>Management</u></div>),
                  content: null,
                },
                {
                    header: 'Afdelingen',
                    content: (
                        <div>De planner kun je opsplitsen naar afdelingen. De afdelingen kun je hier aanmaken.</div>
                    ),
                },
                {
                    header: 'Functies',
                    content: (
                        <div>Om medewerkers voor een bepaalde functie in te plannen moet je deze eerst aanmaken. Functies kun je onder het kopje 'Functies' aanmaken, wijzigen of verwijderen.</div>
                    ),
                },
                {
                    header: 'Diensten',
                    content: (
                        <div>Standaard diensten zijn bedoeld om de tijd die je nodig hebt voor het inplannen fors in te korten. Standaard diensten kun je onder het kopje 'Standaard diensten' aanmaken, wijzigen of verwijderen.</div>
                    ),
                },
                {
                    header: 'Standaard tijden',
                    content: (
                        <div>Standaard tijden zijn bedoeld om snel een begin- en eindtijd te selecteren zonder deze opnieuw handmatig in hoeven te vullen. Standaard tijden kun je onder het kopje 'Standaard tijden' aanmaken, wijzigen of verwijderen.</div>
                    ),
                },
                {
                    header: 'Deadlines',
                    content: (
                        <div>Medewerkers moeten voor de deadlinedatum hun beschikbaarheid ingevuld hebben. Je hebt zelf de keuze wat er na de deadline gebeurt. Deadlines kun je onder het kopje 'Deadlines' aanmaken, wijzigen of verwijderen.</div>
                    ),
                },
                {
                    header: 'Geblokkeerde datums',
                    content: (
                        <div>Geblokkeerde datums zijn bedoeld om bepaalde datums te blokkeren bij het invullen van beschikbaarheid. Deze datums kunnen simpelweg niet ingevuld worden. Geblokkeerde datums kun je onder het kopje 'Geblokkeerde datums' aanmaken, wijzigen of verwijderen.</div>
                    ),
                },
                {
                  header: (<div style={{marginBottom: '-1.5vw', fontSize: '1.2vw'}}><u>Registratie</u></div>),
                  content: null,
                },
                {
                    header: 'Medewerker registreren',
                    content: (
                        <div>Je kunt een medewerker zelf registreren of een medewerker zelf laten registreren door middel van een registratiecode. De registratiecode kun je versturen op vele verschillende manieren die automatisch aan je worden aangeboden.<br/><br/>Heb je via de website een registratiecode aangemaakt? Dan kun je bij 'QR-code scannen' binnen de app een QR-code te scannen en de registratiecode automatisch via de mobiel te versturen.</div>
                    ),
                },
                {
                  header: (<div style={{marginBottom: '-1.5vw', fontSize: '1.2vw'}}><u>Mijn account</u></div>),
                  content: null,
                },
                {
                    header: 'Mijn gewerkte uren',
                    content: (
                        <div>Hier kun je jouw bruto uren, netto uren en pauzes inzien. Gebruik de pijlen bovenin om te navigeren.</div>
                    ),
                },
                {
                    header: 'Mijn verlof',
                    content: (
                        <div>Je hebt hier de mogelijkheid om een dag vrij te vragen of een vakantie door te geven. Op deze pagina zie je ook een overzicht van jouw verlofdagen en/of vakanties over de afgelopen 3 maanden.</div>
                    ),
                },
                {
                    header: 'Ruilen',
                    content: (
                        <div>Hier is een overzicht te zien van jouw geruilde diensten, eigen ruilverzoeken en ontvangen ruilverzoeken.</div>
                    ),
                },
                {
                    header: 'Medewerkers',
                    content: (
                        <div>Een overzicht van al het medewerkers in dienst. Wanneer je op een medewerker drukt, kun je zijn of haar gegevens bewerken of verwijderen.</div>
                    ),
                },
                {
                  header: (<div style={{marginBottom: '-1.5vw', fontSize: '1.2vw'}}><u>Instellingen</u></div>),
                  content: null,
                },
                {
                    header: 'Configuratie',
                    content: (
                        <div>Je hebt hier de mogelijkheid om de globale instellingen van de app te veranderen. De opgeslagen instellingen zullen doorgevoerd worden naar jouw medewerkers.</div>
                    ),
                },
                {
                    header: 'Opties',
                    content: (
                        <div>Hier kun je jouw persoonlijke instellingen aanpassen. Deze gelden alleen voor jezelf.</div>
                    ),
                }]

            : Data.data.accountData.account_rights === '1' ?

                [{
                  header: (<div style={{marginBottom: '-1.5vw', fontSize: '1.2vw'}}><u>Management</u></div>),
                  content: null,
                },
                {
                    header: 'Diensten',
                    content: (
                        <div>Standaard diensten zijn bedoeld om de tijd die je nodig hebt voor het inplannen fors in te korten. Standaard diensten kun je onder het kopje 'Standaard diensten' aanmaken, wijzigen of verwijderen.</div>
                    ),
                },
                {
                    header: 'Standaard tijden',
                    content: (
                        <div>Standaard tijden zijn bedoeld om snel een begin- en eindtijd te selecteren zonder deze opnieuw handmatig in hoeven te vullen. Standaard tijden kun je onder het kopje 'Standaard tijden' aanmaken, wijzigen of verwijderen.</div>
                    ),
                },
                {
                    header: 'Deadlines',
                    content: (
                        <div>Medewerker moeten voor de deadlinedatum hun beschikbaarheid ingevuld hebben. Je hebt zelf de keuze wat er na de deadline gebeurt. Deadlines kun je onder het kopje 'Deadlines' aanmaken, wijzigen of verwijderen.</div>
                    ),
                },
                {
                    header: 'Geblokkeerde datums',
                    content: (
                        <div>Geblokkeerde datums zijn bedoeld om bepaalde datums te blokkeren bij het invullen van beschikbaarheid. Deze datums kunnen simpelweg niet ingevuld worden. Geblokkeerde datums kun je onder het kopje 'Geblokkeerde datums' aanmaken, wijzigen of verwijderen.</div>
                    ),
                },
                {
                  header: (<div style={{marginBottom: '-1.5vw', fontSize: '1.2vw'}}><u>Mijn account</u></div>),
                  content: null,
                },
                {
                    header: 'Mijn gewerkte uren',
                    content: (
                        <div>Hier kun je jouw bruto uren, netto uren en pauzes inzien. Gebruik de pijlen bovenin om te navigeren.</div>
                    ),
                },
                {
                    header: 'Mijn verlof',
                    content: (
                        <div>Je hebt hier de mogelijkheid om een dag vrij te vragen of een vakantie door te geven. Op deze pagina zie je ook een overzicht van jouw verlofdagen en/of vakanties over de afgelopen 3 maanden.</div>
                    ),
                },
                {
                    header: 'Ruilen',
                    content: (
                        <div>Hier is een overzicht te zien van jouw geruilde diensten, eigen ruilverzoeken en ontvangen ruilverzoeken.</div>
                    ),
                },
                {
                    header: 'Medewerkers',
                    content: (
                        <div>Een overzicht van al jouw medewerkers.</div>
                    ),
                },
                {
                  header: (<div style={{marginBottom: '-1.5vw', fontSize: '1.2vw'}}><u>Instellingen</u></div>),
                  content: null,
                },
                {
                    header: 'Opties',
                    content: (
                        <div>Hier kun je jouw persoonlijke instellingen aanpassen. Deze gelden alleen voor jezelf.</div>
                    ),
                }]

            :

                [{
                  header: (<div style={{marginBottom: '-1.5vw', fontSize: '1.2vw'}}><u>Mijn account</u></div>),
                  content: null,
                },
                {
                    header: 'Mijn gewerkte uren',
                    content: (
                        <div>Hier kun je jouw bruto uren, netto uren en pauzes inzien. Gebruik de pijlen bovenin om te navigeren.</div>
                    ),
                },
                {
                    header: 'Mijn verlof',
                    content: (
                        <div>Je hebt hier de mogelijkheid om een dag vrij te vragen of een vakantie door te geven. Op deze pagina zie je ook een overzicht van jouw verlofdagen en/of vakanties over de afgelopen 3 maanden.</div>
                    ),
                },
                {
                    header: 'Ruilen',
                    content: (
                        <div>Hier is een overzicht te zien van jouw geruilde diensten, eigen ruilverzoeken en ontvangen ruilverzoeken.</div>
                    ),
                },
                {
                    header: 'Medewerkers',
                    content: (
                        <div>Een overzicht van al jouw medewerkers.</div>
                    ),
                },
                {
                  header: (<div style={{marginBottom: '-1.5vw', fontSize: '1.2vw'}}><u>Instellingen</u></div>),
                  content: null,
                },
                {
                    header: 'Opties',
                    content: (
                        <div>Hier kun je jouw persoonlijke instellingen aanpassen. Deze gelden alleen voor jezelf.</div>
                    ),
                }]
            }
            _tutorialModal={this.state.tutorialModal}
            _history={this.props.history}
            _navBar={this}
          />

        :

          null
        
        }

        {this.state.showTeamsModal === true ?
                
                <TeamsModal 
                  _closeModal={this.closeTeamsModal.bind(this)}
                  _teams={Data.data.ownTeams}
                  _parent={this.props.parent}
                  _liveSwitch={this.props.liveSwitch}
                  history={this.props.history}
                />
  
              :
  
                null
              
              }

        {this.state.showAlert === true ?
                
          <BetterAlert 
            _closeAlert={this.closeAlert.bind(this)}
            _title={this.state.alertTitle}
            _body={this.state.alertBody}
            _buttons={this.state.alertButtons}
            _buttonText={this.state.alertButtonText}
            _buttonAction={this.state.alertButtonAction}
            _canClose={this.state.alertCanClose}
            history={this.props.history}
          />

        :

          null
        
        }

        {/* {this.state.isChrome === false && this.props.fixed !== true && this.state.chromeBannerDismissed === false ?
          <div className='navbar_chromeAlert'>
            <span style={{fontSize: '1vw'}}>Voor de beste ervaring adviseren wij je om Google Chrome of Firefox te gebruiken. <a className='defaultLinkR' href='https://www.google.com/chrome/'>Klik hier</a> voor Chrome of <a className='defaultLinkR' href='https://www.mozilla.org/firefox/download/'>klik hier</a> voor Firefox.</span>
            <span className='navbar_chromeAlert_close' onClick={() => this.closeChromeBanner()}>&#10005;</span>
          </div>
        : null} */}

        <ul className={`navbar ${this.props.fixed === true ? 'fixedNav' : ''}`} ref={this.setWrapperRef}>

          {Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1' ?

            <div>
              {/* <div 
                className={`dropdown ${this.state.active} ${this.state.menuActive === true ? 'menuActive' : ''}`} 
                onClick={() => this.setState({ active: this.state.active === 'active' ? '' : 'active', navMenuActive: !this.state.navMenuActive})}
              >
                <button className="dropbtn">
                  <i className="fal fa-bars navbaricon"></i>
                  <br></br>
                  Menu
                </button>
              </div> */}
              {/* {(Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1') && Data.data.ownTeams.length > 1 ?
              
                <li><div style={{maxWidth: 100, textOverflow: 'ellipsis', overflow: 'hidden'}} onClick={() => this.openTeamsModal()}>
                  <i className="fal fa-user-friends navbaricon"></i>
                  <br></br>
                  <span style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontSize: '0.7vw'}}>{Data.data.chosenTeamObj.team_naam}</span>
                </div></li>

              :

                null
            
              } */}
              <li><div style={{ position: 'relative' }} className={this.props.active === 'home' ? "active2" : ''} onClick={() => this.navigate('home')}>
                {this.state.activeBadge > 0 ?
                  <p className='navbar_notification2_new'>{this.state.activeBadge}</p>
                : null}
                <i className="fal fa-home-alt navbaricon"></i>
                <br></br>
                Actief
              </div></li>
              <li><div style={{ position: 'relative' }} className={this.props.active === 'trials' ? "active2" : ''} onClick={() => this.navigate('trials')}>
              {this.state.trialsBadge > 0 ?
                <p className='navbar_notification2_new'>{this.state.trialsBadge}</p>
              : null}
              <i className="fal fa-hourglass navbaricon"></i>
                <br></br>
                Proefperiode
              </div></li>
              <li><div style={{ position: 'relative' }} className={this.props.active === 'demo' ? "active2" : ''} onClick={() => this.navigate('demo')}>
              {this.state.leadsBadge > 0 ?
                <p className='navbar_notification2_new'>{this.state.leadsBadge}</p>
              : null}
              <i className="fal fa-users navbaricon"></i>
                <br></br>
                Leads
              </div></li>
              <li><div style={{ position: 'relative' }} className={this.props.active === 'archive' ? "active2" : ''} onClick={() => this.navigate('archive')}>
              {/* {this.state.archiveBadge > 0 ?
                <p className='navbar_notification2_new'>{this.state.archiveBadge}</p>
              : null} */}
              <i className="fal fa-box-archive navbaricon"></i>
                <br></br>
                Archief
              </div></li>
              <li><div className={this.props.active === 'mailing' ? "active2" : ''} onClick={() => this.navigate('mailing')}>
              <i className="fal fa-mail-bulk navbaricon"></i>
                <br></br>
                Mailing
              </div></li>
              <li><div className={this.props.active === 'news' ? "active2" : ''} onClick={() => this.navigate('news')}>
              <i className="fal fa-newspaper navbaricon"></i>
                <br></br>
                Nieuws
              </div></li>
              {/* <li><div className={this.props.active === 'webmail' ? "active2" : ''} onClick={() => window.open('https://app.trengo.com/tickets', '_blank').focus()}>
              <i className="fal fa-at navbaricon"></i>
                <br></br>
                Webmail/chat
              </div></li> */}
              <li><div className={this.props.active === 'add' ? "active2" : ''} onClick={() => this.navigate('add')}>
                <i className="fal fa-plus navbaricon"></i>
                <br></br>
                Toevoegen
              </div></li>
              {/* <li><div className={this.props.active === 'transfer' ? "active2" : ''} onClick={() => this.navigate('transfer')}>
                <i className="fal fa-exchange navbaricon"></i>
                <br></br>
                Overstappen
              </div></li> */}
              {/* <li><div className={this.props.active === 'schedule' ? "active2" : ''} onClick={() => this.navigate('schedule')}>
                <i className="fal fa-calendar-alt navbaricon"></i>
                <br></br>
                Rooster
              </div></li> */}
              {/* {Data.data.accountData.account_rights === '2' ?
                <li><div style={{position: 'relative'}} className={this.props.active === 'employees' ? "active2" : ''} onClick={() => this.navigate('employees')}>
                  {this.state.contractEnding === true ?
                    <span className='far fa-file-exclamation navbar_notification2_new' style={{color: Colors.color.redFilledIn, fontSize: '1vw'}} />
                  :
                  this.state.userNotification === true ?
                    <span className='fas fa-exclamation-triangle navbar_notification2_new' style={{color: Colors.color.redFilledIn, fontSize: '1vw'}} />
                  : null}
                  <i className="fal fa-users navbaricon"></i>
                  <br></br>
                  Medewerkers
                </div></li>
              : null}
              {(Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1') ?
                <li><div className={this.props.active === 'requests' ? "active2" : ''} style={{position: 'relative'}} onClick={() => { this.navigate('requests'); localStorage.setItem('@notificationsChecked', 'true'); }}>
                  {this.state.requestNotification === true ?
                    <span className={'navbar_notification2'}/>
                  : null}
                  <i className="fal fa-hands-helping navbaricon"></i>
                  <br></br>
                  Verzoeken
                </div></li>
              : null}
              {(Data.data.accountData.account_rights === '2' || Data.data.accountData.account_rights === '1') && Constants.isTrue(Data.data.appOptions.opties_urenregistratie2) && Constants.isTrue(Data.data.appOptions.opties_uren_daadwerkelijk) ?
                <li><div className={this.props.active === 'hour_registration' ? "active2" : ''} onClick={() => this.navigate('hour_registration')}>
                  <i className="fal fa-user-clock navbaricon"></i>
                  <br></br>
                  Tijdregistratie
                </div></li>
              : null} */}
              {/* {Data.data.accountData.account_rights === '2' ?
                <li><div className={this.props.active === 'absences' ? "active2" : ''} onClick={() => this.navigate('absences')}>
                  <i className="fal fa-user-chart navbaricon"></i>
                  <br></br>
                  Verlof
                </div></li>
              : null} */}
              {/* nav_disabled */}
              {/* {Data.data.accountData.account_rights === '2' && Data.data.appOptions.opties_versie !== '0' ?
                <li><div className={this.props.active === 'reports' ? "active2" : ''} onClick={() => this.navigate('reports')}>
                  <i className="fal fa-analytics navbaricon"></i>
                  <br></br>
                  Overzichten
                </div></li>
              : null}
              <li><div className={this.props.active === 'planner' ? "active2" : ''} onClick={() => this.navigate('planner')}>
                <i className="fal fa-calendar-alt navbaricon"></i>
                <br></br>
                Rooster
              </div></li> */}
              {/* {Data.data.appOptions.clock === true ?
              
                <li><div className={this.props.active === 'active_users' ? "active2" : ''} onClick={() => this.navigate('active_users')}>
                  <i className="fal fa-globe navbaricon"></i>
                  <br></br>
                  Ingeklokte medewerkers
                </div></li>

              :

                null
            
              } */}

            </div>

          :

            <div>
              {/* <div 
                className={`dropdown ${this.state.active} ${this.state.menuActive === true ? 'menuActive' : ''}`} 
                onClick={() => this.setState({ active: this.state.active === 'active' ? '' : 'active', navMenuActive: !this.state.navMenuActive})}
              >
                <button className="dropbtn">
                  <i className="fal fa-bars navbaricon"></i>
                  <br></br>
                  Menu
                </button>
              </div> */}
              <li><div style={{ position: 'relative' }} className={this.props.active === 'home' ? "active2" : ''} onClick={() => this.navigate('home')}>
                {this.state.activeBadge > 0 ?
                  <p className='navbar_notification2_new'>{this.state.activeBadge}</p>
                : null}
                <i className="fal fa-home-alt navbaricon"></i>
                <br></br>
                Actief
              </div></li>
              <li><div style={{ position: 'relative' }} className={this.props.active === 'trials' ? "active2" : ''} onClick={() => this.navigate('trials')}>
              {this.state.trialsBadge > 0 ?
                <p className='navbar_notification2_new'>{this.state.trialsBadge}</p>
              : null}
              <i className="fal fa-hourglass navbaricon"></i>
                <br></br>
                Proefperiode
              </div></li>
              <li><div style={{ position: 'relative' }} className={this.props.active === 'demo' ? "active2" : ''} onClick={() => this.navigate('demo')}>
              {this.state.leadsBadge > 0 ?
                <p className='navbar_notification2_new'>{this.state.leadsBadge}</p>
              : null}
              <i className="fal fa-users navbaricon"></i>
                <br></br>
                Leads
              </div></li>
              <li><div style={{ position: 'relative' }} className={this.props.active === 'archive' ? "active2" : ''} onClick={() => this.navigate('archive')}>
              {/* {this.state.archiveBadge > 0 ?
                <p className='navbar_notification2_new'>{this.state.archiveBadge}</p>
              : null} */}
              <i className="fal fa-box-archive navbaricon"></i>
                <br></br>
                Archief
              </div></li>
              <li><div className={this.props.active === 'mailing' ? "active2" : ''} onClick={() => this.navigate('mailing')}>
              <i className="fal fa-mail-bulk navbaricon"></i>
                <br></br>
                Mailing
              </div></li>
              <li><div className={this.props.active === 'news' ? "active2" : ''} onClick={() => this.navigate('news')}>
              <i className="fal fa-newspaper navbaricon"></i>
                <br></br>
                Nieuws
              </div></li>
              {/* <li><div className={this.props.active === 'webmail' ? "active2" : ''} onClick={() => window.open('https://app.trengo.com/tickets', '_blank').focus()}>
              <i className="fal fa-at navbaricon"></i>
                <br></br>
                Webmail/chat
              </div></li> */}
              <li><div className={this.props.active === 'add' ? "active2" : ''} onClick={() => this.navigate('add')}>
                <i className="fal fa-plus navbaricon"></i>
                <br></br>
                Toevoegen
              </div></li>
              {/* <li><div className={this.props.active === 'transfer' ? "active2" : ''} onClick={() => this.navigate('transfer')}>
                <i className="fal fa-exchange navbaricon"></i>
                <br></br>
                Overstappen
              </div></li> */}
              {/* {Data.data.appOptions.availability !== 'NONE' ?

                <li><div className={this.props.active === 'availability' ? "active2" : ''} onClick={() => Data.data.appOptions.availability === 'AVAILABILITY' ? this.navigate('availability') : Data.data.appOptions.availability === 'SCHOOL' ? this.navigate('availabilitySchool') : this.navigate('availability')}>
                  <i className="fal fa-calendar-plus navbaricon"></i>
                  <br></br>
                  {Data.data.appOptions.availability === 'UNAVAILABILITY' ?
                    'Onbeschikbaarheid'
                  :
                    'Beschikbaarheid'
                  }
                </div></li>
              
              : null} */}
              {/* <li><div className={this.props.active === 'schedule' ? "active2" : ''} onClick={() => this.navigate('schedule')}>
                <i className="fal fa-calendar-alt navbaricon"></i>
                <br></br>
                Rooster
              </div></li> */}

            </div>
          
          }

          <li className='logout'><div onClick={() => this.logoutAlert()}>
            <i className="fal fa-sign-out-alt navbaricon"></i>
            <br></br>
            Uitloggen
          </div></li>

          {/* {Data.data.accountData.account_rights === '2' ?
            <li className='logout'><div className={this.props.active === 'app_options' ? "active2" : ''} onClick={() => this.navigate('app_options')}>
              {this.state.adminBadge === true ?
                <span className='fas fa-exclamation-triangle navbar_notification2_new' style={{color: Colors.color.redFilledIn, fontSize: '1vw'}} />
              : null}
              <i className="fal fa-cogs navbaricon"></i>
              <br></br>
              Configuratie
            </div></li>
          : null} */}

          {/* {Data.data.storeLogo !== null ?
            <div className='logout' style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '1vw'}}>
              <img src={`https://${Data.data.serverIP}/u/${Data.data.storeCode}/${Data.data.storeLogo}`} className='navbar_logo' />
            </div>
          : null} */}

        </ul>
      </div>
    );
  }
  
}

export default Navbar;