import React, {useState, useEffect, useCallback, useRef} from 'react';
import {toast} from 'react-toastify';
import './emailTemplateModal.css';

const EmailTemplateModal = ({isOpen, onClose, templates, onUpload, onRemove}) => {
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [file, setFile] = useState(null);
    const modalRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setSelectedTemplates([]);
            setFile(null);
        }
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    const handleRowClick = useCallback((templateName) => {
        setSelectedTemplates(prev =>
            prev.includes(templateName)
                ? prev.filter(name => name !== templateName)
                : [...prev, templateName]
        );
    }, []);

    const handleFileChange = useCallback((event) => {
        setFile(event.target.files[0]);
    }, []);

    const handleUpload = useCallback(async () => {
        if (!file) {
            toast.error('Selecteer een bestand om te uploaden');
            return;
        }
        await onUpload(file);
        setFile(null);
    }, [file, onUpload]);

    const handleRemove = useCallback(async () => {
        if (selectedTemplates.length === 0) {
            toast.error('Selecteer ten minste één sjabloon om te verwijderen');
            return;
        }
        await onRemove(selectedTemplates);
        setSelectedTemplates([]);
    }, [selectedTemplates, onRemove]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content" ref={modalRef}>
                <h2>E-mailsjablonen beheren</h2>
                <div className="upload-section">
                    <input
                        type="file"
                        accept=".html"
                        onChange={handleFileChange}
                        aria-label="Selecteer een HTML-bestand om te uploaden"
                    />
                    <button onClick={handleUpload}>Sjabloon uploaden</button>
                </div>
                <table className="template-table">
                    <thead>
                    <tr>
                        <th>Selecteren</th>
                        <th>Sjabloonnaam</th>
                        <th>Uploaddatum</th>
                    </tr>
                    </thead>
                    <tbody>
                    {templates.map((template) => (
                        <tr
                            key={template.name}
                            onClick={() => handleRowClick(template.name)}
                            className={selectedTemplates.includes(template.name) ? 'selected' : ''}
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedTemplates.includes(template.name)}
                                    onChange={() => {
                                    }} // Om React-waarschuwing te voorkomen
                                    aria-label={`Selecteer ${template.name}`}
                                />
                            </td>
                            <td>{template.name}</td>
                            <td>{new Date(template.upload_date).toLocaleString('nl-NL')}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="modal-actions">
                    <button onClick={handleRemove} className="remove-button">Geselecteerde verwijderen</button>
                    <button onClick={onClose}>Sluiten</button>
                </div>
            </div>
        </div>
    );
};

export default EmailTemplateModal;