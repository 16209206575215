


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIRegisterAddRegistrationCode {

    async Request(code,email,phone,rights, user, fid, startDate) {

        user = user !== null ? user.toLowerCase() : null;

        phone = (phone !== null && phone !== '') ? phone.replace(/\s*$/,"") : phone;
        email = (email !== null && email !== '') ? email.replace(/\s*$/,"") : email;
        
        const hash = CryptoJS.MD5(code + Data.data.secretKey).toString();

        let response = null;

        try {
            const string = `https://${Data.data.serverIP}/phpsepcalls/registerAddRegistrationCode.php/`;
            response = await fetch(string, {
                method: 'post',
                body: new URLSearchParams(`DB=${Data.data.storeCode}&code=${code}&email=${email}&phone=${phone}&rights=${rights}&user=${user}&fid=${fid}&startDate=${startDate}&hash=${hash}`)
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.text(); //or response.text() //response.json()
        } catch(err) {
        }


        if (sqlData === "1") {

            return true;

        } else if (sqlData === "0") {

            return false;

        } else {

            return false; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIRegisterAddRegistrationCode());