


import CryptoJS from 'crypto-js';

import Data from '../../constants/Data';

class APIGET {

    async Request(select, from, join, where, group, order, storecode = Data.data.storeCode) {
        
        const username = Data.data.accountData.account_username;
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        where = encodeURIComponent(where);

        try {
            response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/GET.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${storecode}&user=${username}&select=${select}&from=${from}&join=${join}&where=${where}&group=${group}&order=${order}&hash=${hash}`)
            });
        } catch(err) {
            // console.log(err);
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
            let response2 = null;
            try {
                response2 = await fetch(`https://${Data.data.serverIP}/phpsepcalls/GET.php/`, {
                    method: 'post',
                    body: new URLSearchParams(`DB=${storecode}&user=${username}&select=${select}&from=${from}&join=${join}&where=${where}&group=${group}&order=${order}&hash=${hash}`)
                });
            } catch(err) {
                // console.log(err);
                return 'error';
            }
            const error = await response2.text();
            // console.log(error);
        }

        if (sqlData !== null) {

            return sqlData;

        } else if (sqlData === null) {

            return [];

        } else {

            return []; //data

        }
        //return null;
    }

    async webView(select, from, join, where, group, order, storecode, serverIP) {
        
        const username = 'webviewtiemdo';
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        where = encodeURIComponent(where);

        try {
            response = await fetch(`https://${serverIP}/phpsepcalls/GET.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${storecode}&user=${username}&select=${select}&from=${from}&join=${join}&where=${where}&group=${group}&order=${order}&hash=${hash}`)
            });
        } catch(err) {
            // console.log(err);
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
            let response2 = null;
            try {
                response2 = await fetch(`https://${serverIP}/phpsepcalls/GET.php/`, {
                    method: 'post',
                    body: new URLSearchParams(`DB=${storecode}&user=${username}&select=${select}&from=${from}&join=${join}&where=${where}&group=${group}&order=${order}&hash=${hash}`)
                });
            } catch(err) {
                // console.log(err);
                return 'error';
            }
            const error = await response2.text();
            // console.log(error);
        }

        if (sqlData !== null) {

            return sqlData;

        } else if (sqlData === null) {

            return [];

        } else {

            return []; //data

        }
        //return null;
    }
  
}
  
// singleton
export default (new APIGET());