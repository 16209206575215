


import CryptoJS from 'crypto-js';

import Data from '../constants/Data';

class APIAddDefaultDayShift {

    async Request(day, start, end, fid, brk, temp = 1) {

        const username = Data.data.accountData.account_username;
        
        const hash = CryptoJS.MD5(username + Data.data.secretKey).toString();

        let response = null;

        try {
            response = await fetch(`https://${Data.data.serverIP}/phpsepcalls/addDefaultDayShift2.php/`, {
                method: 'post',
                body: new URLSearchParams(`DB=${Data.data.storeCode}&user=${username}&day=${day}&start=${start}&end=${end}&fid=${fid}&brk=${brk}&temp=${temp}&hash=${hash}`) //&temp=${temp}
            });
        } catch(err) {
            return 'error';
        }

        let sqlData = null;

        try {
            sqlData = await response.json(); //or response.text() //response.json()
        } catch(err) {
        }

        if (sqlData !== null) {

            return parseInt(sqlData.dagdienst_id);

        } else if (sqlData === null) {

            return null;

        } else {

            return false; //data

        }
        //return null;
        }
  
}
  
// singleton
export default (new APIAddDefaultDayShift());